import "./VerticalDivider.scss";
import { Box } from "@material-ui/core";

interface Props {
    height?: string;
    mx?: number;
}

const VerticalDivider = (props: Props) => {
    const { height, mx } = props;
    const createStyles = () => {
        if (!height) return {};
        return {
            height: height,
        };
    };
    return (
        <Box mx={mx ?? 0}>
            <aside
                className="divider vertical-divider"
                style={createStyles()}></aside>
        </Box>
    );
};

export default VerticalDivider;
