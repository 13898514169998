import { ManualActionRequestSource } from "../manual-actions/ManualActionRequestSource";
import { AgencyMode } from "./attributes-types/AgencyMode";
import { AllVendorsCap } from "./attributes-types/AllVendorsCap";
import { ApplicantLimitPerJob } from "./attributes-types/ApplicantLimitPerJob";
import { ApplicantLimitPerPriorityJob } from "./attributes-types/ApplicantLimitPerPriorityJob";
import { AutoPilot } from "./attributes-types/AutoPilot";
import { AvoidSoftLaunchJobsCarouselCAP } from "./attributes-types/AvoidSoftLaunchJobsCarouselCAP";
import { BaseAttribute } from "./attributes-types/BaseAttribute";
import { BudgetLimitPerJob } from "./attributes-types/BudgetLimitPerJob";
import { BudgetLimitPerPriorityJob } from "./attributes-types/BudgetLimitPerPriorityJob";
import { CraiglistBudget } from "./attributes-types/CraiglistBudget";
import { EAppGoalMode } from "./attributes-types/EAppGoalMode";
import { EPacingMode } from "./attributes-types/EPacingMode";
import { EPerMode } from "./attributes-types/EPerMode";
import { ExpressWay } from "./attributes-types/ExpressWay";
import { Holdback } from "./attributes-types/HoldBack";
import { IndeedCap } from "./attributes-types/IndeedCap";
import { ManualDistribution } from "./attributes-types/ManualDistribution";
import { PacingMode } from "./attributes-types/PacingMode";
import { ReachAppGoalMode } from "./attributes-types/ReachAppGoalMode";



export interface CompanyAttributesAggregation {
    [key: string]: BaseAttribute | undefined;
    companyId?: any;
    userId?: any;
    allVendorsCap?: AllVendorsCap;
    applicantLimitPerJob?: ApplicantLimitPerJob;
    budgetLimitPerJob?: BudgetLimitPerJob;
    craiglistBudget?: CraiglistBudget;
    indeedCap?: IndeedCap;
    manualDistribution?: ManualDistribution;
    pacingMode?: PacingMode;
    reachAppGoalMode?: ReachAppGoalMode;
    agencyMode?: AgencyMode;
    holdback?: Holdback;
    expressWay?: ExpressWay;
    autoPilot?: AutoPilot;
    avoidSoftLaunchJobsCarouselCAP?: AvoidSoftLaunchJobsCarouselCAP;
    budgetLimitPerPriorityJob?: BudgetLimitPerPriorityJob;
    applicantLimitPerPriorityJob?: ApplicantLimitPerPriorityJob;
}


export function initializeAttributesIfNeeded(attributes: CompanyAttributesAggregation): CompanyAttributesAggregation {
    return {
        ...attributes,
        autoPilot: attributes.autoPilot ?? { isAutoPilot: false, isEdited: false },
        expressWay: attributes.expressWay ?? { isExpressWay: false, isEdited: false },
        holdback: attributes.holdback ?? { isHoldback: false, isEdited: false },
        agencyMode: attributes.agencyMode ?? { cap: 0, isEdited: false },
        reachAppGoalMode: attributes.reachAppGoalMode ?? { appGoalMode: EAppGoalMode.NoStop, isEdited: false },
        pacingMode: attributes.pacingMode ?? { pacingModeType: EPacingMode.None, isEdited: false },
        manualDistribution: attributes.manualDistribution ?? { stopDistribution: false, isEdited: false },
        indeedCap: attributes.indeedCap ?? { cap: 0, isOriginalsOnly: false, isEdited: false },
        craiglistBudget: attributes.craiglistBudget ?? { budget: 0, isEdited: false },
        budgetLimitPerJob: attributes.budgetLimitPerJob ?? { limit: 0, mode: EPerMode.PerCycle, requestSource: ManualActionRequestSource.None, isEdited: false, },
        applicantLimitPerJob: attributes.applicantLimitPerJob ?? { limit: 0, mode: EPerMode.PerCycle, requestSource: ManualActionRequestSource.None, isEdited: false, },
        allVendorsCap: attributes.allVendorsCap ?? { cap: 0, isOriginalsOnly: false, isEdited: false },
        avoidSoftLaunchJobsCarouselCAP: attributes.avoidSoftLaunchJobsCarouselCAP ?? { isAvoidSoftLaunchJobsCarouselCAP: true, isEdited: false },
        budgetLimitPerPriorityJob: attributes.budgetLimitPerPriorityJob ?? { isPriorityLimit: false, limit: 0, mode: EPerMode.PerCycle, requestSource: ManualActionRequestSource.None, isEdited: false },
        applicantLimitPerPriorityJob: attributes.applicantLimitPerPriorityJob ?? { isPriorityLimit: false, limit: 0, mode: EPerMode.PerCycle, requestSource: ManualActionRequestSource.None, isEdited: false }
    };
}