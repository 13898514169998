import { History } from "history";
import { IoMdCut } from "react-icons/io";
import { SiIndeed } from "react-icons/si";
import { CgData } from "react-icons/cg";
import { MdGroupWork } from "react-icons/md";
import { FiLayers } from "react-icons/fi";

import { RoutesPaths } from "../../../constants/RoutePaths";
import { ActionsBuilder } from "../../general/supervision-table/types";
import { Company } from "../../../models/operation/Company";
import { CompanyTableDto } from "./CompanyTable.dto";


type CompaniesTableActionsContext = {
    history: History<unknown>;
}

export const CompaniesTableActionsBuilder: ActionsBuilder<CompanyTableDto, CompaniesTableActionsContext> =
    ({ history }) => [
        {
            text: "Account Settings",
            action: (company) => {
                if (!history) {
                    throw new Error("history doesn't exist in context... Can't perform redirect");
                }

                history.push(
                    RoutesPaths.Supervision.CompanyTrackSystemRoutes.BuildCompanyRoute(company.companyId)
                );
            },
            Icon: CgData,
            isDisplayed: () => true
        },
        {
            text: "Vendor Management",
            action: (company) => {
                if (!history) {
                    throw new Error("history doesn't exist in context... Can't perform redirect");
                }

                history.push(
                   RoutesPaths.Supervision.VendorManagementRoutes.BuildCompanyVendorManagementRoute(company.companyId)
                  // RoutesPaths.PublisherSelection.ChooseCutOff
                );
            },
            Icon: IoMdCut,
            isDisplayed: () => true
        },
        {
            text: "Company Attributes",
            action: (company) => {
                if (!history) {
                    throw new Error("history doesn't exist in context... Can't perform redirect");
                }

                history.push(
                   RoutesPaths.Supervision.AttributesManagementRoutes.BuildCompanyAttributesRouteManagementRoute(company.companyId)
                );
            },
            Icon: FiLayers,
            isDisplayed: () => true
        }
    ]