import { VendorManagementStore } from "../../../../stores/VendorManagementStore";
import companyVendorService from "../../../../services/vendor-management/CompanyVendorService";
import { RoutesPaths } from "../../../../constants/RoutePaths";
import { message } from "antd";
import affiliateVendorService from "../../../../services/vendor-management/AffiliateVendorService";
import groupVendorService from "../../../../services/vendor-management/GroupVendorService";



export const VendorManagmentStateContextInitializers = {


  InitializeGroupSettingsData: async (
    groupId: number,
    vendorManagementStore: VendorManagementStore,
    history: any
  ) => {
    try {
      const _groupSettingsData = await groupVendorService.GetGroupSettingsAsync(
        groupId
      );
      vendorManagementStore.SetEntityState(_groupSettingsData);
    } catch (err) {
      history.push(RoutesPaths.Supervision.Affiliates);
      message.error(
        `Group with ID: ${groupId} doesn't exist. Returned to home page`
      );
    }
  },

    InitializeCompanySettingsData: async (
        companyId: number,
        vendorManagementStore: VendorManagementStore,
        history: any
      ) => {
        try {
          const _companySettingsData = await companyVendorService.GetCompanySettingsAsync(
            companyId
          );
          vendorManagementStore.SetEntityState(_companySettingsData);
        } catch (err) {
          history.push(RoutesPaths.Supervision.Affiliates);
          message.error(
            `Account with ID: ${companyId} doesn't exist. Returned to home page`
          );
        }
      },

      InitializeAffiliateSettingsData: async (
        affiliateId: number,
        vendorManagementStore: VendorManagementStore,
        history: any
      ) => {
        try {
          const _affiliateStateData = await affiliateVendorService.GetAffiliateSettingsAsync(
            affiliateId
          );
          vendorManagementStore.SetEntityState(_affiliateStateData);
        } catch (err) {
          history.push(RoutesPaths.Supervision.Affiliates);
          message.error(
            `Affiliate with ID: ${affiliateId} doesn't exist. Returned to home page`
          );
        }
      },
    
}