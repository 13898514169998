import { Campaign } from "../../models/operation/Campaign";
import { Company } from "../../models/operation/Company";
import { BaseService } from "../BaseService";

export class CompanyService extends BaseService {
  
  async GetCompanyById(companyId: number): Promise<Company> {
    const company = await this.Get<Company>(`/${companyId}`);
    return company;
  }

  async GetCompanyByCampaignId(campaignId: number): Promise<Company> {
    const company = await this.Get<Company>(`/by-campaign/${campaignId}`);
    return company;
  }

  /**
   * Retrieves all companies with at least one active campaigns
   */
  async GetCompaniesWithActiveCampaigns(): Promise<Company[]> {
    const companies = await this.Get<Company[]>("/all");
    return companies;
  }

  async GetManyByIdsAsync(GetManyByIdsRequest: {
    ids: number[];
  }): Promise<Company[]> {
    const companies = await this.Post<Company[]>(
      "/get-multiple-by-ids",
      GetManyByIdsRequest
    );
    return companies;
  }
}

const companyService = new CompanyService("Companies");
export default companyService;
