import { Col, Row, Select, Space, Typography } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { spacingUnit } from "../../assets/styles/theme";
import { EntityVendorResponse } from "../../models/vendor-management/EntityVendorResponse";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { Vendor } from "../../models/explainability/Vendor";
import { JobSerachingType } from "../../models/explainability/JobSerachingType";


const { Option } = Select;

interface Props {
    vendors: Vendor[]
}


const VendorSelect = (props: Props) => {
    const { vendors } = props;
    const [defaultValue, setdefaultValue] = useState<string[]>();
    const { explainabilityStore } = useContext(RootStoreContext);
    const [filterValue, setFilterValue] = useState<string>('');
    const [filteredOptions, setFilteredOptions] = useState<Vendor[]>([]);


    useEffect(() => {
        const defaultValue =  explainabilityStore.SearchedVendors
            .map((vendor) =>
                BuildOptionValue(vendor.vendor_name, vendor.vendor_id)) ;

        setdefaultValue(defaultValue);
        setFilteredOptions(filteredOptions.length ? filteredOptions : vendors);

    }, [explainabilityStore.SearchedVendors]);


    const handleFilter = (inputValue: string) => {
        setFilterValue(inputValue);
        const filtered = vendors.filter(
            (vendor) =>
                vendor.vendor_name.toLowerCase().includes(inputValue.toLowerCase()) ||
                vendor.vendor_id.toString().includes(inputValue)
        );
        setFilteredOptions(filtered);
    };

    const handleBlur = () => {
        setFilteredOptions(vendors)
    };
    /*if (!entityVendors || entityVendors.length == 0 ||
        !vendorManagementStore.SearchedVendors ||vendorManagementStore.SearchedVendors.length == 0) {
        return <Loader position="normal" />;
    }*/

    return (
        <section style={{ margin: `${spacingUnit * 2}px 0` }}>
                <Select
                    disabled= {explainabilityStore.SelectedJobSerachingType != JobSerachingType.JobVendorState}
                    mode="multiple"
                    maxTagCount={1}
                    allowClear
                    value={defaultValue}
                    placeholder=" Search Vendor(s)"
                    style={{ width: "300px" }}
                    onSearch={handleFilter}
                    onBlur={handleBlur}
                    onChange={async (selectedOptionValues: string[]) => {
                        const vendoIds = selectedOptionValues.map(
                            (vendor) => ParseVendorId(vendor)
                        );
                        explainabilityStore.SetSearchedVendors(vendoIds);
                    }}>
                    {filteredOptions.map((vendorsPartial) => (
                        <Option
                            key={vendorsPartial.vendor_id}
                            value={BuildOptionValue(
                                vendorsPartial.vendor_name,
                                vendorsPartial.vendor_id
                            )}>
                            {vendorsPartial.vendor_name} - {vendorsPartial.vendor_id}
                        </Option>
                    ))}
                </Select>
        </section>
    );
};

export default observer(VendorSelect);

const separator = "##";

const BuildOptionValue = (vendorName: string, vendorId: number) => {
    return `${vendorName}${separator}${vendorId}`;
};

const ParseVendorId = (vendor: string) => {
    return Number(vendor.split(separator)[1]);
};