import { JobVendorState } from "../../models/explainability/JobVendorState";
import { ISODateTime } from "../../types/types";
import { TableDto } from "../general/supervision-table/Table.dto";

export class JobVendorStateDto extends TableDto 
{
    public job_id?: number;
    public vendor_id?: number;
    public vendor_name?: string;
    public is_cut_off?: string;
    public reason_name?: string;
    public user_name?: string;
    public last_modified?: ISODateTime


    constructor(jobVendorState?: JobVendorState)
    {
        super();
        this.job_id = jobVendorState?.job_id;
        this.user_name = jobVendorState?.user_name;
        this.last_modified = jobVendorState?.last_modified;
        this.is_cut_off = jobVendorState?.is_cut_off;
        this.reason_name = jobVendorState?.reason_name;
        this.vendor_id = jobVendorState?.vendor_id;
        this.vendor_name = jobVendorState?.vendor_name;

    }
    



}