import { IndeedBudgetFirstMonthBehavior } from "../../models/indeed-manager/IndeedBudgetFirstMonthBehavior";
import { IndeedCampaign } from "../../models/indeed-manager/IndeedCampaign";
import {
  INDEED_CAMPAIGN_OBJECTIVE_DEFAULT_VALUE,
  IndeedCampaignObjectiveType
} from "../../models/indeed-manager/IndeedCampaignObjectiveType";
import { IndeedCampaignStatus } from "../../models/indeed-manager/IndeedCampaignStatus";
import { ISODateTime } from "../../types/types";
import { FormatDate } from "../../utils/DateHelpers";
import { TableDto } from "../general/supervision-table/Table.dto";

export class IndeedCampaignTableDto extends TableDto {
    constructor(
        public id: string,
        public name: string,
        public sourceName: string,
        public status: string,
        public budget: string,
        public objective: string,
        public startDate: ISODateTime,
        public endDate: ISODateTime,
        public budgetFirstMonthBehavior: string,
        public user: string

    ) {
        super();
     }

    public static MapFromIndeedCampaign(indeedCampaign: IndeedCampaign) {
        return new IndeedCampaignTableDto(
            indeedCampaign.id,
            indeedCampaign.name,
            indeedCampaign.indeedCampaignSourceInfo.sourceName ?? "N.A",
            IndeedCampaignStatus[indeedCampaign.status],
            `$${indeedCampaign.campaignBudget ?? 0}`,
            displayObjective(indeedCampaign),
            FormatDate(indeedCampaign.startDate),
            indeedCampaign.endDate ? FormatDate(indeedCampaign.endDate) : "N.A",
            indeedCampaign.budgetFirstMonthBehavior ? IndeedBudgetFirstMonthBehavior[indeedCampaign.budgetFirstMonthBehavior] : "N.A",
            indeedCampaign.userName

        )
    }
}

function displayObjective(indeedCampaign: IndeedCampaign): string {
    if (indeedCampaign.campaignObjectiveType == IndeedCampaignObjectiveType.TARGET_APPLICATIONS) {
        return `${indeedCampaign.campaignObjectiveTarget ?? 0} - Applications Goal`
    }

    if (indeedCampaign.campaignObjectiveType == IndeedCampaignObjectiveType.TARGET_COST_PER_APPLICATION) {
        return `${indeedCampaign.campaignObjectiveTarget ?? 0} - CPA Goal`
    }

    const objectiveType = indeedCampaign.campaignObjectiveType ? IndeedCampaignObjectiveType[indeedCampaign.campaignObjectiveType] : IndeedCampaignObjectiveType[INDEED_CAMPAIGN_OBJECTIVE_DEFAULT_VALUE];
    return objectiveType;
}
