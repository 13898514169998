import { RoutesPaths } from "../../../../constants/RoutePaths";
import { BreadCrumb } from "../../../../models/BreadCrumb";



export const VendorManagmentBreadCrumbs =
{
    AffiliatVendor: (affiliateId: number) => {
        return new BreadCrumb(
            "Vendor-Management",
            RoutesPaths.Supervision.VendorManagementRoutes.BuildAffiliateVendorManagementRoute(affiliateId),
            null
        );
    },

    CompanyVendor: (companyId: number) => {
        return new BreadCrumb(
            "Vendor-Management",
            RoutesPaths.Supervision.VendorManagementRoutes.BuildCompanyVendorManagementRoute(companyId),
            null
        );
    },

    CampaignVendor: (campaignId: number) => {
        return new BreadCrumb(
            "Vendor-Management",
            RoutesPaths.Supervision.VendorManagementRoutes.BuildCampaignVendorManagementRoute(campaignId),
            null
        );
    },

    GroupVendor: (campaignId: number, groupId: number) => {
        return new BreadCrumb(
            "Vendor-Management",
            RoutesPaths.Supervision.VendorManagementRoutes.BuildGroupVendorManagementRoute(campaignId, groupId),
            null
        );
    },



}

export default VendorManagmentBreadCrumbs;