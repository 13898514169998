import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router";

import { RoutesPaths } from "../constants/RoutePaths";
import { RootStoreContext } from "../stores/RootStoreContext";

export type ProtectedRouteProps = {} & RouteProps;

const PrivateRoute = ({ ...routeProps }: ProtectedRouteProps) => {
    const { userStore } = useContext(RootStoreContext);
    if (userStore.User) {
        return <Route {...routeProps} />;
    } else {
        return <Redirect to={{ pathname: RoutesPaths.Root.Login }} />;
    }
};

export default observer(PrivateRoute);
