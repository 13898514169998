import settings from "../../config/AppSettings/SettingsFactory";
import { ManualActionRequestSource } from "../../models/manual-actions/ManualActionRequestSource";
import { EntityVendorRequest } from "../../models/vendor-management/EntityVendorRequest";
import { EntityVendorResponse } from "../../models/vendor-management/EntityVendorResponse";
import { BaseService } from "../BaseService";



export class CampaignVendorService extends BaseService{

    async GetAllAsync(campaignId: number) {
        const vendorManagementdata = await this.Get<EntityVendorResponse[]>(`/all/${campaignId}`);
        return vendorManagementdata;
      }
      
    async SetCampaignsVendors(vendorsWithDecision: EntityVendorRequest[] , campaignId: number, manualActionRequestSource: ManualActionRequestSource) {
    
      const vendorManagementdata = await this.Post<EntityVendorRequest[]>(`/${campaignId}?manualActionRequestSource=${manualActionRequestSource}`, vendorsWithDecision);
      return vendorManagementdata;
    }
}

const campaignVendorService = new CampaignVendorService("CampaignVendors", settings.vendorManagementUrl);
export default campaignVendorService;