import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@material-ui/core";

const PublisherSelectionDialog = () => {
    const { uiStore, publisherSelectionStore } = useContext(RootStoreContext);

    /**
     * Functions
     */
    const handleClose = () => {
        publisherSelectionStore.DialogBox.Reset();
    };

    const HandleConfirm = () => {
        if (
            !publisherSelectionStore.SelectedPublisherActionType ||
            !publisherSelectionStore.SelectedVendor
        ) {
            throw new Error(
                "Cant confirm because either vendor or action type isn't selected"
            );
        }

        publisherSelectionStore.HandleVendorAction(
            publisherSelectionStore.SelectedPublisherActionType,
            publisherSelectionStore.SelectedVendor
        );

        
        publisherSelectionStore.DialogBox.Reset();
    };

    return (
        <Dialog
            open={publisherSelectionStore.DialogBox.IsOn}
            onClose={handleClose}
            aria-labelledby="publisher-selection-dialog">
            <DialogTitle id="publisher-selection-dialog">
                <Typography variant="h4">Warning</Typography>
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="publisher-selection-dialog-description">
                    <Typography variant="h6">
                        <Box component="strong" style={{ color: "#ff1744" }}>
                            {publisherSelectionStore.DialogBox.actionType}
                        </Box>{" "}
                        <strong style={{ color: "#1769aa" }}>
                            {" "}
                            {publisherSelectionStore.DialogBox.Vendor
                                ?.vendorName ?? ""}
                        </strong>{" "}
                        FOR{" "}
                        <strong style={{ color: "#1769aa" }}>
                            {publisherSelectionStore.SelectedEntity?.entityName}
                        </strong>
                    </Typography>
                    <Typography variant="h6" style={{ marginTop: "20px" }}>
                        The following rules will be overriden:
                    </Typography>
                    <div>
                        <List>
                            {publisherSelectionStore.DialogBox.Conflicts.map(
                                (conflict) => (
                                    <ListItem>
                                        <ListItemText primary={conflict} />
                                    </ListItem>
                                )
                            )}
                        </List>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Disagree
                </Button>
                <Button onClick={HandleConfirm} color="primary" /* autoFocus */>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default observer(PublisherSelectionDialog);
