import { useContext } from "react";
import { useHistory } from "react-router";

import { Box } from "@material-ui/core";

import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
} from "antd";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { GroupResponse } from "../../models/groups/GroupResponse";
import { GroupStatus } from "../../models/groups/GroupStatus";
import { FetchError } from "../../exceptions/AlgoSupervisionExceptions";
import { RoutesPaths } from "../../constants/RoutePaths";
import { GroupUpdateRequest } from "../../models/groups/GroupUpdateRequest";
import { GroupService } from "../../services/groups/GroupsService";
import { BudgetType } from "../../models/groups/BudgetType";
import { GroupBudgetRequest } from "../../models/groups/GroupBudgetRequest";
import { GroupBudgetBalance } from "../../models/groups/GroupBudgetBalance";

const { Option } = Select;

type FormState = {
  groupName: string;
  isActive: GroupStatus;
  budget: number;
  budgetType: BudgetType;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface Props {
  campaignId: number;
  group: GroupResponse;
  groupBudgetBalance: GroupBudgetBalance;
}

const groupsService = new GroupService("Groups");

const GroupsEditForm = (props: Props) => {
  const { campaignId, group, groupBudgetBalance } = props;
  const { userStore } = useContext(RootStoreContext);
  const history = useHistory();

  const onFinish = async (formState: FormState) => {
    const changedGroupSettings = GetChangedGroupSettings(
      formState,
      initialValues
    );

    const changedGroupBudget = GetChangedGroupBudget(
      formState,
      initialValues
    );

    if (IsEmptyObject(changedGroupSettings) && IsEmptyObject(changedGroupBudget)) {
      message.info("Nothing changed in group settings.");
      return;
    }

    let hasErrorOccured = false;

    if (!IsEmptyObject(changedGroupSettings)) {
      const updateGroupRequest: GroupUpdateRequest = {
        groupId: group.groupId,
        campaignId: campaignId,
        groupName: formState.groupName,
        isActive: formState.isActive
      };
      try {
        await groupsService.UpdateGroup(group.groupId, updateGroupRequest);
        message.success("Group details updated successfully");
      } catch (error) {
        if (error instanceof FetchError) {
          message.error(error.apiError.errorMessage);
        }
        hasErrorOccured = true;
      }
    }

    if (!IsEmptyObject(changedGroupBudget)) {
      const updateGroupRequest: GroupBudgetRequest = {
        groupId: group.groupId,
        id: group.groupBudget.id,
        amount: Number(formState.budget),
        budgetType: formState.budgetType,
        campaignId: campaignId
      };
      console.log(Number(formState.budget));
      try {
        await groupsService.UpdateGroupBudget(group.groupId, updateGroupRequest);
        message.success("Group budget updated successfully");
      } catch (error) {
        if (error instanceof FetchError) {
          message.error(error.apiError.errorMessage);
        }
        hasErrorOccured = true;
      }
    }

    if (!hasErrorOccured) {
      history.push(
        RoutesPaths.Supervision.GroupsRoutes.BuildGroupRoute(
          campaignId
        )
      );
    }
  };

  const initialValues: FormState = {
    groupName: group.groupName,
    isActive: group.isActive ? GroupStatus.Active : GroupStatus.Removed,
    budget: group.groupBudget?.amount,
    budgetType: group.groupBudget?.budgetType
  };

  return (
    <Box width={650}>
      <Form
        initialValues={initialValues}
        {...layout}
        name="Edit Group"
        onFinish={onFinish}
      >
        <Row style={{ marginBottom: "5px" }}>
          <Col offset={2}>
            <Typography.Title level={4}>General Details</Typography.Title>
          </Col>
        </Row>

        <Form.Item
          name={"groupName"}
          label="Group Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={"isActive"}

          label="Status"
          rules={[{ required: true }]}
        >
          <Select style={{ width: "100%" }} disabled={true}>
            <Option value={GroupStatus.Active}>Active</Option>
            <Option value={GroupStatus.Removed}>Removed</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="budgetType"
          label="Budget Type"
          rules={[{ required: false }]}
        >
          <Select allowClear>
            <Option value={BudgetType.Capped}>Capped</Option>
            <Option value={BudgetType.Fixed}>Fixed</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="budget"
          label="Budget"
          dependencies={['budgetType']}
          rules={[
            {
              required: false,

            },
            ({ getFieldValue }) => ({
              validator(ruleObject, value, err) {
                if (value < 0) {
                  return Promise.reject(
                    "Value must be greater than 0"
                  );
                }
                if (value > groupBudgetBalance.campaignBalance && getFieldValue("budgetType") == BudgetType.Fixed) {
                  return Promise.reject(
                    `Value must be equal or less than ${groupBudgetBalance.campaignBalance}`
                  );
                }
                if (value > groupBudgetBalance.campaignBudget && getFieldValue("budgetType") == BudgetType.Capped) {
                  return Promise.reject(
                    `Value must be equal or less than ${groupBudgetBalance.campaignBudget}`
                  );
                }
                return Promise.resolve();
              }
            })
          ]}>
          <Input prefix={"$"} type="number" />
        </Form.Item>

        <Form.Item
          wrapperCol={{ offset: 2, span: 16 }}
          style={{ marginTop: 50 }}
        >
          <Button type="primary" htmlType="submit">
            Edit Group
          </Button>
        </Form.Item>
      </Form>
    </Box>
  );
};

export default GroupsEditForm;

type ChangedGroupSettings = {
  groupName?: string;
  isActive?: GroupStatus;
  amount?: number;
  budgetType?: BudgetType;
};

function GetChangedGroupSettings(
  newFormState: FormState,
  oldFormState: FormState
): ChangedGroupSettings {
  const changedGroupAttributes: ChangedGroupSettings = {};

  if (oldFormState.groupName != newFormState.groupName) {
    changedGroupAttributes["groupName"] = newFormState.groupName;
  }

  if (oldFormState.isActive != newFormState.isActive) {
    changedGroupAttributes["isActive"] = newFormState.isActive;
  }

  return changedGroupAttributes;
}

function GetChangedGroupBudget(
  newFormState: FormState,
  oldFormState: FormState
): ChangedGroupSettings {
  const changedGroupBudgetAttributes: ChangedGroupSettings = {};

  if (oldFormState.budget != newFormState.budget) {
    changedGroupBudgetAttributes["amount"] = newFormState.budget;
  }

  if (oldFormState.budgetType != newFormState.budgetType) {
    changedGroupBudgetAttributes["budgetType"] = newFormState.budgetType;
  }

  return changedGroupBudgetAttributes;
}

function IsEmptyObject(object: object): boolean {
  return Object.keys(object).length == 0;
}
