import { Empty } from "antd";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { IndeedCampaign } from "../../models/indeed-manager/IndeedCampaign";
import { IndeedCampaignStatus } from "../../models/indeed-manager/IndeedCampaignStatus";
import { RootStoreContext } from "../../stores/RootStoreContext";
import Loader from "../general/loader/Loader";
import SupervisionTable from "../general/supervision-table/SupervisionTable";
import { IndeedCampaignActionsBuilder } from "./IndeedCampaignsTable.actions";
import { IndeedCampaignTableDto } from "./IndeedCampaignsTable.dto";

interface Props {
    campaignId: number;
    indeedCampaigns: IndeedCampaign[] | undefined;
}

const IndeedCampaignsTable = (props: Props) => {
    const { campaignId, indeedCampaigns } = props;

    const { campaignStore, indeedStore, uiStore, userStore } = useContext(RootStoreContext);
    const history = useHistory();

    if (!indeedCampaigns) {
        return <Loader position="normal" />;
    }

    if (indeedCampaigns.length == 0) {
        return (
            <Empty
                style={{ marginTop: "40px" }}
                description={`No Indeed Campaigns for ${campaignStore.SelectedCampaign?.campaignName}`}
            />
        );
    }

    return (
        <SupervisionTable
            dataSource={indeedCampaigns.map(
                IndeedCampaignTableDto.MapFromIndeedCampaign
            )}
            rowActionSettings={{
                actions: IndeedCampaignActionsBuilder({
                    history: history,
                    indeedStore: indeedStore,
                    plCampaignId: campaignId,
                    uiStore,
                    userStore
                }),
                showActionsOnLastColumn: true,
                showActionsOnRightClick: true,
            }}
            categoricalColumns={[
                {
                    column: "status",
                    possibleValues: IndeedCampaignStatus,
                    defaultFilteredValue: ['Active', 'Paused']
                }
            ]}
        />
    );
};

export default observer(IndeedCampaignsTable);
