
// export type breadCrumbActionType = "link" | "state_effect" | "none";

export class BreadCrumb {
    /**
     * ctor
     */
    constructor(
        public breadCrumbText: string,
        public path: string | null, // The path to redirect to on click
        public clickHandler: (() => void) | null, // Causes state side effect
    ) { }
}