import { Affiliate } from "../../../models/operation/Affiliate";
import { TableDto } from "../../general/supervision-table/Table.dto";



export class AffiliateTableDto extends TableDto  {

    public affiliateId: number;
    public affiliateName: string;

    constructor(affiliate: Affiliate) {
        super();
        this._id = affiliate.affiliateId;
        this.affiliateId = affiliate.affiliateId;
        this.affiliateName = affiliate.affiliateName;
    }


}