import {
  Badge,
  Button,
  Empty,
  Table,
  Checkbox,
  Segmented,
  Space,
  Form,
  Input,
  Switch,
  Tag,
} from "antd";
import { observer } from "mobx-react-lite";
import { Key, useContext, useEffect, useState } from "react";
import { EntityVendorResponse } from "../../models/vendor-management/EntityVendorResponse";
import { RootStoreContext } from "../../stores/RootStoreContext";
import type { ColumnsType } from "antd/es/table";
import { ClassificationType } from "../../models/vendor-management/ClassificationType";
import { ISODateTime } from "../../types/types";
import { Box, TextField, Tooltip } from "@material-ui/core";
import { FormatDateIfValid } from "../../utils/DateHelpers";
import { CommentIsEdit } from "./types";
import { EditOutlined } from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import { SearchColumn } from "./SearchColumn";
import { DecompositionString } from "../../utils/StringHelper";
import { ELevelTypes } from "../../models/vendor-management/settings/ELevelTypes";
import "./VendorManagementTable.scss";


interface Props { }

const VendorManagement = (props: Props) => {
  const [vendors, setVendors] = useState<EntityVendorResponse[]>([]);
  const { vendorManagementStore } = useContext(RootStoreContext);
  const { SelectedClassificationType } = vendorManagementStore;
  const [commentIsEdit, seCommentIsEdit] = useState<CommentIsEdit>();

  const selectedRowKeys = vendorManagementStore.SearchedVendors.reduce(
    function (selectedRowIndex: number[], vendor, index) {
      /*   if (vendor.inclusionEnforcement == true && SelectedClassificationType != ClassificationType.Excluded) {
               vendor.classificationType = ClassificationType.Exclusive
           }*/
      if (vendor.classificationType != ClassificationType.None) {
        selectedRowIndex.push(index);
      }
      return selectedRowIndex;
    },
    []
  );
  useEffect(() => {
    setVendors(vendorManagementStore.SearchedVendors);
  }, [vendorManagementStore.SearchedVendors]);

  const rowSelection = {
    onSelectAll: (selected: boolean, selectedRows: any[]) => {
      vendorManagementStore.SearchedVendors.forEach((vendor, index) => {
        var selectedVendor = vendorManagementStore.SearchedVendors[index];

        if (selected == true && ClassificationTypeIsOptional(selectedVendor)) {
          selectedVendor.classificationType = SelectedClassificationType;
        } else if (ClassificationTypeIsOptional(selectedVendor)) {
          selectedVendor.classificationType = ClassificationType.None;
        }
        vendorManagementStore.AddToEffectedRows(selectedVendor);
      });
    },

    onSelect: (record: any, selected: boolean) => {
      let vendorIndex = vendorManagementStore.SearchedVendors.findIndex(
        (x) => x.vendorId == record.vendorId
      );
      if (selected == true) {
        vendorManagementStore.SearchedVendors[vendorIndex].classificationType =
          SelectedClassificationType;
      } else {
        vendorManagementStore.SearchedVendors[vendorIndex].classificationType =
          ClassificationType.None;
      }
      vendorManagementStore.AddToEffectedRows(
        vendorManagementStore.SearchedVendors[vendorIndex]
      );
    },

    getCheckboxProps: (record: EntityVendorResponse) => ({
      disabled: !ClassificationTypeIsOptional(record),
    }),

    selectedRowKeys: selectedRowKeys,
  };

  const columns: ColumnsType<EntityVendorResponse> = [
    {
      dataIndex: "isOperativeCutoff",
      key: "isOperativeCutoff",
      width: "3%",
      render: (value, record: EntityVendorResponse) => (
        <Tooltip placement="top" title="The Vendor Has Been Cut Off">
          <Badge
            offset={[-5, 7]}
            style={{ width: "10px", height: "10px" }}
            dot={record.isOperativeCutoff}
          />
        </Tooltip>
      ),
    },
    {
      title: 'Rule Source',
      dataIndex: "inheritedLevel",
      key: "inheritedLevel",
      width: "10%",
      render: (value, record: EntityVendorResponse) => (
        <Tooltip placement="top" title='The Vendor Has Been Inherited Level'>
          <Tag className="custom-tag">
            {record.inheritedLevel != ELevelTypes.Default ? ELevelTypes[record.inheritedLevel] : ''}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: "VendorId",
      dataIndex: "vendorId",
      key: "vendorId",
      ...new SearchColumn("vendorId").CreateColumn(),
    },
    {
      title: "VendorName",
      dataIndex: "vendorName",
      key: "vendorName",
      width: "15%",
      ...new SearchColumn("vendorName").CreateColumn(),
    },
    { title: "UserName", dataIndex: "userName", key: "userName" },
    Table.SELECTION_COLUMN,
    {
      title: ClassificationType[SelectedClassificationType],
      key: "classificationType",
    },
    {
      title: (record: EntityVendorResponse) => {
        return (
          <>
            <Space>
              <Checkbox
                onChange={(e) => {
                  var updateVendors = [
                    ...vendorManagementStore.SearchedVendors,
                  ];
                  updateVendors.forEach(function (part, index) {
                    if (
                      !InclusionEnforcementIsOptional(
                        vendorManagementStore.SearchedVendors[index]
                      )
                    ) {
                      // use ! (not) , beacuse InclusionEnforcementIsOptional use for disabled attribute
                      vendorManagementStore.SearchedVendors[
                        index
                      ].inclusionEnforcement = e.target.checked;
                      vendorManagementStore.AddToEffectedRows(
                        vendorManagementStore.SearchedVendors[index]
                      );
                    }
                  });
                  setVendors(updateVendors);
                }}
              />
              Inclusion Enforcement
            </Space>
          </>
        );
      },
      key: "InclusionEnforcement",
      dataIndex: "InclusionEnforcement",
      width: "13%",
      render: (value, record: EntityVendorResponse) => (
        <Box>
          <Checkbox
            onChange={(e) => {
              let vendorIndex = vendorManagementStore.SearchedVendors.findIndex(
                (x) => x.vendorId == record.vendorId
              );
              vendorManagementStore.SearchedVendors[
                vendorIndex
              ].inclusionEnforcement = e.target.checked;
              vendorManagementStore.AddToEffectedRows(
                vendorManagementStore.SearchedVendors[vendorIndex]
              );
            }}
            style={{ marginRight: "30px" }}
            checked={record.inclusionEnforcement ?? false}
            disabled={InclusionEnforcementIsOptional(record)}
          ></Checkbox>
        </Box>
      ),
    },
    {
      title: "ModificationDate",
      dataIndex: "modificationDate",
      key: "modificationDate",
      render: (val) => FormatDateIfValid(val),
    },
    {
      title: "Reason Type",
      dataIndex: "reasonType",
      key: "reasonType",
      width: "10%",
      render: (val) => DecompositionString(val),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: "25%",
      render: (value, record: EntityVendorResponse, rowIndex) => {
        if (commentIsEdit?.isEditAble && commentIsEdit.rowIndex == rowIndex) {
          return (
            <Form.Item name="title" style={{ margin: 0 }}>
              <Input
                style={{ margin: 0 }}
                defaultValue={record.comment}
                onBlur={(e) => {
                  let vendorIndex =
                    vendorManagementStore.SearchedVendors.findIndex(
                      (x) => x.vendorId == record.vendorId
                    );
                  vendorManagementStore.SearchedVendors[vendorIndex].comment =
                    e.target.value;
                  vendorManagementStore.AddToEffectedRows(
                    vendorManagementStore.SearchedVendors[vendorIndex]
                  );
                  let commentIsEdit: CommentIsEdit = {
                    isEditAble: false,
                    rowIndex: rowIndex,
                  };
                  seCommentIsEdit(commentIsEdit);
                }}
              />
            </Form.Item>
          );
        }
        return (
          <>
            <div style={{ width: "100%" }}>
              <div style={{ width: "10%", float: "right", cursor: "pointer" }}>
                <EditOutlined />
              </div>
              <div style={{ width: "80%", float: "left" }}>
                <Paragraph
                  ellipsis={{
                    rows: 2,
                  }}
                  title={value}
                >
                  {value}
                </Paragraph>
              </div>
            </div>
          </>
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            let commentIsEdit: CommentIsEdit = {
              isEditAble: true,
              rowIndex: rowIndex,
            };
            seCommentIsEdit(commentIsEdit);
          },
        };
      },
    }
  ];

  if (vendors.length == 0) {
    return (
      <Empty style={{ marginTop: "40px" }} description={`No Vendors Records`} />
    );
  }

  const InclusionEnforcementIsOptional = (record: EntityVendorResponse) => {
    switch (SelectedClassificationType) {
      case ClassificationType.Excluded: {
        if (record.classificationType == ClassificationType.Excluded) {
          return true;
        } else {
          return false;
        }
      }
      case ClassificationType.Exclusive: {
        if (record.classificationType == ClassificationType.Exclusive) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const ClassificationTypeIsOptional = (record: EntityVendorResponse) => {
    switch (SelectedClassificationType) {
      case ClassificationType.Excluded: {
        if (record.inclusionEnforcement == true) {
          return false;
        } else {
          return true;
        }
      }
      case ClassificationType.Exclusive: {
        if (record.inclusionEnforcement == true) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  return (
    <Table
      dataSource={vendors.map((data, i) => ({ ...data, key: i }))}
      pagination={{
        pageSize: 10,
        showSizeChanger: false,
      }}
      columns={columns}
      rowSelection={rowSelection}
    />
  );
};

export default observer(VendorManagement);
