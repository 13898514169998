
import { User } from "../../models/users/User";
import { UserResponse } from "../../models/users/UserResponse";
import { BaseService } from "../BaseService";

export class UserService extends BaseService {
    async GetAll(): Promise<UserResponse[]> {
        return await this.Get<UserResponse[]>("/");
    }

    async GetUserById(userId: number | string): Promise<User> {
        return await this.Get<User>(`/${userId}`);
    }

    async GetByUserEmail(email: string): Promise<User> {
        return await this.Get<User>(`/get-by/email/${email}`);
    }
}

export const userService = new UserService("users");