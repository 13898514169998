import { EntityRecordHierarchyType } from "./campaigns-list";

// export const jobsMock: EntityRecordHierarchyType<any> = {} as EntityRecordHierarchyType<any>

export const jobsMock: EntityRecordHierarchyType<{ location: string, jobTitleText: string }> = {
    "entityLevelName": "Jobs",
    "entities": [
        {
            "entityTypeId": 5,
            "entityId": 1,
            "entityName": "Truck Driver",
            "entityData": {
                jobTitleText: "Foreign Language Teacher",
                location: "Concord, NH"

            }
        },
        {
            "entityTypeId": 5,
            "entityId": 2,
            "entityName": "Dietary Manager",
            "entityData": {
                jobTitleText: "General Assistant Professor",
                location: "South Bend Area"

            }
        },
        {
            "entityTypeId": 5,
            "entityId": 3,
            "entityName": "Clerk",
            "entityData": {
                jobTitleText: "Nurse Manager",
                location: "NE, COLUMBUS"

            }
        },
        {
            "entityTypeId": 5,
            "entityId": 4,
            "entityName": "Chef",
            "entityData": {
                jobTitleText: "Senior Accountant",
                location: "NE, GRAND ISLAND"

            }
        },
        {
            "entityTypeId": 5,
            "entityId": 5,
            "entityName": "Programmer Analyst",
            "entityData": {
                jobTitleText: "MOORHEAD, MN",
                location: "Certified Peer Specialist"

            }
        },
        {
            "entityTypeId": 5,
            "entityId": 7,
            "entityName": "Marketing Representative",
            "entityData": {
                jobTitleText: "Automobile Salesperson",
                location: "FARGO, ND"

            }
        },
        {
            "entityTypeId": 5,
            "entityId": 7,
            "entityName": "Research Communications/Grant Dev Specialist",
            "entityData": {
                jobTitleText: "Pharmacy Technician",
                location: "BISMARCK, ND"

            }
        },
        {
            "entityTypeId": 5,
            "entityId": 7,
            "entityName": "Software Engineer",
            "entityData": {
                jobTitleText: "Sales Representative",
                location: "CONTACT VIRGINIA"

            }
        },
        {
            "entityTypeId": 5,
            "entityId": 7,
            "entityName": "Accountant",
            "entityData": {
                jobTitleText: "Administrative Assistant",
                location: "NEW YORK NEW YORK"

            }
        },
    ]
}