import { message } from "antd";
import { RoutesPaths } from "../../../../constants/RoutePaths";
import { PaginationParams } from "../../../../models/pagination/PaginationParams";
import campaignSettingAuditService from "../../../../services/campaign-setting/CampaignSettingAuditService";
import campaignSettingTypeService from "../../../../services/campaign-setting/CampaignSettingTypeService";
import { CampaignSettingStore } from "../../../../stores/CampaignSettingStore";





export const CampaignSettingContextInitializers = {


    InitializeCampaignAuditData: async (campaignId: number, campaignSettingStore: CampaignSettingStore, history: any) => {
        try {

            const _auditData = await campaignSettingAuditService.GetFilteredCampaignSettingsAudits({ campaignId });
            campaignSettingStore.SetCampaignSettingsAudits(_auditData);

        }
        catch (err) {
            history.push(RoutesPaths.Supervision.Campaigns);
            message.error(
                `Campaign with ID: ${campaignId} doesn't exist. Returned to home page`
            );
        }
    },

    InitializeCampaignSettingTypes: async (campaignSettingStore: CampaignSettingStore, history: any) => {
        try {

            const _settingTypesData = await campaignSettingTypeService.GetCampaignSettingTypes();

            await campaignSettingStore.SetCampaignSettingTypes(_settingTypesData);

        }
        catch (err) {
            history.push(RoutesPaths.Supervision.Campaigns);
            message.error(
                `Failed to find Campaign setting types Returned to home page`
            );
        }
    } ,

    InitializeSelectedCampaignSettingAudit: async (auditId: string, campaignSettingStore: CampaignSettingStore, history: any) => {

        try {

            campaignSettingStore.SetSelectedCampaignSettingAudit(
                campaignSettingStore.CampaignSettingsAuditsResponse?.find(
                    (x) => x.id == auditId
                ));
        }
        catch (err) {
            history.push(RoutesPaths.Supervision.Campaigns);
            message.error(
                `Failed to find Campaign setting types Returned to home page`
            );
        }
    }

}
