import { Button, Form, Input, InputNumber, message, Select } from "antd";
import React, { useState } from "react";

import { FetchError } from "../../../exceptions/AlgoSupervisionExceptions";
import { IndeedCampaignObjectiveStrategy } from "../../../models/indeed-manager/strategy/IndeedCampaignObjectiveStrategy";
import { IndeedCampaignObjectiveType } from "../../../models/indeed-manager/strategy/IndeedCampaignObjectiveType";
import { indeedStrategyManagementService } from "../../../services/indeed/IndeedStrategyManagementService";
import Loader from "../../general/loader/Loader";

const { Option } = Select;

type FormState = {
    campaignObjectiveType: IndeedCampaignObjectiveType;
    percentOfCampaignStrategies: string;
    targetStrategyOptionMaxValue: string;
    targetStrategyOptionMinValue: string;
};

type Props = {
    plCampaignId: string | number;
    onFormSubmitSuccesfully: (
        newlyCreatedStrategy: IndeedCampaignObjectiveStrategy
    ) => void;
};

const IndeedStrategyForm = (props: Props) => {
    const { plCampaignId, onFormSubmitSuccesfully } = props;

    const [form] = Form.useForm();
    const [campaignObjectiveType, setCampaignObjectiveType] =
        useState<IndeedCampaignObjectiveType>();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (formState: FormState) => {
        setLoading(true);
        try {
            const newlyCreatedStrategy =
                await indeedStrategyManagementService.CreateStrategy(
                    plCampaignId,
                    {
                        campaignObjectiveType: formState.campaignObjectiveType,
                        percentOfCampaignStrategies: Number(
                            formState.percentOfCampaignStrategies
                        ),
                        targetStrategyOptionMinValue:
                            formState.targetStrategyOptionMinValue !== ""
                                ? Number(formState.targetStrategyOptionMinValue)
                                : undefined,
                        targetStrategyOptionMaxValue:
                            formState.targetStrategyOptionMaxValue !== ""
                                ? Number(formState.targetStrategyOptionMaxValue)
                                : undefined,
                    }
                );

            onFormSubmitSuccesfully(newlyCreatedStrategy);

            message.success("Created new strategy successfully");
        } catch (error) {
            if (error instanceof FetchError) {
                message.error(error.apiError.errorMessage);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form
            // {...formItemLayout}
            layout={"inline"}
            form={form}
            onFinish={onFinish}
            // initialValues={{ layout: formLayout }}
        >
            <Form.Item
                rules={[{ required: true }]}
                label="Campaign Objective Type"
                name="campaignObjectiveType">
                <Select
                    style={{ width: 170 }}
                    onChange={(val: IndeedCampaignObjectiveType) => {
                        setCampaignObjectiveType(val);
                    }}>
                    <Option value={IndeedCampaignObjectiveType.MAXIMUM}>
                        Maximum
                    </Option>
                    <Option value={IndeedCampaignObjectiveType.BALANCE}>
                        Balance
                    </Option>
                    <Option
                        value={IndeedCampaignObjectiveType.TARGET_APPLICATIONS}>
                        Target Applications
                    </Option>
                    <Option
                        value={
                            IndeedCampaignObjectiveType.TARGET_COST_PER_APPLICATION
                        }>
                        Target CPA
                    </Option>
                </Select>
            </Form.Item>

            <Form.Item
                rules={[{ required: true }]}
                label="Percent of strategies"
                name="percentOfCampaignStrategies">
                <InputNumber type="number" placeholder="5" min={5} step={5}  />
            </Form.Item>

            {(campaignObjectiveType ===
                IndeedCampaignObjectiveType.TARGET_APPLICATIONS ||
                campaignObjectiveType ===
                    IndeedCampaignObjectiveType.TARGET_COST_PER_APPLICATION) && (
                <Form.Item label="Min/Max values">
                    <Input.Group compact>
                        <Form.Item name="targetStrategyOptionMinValue">
                            <Input
                                type="number"
                                placeholder="min"
                                style={{ width: 100 }}
                            />
                        </Form.Item>
                        <Form.Item name="targetStrategyOptionMaxValue">
                            <Input
                                type="number"
                                placeholder="max"
                                style={{ width: 100 }}
                            />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
            )}

            {loading ? (
                <Loader position="normal" />
            ) : (
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            )}
        </Form>
    );
};

export default IndeedStrategyForm;
