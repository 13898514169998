import { RoutesPaths } from "../../../../constants/RoutePaths";
import { BreadCrumb } from "../../../../models/BreadCrumb";



export const AttributesManagmentBreadCrumbs =
{
    CompanyAttributes: (companyId: number) => {
        return new BreadCrumb(
            "Attributes-Managment",
            RoutesPaths.Supervision.AttributesManagementRoutes.BuildCompanyAttributesRouteManagementRoute(companyId),
            null
        );
    },
}

export default AttributesManagmentBreadCrumbs