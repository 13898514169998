import { action, makeObservable, observable, toJS } from "mobx";

import { LocalStorageKeys } from "../constants/LocalStorageKeys";
import { Campaign } from "../models/operation/Campaign";
import LocalStorageService from "../services/LocalStorageService";
import campaignService from "../services/operation/CampaignService";

export class CampaignStore {
  /**
   * Props
   */

  @observable
  public SearchedCampaigns: Campaign[] = [];

  /**
   * The campaign that is selected in the supervision campaigns page to further do operations on
   */
  @observable
  public SelectedCampaign: Campaign | undefined;

  /**
   * Ctor
   */
  constructor() {
    makeObservable(this);

    const campaigns = LocalStorageService.GetItemObject<Campaign[]>(
      LocalStorageKeys.SearchedCampaigns
    );
    if (campaigns != undefined) {
      this.SearchedCampaigns = campaigns;
    }
  }

  @action
  public async SetSearchedCampaigns(ids: number[]) {
    const campaigns = await campaignService.GetManyByIdsAsync({ ids });
    this.SearchedCampaigns = campaigns;

    LocalStorageService.SetItemObject(
      LocalStorageKeys.SearchedCampaigns,
      campaigns
    );
  }

  @action
  public async SetSelectedCampaign(campaign: Campaign) {
    this.SelectedCampaign = campaign;
  }
}
