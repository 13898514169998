import { Col, DatePicker, Form, Input, Row, Select, Typography } from "antd";
import moment, { Moment } from "moment";
import React, { useState } from "react";

import { IndeedBudgetFirstMonthBehavior } from "../../models/indeed-manager/IndeedBudgetFirstMonthBehavior";
import { IndeedCampaignBudgetType } from "../../models/indeed-manager/IndeedCampaignBudgetType";
import { IndeedEndDateType } from "../../models/indeed-manager/IndeedEndDateType";

const { Option } = Select;

interface Props {
    isStartDateEditable: boolean;
    budgetLimitTypeInitialValue?: IndeedCampaignBudgetType;
}

const CampaignBudgetInputs = (props: Props) => {
    const { isStartDateEditable, budgetLimitTypeInitialValue } = props;
    
    const [budgetLimitType, setBudgetLimitType] = useState<
        IndeedCampaignBudgetType | undefined
    >(budgetLimitTypeInitialValue);

    const disableDateBeforeToday = (current?: Moment): boolean => {
        if (!current) return true;
        return current < moment().add(-1, "day").endOf("day");
    };

    return (
        <div>
            <Row style={{ marginTop: "35px", marginBottom: "5px" }}>
                <Col offset={2}>
                    <Typography.Title level={4}>
                        Budget Settings
                    </Typography.Title>
                </Col>
            </Row>

            <Form.Item
                name="budgetType"
                label="Budget Type"
                rules={[{ required: true }]}>
                <Select
                    onChange={(selectedCampaignType: string) => {}}
                    allowClear>
                    <Option value={IndeedEndDateType.Fixed}>Fixed</Option>
                    <Option value={IndeedEndDateType.Target}>Target</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="startDate"
                label="Start Date"
                tooltip="The start date for both monthly and one time budget ads."
                rules={[{ required: true }]}>
                <DatePicker
                disabled={!isStartDateEditable}
                    disabledDate={disableDateBeforeToday}
                    style={{ width: "100%" }}
                    onChange={(val, dateString) => {
                        // console.log(val, dateString);
                    }}
                    onOk={(val) => {
                        // console.log(val);
                    }}
                />
            </Form.Item>

            <Form.Item
                name="endDate"
                label="End Date"
                tooltip="Represents FixedEndDate or TargetEndDate. Depends on the Budget Type selected"
                rules={[{ required: true }]}>
                <DatePicker
                    disabledDate={disableDateBeforeToday}
                    style={{ width: "100%" }}
                    onChange={(val, dateString) => {
                        // console.log(val, dateString);
                    }}
                    onOk={(val) => {
                        // console.log(val);
                    }}
                />
            </Form.Item>

            <Form.Item required label="Budget Limit" style={{ margin: 0 }}>
                <Input.Group>
                    <Row gutter={16}>
                        <Col span={16}>
                            <Form.Item
                                initialValue={IndeedCampaignBudgetType.OneTime}
                                name="budgetLimitSettings"
                                rules={[{ required: true }]}>
                                <Select
                                    style={{ width: "100%" }}
                                    defaultValue= {IndeedCampaignBudgetType.OneTime}
                                    onChange={(
                                        value: IndeedCampaignBudgetType
                                    ) => {
                                        setBudgetLimitType(value);
                                    }}>
                                    <Option
                                        value={
                                            IndeedCampaignBudgetType.OneTime
                                        }>
                                        Budget One Time Limit
                                    </Option>
                                   {/* <Option
                                        value={
                                            IndeedCampaignBudgetType.Monthly
                                        }>
                                        Budget Monthly Limit
                                    </Option> */}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="budgetLimitValue"
                                rules={[
                                    {
                                        required: true,
                                        validator: (ruleObject, value, err) => {
                                            if (value < 50 && value > 0) {
                                                return Promise.reject(
                                                    "Value must be greater than 50"
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}>
                                <Input prefix={"$"} type="number" required />
                            </Form.Item>
                        </Col>
                    </Row>
                </Input.Group>
            </Form.Item>

            <Form.Item
                name="budgetFirstMonthBehavior"
                label="First Month Behavior">
                <Select
                    disabled={ true
                       /* budgetLimitType == IndeedCampaignBudgetType.OneTime*/
                    }
                    onChange={(selectedCampaignType: string) => {}}
                    allowClear>
                    <Option
                        value={
                            IndeedBudgetFirstMonthBehavior.StartNowProratedAmount
                        }>
                        Start Now Prorated Amount
                    </Option>
                    <Option
                        value={
                            IndeedBudgetFirstMonthBehavior.StartNowFullAmount
                        }>
                        Start Now Full Amount
                    </Option>
                    <Option
                        value={
                            IndeedBudgetFirstMonthBehavior.StartNextMonthFullAmount
                        }>
                        Start Next Month Full Amount
                    </Option>
                </Select>
            </Form.Item>
        </div>
    );
};

export default CampaignBudgetInputs;
