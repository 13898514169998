import { Box } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import AffiliateSelectInput from "../../../components/supervision/affiliates/AffiliateSelectInput";
import AffiliatesTable from "../../../components/supervision/affiliates/AffiliatesTable";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { Affiliate } from "../../../models/operation/Affiliate";
import affiliateService from "../../../services/affiliates/AffiliateService";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { observer } from "mobx-react-lite";


interface Props { }



const AffiliatesContainer = (props: Props) => {
    const { affiliateStore, uiStore } = useContext(RootStoreContext);
    //const [allAffiliate, setAllAffiliates] = useState<Affiliate[]>();
    const [title, setTitle] = useState<string>(affiliateStore.SelectedAffiliate?.affiliateName ?? "");

    useEffect(() => {
        if (affiliateStore.SelectedAffiliate != undefined) {

            setTitle(affiliateStore.SelectedAffiliate.affiliateName)
            uiStore.SetPageTitle(`Algo Supervision - Affiliates (${affiliateStore.SelectedAffiliate?.affiliateName})`)

        }
    }, [affiliateStore.SelectedAffiliate]);


    usePageInitialization({
        pageTitle: `Algo Supervision - Affiliates (${title})`,

        onInitAsync: async () => {
            const affiliates = await affiliateService.GetAffiliates();
            await affiliateStore.SetAllAffiliates(affiliates);

        }
    })


    return (
        <div>
            {/*  <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                     <AffiliateSelectInput allAffiliate={allAffiliate}/>
            </Box>*/}
            <AffiliatesTable />

        </div>
    );

}


export default observer(AffiliatesContainer);