import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import GroupsAttachJobsForm from "../../../components/groups/GroupsAttachJobsForm";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import GroupsBreadCrumbs from "./helpers/GroupsBreadCrumbs";
import { GroupsContextInitializers } from "./helpers/GroupsContextInitializers ";

const GroupsAttachJobsPage = () => {
  const match = useParams<{ campaignId: string; groupId: string }>();
  const { groupStore } = useContext(RootStoreContext);
  const history = useHistory();

  usePageInitialization({
    onInitAsync: async () => {
      await GroupsContextInitializers.InitializeGroupJobsData(
        Number(match.groupId),
        groupStore,
        history
      );
      await GroupsContextInitializers.InitializeSelectedGroup(
        Number(match.groupId),
        groupStore,
        history
      );
      await GroupsContextInitializers.InitializeGroupCampaign(
        Number(match.campaignId),
        groupStore,
        history
      );
    },
    onCompanyContextCallBack: (company, uiStore) => {
      uiStore.SetPageTitle(
        `Attach Jobs for Group: ${groupStore.SelectedGroup?.groupName}`
      );
      uiStore.AddBreadCrumbs(
        SupervisionBreadcrumbs.Companies(company.companyName),
        SupervisionBreadcrumbs.Campaigns(
          `${groupStore.SelectedCampaign?.campaignName}`
        ),
        GroupsBreadCrumbs.Groups(
          Number(groupStore.SelectedCampaign?.campaignID)
        ),
        GroupsBreadCrumbs.GroupName(groupStore.SelectedGroup?.groupName ?? ""),
        GroupsBreadCrumbs.AttachJobs(
          Number(groupStore.SelectedGroup?.campaignId),
          Number(match.groupId)
        )
      );
    },
  });

  return (
    <div>
      <GroupsAttachJobsForm
        campaignId={Number(match.campaignId)}
        groupId={Number(match.groupId)}
      />
    </div>
  );
};

export default GroupsAttachJobsPage;
