import React, { useContext } from "react";
import { EntityDistributionSettings } from "../../../../models/vendor-management/settings/EntityDistributionSettings";
import { EntityVendorResponse } from "../../../../models/vendor-management/EntityVendorResponse";
import { UIStore } from "../../../../stores/UIStore";
import { ELevelTypes } from "../../../../components/actions/ELevelTypes";
import { CopyOutlined } from '@ant-design/icons';
import { Divider, List, Typography } from 'antd';
import { Button } from "antd";
import { ManualActionRequestSource } from "../../../../models/manual-actions/ManualActionRequestSource";
import "./ReportPage.scss"


export const ReportPage = {
  BuildReport: async (uiStore: UIStore, rowsEffected: EntityVendorResponse[], resetEntitiesCollections?: EntityDistributionSettings[]
  ): Promise<{ sourceRequest: ManualActionRequestSource, action: boolean }> => {

    const handleCopy = () => {
      const reportText = generateReportText(rowsEffected, resetEntitiesCollections);
      navigator.clipboard.writeText(reportText)
        .then(() => {
          console.log('Text copied successfully!');
        })
        .catch((error) => {
          console.error('Error copying text:', error);
        });
    };

    const generateReportText = (vendors: EntityVendorResponse[], entities?: EntityDistributionSettings[]) => {
      let reportText = 'Affected Entities:\n\n';
      entities?.forEach((entity) => {
        reportText += `${ELevelTypes[entity.eLevelTypes]} ID: ${entity.entityId}\n`;
      });

      reportText += '\nAffected Vendors:\n\n';
      vendors.forEach((vendor) => {
        reportText += `${vendor.vendorName} ID: ${vendor.vendorId}\n`;
      });

      return reportText;
    };

    return new Promise<{ sourceRequest: ManualActionRequestSource, action: boolean }>((resolve, reject) => {
      const popupBodyApply = (
        <div>
          <div className="report-content">
            {resetEntitiesCollections && resetEntitiesCollections.length > 0 && (
              <>
                <List
                  header={<div className="list-header">Affected Entities</div>}
                  bordered
                  dataSource={resetEntitiesCollections}
                  renderItem={(item) => (
                    <List.Item>
                      <Typography.Text mark></Typography.Text> {ELevelTypes[item.eLevelTypes]}  - {item.entityName} - {item.entityId}
                    </List.Item>
                  )}
                />
              </>
            )}
            {rowsEffected && rowsEffected.length > 0 && (
              <>
                <Divider orientation="left" />
                <List
                  header={<div className="list-header">Affected Vendors</div>}
                  bordered
                  dataSource={rowsEffected}
                  renderItem={(item) => (
                    <List.Item>
                      <Typography.Text mark></Typography.Text> {item.vendorName}  - {item.vendorId}
                    </List.Item>
                  )}
                />
              </>
            )}
          </div>
          <Button onClick={handleCopy} className="copy-button" icon={<CopyOutlined />}>
            Copy Text
          </Button>
        </div>
      );

      uiStore.SetPopUp({
        body: popupBodyApply,
        title: 'Report Page',
        okClickHandler: async () => {
          const selectedValue = uiStore.PopUp?.selectedValue ?? ManualActionRequestSource.CampaignTeam;
          resolve({ sourceRequest: selectedValue, action: true });
        },
        cancelClickHandler: async () => {
          const selectedValue = uiStore.PopUp?.selectedValue ?? ManualActionRequestSource.CampaignTeam;
          resolve({ sourceRequest: selectedValue, action: false });
        },
        okButtonText: 'Approve',
        cancelButtonText: 'Abort',
        closable: false,
        hideSelectButton: false,
      })
    });
  },
};

