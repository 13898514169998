import { GroupJobResponse } from "../../models/groups/GroupJobResponse";
import { GroupJobsRequest } from "../../models/groups/GroupJobsRequest";
import { Job } from "../../models/operation/Job";
import { PaginationParams } from "../../models/pagination/PaginationParams";
import { BaseService } from "../BaseService";


export class GroupJobsService extends BaseService {

   async GetAllByGroupIdAsync(groupId: number): Promise<GroupJobResponse[]> {
      const GroupJobsData = await this.Get<GroupJobResponse[]>(`/${groupId}`);
      return GroupJobsData;

   }

   async AttachedDetachedAsync(groupJobRequest: GroupJobsRequest)
   {
      const apiResponse = await this.Post<GroupJobsRequest>(
         "/attached-jobs",
         groupJobRequest
       );
   }

   async GetJobsByCampaignIdPagination(campaignId: number, paginationParams: PaginationParams): Promise<GroupJobResponse[]> {
      const queryString = `campaignId=${campaignId}&offset=${paginationParams.offset}&limit=${paginationParams.limit}`
      const jobsPage = await
          this.Get<GroupJobResponse[]>(`?${queryString}`);
      return jobsPage;
  }



}

const groupJobsService = new GroupJobsService("GroupJobs");
export default groupJobsService;