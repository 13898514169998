import "./NotificationsList.scss";

import { Badge, Empty, List, message, Popover } from "antd";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { io } from "socket.io-client";

import { Box } from "@material-ui/core";

import settings from "../../../../../config/AppSettings/SettingsFactory";
import { FetchError } from "../../../../../exceptions/AlgoSupervisionExceptions";
import useOnInitAsync from "../../../../../hooks/useOnInitAsync";
import { Notification } from "../../../../../models/notifications/Notification";
import NotificationService from "../../../../../services/notifications/NotificationService";
import { RootStoreContext } from "../../../../../stores/RootStoreContext";
import Loader from "../../../loader/Loader";

type Props = {
    collapsed: boolean;
};

const NotificationsList = (props: Props) => {
    const { collapsed } = props;
    const { userStore, notificationStore } = useContext(RootStoreContext);

    useOnInitAsync(async () => {
        /*
        if (!userStore.User) return;
        try {
            const webSocketUri = `${settings.notificationServerUrl}?userId=${userStore.User?.id}`;
            const socket = io(webSocketUri);

            socket.on("notifications", (event: Notification) => {
                notificationStore.Notifications &&
                    notificationStore.Notifications.push(event);
            });

            await notificationStore.SetNotificationStateForUser();
        } catch (error) {
            // We should be able to continue even if notification service is down
            console.error(error);
            
            // if (error instanceof FetchError) {
            //     return message.error(error.apiError.errorMessage);
            // }
            // if (error instanceof Error) {
            //     return message.error(error.message);
            // }
        }
        */
    }, [userStore.User]);

    const notifications = notificationStore.Notifications;

    if (!notifications || !userStore.User) {
        return <Loader position="center" />;
    }

    return (
        <Popover
            className="notifications-popover"
            style={{ width: 400, height: 500, overflowY: "scroll" }}
            onVisibleChange={async (isOpen) => {
                if (!isOpen) return;

                const notificationsToUpdateAsSeen = notifications!
                    .filter((x) => !x.seen)
                    .map((notification) => ({
                        ...notification,
                        seen: true,
                    }));

                await Promise.all(
                    notificationsToUpdateAsSeen.map((notification) =>
                        NotificationService.Update(
                            notification.id,
                            _.omit(notification, ["createdAt", "id"])
                        )
                    )
                );

                notificationStore.Notifications = notifications.map(
                    (notification) => ({
                        ...notification,
                        seen: true,
                    })
                );
            }}
            placement="rightTop"
            title="Notifications"
            content={
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={_.orderBy(
                        notifications,
                        ["createdAt"],
                        ["desc"]
                    )}
                    renderItem={(item) => (
                        <List.Item
                            key={item.id}
                            style={{
                                background: !item.seen
                                    ? "#F6FBF7"
                                    : "transparent",
                            }}>
                            <List.Item.Meta
                                title={item.sentFrom}
                                description={item.createdAt}
                            />
                            {item.message}
                        </List.Item>
                    )}
                />
            }
            trigger="click">
            <Box className="notification-list">
                <Badge count={notifications.filter((x) => !x.seen).length}>
                    <IoMdNotificationsOutline color="#fff" size="30px" />
                </Badge>

                <p
                    style={{
                        display: collapsed ? "none" : "inline-block",
                        marginBottom: 0,
                    }}>
                    Notifications
                </p>
            </Box>
        </Popover>
    );
};

export default observer(NotificationsList);
