import { action, makeObservable, observable } from "mobx";
import { CompanySettingsAuditResponse } from "../models/company-track-system/CompanySettingsAuditResponse";
import { CompanySettingTypeResponse } from "../models/company-track-system/CompanySettingTypeResponse";
import { CompanySettingTypeValueOption } from "../models/company-track-system/CompanySettingTypeValueOption";

export class CompanySettingStore {
  @observable
  public CompanySettingTypes: CompanySettingTypeResponse[] = [];

  @observable
  public CompanySettingTypeValueOptions: CompanySettingTypeValueOption[] = [];

  @observable
  public CompanySettingsAuditsResponse: CompanySettingsAuditResponse[] = [];

  @observable
  public SelectedCompanySettingAudit?: CompanySettingsAuditResponse;

  @observable
  public updateOperationState?: boolean;
  /**
   * ctor
   */
  constructor() {
    makeObservable(this);
  }

  @action
  public async SetCompanySettingTypes(
    companySettingTypes: CompanySettingTypeResponse[]
  ) {
    this.CompanySettingTypes = companySettingTypes;
  }

  @action
  public async SetCompanySettingTypeValueOptions(
    companySettingTypeValueOptions: CompanySettingTypeValueOption[]
  ) {
    this.CompanySettingTypeValueOptions = companySettingTypeValueOptions;
  }

  @action
  public async SetCompanySettingsAudits(
    CompanySettingsAudits: CompanySettingsAuditResponse[]
  ) {
    this.CompanySettingsAuditsResponse = CompanySettingsAudits;
  }

  @action
  public async SetSelectedCompanySettingAudit(
    companySettingAudit?: CompanySettingsAuditResponse
  ) {
    this.SelectedCompanySettingAudit = companySettingAudit;
  }

  @action
  public async SetupdateOperationState(updateOperationState: boolean) {
    this.updateOperationState = updateOperationState;
  }
}
