import { message } from "antd";
import { RoutesPaths } from "../../../../constants/RoutePaths";
import GroupJobsService from "../../../../services/groups/GroupJobsService";
import groupService from "../../../../services/groups/GroupsService";
import GroupsService from "../../../../services/groups/GroupsService";
import campaignService from "../../../../services/operation/CampaignService";
import { GroupStore } from "../../../../stores/GroupStore";

export const GroupsContextInitializers = {

    InitializeGroupCampaign: async (campaignId: number, groupsStore: GroupStore, history: any) => {
        try {
            const campaign = await campaignService.GetById(campaignId);
            groupsStore.SetSelectedCampaign(campaign);
        }
        catch (err) {
            history.push(RoutesPaths.Supervision.Companies);
            message.error(
                `Campaign with ID: ${campaignId} doesn't exist. Returned to home page`
            );
        }
    },

    InitializeGroupData: async (campaignId: number, groupsStore: GroupStore, history: any) => {
        try {

            const _groupData = await GroupsService.GetAllAsync(campaignId);
            groupsStore.SetGroups(_groupData);
        }
        catch (err) {
            history.push(RoutesPaths.Supervision.Companies);
            message.error(
                `Campaign with ID: ${campaignId} doesn't exist. Returned to home page`
            );
        }
    },


    InitializeGroupJobsData: async (groupId: number, groupsStore: GroupStore, history: any) => {
        try {

            const _groupJobsData = await GroupJobsService.GetAllByGroupIdAsync(groupId);
            groupsStore.SetGroupJobs(_groupJobsData);
        }
        catch (err) {
            history.push(RoutesPaths.Supervision.Campaigns);
            message.error(
                `Group with ID: ${groupId} doesn't exist. Returned to home page`
            );
        }
    },


    InitializeSelectedGroup: async (groupId: number, groupsStore: GroupStore, history: any) => {
        try {
            const group = await groupService.GetGroupById(groupId);
            groupsStore.SetSelectedGroup(group);
        } catch (err) {
            message.error(
                `Group with ID: ${groupId} doesn't exist`
            );
        }
    },


    InitializeGroupBudgetBalance: async (campaignId: number, groupsStore: GroupStore, history: any, groupId?: number) => {
        try {
            const _groupBudgetBalance = await groupService.GetGroupBudgetBalance(campaignId,groupId);
            groupsStore.SetGroupBudgetBalance(_groupBudgetBalance);
        } catch (err) {
            message.error(
                `Balance to campaign with ID: ${campaignId} doesn't exist`
            );
        }
    }


}