import { ClassificationType } from "../../../../models/vendor-management/ClassificationType";
import { VendorManagementStore } from "../../../../stores/VendorManagementStore";

export const ClassificationTypeValidation = {

    InitClassificationType: async (vendorManagementStore: VendorManagementStore) => {

        const _vendors = vendorManagementStore.Vendors;

        let mode = _vendors.some(vendor => vendor.classificationType == ClassificationType.Exclusive)
        if (mode == true) {
            vendorManagementStore.SetClassificationType(ClassificationType.Exclusive);
        }
        else {
            vendorManagementStore.SetClassificationType(ClassificationType.Excluded);
        }

    },


}