import { useContext, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import useOnInitAsync from "../../../hooks/useOnInitAsync";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { CompanySettingsAuditResponse } from "../../../models/company-track-system/CompanySettingsAuditResponse";
import { observer } from "mobx-react-lite";
import AuditTable from "../../../components/company-track-system/CompanySettingAuditTable";
import { CompanySettingContextInitializers } from "./helpers/CompanySettingContextInitializers";
import { AutoComplete, Button, DatePicker, Empty, message, Space } from "antd";
import { BiPlusCircle } from "react-icons/bi";
import { RoutesPaths } from "../../../constants/RoutePaths";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import CompanySettingBreadCrumbs from "./helpers/CompanySetting.breadcrumbs";
import { ISODateTime } from "../../../types/types";
import { UserResponse } from "../../../models/users/UserResponse";
import { Moment } from "moment";
import SelectUserInput from "../../../components/general/inputs/SelectUserInput";
import Loader from "../../../components/general/loader/Loader";
import moment from "moment";
import companySettingAuditService from "../../../services/company-settings/CompanySettingAuditService";
import UploadCsvFile from "../../../components/company-track-system/UploadCsvFile";

interface Props {}

type FilterSettings = {
  userId?: number;
  from?: ISODateTime;
  to?: ISODateTime;
  optionTypeId?: string;
  companyId?: number;
};

const CompanyTrackAuditPage = (props: Props) => {
  const match = useParams<{ companyId: string }>();
  const { companySettingStore } = useContext(RootStoreContext);
  const history = useHistory();

  const [filterSettings, setFilterSettings] = useState<FilterSettings>({
    companyId: Number(match.companyId),
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userInputValue, setUserInputValue] = useState<
    UserResponse | undefined
  >();

  const [typeValue, setTypeValue] = useState<string | undefined>();
  const companySettingTypes = companySettingStore.CompanySettingTypes;

  usePageInitialization({
    onInitAsync: async () => {
      await CompanySettingContextInitializers.InitializeCompanyAuditData(
        Number(match.companyId),
        companySettingStore,
        history
      );

      await CompanySettingContextInitializers.InitializeCompanySettingTypes(
        companySettingStore,
        history
      );

      setIsLoading(false);
    },

    onCompanyContextCallBack: (company, uiStore) => {
      uiStore.SetPageTitle(`Company Audits for: ${company.companyName}`);
      uiStore.AddBreadCrumbs(
        SupervisionBreadcrumbs.Companies(company.companyName),
        CompanySettingBreadCrumbs.CompanySetting(company.companyId)
      );
    },
  });

  if (!companySettingStore.CompanySettingsAuditsResponse) {
    return <Loader position="normal" />;
  }

  const filterHandler = async () => {
    setIsLoading(true);
    await companySettingAuditService
      .GetFilteredCompanySettingsAudits(filterSettings)
      .then((audits) => {
        companySettingStore.SetCompanySettingsAudits(audits);
      });

    setIsLoading(false);
    message.info("Filtered actions");
  };

  const initialDateValue = () => {
    let date: [Moment?, Moment?] = [undefined, undefined];

    if (filterSettings.from) {
      date[0] = moment(filterSettings.from);
    }
    if (filterSettings.to) {
      date[1] = moment(filterSettings.to);
    }
    return date;
  };

  if (isLoading) {
    return <Loader position="normal" />;
  }

  const typeOptions = companySettingTypes
    ?.filter((x) => x.settingType.toLowerCase())
    .map((x) => ({ value: x.settingType, id: x.id }));

  return (
    <div>
      <Space style={{ marginBottom: 10 }}>
        <SelectUserInput
          defaultValue={userInputValue}
          onChange={(user) => {
            setUserInputValue(user);
            setFilterSettings({
              ...filterSettings,
              userId: user?.id,
            });
          }}
          onClear={() => {
            setUserInputValue(undefined);
            setFilterSettings({
              ...filterSettings,
              userId: undefined,
            });
          }}
        />
        <AutoComplete
          style={{ width: 300 }}
          options={typeOptions}
          onSelect={(typeValue: string, selectedType: any) => {
            setFilterSettings({
              ...filterSettings,
              optionTypeId: selectedType?.id,
            });
            setTypeValue(typeValue);
          }}
          allowClear
          onClear={() => {
            setFilterSettings({
              ...filterSettings,
              optionTypeId: undefined,
            });
            setTypeValue("");
          }}
          value={typeValue}
          placeholder="Setting Type"
        />

        <DatePicker.RangePicker
          onChange={(date) => {
            const from = date?.[0];
            const to = date?.[1];

            setFilterSettings({
              ...filterSettings,
              from: from?.toISOString() || undefined,
              to: to?.toISOString() || undefined,
            });
          }}
          defaultValue={initialDateValue() as any}
        />
        <Button type="primary" onClick={filterHandler}>
          Filter
        </Button>
      </Space>

      <AuditTable
        companyId={Number(match.companyId)}
        companyAudits={companySettingStore.CompanySettingsAuditsResponse}
      />

      <div style={{ width: 270 }}>
        <Link
          to={RoutesPaths.Supervision.CompanyTrackSystemRoutes.BuildAddCompanySettingAudit(
            Number(match.companyId)
          )}
        >
          <Button
            type="primary"
            shape="round"
            size="large"
            style={{ display: "flex", alignItems: "center" }}
          >
            <BiPlusCircle size={20} style={{ marginRight: "10px" }} />
            <span> Add New Audit</span>
          </Button>
        </Link>
      </div>
      <div style={{ margin: "15px 0" }}>
        <UploadCsvFile />
      </div>
    </div>
  );
};

export default observer(CompanyTrackAuditPage);
