import { AutoComplete, Button, Col, DatePicker, Form, Input, message, Row, TimePicker, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { CampaignSettingTypeResponse } from "../../models/campaign-track-system/CampaignSettingTypeResponse";
import { RootStoreContext } from "../../stores/RootStoreContext";
import campaignSettingTypeService from "../../services/campaign-setting/CampaignSettingTypeService"
import { CampaignSettingTypeValueOption } from "../../models/campaign-track-system/CampaignSettingTypeValueOption";
import { observer } from "mobx-react-lite";
import { CampaignSettingsAuditCreateModel } from "../../models/campaign-track-system/CampaignSettingsAuditCreateModel";
import { CampaignSettingsStatus } from "../../models/campaign-track-system/CampaignSettingsStatus";
import CampaignSettingAuditService from "../../services/campaign-setting/CampaignSettingAuditService";
import moment from "moment";
import { Box } from "@material-ui/core";
import { FetchError } from "../../exceptions/AlgoSupervisionExceptions";
import { RoutesPaths } from "../../constants/RoutePaths";
import { useHistory } from "react-router-dom";

interface Props {
    campaignAuditId: string;
    campaignId: number;
}

const layout = {
    labelCol: { span: 4 },
};

const layoutType = {
    labelCol: { span: 8 },
};

const layoutValueOptionlabel = {
    labelCol: { span: 6 },
};

const AuditCampaignForm = (props: Props) => {

    const { campaignSettingStore, userStore } = useContext(RootStoreContext);

    const { campaignId, campaignAuditId } = props;

    const [selectedType, setSelectedType,] = useState<CampaignSettingTypeResponse>();
    const [valueOptionTypes, setValueOptionTypes] = useState<CampaignSettingTypeValueOption[]>();
    const [selectedValueOption, setSelectedValueOption] = useState<string | undefined>();
    const [caseNumber, setCaseNumber] = useState<string | undefined>();
    const [comment, setComment] = useState<string | undefined>();
    const [campaignSettingTypeTextValue, setCampaignSettingTypeTextValue,] = useState<string | undefined>("");

    const [date, setDate] = useState<any>((moment(Date.now())));

    const history = useHistory();

    const [typeOptions, setTypeOptions] = useState<any[]>();

    useEffect(() => {
        setTypeOptions(campaignSettingStore.CampaignSettingTypes
            ?.filter((x) =>
                x.settingType
                    .toLowerCase()
            )
            .map((x) => ({ value: x.settingType, id: x.id, dataType: x.dataType })))

    },[campaignSettingStore.CampaignSettingTypes])

    useEffect(() => {

        if (campaignSettingStore.updateOperationState) {
            setUpdateState();
            return;
        }
        cleanUpState();
    }, [campaignSettingStore.updateOperationState]);


    const setUpdateState = () => {
        const selectedAudit = campaignSettingStore.SelectedCampaignSettingAudit;

        const typeRes = campaignSettingStore.CampaignSettingTypes?.find(
            (x) => x.settingType == selectedAudit?.type
        );
        setSelectedType(typeRes);
        setCampaignSettingTypeTextValue(typeRes?.settingType);
        setSelectedValueOption(selectedAudit?.value);
        setCaseNumber(selectedAudit?.caseNumber);
        setComment(selectedAudit?.comment);
        setDate(moment(selectedAudit?.creationDate));

    };

    const setSelectedTypeOnSelection = async (data: string, selectedType: any) => {

        setCampaignSettingTypeTextValue(data);

        setSelectedType(selectedType);

        const valueOptions = await campaignSettingTypeService.GetSettingTypeValueOptionsPerType(selectedType?.id);
        setValueOptionTypes(valueOptions);

    }

    const onSubmit = async (e: React.MouseEvent) => {

        let auditCreateModel: CampaignSettingsAuditCreateModel = {
            campaignId: campaignId,
            creationDate: date,
            campaignSettingTypeId: selectedType?.id,
            caseNumber: caseNumber || "",
            status: CampaignSettingsStatus.Active,
            userId: userStore.User!.id,
            value: selectedValueOption,
            comment: comment
        };

        try {
            if (campaignSettingStore.updateOperationState) {

                const updatedAudit = await CampaignSettingAuditService.UpdateCampaignSettingsAudit(campaignAuditId, auditCreateModel);
                message.success("Campaign setting audit created successfully");
            }

            else {

                const createdAudit = await CampaignSettingAuditService.CreateCampaignSettingsAudit(auditCreateModel);
                message.success("Campaign setting audit updated successfully");
            }

            history.push(
                RoutesPaths.Supervision.CampaignTrackSystemRoutes.BuildCampaignRoute(
                    campaignId
                ));

        }
        catch (error) {
            if (error instanceof FetchError) {
                message.error(error.apiError.errorMessage);
            }
        }


        cleanUpState();
    }

    const cleanUpState = () => {
        setCaseNumber("");
        setComment("");
        setSelectedValueOption("");
        setDate(moment(Date.now()));
        setSelectedType(undefined);
        setCampaignSettingTypeTextValue("");
    };

    return (
        <Form
            {...layout}>

            <Input.Group>
                <Row style={{ marginTop: "5px" }}>
                    <Col span={12}>
                        <Typography.Title
                            level={4}
                            style={{ marginBottom: 35, marginLeft: 20 }}>
                            {campaignSettingStore.updateOperationState ? (
                                <span>Edit Audit</span>) : (
                                <span>Create Audit</span>
                            )}
                        </Typography.Title>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}  >
                        <Form.Item
                            {...layoutType}
                            label="Type"
                            rules={[
                                {
                                    required: true,
                                    message: "Please choose type",
                                },
                            ]}>
                            {<AutoComplete
                                options={typeOptions}
                                allowClear={true}
                                onSelect={setSelectedTypeOnSelection}
                                onSearch={setCampaignSettingTypeTextValue}
                                value={campaignSettingTypeTextValue}
                                filterOption={(inputValue, option) =>
                                    option!.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }

                                placeholder="Choose audit criterion"
                            />}
                        </Form.Item>
                    </Col>
                    <Col span={6} >
                        <Form.Item
                            {...layoutValueOptionlabel}
                            label={
                                selectedType?.dataType
                                    ? `Value (${selectedType?.dataType})`
                                    : "Value"
                            }
                            rules={[
                                {
                                    required: true,
                                    message: "Please choose value",
                                },
                            ]}>
                            <AutoComplete
                                options={valueOptionTypes?.map((x) => ({
                                    value: x.valueOption,
                                }))}
                                onSelect={(data: string) => {
                                    setSelectedValueOption(data);
                                }}
                                onSearch={(data: string) =>
                                    setSelectedValueOption(data)
                                }
                                filterOption={(inputValue, option) =>
                                    option!.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                allowClear={true}
                                placeholder="Choose value"
                                value={selectedValueOption}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Input.Group>

            <Row gutter={16} >
                <Col span={12}>
                    <Form.Item
                        label="Date Time"
                        rules={[
                            {
                                required: true,
                                message: "Please choose the dateTime",
                            },
                        ]}>
                        <DatePicker
                            style={{ width: "50%" }}
                            value={date}
                            onChange={(dateInput, dateString) => {
                                setDate(dateInput);
                            }}
                        />
                        <TimePicker
                            style={{ width: "50%" }}
                            value={date}
                            onChange={(time, timeString) => {
                                setDate(time);
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} >
                <Col span={12}>
                    <Form.Item
                        label="Case Number"
                    >
                        <Input
                            onChange={(e) =>
                                setCaseNumber(e.target.value)
                            }
                            value={caseNumber}
                            placeholder="123456"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} >
                <Col span={12}>
                    <Form.Item label="Comment">
                        <Input.TextArea
                            rows={4}
                            placeholder="Leave a comment to help you remember the reason for taking this decision."
                            onChange={(e) => setComment(e.target.value)}
                            value={comment}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                wrapperCol={{ offset: 2, span: 12 }}
                style={{ marginTop: 50 }}>
                <Button onClick={onSubmit} type="primary">
                    {campaignSettingStore.updateOperationState ? (
                        <span>Edit Audit</span>) : (
                        <span>Create Audit</span>
                    )}
                </Button>
            </Form.Item>
        </Form>
    )
}


export default observer(AuditCampaignForm);


