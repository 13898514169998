import { action, makeObservable, observable } from "mobx";

import { BreadCrumb } from "../models/BreadCrumb";
import { PopUp } from "../models/Popup";
import { RouteModel } from "../models/RouteModel";
import { routes } from "../routes/routes";
import { RouteManager } from "../services/RouteManager";

export enum Page {
    InputPage,
    PredictionPage,
    AdminPreload
}


export class UIStore {

    /**
     * Fields
     */
    private _routeManager: RouteManager;

    /**
     * Ctor
     */
    constructor() {
        this._routeManager = new RouteManager();
        makeObservable(this);
    }

    /**
     * Props
     */
    @observable
    public CurrentRoute: RouteModel = {} as RouteModel; //Initialized at root

    @observable
    public BreadCrumbs: BreadCrumb[] = []; // Initialized at root

    @observable
    public IsWebsiteLoading: boolean = false;

    @observable
    public PopUp?: PopUp;


    /**
     * Methods
     */

    @action
    AddBreadCrumbs(...breadCrumb: BreadCrumb[]): void {
        this.BreadCrumbs = [...this.BreadCrumbs, ...breadCrumb];
    }

    @action
    PopLatestBreadcrumb(): void {
        this.BreadCrumbs.pop();
    }

    @action
    RemoveBreadCrumbsUntil(breadCrumb: string | BreadCrumb) {
        const breadCrumbText: string = breadCrumb instanceof BreadCrumb ? breadCrumb.breadCrumbText : breadCrumb;
        this.BreadCrumbs = this.BreadCrumbs.slice(0, this.BreadCrumbs.findIndex(x => x.breadCrumbText == breadCrumbText) + 1);
    }

    @action
    SetCurrentRoute(pathName: string): void {
        const currentRoute: RouteModel = this._routeManager.GetCurrentRoute(pathName);

        this.CurrentRoute = currentRoute;
        this.BreadCrumbs = [...currentRoute.breadCrumbsPrefix];
    }

    @action
    SetPageTitle(title: string) {
        this.CurrentRoute.mainTitle = title;
    }

    @action
    SetIsWebsiteLoading(state: boolean) {
        this.IsWebsiteLoading = state;
    }

    @action
    SetPopUp(popUp: PopUp) {
        this.PopUp = popUp;
    }

    ClearPopUp() {
        this.PopUp = undefined;
    }

}