import { Col, Form, Input, Row, Select, Typography } from "antd";
import { useState } from "react";

import { IndeedCampaignObjectiveType } from "../../models/indeed-manager/IndeedCampaignObjectiveType";
import { IndeedCampaignStatus } from "../../models/indeed-manager/IndeedCampaignStatus";

const { Option } = Select;

const campaignObjectiveTooltipText = `The objective set for the campaign. The four types of objectives are: MAXIMUM - Maximize applicant volume, BALANCE - optimize equally for speed, quantity, and cost, Applicants Goal - target a specific number of applies, CPA Goal - target a specific cost per apply.`;

interface Props {
    isStatusEditable: boolean;
    isCampaignObjectiveTypeEditable: boolean;
    campaignObjectiveTypeDefaultValue: IndeedCampaignObjectiveType;
}

const CampaignDetailsInputs = (props: Props) => {
    const {
        isStatusEditable,
        isCampaignObjectiveTypeEditable,
        campaignObjectiveTypeDefaultValue,
    } = props;

    const [campaignObjectiveType, setCampaignObjectiveType] =
        useState<IndeedCampaignObjectiveType>(
            campaignObjectiveTypeDefaultValue
        );

    const isCampaignTargetInputDisabled = () => {
        return (
            campaignObjectiveType == IndeedCampaignObjectiveType.BALANCE ||
            campaignObjectiveType == IndeedCampaignObjectiveType.MAXIMUM
        );
    };

    /**
     * Components
     */

    return (
        <>
            <Row style={{ marginBottom: "5px" }}>
                <Col offset={2}>
                    <Typography.Title level={4}>
                        General Details
                    </Typography.Title>
                </Col>
            </Row>

            <Form.Item
                name={"campaignName"}
                label="Campaign Name"
                rules={[{ required: true }]}>
                <Input />
            </Form.Item>

            <Form.Item
                name={"campaignStatus"}
                label="Status"
                initialValue={IndeedCampaignStatus.Active}
                rules={[{ required: true }]}>
                <Select style={{ width: "100%" }} disabled={!isStatusEditable}>
                    <Option value={IndeedCampaignStatus.Active}>Active</Option>
                    <Option value={IndeedCampaignStatus.Paused}>Paused</Option>
                    <Option value={IndeedCampaignStatus.Deleted}>
                        Deleted
                    </Option>
                </Select>
            </Form.Item>

            <Form.Item
                required
                tooltip={campaignObjectiveTooltipText}
                label="Objective Type"
                name={"campaignObjective"}
                style={{ margin: 0 }}>
                <Input.Group>
                    <Row gutter={16}>
                        <Col span={16}>
                            <Form.Item
                                name={["campaignObjective", "type"]}
                                rules={[{ required: true }]}>
                                <Select
                                    disabled={!isCampaignObjectiveTypeEditable}
                                    defaultValue={campaignObjectiveType}
                                    style={{ width: "100%" }}
                                    onChange={(
                                        value: IndeedCampaignObjectiveType
                                    ) => {
                                        setCampaignObjectiveType(value);
                                    }}>
                                    <Option
                                        value={
                                            IndeedCampaignObjectiveType.BALANCE
                                        }>
                                        Balance
                                    </Option>
                                    <Option
                                        value={
                                            IndeedCampaignObjectiveType.MAXIMUM
                                        }>
                                        Maximum
                                    </Option>
                                    <Option
                                        value={
                                            IndeedCampaignObjectiveType.TARGET_APPLICATIONS
                                        }>
                                        Applicants Goal
                                    </Option>
                                    <Option
                                        value={
                                            IndeedCampaignObjectiveType.TARGET_COST_PER_APPLICATION
                                        }>
                                        CPA Goal
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={["campaignObjective", "target"]}
                                rules={[
                                    {
                                        required:
                                            !isCampaignTargetInputDisabled(),
                                    },
                                ]}>
                                <Input
                                    disabled={isCampaignTargetInputDisabled()}
                                    prefix={"$"}
                                    type="number"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Input.Group>
            </Form.Item>
        </>
    );
};

export default CampaignDetailsInputs;
