import { ActionsBuilder } from "../../general/supervision-table/types";
import { AffiliateTableDto } from "./AffiliateTable.dto";
import { History } from "history";
import { RoutesPaths } from "../../../constants/RoutePaths";
import { IoMdCut } from "react-icons/io";



type AffiliatesTableActionsContext = {
    history: History<unknown>;
}

export const AffiliatesTableActionsBuilder: ActionsBuilder<AffiliateTableDto, AffiliatesTableActionsContext> =
    ({ history }) => [
        {
            text: "Vendor Management",
            action: (affiliate) => {
                if (!history) {
                    throw new Error("history doesn't exist in context... Can't perform redirect");
                }

                history.push(
                   RoutesPaths.Supervision.VendorManagementRoutes.BuildAffiliateVendorManagementRoute(affiliate.affiliateId)

                );
            },
            Icon: IoMdCut,
            isDisplayed: () => true
        }
    ]