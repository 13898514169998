import { message } from "antd";
import { RoutesPaths } from "../../../../constants/RoutePaths";
import affiliateVendorService from "../../../../services/vendor-management/AffiliateVendorService";
import campaignVendorService from "../../../../services/vendor-management/CampaignVendorService";
import companyVendorService from "../../../../services/vendor-management/CompanyVendorService";
import groupVendorService from "../../../../services/vendor-management/GroupVendorService";

import { VendorManagementStore } from "../../../../stores/VendorManagementStore";

export const VendorManagmentContextInitializers = {

  InitializeAffiliateVendorsData: async (affiliateId: number, vendorManagementStore: VendorManagementStore, history: any) => {
    try {
      const _vendorsAffiliateData = await affiliateVendorService.GetAllAsync(affiliateId);
      vendorManagementStore.SetVendors(_vendorsAffiliateData);
    }
    catch (err) {
      history.push(RoutesPaths.Supervision.Affiliates);
      message.error(
        `Affiliate with ID: ${affiliateId} doesn't exist. Returned to home page`
      );
    }
  },


  InitializeCompanyVendorsData: async (
    companyId: number,
    vendorManagementStore: VendorManagementStore,
    history: any
  ) => {
    try {
      const _vendorsCompanyData = await companyVendorService.GetAllAsync(
        companyId
      );
      vendorManagementStore.SetVendors(_vendorsCompanyData);
    } catch (err) {
      history.push(RoutesPaths.Supervision.Companies);
      message.error(
        `Account with ID: ${companyId} doesn't exist. Returned to home page`
      );
    }
  },

  InitializeCampaignVendorsData: async (
    campaignId: number,
    vendorManagementStore: VendorManagementStore,
    history: any
  ) => {
    try {
      const _vendorsCampaignData = await campaignVendorService.GetAllAsync(
        campaignId
      );
      vendorManagementStore.SetVendors(_vendorsCampaignData);
    } catch (err) {
      history.push(RoutesPaths.Supervision.Campaigns);
      message.error(
        `Campaign with ID: ${campaignId} doesn't exist. Returned to home page`
      );
    }
  },

  InitializeGroupVendorsData: async (
    groupId: number,
    vendorManagementStore: VendorManagementStore,
    history: any
  ) => {
    try {
      const _vendorsGroupData = await groupVendorService.GetAllAsync(groupId);
      vendorManagementStore.SetVendors(_vendorsGroupData);
    } catch (err) {
      history.push(RoutesPaths.Supervision.GroupsRoutes.GroupRoute);
      message.error(
        `Group with ID: ${groupId} doesn't exist. Returned to home page`
      );
    }
  },

  InitializeClassificationType: async (
    campaignId: number,
    vendorManagementStore: VendorManagementStore,
    history: any
  ) => {
    try {
    } catch (err) {
      history.push(RoutesPaths.Supervision.Campaigns);
      message.error(
        `Campaign with ID: ${campaignId} doesn't exist. Returned to home page`
      );
    }
  },
};
