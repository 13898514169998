import { Select, Tooltip } from "antd";
import { useContext } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { EPerMode } from "../../models/company-attributes/attributes-types/EPerMode";
import { ManualActionRequestSource } from "../../models/manual-actions/ManualActionRequestSource";
import { StyledCheckbox, StyledColumnGroupWrapper, StyledInputGroupWrapper, StyledInputLabel, StyledInputNumberWrapper, StyledSubSection, StyledTitle, StyledToggleWrapper } from "./Styles";
import { observer } from "mobx-react-lite";
import { LimitAttrubute } from "../../models/company-attributes/attributes-types/LimitAttrubute";
import { PriorityLimitAttrubute } from "../../models/company-attributes/attributes-types/PriorityLimitAttrubute";


const { Option } = Select;

type Props = {
    priorityLimitAttrubute: PriorityLimitAttrubute | undefined;
    label: string;
    limitType: string
    defaultValueForExpressWay: number;
};

const AttributePriorityLimit = (props: Props) => {

    const { attributesManagementStore } = useContext(RootStoreContext);
    const { CompanyAttributesAggregation } = attributesManagementStore;
    const { expressWay } = CompanyAttributesAggregation;
    const { priorityLimitAttrubute ,label, limitType, defaultValueForExpressWay} = props;

    const onChangeMode = (value: EPerMode) => {
        if (priorityLimitAttrubute) {
            priorityLimitAttrubute.mode = value;
            attributesManagementStore.onChangeAttribute(priorityLimitAttrubute);
        }
    }

    const onChangeLimit = (value: any) => {
        if (priorityLimitAttrubute) {
            priorityLimitAttrubute.limit = value;
            attributesManagementStore.onChangeAttribute(priorityLimitAttrubute);
        }
    }

    const onIsPpriority = () => {
        if (priorityLimitAttrubute) {
            priorityLimitAttrubute.isPriorityLimit = !priorityLimitAttrubute.isPriorityLimit;
            attributesManagementStore.onChangeAttribute(priorityLimitAttrubute);
        }
    }

    const IsPpriorityChecked = () =>{ 
        return priorityLimitAttrubute?.isPriorityLimit || expressWay?.isExpressWay;
    }

    return (
        <>
            <StyledInputGroupWrapper >
                <StyledSubSection>
                    <StyledCheckbox
                        onClick={onIsPpriority}
                        checked={IsPpriorityChecked()} > {label}
                    </StyledCheckbox>
                </StyledSubSection>
            </StyledInputGroupWrapper>
            <Tooltip
                title={expressWay?.isExpressWay
                    ? 'Limits are not available for Express accounts'
                    : undefined} >
                <StyledInputGroupWrapper hidden={!IsPpriorityChecked()}>
                    <StyledColumnGroupWrapper>
                        <StyledInputLabel>{limitType}</StyledInputLabel>
                        <StyledInputNumberWrapper min={0}
                            disabled={expressWay?.isExpressWay}
                            onChange={onChangeLimit}
                            value={expressWay?.isExpressWay ? defaultValueForExpressWay : priorityLimitAttrubute?.limit} />
                    </StyledColumnGroupWrapper>
                    <StyledColumnGroupWrapper>
                        <StyledInputLabel>Per period</StyledInputLabel>
                        <StyledToggleWrapper>
                            <Select style={{ width: '100%' }}
                                disabled={expressWay?.isExpressWay}
                                onChange={onChangeMode}
                                value={priorityLimitAttrubute?.mode}>
                                <Option value={EPerMode.PerCycle}>Per Cycle</Option>
                                <Option value={EPerMode.PerCampaign}>Per Campaign</Option>
                                <Option value={EPerMode.PerCompany}>Per Company</Option>
                            </Select>
                        </StyledToggleWrapper>
                    </StyledColumnGroupWrapper>
                </StyledInputGroupWrapper>
            </Tooltip>
        </>
    )


}

export default observer(AttributePriorityLimit)