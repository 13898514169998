import { action, makeObservable, observable } from "mobx";
import { GroupBudgetBalance } from "../models/groups/GroupBudgetBalance";
import { GroupJobResponse } from "../models/groups/GroupJobResponse";
import { GroupResponse } from "../models/groups/GroupResponse";
import { Campaign } from "../models/operation/Campaign";

export class GroupStore {


    @observable
    public GroupsResponse: GroupResponse[] = [];

    @observable
    public GroupJobsResponse: GroupJobResponse[] = [];

    @observable
    public SelectedGroup?: GroupResponse;

    @observable
    public SelectedCampaign?: Campaign;

    @observable
    public GroupBudgetBalance: GroupBudgetBalance = {
        campaignBudget: 0,
        campaignBalance: 0
    };
    /**
     * ctor
     */
    constructor() {
        makeObservable(this);
    }

    @action
    public async SetGroups(Groups: GroupResponse[]) {
        this.GroupsResponse = Groups;
    }

    @action
    public async SetGroupJobs(GroupJobs: GroupJobResponse[]) {
        this.GroupJobsResponse = GroupJobs;
    }

    @action
    public async SetSelectedGroup(SelectedGroup?: GroupResponse)
    {
        this.SelectedGroup = SelectedGroup;
    }

    @action
    public async SetGroupBudgetBalance(GroupBudgetBalance: GroupBudgetBalance)
    {
        this.GroupBudgetBalance = GroupBudgetBalance;
    }

    @action
    public async SetSelectedCampaign(campaign: Campaign)
    {
        this.SelectedCampaign = campaign;
    }
}