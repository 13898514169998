import { Divider, Select, Tooltip } from "antd";
import { useContext } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { EPerMode } from "../../models/company-attributes/attributes-types/EPerMode";
import { ManualActionRequestSource } from "../../models/manual-actions/ManualActionRequestSource";
import { StyledCheckbox, StyledColumnGroupWrapper, StyledInputGroupWrapper, StyledInputLabel, StyledInputNumberWrapper, StyledSubSection, StyledTitle, StyledToggleWrapper } from "./Styles";
import { observer } from "mobx-react-lite";
import { LimitAttrubute } from "../../models/company-attributes/attributes-types/LimitAttrubute";


const { Option } = Select;


type Props = {
    customerRequestLimit?: number;
    customerRequestPriorityLimit?: number;
    limiAttrubute: LimitAttrubute | undefined;
    header: string
    limitType: string;
    defaultValueForExpressWay: number;
};

const AttributeLimit = (props: Props) => {

    const { attributesManagementStore } = useContext(RootStoreContext);
    const { CompanyAttributesAggregation } = attributesManagementStore;
    const { expressWay } = CompanyAttributesAggregation;
    const { limiAttrubute, header, customerRequestLimit,
        customerRequestPriorityLimit, limitType,defaultValueForExpressWay } = props;

    const onChangeMode = (value: EPerMode) => {
        if (limiAttrubute) {
            limiAttrubute.mode = value;
            attributesManagementStore.onChangeAttribute(limiAttrubute);
        }
    }

    const onRequestSource = (value: ManualActionRequestSource) => {
        if (limiAttrubute) {
            limiAttrubute.requestSource = value;
            attributesManagementStore.onChangeAttribute(limiAttrubute);
        }
    }

    const onChangeLimit = (value: any) => {
        if (limiAttrubute) {
            limiAttrubute.limit = value;
            attributesManagementStore.onChangeAttribute(limiAttrubute);
        }
    }

    return (
        <>
            <StyledTitle><span> {header} </span></StyledTitle>
            <Tooltip
                title={expressWay?.isExpressWay
                    ? 'limits is not available for ExpressWay accounts'
                    : undefined} >
                <StyledInputGroupWrapper>
                    <StyledColumnGroupWrapper>
                        <StyledInputLabel>{limitType}</StyledInputLabel>
                        <StyledInputNumberWrapper min={0}
                            disabled={expressWay?.isExpressWay}
                            onChange={onChangeLimit}
                            value={expressWay?.isExpressWay ? defaultValueForExpressWay : limiAttrubute?.limit} />
                    </StyledColumnGroupWrapper>
                    <StyledColumnGroupWrapper>
                        <StyledInputLabel>Per period</StyledInputLabel>
                        <StyledToggleWrapper>
                            <Select style={{ width: '100%' }}
                                disabled={expressWay?.isExpressWay}
                                onChange={onChangeMode}
                                value={limiAttrubute?.mode}>
                                <Option value={EPerMode.PerCycle}>Per Cycle</Option>
                                <Option value={EPerMode.PerCampaign}>Per Campaign</Option>
                                <Option value={EPerMode.PerCompany}>Per Company</Option>
                            </Select>
                        </StyledToggleWrapper>
                    </StyledColumnGroupWrapper>
                        <StyledColumnGroupWrapper>
                            <StyledInputLabel>Client General</StyledInputLabel>
                            <StyledInputNumberWrapper readOnly={true} disabled={true}
                                value={customerRequestLimit} />
                        </StyledColumnGroupWrapper>
                        <StyledColumnGroupWrapper>
                            <StyledInputLabel>Client Priority</StyledInputLabel>
                            <StyledInputNumberWrapper readOnly={true} disabled={true}
                                value={customerRequestPriorityLimit} />
                        </StyledColumnGroupWrapper>
                </StyledInputGroupWrapper>
            </Tooltip>

        </>
    )


}

export default observer(AttributeLimit)