import { Box } from "@material-ui/core";
import { Alert } from "antd";
import { observer } from "mobx-react-lite"
import { StyledTitle } from "../attributes-management/Styles";
import { SubAccountStatusId } from "../../models/indeed-manager/SubAccountStatusId";
import { IndeedSubAccount } from "../../models/indeed-manager/IndeedSubAccount";

interface Props {
    indeedSubAccount?: IndeedSubAccount;
    showSubAccountName?: boolean;
}

const IndeedSubAccountStatus = (props: Props) => {

    const { indeedSubAccount, showSubAccountName} = props;

    type AlertType = "warning" | "error" | "success" | "info" | undefined;

    // Helper function to generate alert message and type
    const getAlertMessageAndType = (indeedSubAccount?: IndeedSubAccount)
            : { message: string | null, type: AlertType, display: boolean } => {
                console.log(indeedSubAccount?.statusId);
        if (!indeedSubAccount) {
            return {
                message: "This account isn't connected to an active Indeed sub account.",
                type: "error",
                display: true
            };
        }

        const { statusId } = indeedSubAccount;

        switch (statusId) {
            case -1: // Idle
                return {
                    message: "This account is connected to an idle Indeed sub account.",
                    type: "warning",
                    display: true
                };
            case 0: // Revoked
                return {
                    message: "This account is connected to a revoked Indeed sub account.",
                    type: "error",
                    display: true
                };
            default : // Active
                return {
                    message: null, // No alert message needed
                    type: undefined,
                    display: false
                };
        }
    };

    const { message, type, display } = getAlertMessageAndType(indeedSubAccount);

    const statusText = (indeedSubAccount?.statusId != null && indeedSubAccount?.statusId != undefined)
    ? SubAccountStatusId[indeedSubAccount.statusId]
    : "Unknown";
    return (
        <>
            {display && (
                <Box display="flex" my={3}>
                    <Alert
                        description={message}
                        type={type}
                        showIcon
                    />
                </Box>
            )}
            <Box my={3} hidden={!showSubAccountName}>
                <StyledTitle>
                    <span>Indeed Connection: {indeedSubAccount?.indeedSubAccountName}, Status: {statusText}</span>
                </StyledTitle>
            </Box>
        </>
    );
};
export default observer(IndeedSubAccountStatus)


