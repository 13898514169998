import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import AuditCampaignForm from "../../../components/campaign-track-system/AuditCampaignForm";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import CampaignSettingBreadCrumbs from "./helpers/CampaignSetting.breadcrumbs";
import { CampaignSettingContextInitializers } from "./helpers/CampaignSettingContextInitializers";


const CampaignTrackAddAuditPage = () => {

    const { campaignSettingStore } = useContext(RootStoreContext);
    const match = useParams<{ campaignId: string; campaignAuditId: string }>();
    const history = useHistory();


    usePageInitialization({

        onInitAsync: async () => {

            await CampaignSettingContextInitializers.InitializeCampaignSettingTypes(
                campaignSettingStore,
                history
            )
                .then(async () =>
                    await campaignSettingStore.SetupdateOperationState(false)
                );
        },

        onCampaignContextCallBack: (campaign, uiStore) => {
            uiStore.SetPageTitle(
                `Campaign Audits for: ${campaign.campaignName}`
            );
            uiStore.AddBreadCrumbs(
                SupervisionBreadcrumbs.Campaigns(campaign.campaignName),
                CampaignSettingBreadCrumbs.CampaignSetting(campaign.campaignID),
                CampaignSettingBreadCrumbs.AddCampaignSettingAudit(
                    campaign.campaignID
                )
            );
        },
    })

    return (
        <div>
            <AuditCampaignForm
                campaignAuditId={match.campaignAuditId}
                campaignId={Number(match.campaignId)}
            />
        </div>)

}

export default observer(CampaignTrackAddAuditPage);