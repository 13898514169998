import { message } from "antd";
import { RoutesPaths } from "../../../../constants/RoutePaths";
import companyAttributesService from "../../../../services/attributes-management/CompanyAttributesService";
import { AttributesManagementStore } from "../../../../stores/AttributesManagementStore";
import companyPreferenceService from "../../../../services/attributes-management/CompanyPreference";
import { initializeAttributesIfNeeded } from "../../../../models/company-attributes/CompanyAttributesAggregation";


export const AttributesManagmentContextInitializers = {

  InitializeAttributesManagementData: async (companyId: number, attributesManagementStore: AttributesManagementStore, history: any) => {
    try {
      const _companyAttributesData = await companyAttributesService.getAllAsync(companyId);
      const initializedAttributesData = initializeAttributesIfNeeded(_companyAttributesData);
      await attributesManagementStore.SetAttributes(initializedAttributesData);
      
    }
    catch (err) {
      history.push(RoutesPaths.Supervision.Companies);
      message.error(
        `Company with ID: ${companyId} doesn't exist. Returned to home page`
      );
    }
  },

  InitializeCustomerRequestData: async (companyId: number, attributesManagementStore: AttributesManagementStore, history: any) => {
    try {
      const _customerRequestData = await companyAttributesService.getCustomerRequestsAsync(companyId);
      await attributesManagementStore.SetCustomerRequest(_customerRequestData);
      
    }
    catch (err) {
      history.push(RoutesPaths.Supervision.Companies);
      message.error(
        `Company with ID: ${companyId} doesn't exist. Returned to home page`
      );
    }
  },


  InitializeCompanyPreferenceData: async (companyId: number, attributesManagementStore: AttributesManagementStore, history: any) => {
    try {
      const _companyPreferenceData = await companyPreferenceService.getCompanyPreference(companyId);
      await attributesManagementStore.SetCompanyPreference(_companyPreferenceData);
    }
    catch (err) {
      history.push(RoutesPaths.Supervision.Companies);
      message.error(
        `Company with ID: ${companyId} doesn't exist. Returned to home page`
      );
    }
  },


  InitializeCompanyProfileData: async (companyId: number, attributesManagementStore: AttributesManagementStore, history: any) => {
    try {
      const _companyProfile= await companyPreferenceService.getCompanyProfile(companyId);
      await attributesManagementStore.SetCompanyProfile(_companyProfile);
    }
    catch (err) {
      history.push(RoutesPaths.Supervision.Companies);
      message.error(
        `Company with ID: ${companyId} doesn't exist. Returned to home page`
      );
    }
  },




}





