import { useContext, useState } from "react";
import { Select, Space, Typography } from "antd";
import { JobState } from "../../models/explainability/JobState";
import useOnInitAsync from "../../hooks/useOnInitAsync";
import explainabilityService from "../../services/explainability/ExplainabilityService";
import { RootStoreContext } from "../../stores/RootStoreContext";
import Loader from "../general/loader/Loader";
import { spacingUnit } from "../../assets/styles/theme";
import jobService from "../../services/operation/JobService";
import { Job } from "../../models/operation/Job";
import jobsStateService from "../../services/explainability/JobsStateService";
import { ActiveJob } from "../../models/explainability/ActiveJob";

const { Option } = Select;

interface Props {
    companyId?: number;
}

const JobSelectInput = (props: Props) => {
    const [options, setOptions] = useState<ActiveJob[]>([]);
    const { explainabilityStore } = useContext(RootStoreContext);

    useOnInitAsync(async () => {

        if (props.companyId != undefined) {
            const jobs = await jobsStateService.getAciveJobByAccount(props.companyId); /*await jobService.GetJobsByCompanyIdPagination(props.companyId, {
                limit: 1000,
                offset: 0,
            })*/
            setOptions(jobs)
        }

    }, [explainabilityStore.SelectedCompany]);

    return (
        <section>
                <Select
                    placeholder="Search Job Id"
                    mode="multiple"
                    allowClear
                    maxTagCount={1}
                    style={{ width: "300px" }}
                    onChange={(selectedOptionValues: string[]) => {
                        const jobsIds = selectedOptionValues.map(
                            (jobState) => ParsejobId(jobState)
                        );
                        explainabilityStore.SetSearchedJobsIds(jobsIds);
                    }}>
                    {options.map((jobState) => (
                        <Option
                            value={jobState.job_id}>
                            {jobState.job_id}
                        </Option>
                    ))}
                </Select>
        </section>
    );
};

const ParsejobId = (JobId: string) => {
    return Number(JobId);
};

export default JobSelectInput;