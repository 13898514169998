import { AiOutlineFileText } from "react-icons/ai";

import { userActionService } from "../../services/actions/UserActionService";
import { UIStore } from "../../stores/UIStore";
import { ActionsBuilder } from "../general/supervision-table/types";
import { UserActionsTableDto } from "./UserActionsTable.dto";

type UserActionsTableBuilderContext = {
    uiStore: UIStore;
};

export const UserActionsTableBuilder: ActionsBuilder<
    UserActionsTableDto,
    UserActionsTableBuilderContext
> = ({ uiStore }) => [
    {
        Icon: AiOutlineFileText,
        text: "View Data",
        isDisplayed: () => true,
        action: async (userActionDto) => {
            const userAction = await userActionService.GetById(
                userActionDto._id
            );
            uiStore.SetPopUp({
                body: userAction.data ?? "",
                title: "Action data",
                hideCancelButton: true,
            });
        },
    },
];
