import { Card, message, Typography } from "antd";

import { FetchError } from "../../../exceptions/AlgoSupervisionExceptions";
import { IndeedCampaignObjectiveType } from "../../../models/indeed-manager/IndeedCampaignObjectiveType";
import { IndeedCampaignObjectiveStrategy } from "../../../models/indeed-manager/strategy/IndeedCampaignObjectiveStrategy";
import { indeedStrategyManagementService } from "../../../services/indeed/IndeedStrategyManagementService";

const { Text } = Typography;

type Props = {
    indeedCampaignObjectiveStrategy: IndeedCampaignObjectiveStrategy;
    plCampaignId: number | string;
    onDelete: (objectiveId: number) => void;
};

function mapObjectiveTypeToReadableText(
    objectiveType: IndeedCampaignObjectiveType
) {
    switch (objectiveType) {
        case IndeedCampaignObjectiveType.BALANCE:
            return "Balanced";
        case IndeedCampaignObjectiveType.MAXIMUM:
            return "Maximum";
        case IndeedCampaignObjectiveType.TARGET_APPLICATIONS:
            return "Applicants";
        case IndeedCampaignObjectiveType.TARGET_COST_PER_APPLICATION:
            return "CPA";
        default:
            throw new Error(
                `Unsupported indeed objective type ${objectiveType}`
            );
    }
}

const IndeedStrategyCard = (props: Props) => {
    const { indeedCampaignObjectiveStrategy, plCampaignId, onDelete } = props;

    return (
        <Card
            size="small"
            title={`${mapObjectiveTypeToReadableText(
                indeedCampaignObjectiveStrategy.campaignObjectiveType
            )} Strategy`}
            bordered={false}
            actions={[
                <div
                    onClick={async () => {
                        try {
                            await indeedStrategyManagementService.DeleteStrategy(
                                plCampaignId,
                                indeedCampaignObjectiveStrategy.id
                            );
                            onDelete(indeedCampaignObjectiveStrategy.id);
                            message.success("Deleted strategy successfully");
                        } catch (error) {
                            if (error instanceof FetchError) {
                                message.error(error.apiError.errorMessage);
                            }
                        }
                    }}>
                    Delete
                </div>,
            ]}
            style={{ border: "1px solid #d9d9d9" }}>
            <Text style={{ fontSize: "16px" }}>
                Percent{" "}
                <Text
                    style={{
                        fontSize: "16px",
                    }}
                    strong>
                    {
                        indeedCampaignObjectiveStrategy.percentOfCampaignStrategies
                    }
                    %
                </Text>
            </Text>
            <br />
            {indeedCampaignObjectiveStrategy.targetStrategyOptionMinValue && (
                <>
                    <Text style={{ fontSize: "16px" }}>
                        Min Target Value{" "}
                        <Text
                            style={{
                                fontSize: "16px",
                            }}
                            strong>
                            {
                                indeedCampaignObjectiveStrategy.targetStrategyOptionMinValue
                            }
                        </Text>
                    </Text>
                    <br />
                </>
            )}

            {indeedCampaignObjectiveStrategy.targetStrategyOptionMaxValue && (
                <>
                    <Text style={{ fontSize: "16px" }}>
                        Max Target Value{" "}
                        <Text
                            style={{
                                fontSize: "16px",
                            }}
                            strong>
                            {
                                indeedCampaignObjectiveStrategy.targetStrategyOptionMaxValue
                            }
                        </Text>
                    </Text>
                    <br />
                </>
            )}
        </Card>
    );
};

export default IndeedStrategyCard;
