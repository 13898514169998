import { action, computed, makeObservable, observable } from "mobx";
import { CompanyAttributesAggregation } from "../models/company-attributes/CompanyAttributesAggregation";
import { BaseAttribute } from "../models/company-attributes/attributes-types/BaseAttribute";
import { CompanyPreferenceDto } from "../models/company-attributes/CompanyPreferenceDto";
import { CompanyProfile } from "../models/company-attributes/CompanyProfile";
import { CustomerCampaignAttributesAggregation } from "../models/company-attributes/CustomerCampaignAttributesAggregation";



export class AttributesManagementStore {

    @observable
    CompanyAttributesAggregation: CompanyAttributesAggregation = {};

    @observable
    CustomerCampaignAttributesAggregation: CustomerCampaignAttributesAggregation = {};

    @observable
    CompanyPreference: CompanyPreferenceDto = {};

    @observable
    CompanyProfile: CompanyProfile = {};

    constructor() {
        makeObservable(this);
    }


    @action
    public onChangeAttribute = (baseAttribute: BaseAttribute) => {
        baseAttribute.isEdited = true;
    }

    @action
    public async SetAttributes(attributes: CompanyAttributesAggregation) {
        this.CompanyAttributesAggregation = attributes;
    }

    @action
    public async SetCustomerRequest(customerRequest: CustomerCampaignAttributesAggregation) {
        this.CustomerCampaignAttributesAggregation = customerRequest;
    }

    @action
    public async SetCompanyPreference(companyPreferenceDto: CompanyPreferenceDto) {
        this.CompanyPreference = companyPreferenceDto;
    }

    @action
    public async SetCompanyProfile(companyProfile: CompanyProfile) {
        this.CompanyProfile = companyProfile;
    }

    @action
    public getCompanyPreference(): CompanyPreferenceDto {
        return this.CompanyPreference;
    }

    @action
    public getEditedAttributes(userId: number |undefined): Partial<CompanyAttributesAggregation> {

        const editedAttributes: Partial<CompanyAttributesAggregation> = {};
        editedAttributes.userId = userId;
        editedAttributes.companyId = this.CompanyAttributesAggregation.companyId;

        for (const key in this.CompanyAttributesAggregation) {
            const attribute = this.CompanyAttributesAggregation[key];
            if (attribute && attribute.isEdited == true) {
                editedAttributes[key] = attribute
            }
        }

        return editedAttributes;
    }

    public resetIsEdited = () => {
        for (const key in this.CompanyAttributesAggregation) {
            const attribute = this.CompanyAttributesAggregation[key];
            if (attribute && attribute.isEdited !== undefined) {
                attribute.isEdited = false;
            }
        }
    }

}






