import { Button, message, Radio, Tooltip, Typography } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useHistory } from "react-router-dom";

import { RoutesPaths } from "../../constants/RoutePaths";
import { FetchError } from "../../exceptions/AlgoSupervisionExceptions";
import { IndeedAttachJobsToCampaignEventPayload } from "../../models/indeed-manager/IndeedAttachJobsToCampaignEventPayload";
import indeedService from "../../services/indeed/IndeedService";
import { RootStoreContext } from "../../stores/RootStoreContext";
import IndeedJobsToCampaignTransfer from "./IndeedJobsToCampaignTransfer";

const uniqueRadioExplanation =
    'If "Unique" is selected, all the jobs that are attached to this campaign are automatically detached from every other campaign (if they were attached)';

const multipleRadioExplanation =
    'If "Multiple" is selected, All the jobs that are attached to this campaign will NOT be detached from other campaigns';

enum AttachmentType {
    unique = "unique",
    multiple = "multiple",
}

interface Props {
    plCampaignId: number;
    indeedCampaignId: string;
}

const IndeedAttachJobsForm = (props: Props) => {
    const { plCampaignId, indeedCampaignId } = props;
    const { userStore, indeedStore } = useContext(RootStoreContext);
    const history = useHistory();

    const [campaignJobsIds, setCampaignJobsIds] = useState<number[]>([]);
    const [radioAttachmentType, setRadioAttachmentType] =
        useState<AttachmentType>(AttachmentType.unique);

    const handleAttachJobsClick = async () => {
        const attachJobsEventPayload =
            new IndeedAttachJobsToCampaignEventPayload(
                indeedCampaignId,
                userStore.User!.id,
                campaignJobsIds,
                radioAttachmentType == AttachmentType.unique ? true : false,
            );

        try {
            var response = await indeedService.AttachJobsToCampaign(attachJobsEventPayload);
            message.success(response.body);
            history.push(
                RoutesPaths.Supervision.IndeedRoutes.BuildCampaignRoute(
                    plCampaignId
                )
            );
        } catch (error) {
            if (error instanceof FetchError) {
                message.error(error.apiError.errorMessage);
            }
        }
    };

    return (
        <div>
            <Typography.Title level={4} style={{ marginBottom: 15 }}>
                Attach Jobs for Indeed Campaign
            </Typography.Title>

            <IndeedJobsToCampaignTransfer
                onChange={(jobIds) => {
                    setCampaignJobsIds(jobIds);
                }}
                onJobsLoad={(jobIds) => {
                    setCampaignJobsIds(jobIds);
                }}
                plCampaignId={plCampaignId}
                indeedCampaignId={indeedCampaignId}
            />

            {/* NOTE: currently this feature is flagged off. All attachments are unique */}
            {/* <Typography.Title level={5} style={{ margin: "15px 0 5px" }}>
                Attachment Type
            </Typography.Title>
            <div>
                <Radio.Group
                    onChange={(e) => {
                        switch (e.target.value) {
                            case AttachmentType.unique:
                                setRadioAttachmentType(AttachmentType.unique);
                                break;
                            case AttachmentType.multiple:
                                setRadioAttachmentType(AttachmentType.multiple);
                                break;
                            default:
                                throw new Error(
                                    "unsupported radio button value"
                                );
                        }
                    }}
                    value={radioAttachmentType}>
                    <Tooltip placement="bottom" title={uniqueRadioExplanation}>
                        <Radio value={AttachmentType.unique}>Unique</Radio>
                    </Tooltip>

                    <Tooltip
                        placement="bottom"
                        title={multipleRadioExplanation}>
                        <Radio value={AttachmentType.multiple}>Multiple</Radio>
                    </Tooltip>
                </Radio.Group>
            </div> */}

            <div>
                <Button
                    type="primary"
                    onClick={handleAttachJobsClick}
                    style={{ marginTop: 50, marginRight: 15 }}>
                    Attach Jobs
                </Button>

                <Button type="default" style={{ marginTop: 50 }}>
                    <CopyToClipboard
                        text={campaignJobsIds.join(",")}
                        onCopy={() => {
                            message.info("Copied to clipboard");
                        }}>
                        <span>Copy Active Job Ids</span>
                    </CopyToClipboard>
                </Button>
            </div>
        </div>
    );
};

export default observer(IndeedAttachJobsForm);
