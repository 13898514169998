import moment from "moment";

import { ISODateTime } from "../types/types";

export const dateFormat = "MMM Do YYYY";
export const dateTimeFormat = "MMM Do YYYY, h:mm:ss a";
export const minDate = moment.utc("0001-01-01");
     


export const FormatDate = (date: ISODateTime) => {

   return moment(date).format(dateFormat);
}

export const FormatDateTime = (date: ISODateTime) => {
    // const format = "MM/DD/YYYY, h:mm:ss a";
    return moment(date).format(dateTimeFormat)
}

export const FormatDateIfValid = (date: ISODateTime) => {
    if (moment(date).isAfter(minDate)) {
        return FormatDate(date)
     }
     else{
         return'';
     }
 }