import { action, makeObservable, observable } from "mobx";
import { CampaignSettingsAuditResponse } from "../models/campaign-track-system/CampaignSettingsAuditResponse";
import { CampaignSettingTypeResponse } from "../models/campaign-track-system/CampaignSettingTypeResponse";
import { CampaignSettingTypeValueOption } from "../models/campaign-track-system/CampaignSettingTypeValueOption";



export class CampaignSettingStore
{

@observable
public CampaignSettingTypes :  CampaignSettingTypeResponse[] = [];

@observable
public CampaignSettingTypeValueOptions :  CampaignSettingTypeValueOption[] = [];

@observable
public CampaignSettingsAuditsResponse : CampaignSettingsAuditResponse[] = []; 

@observable
public SelectedCampaignSettingAudit? : CampaignSettingsAuditResponse; 

@observable
public  updateOperationState?: boolean ;
/**
 * ctor
 */
constructor() {
        makeObservable(this);    
}


@action
public async SetCampaignSettingTypes(campaignSettingTypes : CampaignSettingTypeResponse[])
{
    this.CampaignSettingTypes = campaignSettingTypes;
}

@action
public async SetCampaignSettingTypeValueOptions(campaignSettingTypeValueOptions : CampaignSettingTypeValueOption[])
{
    this.CampaignSettingTypeValueOptions = campaignSettingTypeValueOptions;
}

@action
public async SetCampaignSettingsAudits(CampaignSettingsAudits : CampaignSettingsAuditResponse[])
{
    this.CampaignSettingsAuditsResponse = CampaignSettingsAudits;
}


@action
public async SetSelectedCampaignSettingAudit(campaignSettingAudit?: CampaignSettingsAuditResponse) {
    this.SelectedCampaignSettingAudit = campaignSettingAudit;
}


@action
public async SetupdateOperationState(updateOperationState: boolean) {
    this.updateOperationState = updateOperationState;
}


}