import settings from "../../config/AppSettings/SettingsFactory";
import { EntityDistributionSettings } from "../../models/vendor-management/settings/EntityDistributionSettings";
import { EntityVendorRequest } from "../../models/vendor-management/EntityVendorRequest";
import { EntityVendorResponse } from "../../models/vendor-management/EntityVendorResponse";
import { BaseService } from "../BaseService";
import { EntityDistributionSettingsResponse } from "../../models/vendor-management/settings/EntityDistributionSettingsResponse";
import { ValidAction } from "../../models/ValidAction";
import { ManualActionRequestSource } from "../../models/manual-actions/ManualActionRequestSource";

export class CompanyVendorService extends BaseService {

  async GetAllAsync(companyId: number) {
    const vendorManagementdata = await this.Get<EntityVendorResponse[]>(
      `/all/${companyId}`
    );
    return vendorManagementdata;
  }

  async SetCompanysVendors(
    vendorsWithDecision: EntityVendorRequest[],
    companyId: number, 
    manualActionRequestSource: ManualActionRequestSource
  ) {
    const vendorManagementdata = await this.Post<EntityVendorRequest[]>(
      `/${companyId}?manualActionRequestSource=${manualActionRequestSource}`,
      vendorsWithDecision
    );
    return vendorManagementdata;
  }

  // Settings
  async GetCompanySettingsAsync(companyId: number) {
    const vendorManagementdata = await this.Get<EntityDistributionSettings>( `/settings/${companyId}`);
    return vendorManagementdata;
  }

  async SetCompanyDistributionSettings(entityDistributionSettings: EntityDistributionSettings, companyId: number) : Promise<EntityDistributionSettings>{
    const entitySettings = await this.Post<EntityDistributionSettings>(
      `/settings/${companyId}`,
      entityDistributionSettings
    );
    return entitySettings;
  }

  async GetEffectedEntitesChangeModeAsync(entityDistributionSettings: EntityDistributionSettings, companyId: number) {
    const entitySettingsResponse = await this.Post<EntityDistributionSettingsResponse>(
      `/settings-validation/${companyId}`,
      entityDistributionSettings
    );
    return entitySettingsResponse;
  }

  async ResetCompanyVendors(companyId: number) {
    const dataState = await this.Delete(`/reset/${companyId}`);
    return dataState;
  }

  async ChangeSettingsIsValidValidationAsync(entityDistributionSettings: EntityDistributionSettings, companyId: number) {
    const entitySettingsResponse = await this.Post<ValidAction>(
      `/settings-action-validation/${companyId}`,entityDistributionSettings)
    return entitySettingsResponse;
  }

}

const companyVendorService = new CompanyVendorService(
  "CompanyVendors",
  settings.vendorManagementUrl
);
export default companyVendorService;
