import { Affiliate } from "../../models/operation/Affiliate";
import { BaseService } from "../BaseService";


export class AffiliateService extends BaseService {
    
    async GetAffiliates() {

        const AffiliateData = await this.Get<Affiliate[]>("");
        return AffiliateData;
    }

    async GetById(affiliateId: number): Promise<Affiliate> {
        const affilaite = await this.Get<Affiliate>(`/${affiliateId}`);
        return affilaite;
    }
}

const affiliateService = new AffiliateService("Affiliate");
export default affiliateService;

