import React from 'react'

interface Props {
    
}


/**
 * TODO: Customize that page
 */
const NotFoundPage = (props: Props) => {
    return (
        <div>
            404 Page Not Found
        </div>
    )
}

export default NotFoundPage
