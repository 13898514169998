import React from "react";

import { Box } from "@material-ui/core";

import CampaignSelectInput from "../../../components/supervision/campaigns/CampaignSelectInput";
import CampaignsTable from "../../../components/supervision/campaigns/CampaignsTable";
import CampaignsTableIconActions from "../../../components/supervision/campaigns/CampaignsTableIconActions";
import usePageInitialization from "../../../hooks/usePageInitialization";

interface Props {}

const CampaignsContainer = (props: Props) => {
    usePageInitialization({
        pageTitle: "Algo Supervision - Campaigns"
    })

    return (
        <div>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <CampaignSelectInput />

                <CampaignsTableIconActions />
            </Box>

            <CampaignsTable />
        </div>
    );
};

export default CampaignsContainer;
