import { EntityRecordDetailed } from "../../../models/EntityRecord"

export type EntityRecordHierarchyType<T> = {
    entityLevelName: "Campaigns" | "Groups" | "Jobs",
    entities: EntityRecordDetailed<T>[]
    // entities: EntityRecordDetailed<{ campaignName: string, startDate: string, endDate: string }>[]
}

export const campaignsMock: EntityRecordHierarchyType<{ campaignName: string, startDate: string, endDate: string }> = {
    "entityLevelName": "Campaigns",
    "entities": [
        {
            "entityTypeId": 5,
            "entityId": 1,
            "entityName": "Blount memorial Hospital",
            "entityData": {
                "campaignName": "Blount memorial Hospital",
                "startDate": "2017-11-26 00:00:00",
                "endDate": "2018-11-25 23:59:59"
            }

        },
        {
            "entityTypeId": 5,
            "entityId": 2,
            "entityName": "Parrish Medical Center",
            "entityData": {
                "campaignName": "Parrish Medical Center",
                "startDate": "2017-07-24 00:00:00",
                "endDate": "2018-07-28 23:59:59"
            }

        },
        {
            "entityTypeId": 5,
            "entityId": 3,
            "entityName": "Advance Financial",
            "entityData": {
                "campaignName": "Advance Financial",
                "startDate": "2018-11-17 00:00:00",
                "endDate": "2019-04-23 23:59:59"
            }

        },
        {
            "entityTypeId": 5,
            "entityId": 4,
            "entityName": "Lazer Spot-Pp",
            "entityData": {
                "campaignName": "Lazer Spot-Pp",
                "startDate": "2018-02-11 00:00:00",
                "endDate": "2019-05-28 23:59:59"
            }

        },
        {
            "entityTypeId": 5,
            "entityId": 5,
            "entityName": "Meridian Health",
            "entityData": {
                "campaignName": "Meridian Health",
                "startDate": "2017-01-02 00:00:00",
                "endDate": "2017-04-28 23:59:59"
            }

        },
        {
            "entityTypeId": 5,
            "entityId": 7,
            "entityName": "Mesquite Gaming",
            "entityData": {
                "campaignName": "Mesquite Gaming",
                "startDate": "2016-07-28 00:00:00",
                "endDate": "2016-12-28 23:59:59"
            }

        },
        {
            "entityTypeId": 5,
            "entityId": 8,
            "entityName": "Integrity Staffing",
            "entityData": {
                "campaignName": "Integrity Staffing",
                "startDate": "2017-11-26 00:00:00",
                "endDate": "2018-11-25 23:59:59"
            }

        },
        {
            "entityTypeId": 5,
            "entityId": 1,
            "entityName": "Classic Carriers",
            "entityData": {
                "campaignName": "Classic Carriers",
                "startDate": "2017-11-26 00:00:00",
                "endDate": "2018-11-25 23:59:59"
            }

        },
        {
            "entityTypeId": 5,
            "entityId": 1,
            "entityName": "Witham Memorial Hospital",
            "entityData": {
                "campaignName": "Witham Memorial Hospital",
                "startDate": "2017-11-26 00:00:00",
                "endDate": "2018-11-25 23:59:59"
            }

        },
        {
            "entityTypeId": 5,
            "entityId": 2,
            "entityName": "Avis Budget Group",
            "entityData": {
                "campaignName": "Avis Budget Group",
                "startDate": "2017-11-26 00:00:00",
                "endDate": "2018-11-25 23:59:59"
            }

        }
    ]
}