import "./JobsTransfer.scss";

import { Transfer } from "antd";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { FiInfo } from "react-icons/fi";

import { Box } from "@material-ui/core";

import useOnInitAsync from "../../hooks/useOnInitAsync";
import { Job } from "../../models/operation/Job";
import jobService from "../../services/operation/JobService";
import { isNumber } from "../../utils/NumbersHelper";
import Loader from "../general/loader/Loader";
import groupJobsService from "../../services/groups/GroupJobsService";
import { GroupJobResponse } from "../../models/groups/GroupJobResponse";
import Paragraph from "antd/lib/typography/Paragraph";

interface Props {
    plCampaignId: number;
    groupId?: number;
    onChange: (jobIds: number[]) => void;
    onJobsLoad?: (jobIds: number[]) => void;
}

const GroupJobTransfer = (props: Props) => {
    const { plCampaignId, groupId, onChange } = props;
    const [hasOptionsLoaded, setHasOptionsLoaded] = useState<boolean>(false);
    const [jobOptions, setJobOptions] = useState<GroupJobResponse[]>([]);
    const [selectedJobIds, setSelectedJobIds] = useState<any>();

    useOnInitAsync(async () => {

        if (groupId) {
            const attachedJobs = await groupJobsService.GetAllByGroupIdAsync(groupId);
            setSelectedJobIds(attachedJobs.map((jobId) => jobId.jobId.toString()));
        }

        //TODO: refactor to a Paginator service (this method exhausts all pages)
        const jobsCount = await jobService.GetCountAsync({
            campaignId: plCampaignId,
        });
        let tasks = [];
        let batchSize = 1000;
        for (let index = 0; index < jobsCount.jobsCount; index += batchSize) {
            tasks.push(
                groupJobsService.GetJobsByCampaignIdPagination(plCampaignId, {
                    limit: batchSize,
                    offset: index,
                })
            );
        }
        const jobBatches = await Promise.all(tasks);
        const _jobs = jobBatches.reduce((prev, cur) => {
            return [...prev, ...cur];
        }, []);

        setJobOptions(_jobs);
        setHasOptionsLoaded(true);
    });



    const filterOption = (inputValue: string, option: any) => {
        if (isNumber(inputValue)) {
            return searchByJobId(Number(inputValue));
        }

        if (isJobIdsInput(inputValue)) {
            const jobIds: number[] = inputValue
                .split(",")
                .map((jobId) => Number(jobId.trim()));
            return searchByJobIdsMultiple(jobIds);
        }
        if (inputValue.length >= 2) {
            return searchByJobTitleTextOrLocation();
        }
        return true;

        function searchByJobTitleTextOrLocation() {
            return (
                (getJobLocation(option.job).toLowerCase() ?? "").indexOf(
                    inputValue.toLowerCase()
                ) > -1 ||
                (option.job.extJobTitleText?.toLowerCase() ?? "").indexOf(
                    inputValue.toLowerCase()
                ) > -1
            );
        }

        function searchByJobId(inputValue: number) {
            return option.job.jobId == inputValue;
        }

        function isJobIdsInput(inputValue: string): boolean {
            return new RegExp(/^[\d, ]+$/gm).test(inputValue);
        }

        function searchByJobIdsMultiple(jobIds: number[]): boolean {
            return jobIds.some((x) => x == option.job.jobId);
        }
    };

    const handleChange = (jobIdsRightSide: string[]) => {
        setSelectedJobIds(jobIdsRightSide);
        onChange(jobIdsRightSide.map((jobId) => Number(jobId)));
    };

    const handleSearch = (
        direction: "left" | "right",
        searchValue: string
    ) => { };

    const getJobLocation = (job: Job) => {

        return `${job.jobLocation.cityName}, ${job.jobLocation.stateShortName}`;

    };

    const getGroupId = (groupJobResponse: GroupJobResponse) => {
        if (groupJobResponse.groupId == undefined || !groupJobResponse.isActive) {
            return '';
        }
        return `Group Name: ${groupJobResponse.groupName}`;
    };

    const IsJobAttachedToGroup = (groupJobResponse: GroupJobResponse) => {
        if (groupJobResponse.isActive) {
            return (
                <FiInfo style={{ color: 'blue', alignItems: "baseline" }} />
            )
        }
        else return '';

    }

    return (
        <Box width={800}>
            <Box >
                {!hasOptionsLoaded ? (
                    <Loader position="normal" />
                ) : (
                    <Transfer
                        dataSource={jobOptions.map((groupJob) => ({
                            key: groupJob.job.jobId.toString(),
                            ...groupJob,
                        }))}
                        showSearch
                        filterOption={filterOption}
                        targetKeys={selectedJobIds}
                        onChange={handleChange}
                        onSearch={handleSearch}
                        pagination
                        className="jobs-transfer"
                        render={(groupJob) => (
                            <Paragraph
                                ellipsis={{
                                    rows: 1,
                                }}
                                title={`${getGroupId(groupJob)} | (${groupJob.job.jobId}) ${groupJob.job.extJobTitleText
                                    } | Location: ${getJobLocation(groupJob.job)}`}>
                                {IsJobAttachedToGroup(groupJob)} ({groupJob.job.jobId}) {groupJob.job.extJobTitleText} |
                                Location: {getJobLocation(groupJob.job)}

                            </Paragraph>
                        )}
                    />
                )}
            </Box>
        </Box>
    );
};

export default observer(GroupJobTransfer);