import { BudgetType } from "../../models/groups/BudgetType";
import { GroupResponse } from "../../models/groups/GroupResponse";
import { GroupStatus } from "../../models/groups/GroupStatus";
import { ISODateTime } from "../../types/types";
import { FormatDate } from "../../utils/DateHelpers";
import { TableDto } from "../general/supervision-table/Table.dto";


export class GroupTableDto extends TableDto{
    constructor(
       public  groupId: number,
       public  campaignId: number,
       public  groupName: string,
       public  status: string,
       public  userName: string,
       public  budget: number,
       public  budgetType: string,
       public  creationDate : ISODateTime,
    )
    {
        super();
    }
    public static MapFromGroup(groupResponse: GroupResponse) {
        return new GroupTableDto(
            groupResponse.groupId,
            groupResponse.campaignId,
            groupResponse.groupName,
            GroupStatus[Number(groupResponse.isActive)],
            groupResponse.userName,
            groupResponse.groupBudget.amount,
            BudgetType[groupResponse.groupBudget.budgetType],
            FormatDate(groupResponse.creationDate),

        )
    }

}