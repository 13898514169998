
export class LocalStorageService {
    SetItemString(key: string, value: string) {
        window.localStorage.setItem(key, value);
    }

    SetItemObject(key: string, value: object) {
        window.localStorage.setItem(key, JSON.stringify(value));
    }

    GetItemString(key: string): string | undefined {
        const item = window.localStorage.getItem(key);
        if (item == null) {
            return undefined;
        }
        return item;
    }

    GetItemObject<T>(key: string): T | undefined {
        const item = window.localStorage.getItem(key);
        if (item == null) {
            return undefined;
        }
        return JSON.parse(item);
    }
}

export default new LocalStorageService();