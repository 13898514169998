import { Empty } from "antd";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { CompanySettingsAuditResponse } from "../../models/company-track-system/CompanySettingsAuditResponse";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { CompanySettingActionsBuilder } from "./CompanySettingAuditTable.actions";
import { CompanySettingsAuditDto } from "./CompanySettingsAudit.dto";

import { observer } from "mobx-react-lite";
import SupervisionTable from "../general/supervision-table/SupervisionTable";

interface Props {
  companyId: number;
  companyAudits: CompanySettingsAuditResponse[];
}

const CompanySettingAuditTable = (props: Props) => {
  const { companyAudits, companyId } = props;
  const { companySettingStore, uiStore } = useContext(RootStoreContext);
  const history = useHistory();

  if (companyAudits.length == 0) {
    return (
      <Empty
        style={{ marginTop: "40px" }}
        description={`No Company Setting Audits`}
      />
    );
  }

  return (
    <div>
      <SupervisionTable
        dataSource={companyAudits.map(
          CompanySettingsAuditDto.MapFromCompanySettingsAudit
        )}
        rowActionSettings={{
          actions: CompanySettingActionsBuilder({
            history: history,
            companySettingStore: companySettingStore,
            companyId: companyId,
            uiStore: uiStore,
          }),
          showActionsOnLastColumn: true,
          showActionsOnRightClick: true,
        }}
      />
    </div>
  );
};

export default observer(CompanySettingAuditTable);
