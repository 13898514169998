import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";

import { Box, Paper, Typography } from "@material-ui/core";

import useKeyboardListener from "../../../hooks/useKeyboardListener";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { Guid } from "../../../utils/Guid";
import AppBreadCrumbs from "../app-bread-crumbs/AppBreadCrumbs";
import Loader from "../loader/Loader";
import { useStyles } from "./PageHeader.styles";

interface Props { }

const PageHeader = (props: Props) => {
    const { uiStore } = useContext(RootStoreContext);
    const [isPageHeaderActive, setIsPageHeaderActive] = useState<boolean>(true);

    const classes = useStyles();

    /**
     * Functions
     */
    const GetHeaderClassName = (): string => {
        let className = classes.pageHeader;
        className += " "; //space
        className += isPageHeaderActive
            ? classes.pageHeaderOn
            : classes.pageHeaderOff;
        return className;
    };

    /**
     * Short cuts
     */
    useKeyboardListener(Guid.NewGuid(), ["Alt", "h"], () => {
        setIsPageHeaderActive(!isPageHeaderActive);
    });

    return (
        <Paper
            elevation={3}
            variant="outlined"
            className={GetHeaderClassName()}>
            <Box pt={3} px={6}>
                <Typography variant="h5" component="h1">
                <p>{uiStore.CurrentRoute.mainTitle}</p>
                </Typography>

                <AppBreadCrumbs />
            </Box>
        </Paper>
    );
};

export default observer(PageHeader);
