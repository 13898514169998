import { APIResponse } from "../../models/APIResponse";
import { CompanySettingsAuditCreateModel } from "../../models/company-track-system/CompanySettingsAuditCreateModel";
import { CompanySettingsAuditResponse } from "../../models/company-track-system/CompanySettingsAuditResponse";
import { PaginationParams } from "../../models/pagination/PaginationParams";
import { ISODateTime } from "../../types/types";
import { BaseService } from "../BaseService";

export class CompanySettingAuditService extends BaseService {
  async GetCompanySettingsAuditByPagination(
    companyId: number,
    paginationOptions: PaginationParams
  ) {
    const companySettingsAuditData = await this.Post<
      CompanySettingsAuditResponse[]
    >(`/paginate/${companyId}`, paginationOptions);
    return companySettingsAuditData;
  }

  async UpdateCompanySettingsAudit(
    auditId: string,
    auditUpdateModel: CompanySettingsAuditCreateModel
  ): Promise<CompanySettingsAuditResponse> {
    const UpdatedAudit = await this.Put<CompanySettingsAuditResponse>(
      `/${auditId}`,
      auditUpdateModel
    );
    return UpdatedAudit;
  }

  async CreateCompanySettingsAudit(
    campaignSettingsAuditCreateModel: CompanySettingsAuditCreateModel
  ): Promise<CompanySettingsAuditResponse> {
    const createdAudit = await this.Post<CompanySettingsAuditResponse>(
      "",
      campaignSettingsAuditCreateModel
    );
    return createdAudit;
  }

  async DeleteCompanySettingsAudit(
    companyId: number,
    auditId: string
  ): Promise<CompanySettingsAuditResponse> {
    const deletedAudit = await this.Delete<CompanySettingsAuditResponse>(
      `/${companyId}/${auditId}`
    );
    return deletedAudit;
  }

  async UploadFile(file: any, userId: string): Promise<APIResponse<string>> {
    const fromData = new FormData();
    fromData.append("csvFile", file);
    fromData.append("userId", userId);

    const fileUploaded = await this.Post<APIResponse<string>>(
      `/bulk-upload`,
      fromData
    );

    return fileUploaded;
  }

  async GetFilteredCompanySettingsAudits(getActionsRequest: {
    userId?: number;
    from?: ISODateTime;
    to?: ISODateTime;
    optionTypeId?: string;
    companyId?: number;
  }): Promise<CompanySettingsAuditResponse[]> {
    let queryString = "";

    if (getActionsRequest.companyId) {
      queryString += `&companyId=${getActionsRequest.companyId}`;
    }
    if (getActionsRequest.optionTypeId) {
      queryString += `&optionTypeId=${getActionsRequest.optionTypeId}`;
    }
    if (getActionsRequest.userId) {
      queryString += `&userId=${getActionsRequest.userId}`;
    }
    if (getActionsRequest.from) {
      queryString += `&from=${getActionsRequest.from}`;
    }
    if (getActionsRequest.to) {
      queryString += `&to=${getActionsRequest.to}`;
    }

    let url = `/?q${queryString}`;
    return await this.Get<CompanySettingsAuditResponse[]>(url);
  }
}

const companySettingAuditService = new CompanySettingAuditService(
  "CompanySettingsAudit"
);
export default companySettingAuditService;
