
import { action, makeObservable, observable, toJS } from "mobx";

import { LocalStorageKeys } from "../constants/LocalStorageKeys";
import { Company } from "../models/operation/Company";
import LocalStorageService from "../services/LocalStorageService";
import companyService from "../services/operation/CompanyService";

export class CompanyStore {
    
    /**
    * Props
    */
    @observable
    public SearchedCompanies: Company[] = [];

    /**
     * The Company that is selected in the supervision companies page to further do operations on
     */
    @observable
    public SelectedCompany: Company | undefined;

    /**
     * Ctor
     */
    constructor() {
        makeObservable(this);

        const selectedCompany = LocalStorageService.GetItemObject<Company>(LocalStorageKeys.SearchedCompanies)
        if (selectedCompany != undefined) {
            this.SelectedCompany = selectedCompany;
        }
    }

    @action
    public async SetSearchedCompanies(ids: number[]) {
        const companies = await companyService.GetManyByIdsAsync({ ids });
        this.SearchedCompanies = companies;
       // LocalStorageService.SetItemObject(LocalStorageKeys.SearchedCompanies, companies);
    }

    @action
    public async SetCompanies(companies: Company[]) {
        //const companies = await companyService.GetManyByIdsAsync({ ids });
        this.SearchedCompanies = companies;
        
    }

    @action
    public async SetSelectedCompany(company: Company) {
        this.SelectedCompany = company;
        LocalStorageService.SetItemObject(LocalStorageKeys.SearchedCompanies, company);
    }
}