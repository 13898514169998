

export const numberWithCommas = (num: number | string): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const stripCommas = (formattedNumber: string): number => {
    return Number(formattedNumber.replace(",", ""));
}

export const isNumber = (value: string): boolean => {
    try {
        return !isNaN(Number(value));
    }
    catch {
        return false;
    }
}

export const Round = (value: number, decimalPoints: number): number => {
    if (decimalPoints === 0) {
        return Math.round(value);
    }

    const multiplier = Math.pow(10, decimalPoints);
    return Math.round(value * multiplier) / multiplier;
}

export const toFixed = (value: number, decimalPoints: number): number => {
    return Number(value.toFixed(decimalPoints));
}