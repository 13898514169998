import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import IndeedAddCampaignForm from "../../../components/indeed-manager/IndeedAddCampaignForm";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import { IndeedContextInitializers } from "./helpers/IndeedContextInitializers";
import { IndeedBreadcrumbs } from "./helpers/IndeedManager.breadcrumbs";

interface Props {}

const IndeedAddCampaignContainer = (props: Props) => {
    const match = useParams<{ campaignId: string }>();
    const history = useHistory();
    const { indeedStore } = useContext(RootStoreContext);

    usePageInitialization({
        onInitAsync: async () => {
            await IndeedContextInitializers.InitializePLCampaignData(
                Number(match.campaignId),
                indeedStore,
                history
            );
        },
        onCompanyContextCallBack: (company, uiStore) => {
            uiStore.SetPageTitle(
                `Add Indeed Campaign for: ${indeedStore.PLCampaignData?.indeedCampaignSourceInfo.campaignName}`
            );
            uiStore.AddBreadCrumbs(
                SupervisionBreadcrumbs.Companies(company.companyName),
                SupervisionBreadcrumbs.Campaigns(`${indeedStore.PLCampaignData?.indeedCampaignSourceInfo.campaignName}`),
                IndeedBreadcrumbs.Indeed(Number(indeedStore.PLCampaignData?.indeedCampaignSourceInfo.campaignID)),
                IndeedBreadcrumbs.AddCampaign(Number(indeedStore.PLCampaignData?.indeedCampaignSourceInfo.campaignID))
            );
        },
    });

    return (
        <div>
            <IndeedAddCampaignForm plCampaignId={Number(match.campaignId)} />
        </div>
    );
};

export default observer(IndeedAddCampaignContainer);
