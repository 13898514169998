import { message } from "antd";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { RoutesPaths } from "../constants/RoutePaths";
import { BreadCrumb } from "../models/BreadCrumb";
import { Affiliate } from "../models/operation/Affiliate";
import affiliateService from "../services/affiliates/AffiliateService";
import { RootStoreContext } from "../stores/RootStoreContext";
import { UIStore } from "../stores/UIStore";
import useOnInit from "./useOnInit";
import useOnInitAsync from "./useOnInitAsync";
import useSetCurrentRoute from "./useSetCurrentRoute";

/**
 * NOTE: Use that hook on every page component
 * Initializes page configuration
 */
const usePageInitializationAffiliate = (initializeConfig: {
    pageTitle?: string;
    pageBreadCrumbs?: BreadCrumb[];
    onInitAsync?: () => Promise<void>;
    onCallback?: (uiStore: UIStore) => void;
    onAffiliateContextCallBack?: (affiliate: Affiliate, uiStore: UIStore) => void;
}) => {
    const match = useParams<{ affiliateId?: string }>();
    const history = useHistory();

    const { uiStore, affiliateStore } = useContext(RootStoreContext);

    useSetCurrentRoute();

    useOnInit(() => {
        if (initializeConfig.pageTitle) {
            uiStore.SetPageTitle(initializeConfig.pageTitle);
        }
        if (initializeConfig.pageBreadCrumbs) {
            uiStore.AddBreadCrumbs(...initializeConfig.pageBreadCrumbs);
        }

        if (initializeConfig.onCallback) {
            initializeConfig.onCallback(uiStore);
        }
    });

    useOnInitAsync(async () => {
        if(initializeConfig.onInitAsync) {
            await initializeConfig.onInitAsync();
        }

        if (match.affiliateId) {
            uiStore.SetIsWebsiteLoading(true);
            try {
                const affiliate = await affiliateService.GetById(
                    Number(match.affiliateId)
                );
                affiliateStore.SetSelectedAffiliate(affiliate);
                
                if (initializeConfig.onAffiliateContextCallBack) {
                    initializeConfig.onAffiliateContextCallBack(
                        affiliate,
                        uiStore
                    );
                }
            } catch (err) {
                history.push(RoutesPaths.Supervision.Affiliates);
                message.error(
                    `Affiliate with ID: ${match.affiliateId} doesn't exist. Returned to home page`
                );
            } finally {
                uiStore.SetIsWebsiteLoading(false);
            }
        }
    });
};

export default usePageInitializationAffiliate;