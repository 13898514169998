import { Box, Drawer, Typography } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import VendorDrawerList from "../vendor-drawer-list/VendorDrawerList";
import VendorDrawerAutocomplete from "./../vendor-drawer-autocomplete/VendorDrawerAutocomplete";
import { vendorMockType, vendorsData } from "../mock-data/vendors-list";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStoreContext";

interface Props {
    isOpen: boolean;
    onClose:
        | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
        | undefined;
}

const VendorDrawer = (props: Props) => {
    const { uiStore, publisherSelectionStore } = useContext(RootStoreContext);
    const [vendorsFiltered, setVendorsFiltered] = useState<string[]>([]);

    /**
     * Functions
     */
    const HandleAutoCompleteSearch = (vendorList: string[]) => {
        setVendorsFiltered(vendorList);
    };

    const GetVendorsAfterFilter = (): vendorMockType[] => {
        if (vendorsFiltered.length == 0) {
            return publisherSelectionStore.VendorsForEntity!;
        }

        return publisherSelectionStore.VendorsForEntity!.filter((vendor) =>
            vendorsFiltered.some((filteredVendor) =>
                vendor.vendorName
                    .toLowerCase()
                    .includes(filteredVendor.toLowerCase())
            )
        );
    };

    /**
     * Hooks
     */
    useEffect(() => {
        if (!publisherSelectionStore.VendorsForEntity) {
            publisherSelectionStore.SetVendorsForEntity();
        }
    }, []);

    /**
     * Render
     */

    if (!publisherSelectionStore.VendorsForEntity) {
        return <div>loading...</div>;
    }

    return (
        <Drawer anchor={"right"} open={props.isOpen} onClose={props.onClose}>
            <Box my={4} mx={4}>
                <VendorDrawerAutocomplete
                    vendorData={publisherSelectionStore.VendorsForEntity!}
                    HandleInputChange={HandleAutoCompleteSearch}
                />
            </Box>

            <Typography
                style={{ marginLeft: "20px" }}
                variant="h5"
                component="h5">
                Select publishers for:{" "}
                <strong>
                    {" "}
                    {publisherSelectionStore.SelectedEntity?.entityName ?? ""} (
                    {publisherSelectionStore.MockActiveEntityType ?? ""})
                </strong>
            </Typography>

            <VendorDrawerList vendorsData={GetVendorsAfterFilter()} />
        </Drawer>
    );
};

export default observer(VendorDrawer);
