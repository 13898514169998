/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { vendorMockType } from "../mock-data/vendors-list";
import { observer } from 'mobx-react-lite';

interface Props {
    vendorData: vendorMockType[];
    HandleInputChange: (text: string[]) => void;
}

const VendorDrawerAutocomplete = (props: Props) => {
    return (
        <div>
            <Autocomplete
                freeSolo
                id="vendor-page-auto-complete"
                multiple
                fullWidth
                clearOnEscape
                options={props.vendorData.map((option) => option.vendorName)}
                onChange={(_, vendorsList: string[]) => {
                    props.HandleInputChange(vendorsList);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search input"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: "search" }}
                    />
                )}
            />
        </div>
    );
};

export default observer(VendorDrawerAutocomplete);
