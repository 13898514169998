import { Box } from "@material-ui/core";
import { Button, message, Segmented } from "antd";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import VendorManagement from "../../../components/vendor-management/VendorManagement";
import VendorSelectInput from "../../../components/vendor-management/VendorSelectInput";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { VendorManagmentContextInitializers } from "./helpers/VendorManagmentContextInitializers";
import groupVendorService from "../../../services/vendor-management/GroupVendorService";
import VendorManagmentMode from "../../../components/vendor-management/VendorManagmentMode";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import VendorManagmentBreadCrumbs from "./helpers/VendorManagmentBreadCrumbs";
import GroupsBreadCrumbs from "../groups/helpers/GroupsBreadCrumbs";
import { ClassificationType } from "../../../models/vendor-management/ClassificationType";
import { RoutesPaths } from "../../../constants/RoutePaths";
import VlidateVendorActions from "./helpers/VlidateVendorActions";
import { ValidationDto } from "../../../models/vendor-management/ValidationDto";
import { GroupsContextInitializers } from "../groups/helpers/GroupsContextInitializers ";
import PanelControl from "../../../components/vendor-management/ControlPanael";
import Commands from "../../../components/vendor-management/Commands";
import { VendorManagmentStateContextInitializers } from "./helpers/VendorManagmentStateContextInitializers";
import { ReportPage } from "./helpers/ReportPage";
import { ValidAction } from "../../../models/ValidAction";
import { EntityDistributionSettings } from "../../../models/vendor-management/settings/EntityDistributionSettings";
import { ManualActionRequestSource } from "../../../models/manual-actions/ManualActionRequestSource";


interface Props { }

const GroupVendorPage = (props: Props) => {
  const match = useParams<{ groupId: string; campaignId: string }>();
  const { vendorManagementStore, userStore, groupStore, uiStore } =
    useContext(RootStoreContext);
  const history = useHistory();

  usePageInitialization({
    onInitAsync: async () => {
      await vendorManagementStore.SetSearchedVendors([]); //reset
      await VendorManagmentStateContextInitializers.InitializeGroupSettingsData(
        Number(match.groupId),
        vendorManagementStore,
        history
      )
      await VendorManagmentContextInitializers.InitializeGroupVendorsData(
        Number(match.groupId),
        vendorManagementStore,
        history
      );
      vendorManagementStore.SearchedVendors =
        vendorManagementStore.Vendors?.filter(
          (vendor) =>
            vendor.classificationType != ClassificationType.None ||
            vendor.inclusionEnforcement !== false
        );
      await GroupsContextInitializers.InitializeSelectedGroup(
        Number(match.groupId),
        groupStore,
        history
      );
      await GroupsContextInitializers.InitializeGroupCampaign(
        Number(match.campaignId),
        groupStore,
        history
      );
    },
    onCompanyContextCallBack: (company, uiStore) => {
      uiStore.SetPageTitle(
        `Vendor Managment for Group: ${groupStore.SelectedGroup?.groupName}`
      );
      uiStore.AddBreadCrumbs(
        SupervisionBreadcrumbs.Companies(company.companyName),
        SupervisionBreadcrumbs.Campaigns(
          `${groupStore.SelectedCampaign?.campaignName}`
        ),
        GroupsBreadCrumbs.Groups(
          Number(groupStore.SelectedCampaign?.campaignID)
        ),
        GroupsBreadCrumbs.GroupName(
          groupStore.SelectedGroup?.groupName ?? ""
        ),
        VendorManagmentBreadCrumbs.GroupVendor(Number(match.campaignId), Number(groupStore.SelectedGroup?.groupId),)
      );
    },
  });


  const validateAction = async (validateRules: ValidationDto[]) => {
    let messgae: string = 'Warning!, Break vendor hierarchy\n';
    validateRules.forEach(validateRule => {
        validateRule.rules.forEach(rule => {
            messgae += `Rules Break: ${rule.message}  `;
        }
        )
    });

    return messgae;

}


  const onApply = async () => {
    try {
      vendorManagementStore.SetVendorsToApply(
        vendorManagementStore.rowsEffected
      );
      vendorManagementStore.SelectedVendorsToApply.forEach((vendor) => {
        vendor.userId = userStore.User!.id;
      });

      const reportResult = await ReportPage.BuildReport(uiStore, vendorManagementStore.rowsEffected);

      /*let validateRules = await groupVendorService.ValidateRules(
        vendorManagementStore.SelectedVendorsToApply,
        Number(match.groupId)
      );

      if (validateRules.length > 0) {
        await validateAction(validateRules);
        return;
      }*/
      if (reportResult.action) {
        await setGroupVendors(reportResult.sourceRequest);
        await groupVendorService.SetCompanyDistributionSettings(
          vendorManagementStore.EntitySettings,
          Number(match.groupId)
        )
        vendorManagementStore.rowsEffected = [];
      }
      else return;

    } catch (error) {
      message.error("Group vendors updated failed");
    }
  };

  const setGroupVendors = async (sourceRequest: ManualActionRequestSource) => {
    await groupVendorService.SetGroupVendors(
      vendorManagementStore.SelectedVendorsToApply,
      Number(match.groupId),sourceRequest
    );

    message.success("Group vendors updated successfully");
    history.push(
      RoutesPaths.Supervision.GroupsRoutes.BuildGroupRoute(
        Number(match.campaignId)
      )
    );
  };

  const onReset = async () => {
    try {

      await groupVendorService.ResetGroupVendors(Number(match.groupId));
      message.success("Group vendors reset successfully");
      history.push(
        RoutesPaths.Supervision.GroupsRoutes.BuildGroupRoute(
          Number(match.campaignId)
        )
      );

    } catch (error) {
      message.error("Company vendors reset failed");
    }
  };

  const onVaild = async (newSettings: EntityDistributionSettings) => {
    console.log("Group Vendor Page onVaild ===> ")
    return await groupVendorService.ChangeSettingsIsValidValidationAsync(
      newSettings,
      Number(newSettings.entityId)
    );
  }

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <VendorSelectInput entityVendors={vendorManagementStore.Vendors} />
        <PanelControl onValid={onVaild} />
      </Box>
      <VendorManagement />
      <Commands onApply={onApply} onReset={onReset} />
    </div>
  );
};

export default observer(GroupVendorPage);
