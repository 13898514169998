import { Box } from "@material-ui/core";
import { Button, message } from 'antd';
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import VendorManagement from "../../../components/vendor-management/VendorManagement";
import VendorSelectInput from "../../../components/vendor-management/VendorSelectInput";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { VendorManagmentContextInitializers } from "./helpers/VendorManagmentContextInitializers";
import vendorManagementService from "../../../services/vendor-management/CampaignVendorService";
import { ClassificationTypeValidation } from "./helpers/ClassificationTypeValidation";
import VendorManagmentMode from "../../../components/vendor-management/VendorManagmentMode";
import { ClassificationType } from "../../../models/vendor-management/ClassificationType";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import VendorManagmentBreadCrumbs from "./helpers/VendorManagmentBreadCrumbs";
import { RoutesPaths } from "../../../constants/RoutePaths";
import { ValidAction } from "../../../models/ValidAction";
import { EntityDistributionSettings } from "../../../models/vendor-management/settings/EntityDistributionSettings";
import { ManualActionRequestSource } from "../../../models/manual-actions/ManualActionRequestSource";
import { ReportPage } from "./helpers/ReportPage";



interface Props { }

const CampaignVendorPage = (props: Props) => {

    const match = useParams<{ campaignId: string }>();
    const { vendorManagementStore, userStore, uiStore } = useContext(RootStoreContext);

    const history = useHistory();

    usePageInitialization({

        onInitAsync: async () => {
            await vendorManagementStore.SetSearchedVendors([]) // reset
            await VendorManagmentContextInitializers.InitializeCampaignVendorsData(
                Number(match.campaignId),
                vendorManagementStore,
                history
            )
            var isWhitelist = vendorManagementStore.Vendors?.some(vendor => vendor.classificationType === ClassificationType.Exclusive);
            var manualVendors = vendorManagementStore.Vendors?.filter(vendor =>
                vendor.classificationType == ClassificationType.Excluded ||
                vendor.classificationType == ClassificationType.Exclusive ||
                vendor.inclusionEnforcement
            );
            var operativeVendors = vendorManagementStore.Vendors?.filter(operativeVendor =>
                !manualVendors.some(manualVendor => manualVendor.vendorId == operativeVendor.vendorId));
            var sortedVendors = manualVendors.concat(operativeVendors);
            vendorManagementStore.SearchedVendors = sortedVendors.filter(vendor =>

                vendor.classificationType != ClassificationType.None || vendor.inclusionEnforcement !== false || !isWhitelist && vendor.isOperativeCutoff
            );
            ClassificationTypeValidation.InitClassificationType(vendorManagementStore);
        },
        onCampaignContextCallBack: (campaign, uiStore) => {
            uiStore.SetPageTitle(
                `Vendor Managment for: ${campaign.campaignName}`
            );
            uiStore.AddBreadCrumbs(
                SupervisionBreadcrumbs.Campaigns(campaign.campaignName),
                VendorManagmentBreadCrumbs.CampaignVendor(campaign.campaignID),
            );
        },
    })

    const onApply = async () => {
        try {
            let messageToPopUp = "Please Choose Manual Action Request Source!"
            vendorManagementStore.SetVendorsToApply(vendorManagementStore.rowsEffected);
            vendorManagementStore.SelectedVendorsToApply.forEach(vendor => {
                vendor.userId = userStore.User!.id;
            });
            vendorManagementStore.rowsEffected = [];
            let reportResult: any  = {
                sourceRequest: ManualActionRequestSource,
                action: true
            }; //await ReportPage.BuildReport(uiStore,messageToPopUp);

            if (reportResult.action) {
                await vendorManagementService.SetCampaignsVendors(
                    vendorManagementStore.SelectedVendorsToApply, Number(match.campaignId), reportResult.sourceRequest);
            }
            else {
                return;
            }

            message.success("Campaign vendors updated successfully");
            history.push(RoutesPaths.Supervision.Campaigns);
        } catch (error) {
            message.error("Campaign vendors updated failed");

        }

    }

    return (
        <div>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <VendorSelectInput
                    entityVendors={vendorManagementStore.Vendors}
                />
                <VendorManagmentMode onValid={function (newSettings: EntityDistributionSettings): Promise<ValidAction> {
                    throw new Error("Function not implemented.");
                } } />
            </Box>
            <VendorManagement />
            <div style={{ width: 270 }}>
                <Button
                    onClick={onApply}
                    type="primary"
                    size="large"
                    style={{ display: "flex", alignItems: "center", borderRadius: '4px' }}>
                    <span>Apply Changes</span>
                </Button>

            </div>
        </div>
    )
}

export default observer(CampaignVendorPage);

