import { message, Segmented } from "antd";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import useOnInitAsync from "../../hooks/useOnInitAsync";
import { ClassificationType } from "../../models/vendor-management/ClassificationType";
import { RootStoreContext } from "../../stores/RootStoreContext";
import companyVendorsService from "../../services/vendor-management/CompanyVendorService";
import "./CampaignVendorPage.scss";
import { EntityDistributionSettings } from "../../models/vendor-management/settings/EntityDistributionSettings";
import { ValidAction } from "../../models/ValidAction";


interface Props { 
    onValid: (newSettings: EntityDistributionSettings) => Promise<ValidAction>;
}

const VendorManagmentMode = (props: Props) => {

    const { vendorManagementStore, uiStore } = useContext(RootStoreContext);
    const [classificationType, setClassificationType] = useState<string>('Black List');
    const { onValid } = props;

    useOnInitAsync(async () => {
        if (vendorManagementStore.EntitySettings) {
            let selectedType = fromClassificationTypeToMode(vendorManagementStore.EntitySettings.classificationType);
            setClassificationType(selectedType);
        }
    }, [vendorManagementStore.SelectedClassificationType]);

    const onChange = async (value: any) => {

        let seletedclassificationType = fromModeToClassificationType(value);
        let validAction = await actionIsValid(seletedclassificationType);


        if (validAction.isValid) {
            setClassificationType(value);
            vendorManagementStore.SetClassificationType(seletedclassificationType);
        }
        else {
            const popupBody = (
                <p>
                    {validAction.errorMessage.toString()}
                </p>
            );

            uiStore.SetPopUp(
                {
                    body: popupBody,
                    title: "Change Work Mode",
                    okClickHandler: async () => {
                        message.error(
                            `Can't change mode`
                        );
                    },
                    okButtonText: "Ok",
                })
        }
    }

    const actionIsValid = async (value: ClassificationType) => {

        let validAction: ValidAction = {
            isValid: true,
            errorMessage: ''
        }

        let newSettings: EntityDistributionSettings;
        newSettings = vendorManagementStore.EntitySettings
        newSettings.classificationType = value;

        let validActionReponse = await onValid(newSettings);
        if (!validActionReponse.isValid) {
            validAction = validActionReponse;
            return validAction;
        }
        
        CheckModeCompatiableToTableValues(value,validAction);

        return validAction;
    }

    const CheckModeCompatiableToTableValues = (value: ClassificationType, validAction: ValidAction) => {

        validAction.errorMessage = 'The work mode cannot be changed because there are vendors with values ​from the opposite work mode:\n Vendor ids:'

        if (value == ClassificationType.Exclusive) {
            let vendorsInBlackList = vendorManagementStore.Vendors
                .filter(vendor => vendor.classificationType == ClassificationType.Excluded ||
                    vendor.inclusionEnforcement)

            if (vendorsInBlackList.length > 0) {
                validAction.errorMessage += buildMessageWithVendorIds(vendorsInBlackList);
                validAction.isValid = false;
            }
        }
        else if (value == ClassificationType.Excluded) {
            let vendorsInWhiteList = vendorManagementStore.Vendors
                .filter(vendor => vendor.classificationType == ClassificationType.Exclusive)

            if (vendorsInWhiteList.length > 0) {
                validAction.errorMessage += buildMessageWithVendorIds(vendorsInWhiteList);
                validAction.isValid = false;
            }
        }
    }

    const buildMessageWithVendorIds = (vendors: any[]) => {
        let vendorIdsMessage: string = '';
        vendors.forEach(vendor => {
            vendorIdsMessage += vendor.vendorId.toString() + ', '
        });

        return vendorIdsMessage;
    }

    const fromModeToClassificationType = (value: any) => {
        if (value == 'White List') {
            return ClassificationType.Exclusive;
        }
        else {
            return ClassificationType.Excluded;
        }
    }

    const fromClassificationTypeToMode = (value: any) => {
        if (value == ClassificationType.Exclusive) {
            return 'White List';
        }
        else {
            return 'Black List';
        }
    }

    return (
        <>
            <Segmented
                options={['White List', 'Black List']}
                style={{ fontWeight: 'bold' }}
                value={classificationType}
                onChange={onChange} />

        </>
    )
}

export default observer(VendorManagmentMode);