import { extend } from "lodash";
import { APIResponse } from "../../models/APIResponse";
import { GroupBudgetBalance } from "../../models/groups/GroupBudgetBalance";
import { GroupBudgetRequest } from "../../models/groups/GroupBudgetRequest";
import { GroupCreateRequest } from "../../models/groups/GroupCreateRequest";
import { GroupResponse } from "../../models/groups/GroupResponse";
import { GroupUpdateRequest } from "../../models/groups/GroupUpdateRequest";
import { BaseService } from "../BaseService";

export class GroupService extends BaseService {
  async GetAllAsync(campaignId: number) {
    const groupsData = await this.Get<GroupResponse[]>(
      `/${campaignId}`
    );
    return groupsData;
  }

  async CreateAsync(groupCreateRequest: GroupCreateRequest): Promise<void> {
    const apiResponse = await this.Post<APIResponse<string>>(
      "",
      groupCreateRequest
    );
    if (apiResponse.isError) {
      throw new Error(apiResponse.body);
    }
  }

  async DeleteAsync(campaignId: number, groupId: number) {
    const deletedGroup = await this.Delete<GroupResponse>(
      `/${campaignId}/${groupId}`
    );
    return deletedGroup;
  }

  async GetGroupById(groupId: number) {
    return await this.Get<GroupResponse>(`/group/${groupId}`);
  }

  async UpdateGroup(
    groupId: number,
    updateGroupRequest: GroupUpdateRequest
  ): Promise<void> {
    const apiResponse = await this.Put<APIResponse<string>>(
      `/${groupId}`,
      updateGroupRequest
    );
    if (apiResponse.isError) {
      throw new Error(apiResponse.body);
    }
  }


  async UpdateGroupBudget(
    groupId: number,
    updateGroupBudgetRequest: GroupBudgetRequest
  ): Promise<void> {
    const apiResponse = await this.Put<APIResponse<string>>(
      `/group-budget/${groupId}`,
      updateGroupBudgetRequest
    );
    if (apiResponse.isError) {
      throw new Error(apiResponse.body);
    }
  }
  

  async GetGroupBudgetBalance(
    campaignId: number,
    groupId?: number
  ) : Promise<GroupBudgetBalance> 
  {
    const groupBudgetBalance = await this.Get<GroupBudgetBalance>(
      `/group-budget/${campaignId}/${groupId ?? ''}`
    );
    return groupBudgetBalance;
  }
  
}

const groupService = new GroupService("Groups");
export default groupService;
