import { Action } from "../../models/users/Action";
import { ActionCreateRequest } from "../../models/users/ActionCreateRequest";
import { ActionResponse } from "../../models/users/ActionResponse";
import { ISODateTime } from "../../types/types";
import { BaseService } from "../BaseService";

class UserActionService extends BaseService {


    public async GetById(id: number) {
        return await this.Get<ActionResponse>(`/${id}`);
    }

    public async Create(createRequest: ActionCreateRequest): Promise<void> {
        return await this.Post("/", createRequest);
    }

    public async GetActionsFiltered(getActionsRequest: {
        userId?: number,
        from?: ISODateTime,
        to?: ISODateTime,
        actionTypeIds?: string
    }): Promise<ActionResponse[]> {
        let queryString = "";
        if (getActionsRequest.actionTypeIds) {
            queryString += `&actionTypeIds=${getActionsRequest.actionTypeIds}`
        }
        if (getActionsRequest.userId) {
            queryString += `&userId=${getActionsRequest.userId}`
        }
        if (getActionsRequest.from) {
            queryString += `&from=${getActionsRequest.from}`
        }
        if (getActionsRequest.to) {
            queryString += `&to=${getActionsRequest.to}`
        }
        let url = `/?q${queryString}`
        return await this.Get<ActionResponse[]>(url);
    }
}


export const userActionService = new UserActionService("userActions");