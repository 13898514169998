import "./AppBreadcrumbs.scss";

import { Breadcrumb } from "antd";
import { observer } from "mobx-react-lite";
import React, { CSSProperties, useContext } from "react";
import { Link } from "react-router-dom";

import { blue } from "@ant-design/colors";

import { BreadCrumb } from "../../../models/BreadCrumb";
import { RootStoreContext } from "../../../stores/RootStoreContext";

interface Props {}

const AppBreadCrumbs = (props: Props) => {
    const { uiStore } = useContext(RootStoreContext);

    const CreateBreadcrumbStyles = (breadCrumb: BreadCrumb): CSSProperties => {
        // The last bread crumb should not be clickable
        const doesBreadcrumbContainPathOrAction =
            breadCrumb.clickHandler || breadCrumb.path;
        const isLastBreadcrumbInUi =
            uiStore.BreadCrumbs[uiStore.BreadCrumbs.length - 1]
                .breadCrumbText == breadCrumb.breadCrumbText;

        let styles: CSSProperties = {};
     

        if(doesBreadcrumbContainPathOrAction && !isLastBreadcrumbInUi) {
            styles["cursor"] = "pointer";
            styles["color"] = blue[6];
        }
        else {
            styles["cursor"] = "default";
        }
        return styles;
    };

    return (
        <Breadcrumb style={{ margin: "10px 0" }}>
            {uiStore.BreadCrumbs.map((breadCrumb) =>
                breadCrumb.path ? (
                    <Breadcrumb.Item
                        key={breadCrumb.path}
                        onClick={() =>
                            breadCrumb.clickHandler && breadCrumb.clickHandler()
                        }>
                        <Link
                            to={breadCrumb.path}
                            style={CreateBreadcrumbStyles(breadCrumb)}>
                            {breadCrumb.breadCrumbText}
                        </Link>
                    </Breadcrumb.Item>
                ) : (
                    <Breadcrumb.Item
                        key={breadCrumb.path}
                        onClick={() =>
                            breadCrumb.clickHandler && breadCrumb.clickHandler()
                        }>
                        <div
                            style={{
                                display: "inline-block",
                                ...CreateBreadcrumbStyles(breadCrumb),
                            }}>
                            {breadCrumb.breadCrumbText}
                        </div>
                    </Breadcrumb.Item>
                )
            )}
        </Breadcrumb>
    );
};

export default observer(AppBreadCrumbs);
