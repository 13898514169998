import { CreateIndeedCampaignObjectiveStrategyRequest } from "../../models/indeed-manager/strategy/CreateIndeedCampaignObjectiveStrategyRequest";
import { IndeedCampaignObjectiveStrategy } from "../../models/indeed-manager/strategy/IndeedCampaignObjectiveStrategy";
import { BaseService } from "../BaseService";

class IndeedStrategyManagementService extends BaseService {
    public async GetStrategiesByPlCampaign(
        plCampaignId: number | string
    ): Promise<IndeedCampaignObjectiveStrategy[]> {
        return await this.Get<IndeedCampaignObjectiveStrategy[]>(
            `/${plCampaignId}/indeed-strategy`
        );
    }

    public async CreateStrategy(
        plCampaignId: number | string,
        createIndeedCampaignObjectiveStrategyRequest: CreateIndeedCampaignObjectiveStrategyRequest
    ): Promise<IndeedCampaignObjectiveStrategy> {
        return await this.Post<IndeedCampaignObjectiveStrategy>(
            `/${plCampaignId}/indeed-strategy`,
            createIndeedCampaignObjectiveStrategyRequest
        );
    }

    public async DeleteStrategy(
        plCampaignId: number | string,
        id: number
    ): Promise<IndeedCampaignObjectiveStrategy> {
        return await this.Delete<IndeedCampaignObjectiveStrategy>(
            `/${plCampaignId}/indeed-strategy/${id}`
        );
    }
}

export const indeedStrategyManagementService = new IndeedStrategyManagementService("campaign");