import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useHistory } from "react-router";

import { RoutesPaths } from "../../constants/RoutePaths";
import {
  PageNotFoundexception,
  UnauthorizedError
} from "../../exceptions/AlgoSupervisionExceptions";
import AccessDeniedPage from "../../routes/pages/AccessDeniedPage";
import NotFoundPage from "../../routes/pages/NotFoundPage";

interface Props {
    children: React.ReactNode;
}

const ErrorHandlerComponent = (props: Props) => {
    const history = useHistory();

    return (
        //TODO: component stil breaks after reaching 404 page since it re renders a second time
        <ErrorBoundary
            FallbackComponent={({ error, resetErrorBoundary }) => {
                console.error(error);
                
                if (error instanceof PageNotFoundexception) {
                    // <NotFoundPage />
                }

                if (error instanceof UnauthorizedError) {
                    // return <AccessDeniedPage />;
                }

                
                return <div>An error has occured</div>;
            }}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}>
            {props.children}
        </ErrorBoundary>
    );
};

export default ErrorHandlerComponent;
