import { CampaignSettingTypeResponse } from "../../models/campaign-track-system/CampaignSettingTypeResponse";
import { CampaignSettingTypeValueOption } from "../../models/campaign-track-system/CampaignSettingTypeValueOption";
import { BaseService } from "../BaseService";



export class CampaignSettingTypeService extends BaseService 
{

    async GetCampaignSettingTypes() {

        const CampaignSettingTypeData = await this.Get<CampaignSettingTypeResponse[]>("");
        return CampaignSettingTypeData;
    }

    async GetSettingTypeValueOptionsPerType(campaignSettingTypeId: number) {

        const CampaignSettingTypeData = await this.Get<CampaignSettingTypeValueOption[]>(`/${campaignSettingTypeId}/valueOptions`);
        return CampaignSettingTypeData;
    }


}

const campaignSettingTypeService = new CampaignSettingTypeService("CampaignSettingTypes");
export default campaignSettingTypeService;