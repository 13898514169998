import { Col, Row, Select, Space, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";

import { spacingUnit } from "../../../assets/styles/theme";
import useOnInitAsync from "../../../hooks/useOnInitAsync";
import { CampaignPartial } from "../../../models/operation/CampaignPartial";
import campaignService from "../../../services/operation/CampaignService";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import Loader from "../../general/loader/Loader";

const { Option } = Select;

interface Props {}

const CampaignSelectInput = (props: Props) => {
    const [options, setOptions] = useState<CampaignPartial[]>();
    const { campaignStore } = useContext(RootStoreContext);

    useOnInitAsync(async () => {
        const campaignsPartial = await campaignService.GetAllPartial();
        setOptions(campaignsPartial);
    });

    if (!options || options.length == 0) {
        return <Loader position="normal" />;
    }

    const defaultValue =
        campaignStore.SearchedCampaigns.length > 0
            ? campaignStore.SearchedCampaigns.map((campaign) =>
                  BuildOptionValue(campaign.campaignName, campaign.campaignID)
              )
            : [];
    return (
        <section style={{ margin: `${spacingUnit * 2}px 0` }}>
            <Space wrap style={{ width: 1000 }} size="middle">
                <Typography.Title level={5}>
                    Search Campaign(s)
                </Typography.Title>

                <Select
                    mode="multiple"
                    allowClear
                    defaultValue={defaultValue}
                    style={{ width: "700px" }}
                    onChange={(selectedOptionValues: string[]) => {
                        const campaignIds = selectedOptionValues.map(
                            (campaign) => ParseCampaignId(campaign)
                        );
                        campaignStore.SetSearchedCampaigns(campaignIds);
                    }}>
                    {options.map((campaignPartial) => (
                        <Option
                            key={campaignPartial.campaignID}
                            value={BuildOptionValue(
                                campaignPartial.campaignName,
                                campaignPartial.campaignID
                            )}>
                            {campaignPartial.campaignName} - {campaignPartial.campaignID}
                        </Option>
                    ))}
                </Select>
            </Space>
        </section>
    );
};

export default CampaignSelectInput;

const separator = "##";

const BuildOptionValue = (campaignName: string, campaignId: number) => {
    return `${campaignName}${separator}${campaignId}`;
};

const ParseCampaignId = (campaign: string) => {
    return Number(campaign.split(separator)[1]);
};
