
import { Campaign } from "../../../models/operation/Campaign";
import { ISODateTime } from "../../../types/types";
import { FormatDate } from "../../../utils/DateHelpers";
import { TableDto } from "../../general/supervision-table/Table.dto";

/**
 * Creates a campaign table dto that is displayed in the table
 */
export class CampaignTableDto extends TableDto {
    
    public CampaignId: number;
    public CampaignName: string;
    public CreationDate: ISODateTime;
    public EndDate: ISODateTime;
    public Status: string;

    constructor(campaign: Campaign) {
        super();
        this._id = campaign.companyId;
        this.CampaignId = campaign.campaignID;
        this.CampaignName = campaign.campaignName;
        this.CreationDate = FormatDate(campaign.creationDate);
        this.EndDate = FormatDate(campaign.forceEndDate ?? campaign.endDate);
        this.Status = this.DetermineStatus(campaign.statusId);
    }


    private DetermineStatus(statusId: number) {
        switch (statusId) {
            case 1: return "Setup";
            case 2: return "Active";
            case 3: return "Removed";
            case 4: return "Stop";
            case 5: return "Paused";

            default: throw new Error(`Unsupported Status number ${statusId}`);
        }
    }
}