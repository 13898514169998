import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { JobSerachingType } from "../../models/explainability/JobSerachingType";
import jobVendorStateService from "../../services/explainability/JobVendorStateService";
import jobsStateService from "../../services/explainability/JobsStateService";
import { Button, Space } from "antd";

interface Props {
    isLoading : boolean;
    setLoding: (isLoading : boolean) => void;
 }


const SearchJobState = (props: Props) => {

    const { explainabilityStore } = useContext(RootStoreContext);
    const { SelectedJobSerachingType, SelectedCompany } = explainabilityStore
    const companyId = SelectedCompany ? SelectedCompany.companyId : undefined;

    const onClick = async () => {
        if (companyId == undefined) {
            return;
        }
        props.setLoding(true);
        if (SelectedJobSerachingType == JobSerachingType.JobVendorState) {
            if (explainabilityStore.SearchedJobs.length > 0 || explainabilityStore.SearchedVendors.length > 0) {
                let vendors_ids = explainabilityStore.SearchedVendors.map( v => v.vendor_id)
                let jobsStateVendorByJobIds = await jobVendorStateService
                        .getJobVendorStateByJobsIds(companyId, explainabilityStore.SearchedJobs, vendors_ids)
                explainabilityStore.JobsState = jobsStateVendorByJobIds;
            }
            else {// get all
                let jobsStateVendorByAccountId = await jobVendorStateService.getJobVendorStateByCompanyId(companyId);
                explainabilityStore.JobsState = jobsStateVendorByAccountId;
            }

        }
        else {
            if (explainabilityStore.SearchedJobs.length > 0) {
                let jobsStateByAccountId = await jobsStateService
                            .getJobStateByJobsIds(explainabilityStore.SearchedJobs);
                explainabilityStore.JobsState = jobsStateByAccountId;
            }
            else {// get all 
                let jobsStateByAccountId = await jobsStateService.getJobStateByCompanyId(companyId);
                explainabilityStore.JobsState = jobsStateByAccountId;
            }

        }
        props.setLoding(false);
    }


    return (
        <Button onClick={onClick} type="primary" disabled={props.isLoading}>Search </Button>
    )
}


export default observer(SearchJobState);