

export class IndeedAttachJobsToCampaignEventPayload {

    constructor(
        public indeedCampaignId: string,
        public userId: number,
        public jobsList: number[],
        public detachFromOtherCampaigns: boolean,
    ) { }
}