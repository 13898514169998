import "./CompaniesTable.scss";

import { Empty, Table } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { RootStoreContext } from "../../../stores/RootStoreContext";
import { DOMCoordinates } from "../../../types/types";
import Loader from "../../general/loader/Loader";
import SupervisionTable from "../../general/supervision-table/SupervisionTable";
import { Company } from "../../../models/operation/Company";
import { CompaniesTableActionsBuilder } from "./CompaniesTable.actions";
import { CompanyTableDto } from "./CompanyTable.dto";
import { Campaign } from "../../../models/operation/Campaign";
import { CampaignTableDto } from "../campaigns/CampaignTable.dto";
import { CampaignsTableActionsBuilder } from "../campaigns/CampaignsTable.actions";
import { TableRowSelection } from "antd/lib/table/interface";

export type RightClickData = {
    clickCoordinates: DOMCoordinates;
    clickedCompany: CompanyTableDto;
    rowIndex: number;
};

interface Props { }
const CompaniesTable = (props: Props) => {
    const history = useHistory();

    const { companyStore , uiStore } = useContext(RootStoreContext);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([companyStore.SelectedCompany?.companyId]);

    const [companies, setCompanies] = useState<Company[] | undefined>(
        undefined
    );

    const [campaigns, setCampaigns] = useState<Campaign[] | undefined>(
        undefined
    );

    useEffect(() => {
        SortCompanies();
        setCompanies(companyStore.SearchedCompanies);
        var campaignsFlat = companyStore.SearchedCompanies?.flatMap(company => company.campaign);
        setCampaigns(campaignsFlat);
    }, [companyStore.SearchedCompanies]);

    const SortCompanies = () =>{
        companyStore.SearchedCompanies =  companyStore.SearchedCompanies.sort((a, b) => {
            if (a.companyId == companyStore.SelectedCompany?.companyId) {
              return -1; // Move 'a' to the beginning
            } else if (b.companyId == companyStore.SelectedCompany?.companyId) {
              return 1; // Move 'b' to the beginning
            } else {
              return 0; // Keep the order unchanged
            }
          });

    }

    if (companies == undefined) {
        return <Loader position="pad-top" />;
    }

    if (companies.length == 0) {
        return (
            <Empty
                style={{ marginTop: "40px" }}
                description="Please select accounts to show"
            />
        );
    }

    const rowSelection = {
        selectedRowKeys,
        hideSelectAll: true,
        renderCell: (originNode: any, record: any) => {
            return <a onClick={() => rowSelection.onSelect(record)}>Select</a>;
        },
        onSelect: (record: any) => {
            const company = companies.find(c => c.companyId == record.companyId);

            if (company != undefined) {
                companyStore.SetSelectedCompany(company);
            }
            setSelectedRowKeys([record.key]);
        }
    };

    return (
        <SupervisionTable
            dataSource={companies.map((c) => new CompanyTableDto(c))}
            rowActionSettings={{
                actions: CompaniesTableActionsBuilder({
                    history: history
                }),
                showActionsOnRightClick: true,
                showActionsOnLastColumn: true,
            }}
            tableRowSelection={rowSelection}
            expandableDataSource={campaigns?.map((c) => new CampaignTableDto(c))}
            isExpendable={true}
            isShowSizeChanger={true}
            expandableActionSettings={{
                actions: CampaignsTableActionsBuilder({ history: history }),
                showActionsOnRightClick: true,
                showActionsOnLastColumn: true,
            }}
   
        />
    );
};

export default observer(CompaniesTable);
