import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import GroupAddForm from "../../../components/groups/GroupAddForm";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import GroupsBreadCrumbs from "./helpers/GroupsBreadCrumbs";
import { GroupsContextInitializers } from "./helpers/GroupsContextInitializers ";

interface Props {}

const GroupsAddPage = (props: Props) => {
  const match = useParams<{ campaignId: string }>();
  const { groupStore } = useContext(RootStoreContext);
  const history = useHistory();

  usePageInitialization({
    onInitAsync: async () => {
      await GroupsContextInitializers.InitializeGroupData(
        Number(match.campaignId),
        groupStore,
        history
      );

      await GroupsContextInitializers.InitializeGroupBudgetBalance(
        Number(match.campaignId),
        groupStore,
        history
      );
    },

    onCompanyContextCallBack: (company, uiStore) => {
      uiStore.SetPageTitle(
        `Add Groups for: ${groupStore.SelectedCampaign?.campaignName}`
      );
      uiStore.AddBreadCrumbs(
        SupervisionBreadcrumbs.Companies(company.companyName),
        SupervisionBreadcrumbs.Campaigns(
          `${groupStore.SelectedCampaign?.campaignName}`
        ),
        GroupsBreadCrumbs.Groups(
          Number(groupStore.SelectedCampaign?.campaignID)
        ),
        GroupsBreadCrumbs.AddGroup(
          Number(groupStore.SelectedCampaign?.campaignID)
        )
      );
    },
  });

  return (
    <div>
      <GroupAddForm
        campaignId={Number(match.campaignId)}
        isStatusEditable={false}
        groupBudgetBalance={groupStore.GroupBudgetBalance}
      />
    </div>
  );
};

export default observer(GroupsAddPage);
