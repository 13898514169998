import Cookies from "js-cookie";
import jwt from "jsonwebtoken";

import { User } from "../models/users/User";
import { UserStore } from "../stores/UserStore";

type UserIdentityJwtSchema = {
    aud: string;
    email: string;
    exp: number;
    iat: number;
    iss: string;
    nbf: number;
    policies: string;
    role_id: string;
    user_id: string;
    user_name: string;
};

const SeedUserData = (userStore: UserStore) => {
    const userIdentityJwt = Cookies.get("User.Identity");
    if (!userIdentityJwt) {
        return;
    }

    const decodedJwt = jwt.decode(userIdentityJwt) as UserIdentityJwtSchema;

    const user: User = {
        id: Number(decodedJwt.user_id),
        email: decodedJwt.email,
        name: decodedJwt.user_name,
        roleId: Number(decodedJwt.role_id),
    };
    userStore.SetUser(user);
};

export default SeedUserData;
