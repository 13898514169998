import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import IndeedAttachJobsForm from "../../../components/indeed-manager/IndeedAttachJobsForm";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import { IndeedContextInitializers } from "./helpers/IndeedContextInitializers";
import { IndeedBreadcrumbs } from "./helpers/IndeedManager.breadcrumbs";

const IndeedAttachJobsToCampaignPage = () => {
    const match = useParams<{ campaignId: string; indeedCampaignId: string }>();
    const history = useHistory();
    const { indeedStore } = useContext(RootStoreContext);

    usePageInitialization({
        onInitAsync: async () => {
            await IndeedContextInitializers.InitializePLCampaignData(
                Number(match.campaignId),
                indeedStore,
                history
            );
            await IndeedContextInitializers.InitializeSelectedIndeedCampaign(
                match.indeedCampaignId,
                indeedStore.PLCampaignData!,
                indeedStore,
                history
            );
        },
        onCompanyContextCallBack: (company, uiStore) => {
            uiStore.SetPageTitle(
                `Attach PL Jobs to Indeed Campaign: ${indeedStore.SelectedIndeedCampaign?.name}`
            );
            uiStore.AddBreadCrumbs(
                SupervisionBreadcrumbs.Companies(company.companyName),
                SupervisionBreadcrumbs.Campaigns(`${indeedStore.PLCampaignData?.indeedCampaignSourceInfo.campaignName}`),
                IndeedBreadcrumbs.Indeed(Number(indeedStore.PLCampaignData?.indeedCampaignSourceInfo.campaignID)),
                IndeedBreadcrumbs.IndeedCampaign(
                    indeedStore.SelectedIndeedCampaign?.name ?? ""
                ),
                IndeedBreadcrumbs.AttachJobsToIndeedCampaign(
                    Number(indeedStore.PLCampaignData?.indeedCampaignSourceInfo.campaignID),
                    match.indeedCampaignId
                )
            );
        },
    });

    return (
        <div>
            <IndeedAttachJobsForm
                plCampaignId={Number(match.campaignId)}
                indeedCampaignId={match.indeedCampaignId}
            />
        </div>
    );
};

export default observer(IndeedAttachJobsToCampaignPage);
