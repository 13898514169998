import { Button, DatePicker, message, Space } from "antd";
import { observer } from "mobx-react-lite";
import moment, { Moment } from "moment";
import { useState } from "react";

import UserActionsTable from "../../../components/actions/UserActionsTable";
import SelectUserInput from "../../../components/general/inputs/SelectUserInput";
import SupervisionAutoComplete from "../../../components/general/inputs/SupervisionAutoComplete";
import Loader from "../../../components/general/loader/Loader";
import useOnInitAsync from "../../../hooks/useOnInitAsync";
import { ActionResponse } from "../../../models/users/ActionResponse";
import { ActionType } from "../../../models/users/ActionType";
import { UserResponse } from "../../../models/users/UserResponse";
import { actionTypeService } from "../../../services/actions/ActionTypeService";
import { userActionService } from "../../../services/actions/UserActionService";
import { ISODateTime } from "../../../types/types";

type Props = {};

type FilterSettings = {
    userId?: number;
    from?: ISODateTime;
    to?: ISODateTime;
    actionTypeIds?: string;
};

const UserActionsTablePage = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [userActions, setUserActions] = useState<ActionResponse[]>([]);
    const [filterSettings, setFilterSettings] = useState<FilterSettings>({});
    const [userInputValue, setUserInputValue] = useState<
        UserResponse | undefined
    >();
    const [actionTypes, setActionTypes] = useState<ActionType[]>([]);

    useOnInitAsync(async () => {
        setUserActions(await userActionService.GetActionsFiltered({}));
        setActionTypes(await actionTypeService.GetAll());
        setIsLoading(false);
    });

    const filterHandler = async () => {
        setIsLoading(true);
        setUserActions(
            await userActionService.GetActionsFiltered(filterSettings)
        );
        setIsLoading(false);
        message.info("Filtered actions");
    };

    const initialActionTypeValue = () => {
        if (!filterSettings.actionTypeIds) return "";

        const actionType = actionTypes.find(
            (x) => x.id == Number(filterSettings.actionTypeIds)
        );
        return actionType?.name;
    };

    const initialDateValue = () => {
        let date: [Moment?, Moment?] = [undefined, undefined];

        if (filterSettings.from) {
            date[0] = moment(filterSettings.from);
        }
        if (filterSettings.to) {
            date[1] = moment(filterSettings.to);
        }
        return date;
    };

    if (isLoading) {
        return <Loader position="normal" />;
    }

    return (
        <div>
            <Space style={{ marginBottom: 10 }}>
                <SelectUserInput
                    defaultValue={userInputValue}
                    onChange={(user) => {
                        setUserInputValue(user);
                        setFilterSettings({
                            ...filterSettings,
                            userId: user?.id,
                        });
                    }}
                    onClear={() => {
                        setUserInputValue(undefined);
                        setFilterSettings({
                            ...filterSettings,
                            userId: undefined,
                        });
                    }}
                />
                <div style={{ width: 300 }}>
                    <SupervisionAutoComplete
                        options={actionTypes}
                        optionsToAutoCompleteMapFunction={(acionType) => ({
                            value: acionType.name,
                        })}
                        onSelectHandle={(actionType) => {
                            setFilterSettings({
                                ...filterSettings,
                                actionTypeIds: actionType.id.toString(),
                            });
                        }}
                        initialValue={initialActionTypeValue()}
                        placeHolder="Action Types"
                    />
                </div>

                <DatePicker.RangePicker
                    onChange={(date) => {
                        const from = date?.[0];
                        const to = date?.[1];

                        setFilterSettings({
                            ...filterSettings,
                            from: from?.toISOString() || undefined,
                            to: to?.toISOString() || undefined,
                        });
                    }}
                    defaultValue={initialDateValue() as any}
                />
                <Button type="primary" onClick={filterHandler}>
                    Filter
                </Button>
            </Space>

            <UserActionsTable userActions={userActions} />
        </div>
    );
};

export default observer(UserActionsTablePage);
