import { Empty } from "antd";
import { observer } from "mobx-react-lite";

import { GroupJobResponse } from "../../models/groups/GroupJobResponse";
import Loader from "../general/loader/Loader";
import SupervisionTable from "../general/supervision-table/SupervisionTable";
import { GroupJobsTableDto } from "./GroupJobsTable.dto";

interface Props {
    groupName: string;
    groupId: number;
    groupJobs: GroupJobResponse[];
}

const GroupsJobsTable = (props: Props) => {

    const { groupJobs, groupName } = props;


    if (groupJobs.length == 0) {
        return (
            <Empty
                style={{ marginTop: "40px" }}
                description={`No jobs attached to group: ${groupName}`}
            />
        );
    }

    if (groupJobs.length == 0) {
        return <Loader position="normal" />;
    }

    return (
        <SupervisionTable
            dataSource={groupJobs.map(GroupJobsTableDto
                .MapFromGroupJob)}
        />
    );
};

export default observer(GroupsJobsTable);