import { Upload, message, Button, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/RootStoreContext';
import { Box } from '@material-ui/core';
import "./UploadCsvFile.scss";
import companySettingAuditService from '../../services/company-settings/CompanySettingAuditService';

const UploadCsvFile = () => {

  const [uploadDisabled, setUploadDisabled] = useState<boolean>(true);
  const { userStore } = useContext(RootStoreContext);
  const [infoFile, setInfoFile] = useState<any>({});

  const onChange = (info: any) => {

    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.error}. ${info.file.name} file upload failed.`);
    }
  }

  const uploadFile = async () => {


    await companySettingAuditService.UploadFile(infoFile.file, userStore.User!.id.toString())
      .then((response) => {
        if (response.isError) {
          infoFile.onError(response.body);
        }
        else
          infoFile.onSuccess("ok");

        setUploadDisabled(true);
      })


  }

  const chooseFile = (info: any) => {

    setInfoFile(info);
    setUploadDisabled(false);

  }

  const onRemove = () => {

    setUploadDisabled(true);
  }

  return (
    <Row gutter={8}>

      <Box sx={{
        width: 200,
        height: 200
      }}>
        <Upload
          onRemove={onRemove}
          customRequest={chooseFile}
          onChange={onChange}
          accept=".csv"
        >
          <Col  >
            <Button
              disabled={!uploadDisabled}
              icon={<UploadOutlined />}
              type="primary"
              shape="round"
              size="large"
              style={{
                alignItems: "center",
                display: 'inline-flex',
                width: 177,
              }}>
              Choose File
            </Button>
          </Col>
        </Upload>
      </Box>
      <Button
        onClick={uploadFile}
        disabled={uploadDisabled}
        icon={<UploadOutlined style={{ display: "flex" }} />}
        type="primary"
        shape="round"
        size="large"
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: 10
        }} >
        Upload
      </Button>
    </Row>
  )
}



export default observer(UploadCsvFile);