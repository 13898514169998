import { APIResponse } from "../../models/APIResponse";
import { CampaignSettingsAuditCreateModel } from "../../models/campaign-track-system/CampaignSettingsAuditCreateModel";
import { CampaignSettingsAuditResponse } from "../../models/campaign-track-system/CampaignSettingsAuditResponse";
import { PaginationParams } from "../../models/pagination/PaginationParams";
import { ISODateTime } from "../../types/types";
import { BaseService } from "../BaseService";



export class CampaignSettingAuditService extends BaseService {


    async GetCampaignSettingsAuditByPagination(campaignId: number, paginationOptions: PaginationParams)
    {

        const CampaignSettingsAuditData = await this.Post<CampaignSettingsAuditResponse[]>(`/paginate/${campaignId}` , paginationOptions);
        return CampaignSettingsAuditData;
    }


    async UpdateCampaignSettingsAudit(auditId: string, auditUpdateModel: CampaignSettingsAuditCreateModel): Promise<CampaignSettingsAuditResponse> {
        const UpdatedAudit = await this.Put<CampaignSettingsAuditResponse>(`/${auditId}`, auditUpdateModel);
        return UpdatedAudit;
    }


    async CreateCampaignSettingsAudit(campaignSettingsAuditCreateModel: CampaignSettingsAuditCreateModel): Promise<CampaignSettingsAuditResponse> {

        const createdAudit = await this.Post<CampaignSettingsAuditResponse>("" , campaignSettingsAuditCreateModel);
        return createdAudit;
    }

    
    async DeleteCampaignSettingsAudit(campaignId: number, auditId: string): Promise<CampaignSettingsAuditResponse> {

        const deletedAudit = await this.Delete<CampaignSettingsAuditResponse>(`/${campaignId}/${auditId}`);
        return deletedAudit;
    }


    async UploadFile(file : any ,userId : string) : Promise<APIResponse<string>>
    {
        const fromData = new FormData();
        fromData.append("csvFile" , file);
        fromData.append("userId" , userId);

        const fileUploaded = await this.Post<APIResponse<string>>(`/bulk-upload` , fromData);

        return fileUploaded;
    }


     async GetFilteredCampaignSettingsAudits(getActionsRequest: { userId?: number, from?: ISODateTime, to?: ISODateTime,
        optionTypeId?: string , campaignId?: number
    }): Promise<CampaignSettingsAuditResponse[]> {

        let queryString = "";
        
        if (getActionsRequest.campaignId) {
            queryString += `&campaignId=${getActionsRequest.campaignId}`
        }
        if (getActionsRequest.optionTypeId) {
            queryString += `&optionTypeId=${getActionsRequest.optionTypeId}`
        }
        if (getActionsRequest.userId) {
            queryString += `&userId=${getActionsRequest.userId}`
        }
        if (getActionsRequest.from) {
            queryString += `&from=${getActionsRequest.from}`
        }
        if (getActionsRequest.to) {
            queryString += `&to=${getActionsRequest.to}`
        }

        let url = `/?q${queryString}`
        return await this.Get<CampaignSettingsAuditResponse[]>(url);
    
    }
}


const campaignSettingAuditService = new CampaignSettingAuditService("CampaignSettingsAudit");
export default campaignSettingAuditService;