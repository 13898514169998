import axios, { AxiosError, AxiosResponse } from "axios";
import Cookies from "js-cookie";

import settings from "../config/AppSettings/SettingsFactory";
import {
  FetchError,
  UnauthorizedError
} from "../exceptions/AlgoSupervisionExceptions";
import { ApiErrorSchema } from "../models/ApiErrorSchema";

export abstract class BaseService {
    protected serverUrl: string;
    protected controllerPath: string;

    constructor(controllerPath: string, serverUrl?: string) {
        this.serverUrl = serverUrl ?? settings.serverUrl;
        this.controllerPath = controllerPath;
    }

    protected async Get<T>(relativeUrl: string): Promise<T> {
        try {
            const response: AxiosResponse<T> = await axios.get(this.buildUrl(relativeUrl), {
                headers: this.ConstructRequestHeaders(),
                withCredentials: true
            });
            if (this.IsSuccessfulResponse(response)) {
                return response.data;
            }

            throw this.CreateUnsuccessfulFetchError(response);
        }
        catch (error: any) {
            throw this.CreateError(error);
        }

    }

    protected async Post<T>(relativeUrl: string, payload: object, isArrayBufferResponseType: boolean = false): Promise<T> {
        const headers = {
            'Content-Type': 'application/json',
        };

        let options: any = {}
        options['headers'] = { ...this.ConstructRequestHeaders(), ...headers }
        if (isArrayBufferResponseType) {
            options['responseType'] = 'arraybuffer'
        }
        options['withCredentials'] = true;

        try {
            const response: AxiosResponse<T> = await axios.post(this.buildUrl(relativeUrl), payload, {
                ...options,
            });
            if (this.IsSuccessfulResponse(response)) {
                return response.data;
            }

            throw this.CreateUnsuccessfulFetchError(response);
        }
        catch (error: any) {
            throw this.CreateError(error);
        }

    }

    protected async Patch<T>(relativeUrl: string, payload: object): Promise<T> {
        try {
            const response: AxiosResponse<T> = await axios.patch(this.buildUrl(relativeUrl), payload, {
                headers: this.ConstructRequestHeaders(),
                withCredentials: true
            });
            if (this.IsSuccessfulResponse(response)) {
                return response.data;
            }

            throw this.CreateUnsuccessfulFetchError(response);
        }
        catch (error) {
            throw this.CreateError(error);
        }

    }

    protected async Put<T>(relativeUrl: string, payload: object): Promise<T> {
        try {
            const response: AxiosResponse<T> = await axios.put(this.buildUrl(relativeUrl), payload, {
                headers: this.ConstructRequestHeaders(),
                withCredentials: true
            });
            if (this.IsSuccessfulResponse(response)) {
                return response.data;
            }

            throw this.CreateUnsuccessfulFetchError(response);
        }
        catch (error) {
            throw this.CreateError(error);
        }

    }

    protected async Delete<T>(relativeUrl: string): Promise<T> {
        try {
            const response: AxiosResponse<T> = await axios.delete(this.buildUrl(relativeUrl), {
                headers: this.ConstructRequestHeaders(),
                withCredentials: true
            });
            if (this.IsSuccessfulResponse(response)) {
                return response.data;
            }

            throw this.CreateUnsuccessfulFetchError(response);
        }

        catch (error) {
            throw this.CreateError(error);
        }
    }

    private buildUrl(relativeUrl: string): string {
        return `${this.serverUrl}/api/${this.controllerPath}${relativeUrl}`;
    }

    private IsSuccessfulResponse<T>(response: AxiosResponse<T>): boolean {
        if (response.status >= 200 && response.status <= 226) {
            return true;
        }
        return false;
    }

    private CreateUnsuccessfulFetchError(response: AxiosResponse<any>) {
        console.error(`FETCH ERROR: response=${response}`);
        return new FetchError(response.data as ApiErrorSchema);
    }

    private CreateError(error: any) {
        if (error instanceof FetchError) {
            throw error;
        }

        if (!error.response) {
            throw new Error(error);
        }

        if (error.response.status === 401) {
            return new UnauthorizedError(error.response.statusText);
        }
        return new FetchError(error.response.data);
    }

    private ConstructRequestHeaders = () => {
        let requestHeaders: any = {};

        const userIdentityJwt = Cookies.get("User.Identity");
        if (userIdentityJwt) {
            requestHeaders["Authorization"] = `Bearer ${Cookies.get("User.Identity")}`;
        }

        return requestHeaders;
    }
}