import { action, makeObservable, observable } from "mobx";
import { vendorMockType } from "../../components/publisher-selection/mock-data/vendors-list";
import { Nullable } from "../../types/types";
import { PublisherSelectionActionType } from "../../types/publisher-selection/PublisherSelectionTypes";


export class PublisherSelectionDialog {

    /**
     * Ctor
     */

    @observable
    public Vendor: Nullable<vendorMockType>;

    @observable
    public actionType: Nullable<PublisherSelectionActionType>;

    @observable
    public Conflicts: string[];

    @observable
    public IsOn: boolean;

    constructor(
    ) {
        makeObservable(this);
        this.Conflicts = [];
        this.IsOn = false;
    }

    @action
    public Reset(): void {
        this.Vendor = undefined;
        this.actionType = undefined;
        this.Conflicts = [];
        this.IsOn = false;
    }

    @action
    public Set(vendor: vendorMockType, actionType: PublisherSelectionActionType, conflicts: string[]): void {
        this.Vendor = vendor;
        this.actionType = actionType;
        this.Conflicts = conflicts;
        this.IsOn = true;
    }

}