import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import AuditCompanyForm from "../../../components/company-track-system/AuditCompanyForm";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import CompanySettingBreadCrumbs from "./helpers/CompanySetting.breadcrumbs";
import { CompanySettingContextInitializers } from "./helpers/CompanySettingContextInitializers";

const CompanyTrackAddAuditPage = () => {
  const { companySettingStore } = useContext(RootStoreContext);
  const match = useParams<{ companyId: string; companyAuditId: string }>();
  const history = useHistory();

  usePageInitialization({
    onInitAsync: async () => {
      await CompanySettingContextInitializers.InitializeCompanySettingTypes(
        companySettingStore,
        history
      ).then(
        async () => await companySettingStore.SetupdateOperationState(false)
      );
    },

    onCompanyContextCallBack: (company, uiStore) => {
      uiStore.SetPageTitle(`Company Audits for: ${company.companyName}`);
      uiStore.AddBreadCrumbs(
        SupervisionBreadcrumbs.Companies(company.companyName),
        CompanySettingBreadCrumbs.CompanySetting(company.companyId),
        CompanySettingBreadCrumbs.AddCompanySettingAudit(company.companyId)
      );
    },
  });

  return (
    <div>
      <AuditCompanyForm
        companyAuditId={match.companyAuditId}
        companyId={Number(match.companyId)}
      />
    </div>
  );
};

export default observer(CompanyTrackAddAuditPage);
