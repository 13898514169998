class Root {
  private prefix = "/";

  public Index = `${this.prefix}`;
  public NotFound = `/not-found`;
  public AccessDenied = `/access-denied`;
  public Login = `/login`;
  public Logout = `/logout`;
}

class PublisherSelection {
  private prefix = "/publisherSelection";

  public ChooseCutOff = `${this.prefix}/cutoff`;
  public Analytics = `${this.prefix}/analytics`;
}

class Explainability {
  private prefix = "/explainability";

  public JobExplainabilityRoute = `${this.prefix}/table`

}

class Actions {
  private prefix = "/actions";

  public Table = `${this.prefix}/table`;
}

class Supervision {
  private prefix = "/";
  public CompaniesPrefix = "/companies"; //Didn't use prefix in purpose. Campaigns is the root route
  public Companies = "/"; //Didn't use prefix in purpose. Campaigns is the root route
  public Campaigns = "/campaigns";
  public Affiliates = "/Affiliates"

  public IndeedRoutes = {
    CampaignRoute: `${this.Campaigns}/:campaignId/indeed`,
    BuildCampaignRoute: (campaignId: number) =>
      `${this.IndeedRoutes.CampaignRoute}`.replace(
        ":campaignId",
        campaignId.toString()
      ),
    AddCampaign: `${this.Campaigns}/:campaignId/indeed/add-campaign`,
    BuildAddCampaign: (campaignId: number) =>
      `${this.IndeedRoutes.AddCampaign.replace(
        ":campaignId",
        campaignId.toString()
      )}`,
    CampaignJobsRoute: `${this.Campaigns}/:campaignId/indeed/:indeedCampaignId/jobs`,
    BuildCampaignJobsRoute: (campaignId: number, indeedCampaignId: string) =>
      `${this.IndeedRoutes.CampaignJobsRoute}`
        .replace(":campaignId", campaignId.toString())
        .replace(":indeedCampaignId", indeedCampaignId),
    AttachJobsToIndeedCampaign: `${this.Campaigns}/:campaignId/indeed/:indeedCampaignId/attach-jobs`,
    BuildAttachJobsToIndeedCampaign: (
      campaignId: number,
      indeedCampaignId: string
    ) =>
      `${this.IndeedRoutes.AttachJobsToIndeedCampaign}`
        .replace(":campaignId", campaignId.toString())
        .replace(":indeedCampaignId", indeedCampaignId),
    EditIndeedCampaign: `${this.Campaigns}/:campaignId/indeed/:indeedCampaignId/edit`,
    BuildEditIndeedCampaign: (campaignId: number, indeedCampaignId: string) =>
      `${this.IndeedRoutes.EditIndeedCampaign}`
        .replace(":campaignId", campaignId.toString())
        .replace(":indeedCampaignId", indeedCampaignId),
  };

  public CompanyTrackSystemRoutes = {
    CompanySettingsRoute: `${this.CompaniesPrefix}/:companyId/company-settings`,
    BuildCompanyRoute: (companyId: number) =>
      `${this.CompanyTrackSystemRoutes.CompanySettingsRoute}`.replace(
        ":companyId",
        companyId.toString()
      ),

    EditCompanySettingAudit: `${this.CompaniesPrefix}/:companyId/company-settings/:auditId/edit`,
    BuildEditCompanySettingAudit: (companyId: number, auditId: string) =>
      `${this.CompanyTrackSystemRoutes.EditCompanySettingAudit}`
        .replace(":companyId", companyId.toString())
        .replace(":auditId", auditId),

    AddCompanySettingAudit: `${this.CompaniesPrefix}/:companyId/company-settings/add`,
    BuildAddCompanySettingAudit: (companyId: number) =>
      `${this.CompanyTrackSystemRoutes.AddCompanySettingAudit.replace(
        ":companyId",
        companyId.toString()
      )}`,
  };

  public CampaignTrackSystemRoutes = {
    CampaignSettingsRoute: `${this.Campaigns}/:campaignId/campaign-settings`,
    BuildCampaignRoute: (campaignId: number) =>
      `${this.CampaignTrackSystemRoutes.CampaignSettingsRoute}`.replace(
        ":campaignId",
        campaignId.toString()
      ),

    EditCampaignSettingAudit: `${this.Campaigns}/:campaignId/campaign-settings/:auditId/edit`,
    BuildEditCampaignSettingAudit: (campaignId: number, auditId: string) =>
      `${this.CampaignTrackSystemRoutes.EditCampaignSettingAudit}`
        .replace(":campaignId", campaignId.toString())
        .replace(":auditId", auditId),

    AddCampaignSettingAudit: `${this.Campaigns}/:campaignId/campaign-settings/add`,
    BuildAddCampaignSettingAudit: (campaignId: number) =>
      `${this.CampaignTrackSystemRoutes.AddCampaignSettingAudit.replace(
        ":campaignId",
        campaignId.toString()
      )}`,
  };

  public GroupsRoutes = {
    GroupRoute: `${this.Campaigns}/:campaignId/groups`,
    BuildGroupRoute: (campaignId: number) =>
      `${this.GroupsRoutes.GroupRoute}`.replace(
        ":campaignId",
        campaignId.toString()
      ),

    AddGroup: `${this.Campaigns}/:campaignId/groups/add`,
    BuildAddGroup: (campaignId: number) =>
      `${this.GroupsRoutes.AddGroup}`.replace(
        ":campaignId",
        campaignId.toString()
      ),

    GroupJobsRoute: `${this.Campaigns}/:campaignId/groups/:groupId/jobs`,
    BuildGroupJobsRoute: (campaignId: number, groupId: number) =>
      `${this.GroupsRoutes.GroupJobsRoute}`
        .replace(":campaignId", campaignId.toString())
        .replace(":groupId", groupId.toString()),

    EditGroup: `${this.Campaigns}/:campaignId/groups/:groupId/edit`,
    BuildEditGroup: (campaignId: number, groupId: number) =>
      `${this.GroupsRoutes.EditGroup}`
        .replace(":campaignId", campaignId.toString())
        .replace(":groupId", groupId.toString()),

    AttachJobsToGroup: `${this.Campaigns}/:campaignId/groups/:groupId/attach-jobs`,
    BuildAttachJobsToGroup: (campaignId: number, groupId: number) =>
      `${this.GroupsRoutes.AttachJobsToGroup}`
        .replace(":campaignId", campaignId.toString())
        .replace(":groupId", groupId.toString()),
  };


  public VendorManagementRoutes = {

    AffiliateVendorRoute: `${this.Affiliates}/:affiliateId/vendor-management`,
    BuildAffiliateVendorManagementRoute: (affiliateId: number) =>
      `${this.VendorManagementRoutes.AffiliateVendorRoute}`
        .replace(":affiliateId", affiliateId.toString()
        ),

    CompanyVendorRoute: `${this.CompaniesPrefix}/:companyId/vendor-management`,
    BuildCompanyVendorManagementRoute: (companyId: number) =>
      `${this.VendorManagementRoutes.CompanyVendorRoute}`
        .replace(":companyId", companyId.toString()
        ),

    CampaignVendorRoute: `${this.Campaigns}/:campaignId/vendor-management`,
    BuildCampaignVendorManagementRoute: (campaignId: number) =>
      `${this.VendorManagementRoutes.CampaignVendorRoute}`
        .replace(":campaignId", campaignId.toString()
        ),

    GroupVendorRoute: `${this.Campaigns}/:campaignId/groups/:groupId/vendor-management`,
    BuildGroupVendorManagementRoute: (campaignId: number, groupId: number) =>
      `${this.VendorManagementRoutes.GroupVendorRoute}`
        .replace(":campaignId", campaignId.toString())
        .replace(":groupId", groupId.toString()
        ),
  }

  public AttributesManagementRoutes = {

    CompanyAttributesRoute: `${this.CompaniesPrefix}/:companyId/attributes-management`,
    BuildCompanyAttributesRouteManagementRoute: (companyId: number) =>
      `${this.AttributesManagementRoutes.CompanyAttributesRoute}`
        .replace(":companyId", companyId.toString()
        ),
  }
  
}

class Settings {
  private prefix = "/settings";

  public ShortCutsManual = `${this.prefix}/short-cuts-manual`;
}

export class RoutesPaths {
  public static Supervision = new Supervision();
  public static Explainability = new Explainability();

  public static PublisherSelection = new PublisherSelection();
  // public static IndeedManager = new IndeedManagerRoutes();
  public static Actions = new Actions();
  public static Settings = new Settings();
  public static Root = new Root();
}
