import "./IndeedStrategyManagement.scss";

import { Col, Collapse, Row } from "antd";
import { useState } from "react";

import { Box } from "@material-ui/core";

import useOnInitAsync from "../../../hooks/useOnInitAsync";
import { IndeedCampaignObjectiveStrategy } from "../../../models/indeed-manager/strategy/IndeedCampaignObjectiveStrategy";
import { indeedStrategyManagementService } from "../../../services/indeed/IndeedStrategyManagementService";
import Loader from "../../general/loader/Loader";
import IndeedStrategyCard from "./IndeedStrategyCard";
import IndeedStrategyForm from "./IndeedStrategyForm";

const { Panel } = Collapse;

type Props = {
    plCampaignId: string | number;
};

const IndeedStrategyManagement = (props: Props) => {
    const { plCampaignId } = props;

    const [campaignObjectiveStrategies, setCampaignObjectiveStrategies] =
        useState<IndeedCampaignObjectiveStrategy[]>();

    const callback = (key: any) => {};

    useOnInitAsync(async () => {
        const campaignStrategies =
            await indeedStrategyManagementService.GetStrategiesByPlCampaign(
                plCampaignId
            );
        setCampaignObjectiveStrategies(campaignStrategies);
    });

    if (!campaignObjectiveStrategies) {
        return <Loader position="normal" />;
    }

    return (
        <Box mb={5}>
            <Collapse
                defaultActiveKey={
                    campaignObjectiveStrategies.length == 0 ? ["0"] : ["1"]
                }
                /* onChange={callback} */
            >
                <Panel header="Strategy Management" key="1">
                    <Box mt={2} mb={6}>
                        <IndeedStrategyForm
                            plCampaignId={plCampaignId}
                            onFormSubmitSuccesfully={(newlyCreatedStrategy) => {
                                setCampaignObjectiveStrategies([
                                    ...campaignObjectiveStrategies,
                                    newlyCreatedStrategy,
                                ]);
                            }}
                        />
                    </Box>

                    <Row gutter={[16, 16]}>
                        {campaignObjectiveStrategies.map(
                            (campaignObjectiveStrategy, index) => (
                                <Col className="gutter-row" span={4}>
                                    <IndeedStrategyCard
                                        key={index}
                                        indeedCampaignObjectiveStrategy={
                                            campaignObjectiveStrategy
                                        }
                                        plCampaignId={plCampaignId}
                                        onDelete={(objectiveId) => {
                                            setCampaignObjectiveStrategies(
                                                campaignObjectiveStrategies.filter(
                                                    (x) => x.id !== objectiveId
                                                )
                                            );
                                        }}
                                    />
                                </Col>
                            )
                        )}
                    </Row>
                </Panel>
            </Collapse>
        </Box>
    );
};

export default IndeedStrategyManagement;
