import { AutoComplete } from "antd";
import React, { useEffect, useState } from "react";

type Props<T> = {
    options: T[];
    optionsToAutoCompleteMapFunction: (value: T) => { value: string };
    onSelectHandle: (value: T) => void;
    initialValue?: string;
    placeHolder?: string;
};

const SupervisionAutoComplete = <T extends unknown>(props: Props<T>) => {
    const {
        options,
        optionsToAutoCompleteMapFunction,
        onSelectHandle,
        initialValue,
        placeHolder,
    } = props;

    const [searchText, setSearchText] = useState(initialValue ?? "");
    const [filteredOptions, setFilteredOptions] = useState(options);

    useEffect(() => {
        if (!initialValue) return;
        setFilteredOptions(
            options.filter((option) =>
                optionsToAutoCompleteMapFunction(option)
                    .value.toLowerCase()
                    .includes(initialValue.toLowerCase())
            )
        );
    }, []);

    return (
        <AutoComplete
            style={{ width: "100%" }}
            options={filteredOptions.map(optionsToAutoCompleteMapFunction)}
            value={searchText}
            onSelect={(value: string) => {
                const selectedOption = options.find((option) =>
                    optionsToAutoCompleteMapFunction(option)
                        .value.toLowerCase()
                        .includes(value.toLowerCase())
                );
                if (!selectedOption) return;

                onSelectHandle(selectedOption);
                setFilteredOptions([selectedOption]);
                setSearchText(value);
            }}
            onSearch={(value) => {
                setSearchText(value);
                setFilteredOptions(
                    options.filter((option) =>
                        optionsToAutoCompleteMapFunction(option)
                            .value.toLowerCase()
                            .includes(value.toLowerCase())
                    )
                );
            }}
            allowClear
            onClear={() => {
                setSearchText("");
                setFilteredOptions(options);
            }}
            placeholder={placeHolder}
        />
    );
};

export default SupervisionAutoComplete;
