import { AffiliateStore } from "./AffiliateStore";
import { CampaignSettingStore } from "./CampaignSettingStore";
import { CampaignStore } from "./CampaignStore";
import { CompanyStore } from "./CompanyStore";
import { CompanySettingStore } from "./CompanySettingStore";
import { GroupStore } from "./GroupStore";
import { IndeedStore } from "./IndeedStore";
import { NotificationStore } from "./NotificationStore";
import { PublisherSelectionStore } from "./PublisherSelectionStore";
import { UIStore } from "./UIStore";
import { UserStore } from "./UserStore";
import { VendorManagementStore } from "./VendorManagementStore";
import { ExplainabilityStore } from "./ExplainabilityStore";
import { AttributesManagementStore } from "./AttributesManagementStore";

export class RootStore {
    /**
     * Props
     */

    public uiStore: UIStore;
    public publisherSelectionStore: PublisherSelectionStore;
    public companyStore: CompanyStore;
    public campaignStore: CampaignStore;
    public indeedStore: IndeedStore;
    public userStore: UserStore;
    public notificationStore: NotificationStore;
    public campaignSettingStore: CampaignSettingStore;
    public companySettingStore: CompanySettingStore;
    public groupStore: GroupStore;
    public vendorManagementStore: VendorManagementStore;
    public affiliateStore: AffiliateStore;
    public explainabilityStore : ExplainabilityStore;
    public attributesManagementStore: AttributesManagementStore;

    constructor() {
        this.uiStore = new UIStore();
        this.publisherSelectionStore = new PublisherSelectionStore();
        this.companyStore = new CompanyStore();
        this.campaignStore = new CampaignStore();
        this.indeedStore = new IndeedStore();
        this.userStore = new UserStore();
        this.notificationStore = new NotificationStore();
        this.campaignSettingStore = new CampaignSettingStore();
        this.companySettingStore = new CompanySettingStore();
        this.groupStore = new GroupStore();
        this.vendorManagementStore = new VendorManagementStore();
        this.affiliateStore = new AffiliateStore();
        this.explainabilityStore = new ExplainabilityStore();
        this.attributesManagementStore = new AttributesManagementStore();
    }
}
