import { LoginRequest } from "../models/auth/LoginRequest";
import { LoginResponse } from "../models/auth/LoginResponse";
import { BaseService } from "./BaseService";

export class AuthService  extends BaseService {
    public async Login(loginRequest: LoginRequest) {
        const loginResponse = await this.Post<LoginResponse>("/login", loginRequest);
        return loginResponse;
    }
}


export const authService = new AuthService("auth");