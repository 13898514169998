import settings from "../../config/AppSettings/SettingsFactory";
import { ValidAction } from "../../models/ValidAction";
import { ManualActionRequestSource } from "../../models/manual-actions/ManualActionRequestSource";
import { EntityVendorRequest } from "../../models/vendor-management/EntityVendorRequest";
import { EntityVendorResponse } from "../../models/vendor-management/EntityVendorResponse";
import { ValidationDto } from "../../models/vendor-management/ValidationDto";
import { EntityDistributionSettings } from "../../models/vendor-management/settings/EntityDistributionSettings";
import { BaseService } from "../BaseService";



export class GroupVendorService extends BaseService {

  async GetAllAsync(groupId: number) {
    const vendorManagementdata = await this.Get<EntityVendorResponse[]>(`/all/${groupId}`);
    return vendorManagementdata;
  }

  async ValidateRules(vendorsWithDecision: EntityVendorRequest[], groupId: number) {

    const validateRules = await this.Post<ValidationDto[]>(`/validate/${groupId}`, vendorsWithDecision);
    return validateRules;
  }

  async SetGroupVendors(vendorsWithDecision: EntityVendorRequest[], groupId: number, manualActionRequestSource: ManualActionRequestSource) {
    const vendorManagementdata = await this.Post<EntityVendorRequest[]>(`/${groupId}?manualActionRequestSource=${manualActionRequestSource}`, vendorsWithDecision);
    return vendorManagementdata;
  }

  async ResetGroupVendors(groupId: number) {
    const dataSettings = await this.Delete(`/reset/${groupId}`);
    return dataSettings;
  }

  async GetGroupSettingsAsync(groupId: number) {
    const vendorManagementdata = await this.Get<EntityDistributionSettings>( `/settings/${groupId}`);
    return vendorManagementdata;
  }

  async SetCompanyDistributionSettings(entityDistributionSettings: EntityDistributionSettings, groupId: number) : Promise<EntityDistributionSettings>{
    const entitySettings = await this.Post<EntityDistributionSettings>(
      `/settings/${groupId}`,
      entityDistributionSettings
    );
    return entitySettings;
  }

  async ChangeSettingsIsValidValidationAsync(entityDistributionSettings: EntityDistributionSettings, groupId: number) {
    const entitySettingsResponse = await this.Post<ValidAction>(
      `/settings-action-validation/${groupId}`,entityDistributionSettings)
    return entitySettingsResponse;
  }


}

const groupVendorService = new GroupVendorService("GroupVendors", settings.vendorManagementUrl);
export default groupVendorService;