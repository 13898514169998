import { Space, Table, TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { EntityRecordDetailed } from "../../../models/EntityRecord";
import { Box, Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";

const externalColumn: any = {
    title: "External",
    key: "action",
    render: (text: any, record: EntityRecordDetailed) => (
        <Box>
            <Button
                onClick={() =>
                    window.alert(
                        "Will redirect you to campaign details in the future"
                    )
                }
                variant="text"
                color="primary"
                style={{ marginRight: "30px" }}>
                Campaign Details
            </Button>
        </Box>
    ),
};

type entityTypes = "Campaigns" | "Groups" | "Jobs";

const GetChildEntityName = (entityName: entityTypes): entityTypes | "" => {
    switch (entityName) {
        case "Campaigns":
            // return "Groups";
            return "Jobs";
        case "Groups":
            return "Jobs";
        default:
            return "";
    }
};

interface Props {
    entityName: entityTypes;
    entityData: EntityRecordDetailed[];
    columns: {title: string, dataIndex: string | string[]}[]
    HandleChildEntityButtonClick: (entityName: string) => void;
    HandleVendorButtonClick: (record: EntityRecordDetailed) => void;
}

const EntityPage = (props: Props) => {
    const actionColumn: any = {
        title: "Action",
        key: "action",
        render: (text: any, record: EntityRecordDetailed) => (
            <Box>
                <Button
                    onClick={() => props.HandleVendorButtonClick(record)}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "30px" }}>
                    Vendors
                </Button>
                {props.entityName != "Jobs" && (
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() =>
                            props.HandleChildEntityButtonClick(
                                record.entityName
                            )
                        }>
                        View {GetChildEntityName(props.entityName)}
                    </Button>
                )}
            </Box>
        ),
    };

    // const columns = [
    //     {
    //         title: props.entityName,
    //         dataIndex: "entityName",
    //     },
    //     {
    //         title: "Jobs",
    //         dataIndex: "jobsCount",
    //         sorter: {
    //             compare: (a: EntityRecordDetailed, b: EntityRecordDetailed) =>
    //                 a.jobsCount - b.jobsCount,
    //             //multiple: 3,
    //         },
    //     },
    // ];

    return (
        <Table
            columns={[...props.columns, actionColumn, externalColumn]}
            dataSource={props.entityData}
            onChange={(pagination, filters, sorter, extra) => {
                // console.log("params", pagination, filters, sorter, extra);
            }}
        />
    );
};

export default observer(EntityPage);
