import "./SupervisionTitle.scss";

import React from "react";

type Props = {};

const SupervisionTitle = (props: Props) => {
    return (
        <div className="sp-container">
            <div className="sp-content">
                <div className="sp-globe"></div>
                <h2 className="frame-1">Algo</h2>
                <h2 className="frame-2">Supervision</h2>
                <h2 className="frame-5">
                    <span>Algo</span>
                    <span>Supervision</span>
                </h2>
            </div>
        </div>
    );
};

export default SupervisionTitle;
