import "./CampaignTable.scss";

import { Empty, Table } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Campaign } from "../../../models/operation/Campaign";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { DOMCoordinates } from "../../../types/types";
import Loader from "../../general/loader/Loader";
import SupervisionTable from "../../general/supervision-table/SupervisionTable";
import { CampaignsTableActionsBuilder } from "./CampaignsTable.actions";
import { CampaignTableDto } from "./CampaignTable.dto";

export type RightClickData = {
    clickCoordinates: DOMCoordinates;
    clickedCampaign: CampaignTableDto;
    rowIndex: number;
};

interface Props {}
const CampaignsTable = (props: Props) => {
    const history = useHistory();

    const { campaignStore } = useContext(RootStoreContext);

    const [campaigns, setCampaigns] = useState<Campaign[] | undefined>(
        undefined
    );

    useEffect(() => {
        setCampaigns(campaignStore.SearchedCampaigns);
    }, [campaignStore.SearchedCampaigns]);

    if (campaigns == undefined) {
        return <Loader position="pad-top" />;
    }

    if (campaigns.length == 0) {
        return (
            <Empty
                style={{ marginTop: "40px" }}
                description="Please select campaigns to show"
            />
        );
    }

    return (
        <SupervisionTable
            dataSource={campaigns.map((c) => new CampaignTableDto(c))}
            rowActionSettings={{
                actions: CampaignsTableActionsBuilder({
                    history: history
                }),
                showActionsOnRightClick: true,
                showActionsOnLastColumn: true,
            }}
        />
    );
};

export default observer(CampaignsTable);
