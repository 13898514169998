import { message } from "antd";
import { throws } from "assert";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { RoutesPaths } from "../constants/RoutePaths";
import { BreadCrumb } from "../models/BreadCrumb";
import { Campaign } from "../models/operation/Campaign";
import { Company } from "../models/operation/Company";
import campaignService from "../services/operation/CampaignService";
import companyService from "../services/operation/CompanyService";
import { RootStoreContext } from "../stores/RootStoreContext";
import { UIStore } from "../stores/UIStore";
import useOnInit from "./useOnInit";
import useOnInitAsync from "./useOnInitAsync";
import useSetCurrentRoute from "./useSetCurrentRoute";

/**
 * NOTE: Use that hook on every page component
 * Initializes page configuration
 */
const usePageInitialization = (initializeConfig: {
  pageTitle?: string;
  pageBreadCrumbs?: BreadCrumb[];
  onInitAsync?: () => Promise<void>;
  onCallback?: (uiStore: UIStore) => void;
  onCampaignContextCallBack?: (campaign: Campaign, uiStore: UIStore) => void;
  onCompanyContextCallBack?: (company: Company, uiStore: UIStore) => void;
}) => {
  const campaignMatch = useParams<{ campaignId?: string }>();
  const companyMatch = useParams<{ companyId?: string }>();
  const history = useHistory();

  const { uiStore, companyStore, campaignStore } = useContext(RootStoreContext);

  useSetCurrentRoute();

  useOnInit(() => {
    if (initializeConfig.pageTitle) {
      uiStore.SetPageTitle(initializeConfig.pageTitle);
    }
    if (initializeConfig.pageBreadCrumbs) {
      uiStore.AddBreadCrumbs(...initializeConfig.pageBreadCrumbs);
    }

    if (initializeConfig.onCallback) {
      initializeConfig.onCallback(uiStore);
    }
  });

  useOnInitAsync(async () => {
    if (initializeConfig.onInitAsync) {
      await initializeConfig.onInitAsync();
    }

    if (companyMatch.companyId) {
      uiStore.SetIsWebsiteLoading(true);
      try {
        const company = await companyService.GetCompanyById(
          Number(companyMatch.companyId)
        );
        companyStore.SetSelectedCompany(company);

        if (initializeConfig.onCompanyContextCallBack) {
          initializeConfig.onCompanyContextCallBack(company, uiStore);
        }
      } catch (err) {
        history.push(RoutesPaths.Supervision.Companies);
        message.error(
          `Account with ID: ${companyMatch.companyId} doesn't exist. Returned to home page`
        );
      } finally {
        uiStore.SetIsWebsiteLoading(false);
      }
    }

    if (campaignMatch.campaignId) {
      uiStore.SetIsWebsiteLoading(true);
      try {

        const campaign = await campaignService.GetById(
          Number(campaignMatch.campaignId)
        );
        campaignStore.SetSelectedCampaign(campaign);

        const company = await companyService.GetCompanyById(
          Number(campaign.companyId)
        );
        companyStore.SetSelectedCompany(company);

        if (initializeConfig.onCompanyContextCallBack) {
          initializeConfig.onCompanyContextCallBack(company, uiStore);
        }
      } catch (err) {
        history.push(RoutesPaths.Supervision.Companies);
        message.error(
          `Campaign with ID: ${campaignMatch.campaignId} doesn't exist. Returned to home page`
        );
      } finally {
        uiStore.SetIsWebsiteLoading(false);
      }
    }
  });
};

export default usePageInitialization;
