import settings from "../../config/AppSettings/SettingsFactory";
import { CompanyAttributesAggregation } from "../../models/company-attributes/CompanyAttributesAggregation";
import { CompanyPreferenceDto } from "../../models/company-attributes/CompanyPreferenceDto";
import { CustomerCampaignAttributesAggregation } from "../../models/company-attributes/CustomerCampaignAttributesAggregation";
import { BaseService } from "../BaseService";




export class CompanyAttributesService extends BaseService {

    async getAllAsync(companyId: number) {
        const companyAttributesdata = await this.Get<CompanyAttributesAggregation>(`/${companyId}`);
        return companyAttributesdata;
    }

    async setCompanysAttributes(companyAttributes: CompanyAttributesAggregation) {
        const companyAttributesdata = await this.Post(``, companyAttributes);
        return companyAttributesdata;
    }

    async getCustomerRequestsAsync(companyId: number) {
        const companyAttributesdata = await this.Get<CustomerCampaignAttributesAggregation>(`/customer-campaign-attributes/${companyId}`);
        return companyAttributesdata;
    }

}


const companyAttributesService = new CompanyAttributesService("company-attributes", settings.attributesManagementServiceUrl);
export default companyAttributesService;

