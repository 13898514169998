import { GroupJobResponse } from "../../models/groups/GroupJobResponse";
import { GroupStatus } from "../../models/groups/GroupStatus";
import { ISODateTime } from "../../types/types";
import { FormatDate } from "../../utils/DateHelpers";
import { TableDto } from "../general/supervision-table/Table.dto";


export class GroupJobsTableDto extends TableDto {
    constructor(
        public groupJobId: number,
        public groupId: string,
        public campaignId: number,
        public jobId: number,
        public status: string,
        public userName: string,
        public creationDate: ISODateTime,
        public modificationDate: ISODateTime,
    ) {
        super();
    }

    public static MapFromGroupJob(groupJobResponse: GroupJobResponse) {
        return new GroupJobsTableDto(
            groupJobResponse.groupJobId,
            groupJobResponse.groupId,
            groupJobResponse.campaignId,
            groupJobResponse.jobId,
            GroupStatus[Number(groupJobResponse.isActive)],
            groupJobResponse.userName,
            FormatDate(groupJobResponse.creationDate),
            FormatDate(groupJobResponse.modificationDate),

        )
    }
}