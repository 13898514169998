
import { DockerSettings } from "./DockerSettings";
import { ISettings } from "./ISettings";
import { LocalSettings } from "./LocalSettings";
import { ProdSettings } from "./ProdSettings";
import { StageSettings } from "./StageSettings";

const environment = process.env.REACT_APP_ENV as string
let settings: ISettings | undefined;

switch (environment) {
    case "prod":
        settings = new ProdSettings();
        break;

    case "stage":
        settings = new StageSettings();
        break;

    case "docker":
        settings = new DockerSettings();
        break;

    case "local":
    default:
        settings = new LocalSettings();
        break;

}

export default settings as ISettings;