import Sider from "antd/lib/layout/Sider";
import styled from "styled-components";

import { makeStyles } from "@material-ui/core";

// Material UI

export const useStyles = makeStyles((theme) => ({
    pageHeader: {
        transition: '250ms all ease-in-out',
        overflow: "hidden"
    },
    pageHeaderOn: {
        height: '95px',
        opacity: 1
    },
    pageHeaderOff: {
        height: '0px',
        opacity: 0
    },
}))
