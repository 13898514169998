import React, { useContext } from "react";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { Button, ButtonGroup } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStyles } from "./VendorDrawerList.styles";
import { PublisherSelectionActionType } from "../../../types/publisher-selection/PublisherSelectionTypes";
import { RootStoreContext } from "./../../../stores/RootStoreContext";
import { vendorMockType, vendorsData } from "./../mock-data/vendors-list";

/**
 * Helper Components
 */
interface ButtonGroupComponentPropsType {
    HandleButtonActionClick: (
        actionType: PublisherSelectionActionType,
        vendor: vendorMockType
    ) => (event: any) => void;
    vendor: vendorMockType;
}

const ButtonGroupComponent = (props: ButtonGroupComponentPropsType) => {
    const { HandleButtonActionClick, vendor } = props;

    const IsSelected = (actionType: PublisherSelectionActionType) => {
        const styledProps = {
            variant: "contained",
            color: "primary",
        };
        if (actionType == "Exclude" && vendor.operationTypeId == 1) {
            return styledProps;
        }
        if (actionType == "Include" && vendor.operationTypeId == 2) {
            return styledProps;
        }

        if (actionType == "Exclusive" && vendor.operationTypeId == 3) {
            return styledProps;
        }
    };

    return (
        <ButtonGroup size="small">
            <Button
                onClick={HandleButtonActionClick("Include", vendor)}
                {...(IsSelected("Include") as any)}>
                Include
            </Button>
            <Button
                onClick={HandleButtonActionClick("Exclude", vendor)}
                {...(IsSelected("Exclude") as any)}>
                Exclude
            </Button>
            <Button
                onClick={HandleButtonActionClick("Exclusive", vendor)}
                {...(IsSelected("Exclusive") as any)}>
                Exclusive
            </Button>
        </ButtonGroup>
    );
};

interface Props {
    vendorsData: vendorMockType[];
}

const VendorDrawerList = (props: Props) => {
    const { uiStore, publisherSelectionStore } = useContext(RootStoreContext);

    const classes = useStyles();

    /**
     * Functions
     */
    const HandleButtonActionClick =
        (actionType: PublisherSelectionActionType, vendor: vendorMockType) =>
        (event: any): void => {
            publisherSelectionStore.DialogBox.Set(vendor, actionType, [
                "Job A: Include",
                "Job C: Exclude",
            ]);

            publisherSelectionStore.SetSelectedPublisherActionType(actionType);
            publisherSelectionStore.SetSelectedVendor(vendor);
        };

    return (
        <List style={{ width: 700 }} dense={false}>
            {props.vendorsData.map((vendor: vendorMockType) => (
                <ListItem
                    className={
                        vendor.isCutOff
                            ? classes.inActiveVendor
                            : classes.activeVendor
                    }>
                    <ListItemAvatar>
                        <Avatar className={classes.avatarBackground}>
                            <img
                                src={vendor.imageUrl}
                                alt={vendor.vendorName}
                                className={classes.vendorImg}
                            />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={vendor.vendorName} />
                    <ListItemSecondaryAction>
                        <ButtonGroupComponent
                            HandleButtonActionClick={HandleButtonActionClick}
                            vendor={vendor}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
};

export default observer(VendorDrawerList);
