

import { JobsCountResponse } from "../../models/jobs/JobsCountResponse";
import { Campaign } from "../../models/operation/Campaign";
import { CampaignPartial } from "../../models/operation/CampaignPartial";
import { GetJobsByIdApiDto } from "../../models/operation/GetJobsByIdApiDto";
import { Job } from "../../models/operation/Job";
import { CachePaginationBatchRequest } from "../../models/pagination/CachePaginationBatchRequest";
import { CachePaginationBatchResponse } from "../../models/pagination/CachePaginationBatchResponse";
import { PaginationParams } from "../../models/pagination/PaginationParams";
import { BaseService } from "../BaseService";
import { CachePaginationUtils } from "../pagination/CachePaginationUtils";

export class JobService extends BaseService {

    async GetJobsByCampaignIdPagination(campaignId: number, paginationParams: PaginationParams): Promise<Job[]> {
        const queryString = `campaignId=${campaignId}&offset=${paginationParams.offset}&limit=${paginationParams.limit}`
        const jobsPage = await
            this.Get<Job[]>(`?${queryString}`);
        return jobsPage;
    }

    async GetJobsByCompanyIdPagination(companyId: number, paginationParams: PaginationParams): Promise<Job[]> {
        const queryString = `companyId=${companyId}&offset=${paginationParams.offset}&limit=${paginationParams.limit}`
        const jobsPage = await
            this.Get<Job[]>(`?${queryString}`);
        return jobsPage;
    }


    async GetJobsByIds(getJobsByIdApiDto: GetJobsByIdApiDto): Promise<Job[]> {
        const jobs = await this.Post<Job[]>(`/byIds`, getJobsByIdApiDto);
        return jobs;
    }


    async GetCountAsync(getCountRequest: { campaignId?: number, companyId?: number }): Promise<JobsCountResponse> {
        let queryString = `?q`;
        if (getCountRequest.campaignId) {
            queryString += `&campaignId=${getCountRequest.campaignId}`
        }
        if (getCountRequest.companyId) {
            queryString += `&companyId=${getCountRequest.companyId}`
        }

        const jobCountRespose = await this.Get<JobsCountResponse>(`/count${queryString}`);
        return jobCountRespose;
    }


}


const jobService = new JobService("Jobs");
export default jobService;