import { Button, Col, Form, message, Row, Select, Typography } from "antd";
import { Moment } from "moment";
import { useContext, useState } from "react";
import { useHistory } from "react-router";

import { Box } from "@material-ui/core";

import { RoutesPaths } from "../../constants/RoutePaths";
import { FetchError } from "../../exceptions/AlgoSupervisionExceptions";
import { IndeedBudgetFirstMonthBehavior } from "../../models/indeed-manager/IndeedBudgetFirstMonthBehavior";
import { IndeedCampaignBudgetType } from "../../models/indeed-manager/IndeedCampaignBudgetType";
import { IndeedCampaignObjective } from "../../models/indeed-manager/IndeedCampaignObjective";
import {
  INDEED_CAMPAIGN_OBJECTIVE_DEFAULT_VALUE,
  IndeedCampaignObjectiveType
} from "../../models/indeed-manager/IndeedCampaignObjectiveType";
import { IndeedCampaignStatus } from "../../models/indeed-manager/IndeedCampaignStatus";
import { IndeedCreateCampaignEventPayload } from "../../models/indeed-manager/IndeedCreateCampaignEventPayload";
import { IndeedEndDateType } from "../../models/indeed-manager/IndeedEndDateType";
import indeedService from "../../services/indeed/IndeedService";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { ISODateTime } from "../../types/types";
import CampaignBudgetInputs from "./CampaignBudgetInputs";
import CampaignDetailsInputs from "./CampaignDetailsInputs";
import IndeedJobsToCampaignTransfer from "./IndeedJobsToCampaignTransfer";

const { Option } = Select;

interface Props {
    plCampaignId: number;
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

type FormState = {
    campaignName: string;
    campaignStatus: IndeedCampaignStatus;
    budgetType: IndeedEndDateType;
    startDate: Moment;
    endDate: Moment;
    budgetLimitSettings: IndeedCampaignBudgetType;
    budgetLimitValue: string;
    budgetFirstMonthBehavior: IndeedBudgetFirstMonthBehavior;
    campaignObjective: IndeedCampaignObjective;
};

interface Props {
    plCampaignId: number;
}

const IndeedAddCampaignForm = (props: Props) => {
    const { plCampaignId } = props;
    const { campaignStore, userStore, indeedStore } =
        useContext(RootStoreContext);

    const history = useHistory();
    const [campaignJobsIds, setCampaignJobsIds] = useState<number[]>([]);

    const onFinish = async (formState: FormState) => {
        try {
            var response = await indeedService.CreateCampaign(
                new IndeedCreateCampaignEventPayload(
                    formState.campaignName,
                    userStore.User!.id,
                    formState.campaignStatus,
                    campaignJobsIds,
                    formState.startDate.toISOString(),
                    plCampaignId,
                    campaignStore.SelectedCampaign!.companyId,
                    formState.campaignObjective,
                    indeedStore.PLCampaignData!.indeedCampaignSourceInfo.sourceName,
                    formState.budgetFirstMonthBehavior,
                    deriveEndDate(
                        formState.budgetType,
                        IndeedEndDateType.Fixed,
                        formState.endDate
                    ),
                    deriveEndDate(
                        formState.budgetType,
                        IndeedEndDateType.Target,
                        formState.endDate
                    ),
                    deriveBudgetLimit(
                        formState.budgetLimitSettings,
                        formState.budgetLimitValue,
                        IndeedCampaignBudgetType.OneTime
                    ),
                    deriveBudgetLimit(
                        formState.budgetLimitSettings,
                        formState.budgetLimitValue,
                        IndeedCampaignBudgetType.Monthly
                    )
                )
            );
            
            message.success(response.body);
            history.push(
                RoutesPaths.Supervision.IndeedRoutes.BuildCampaignRoute(
                    plCampaignId
                )
            );
        } catch (error) {
            if (error instanceof FetchError) {
                message.error(error.message);
            }
        }
    };

    return (
        <Box width={650}>
            <Form
                {...layout}
                name="Add Camapign"
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    campaignObjective: {
                        type: INDEED_CAMPAIGN_OBJECTIVE_DEFAULT_VALUE,
                    },
                }}>
                <CampaignDetailsInputs
                    isStatusEditable={false}
                    isCampaignObjectiveTypeEditable={true}
                    campaignObjectiveTypeDefaultValue={
                        INDEED_CAMPAIGN_OBJECTIVE_DEFAULT_VALUE
                    }
                />

                <CampaignBudgetInputs isStartDateEditable={true} />

                <Row style={{ marginTop: "50px" }}>
                    <Col offset={2} span={16}>
                        <Typography.Title
                            level={4}
                            style={{ marginBottom: 15 }}>
                            Choose Jobs for Indeed Campaign
                        </Typography.Title>
                    </Col>
                    <Col offset={2} span={16}>
                        <IndeedJobsToCampaignTransfer
                            onChange={(jobIds) => {
                                setCampaignJobsIds(jobIds);
                            }}
                            plCampaignId={plCampaignId}
                        />
                    </Col>
                </Row>

                <Form.Item
                    wrapperCol={{ offset: 2, span: 16 }}
                    style={{ marginTop: 50 }}>
                    <Button type="primary" htmlType="submit">
                        Create Campaign
                    </Button>
                </Form.Item>
            </Form>
        </Box>
    );
};

export default IndeedAddCampaignForm;

const deriveEndDate = (
    currentType: IndeedEndDateType,
    budgetType: IndeedEndDateType,
    endDate: Moment
): ISODateTime | undefined => {
    return currentType == budgetType ? endDate.toISOString() : undefined;
};

const deriveBudgetLimit = (
    formBudgetLimitSettings: IndeedCampaignBudgetType,
    budgetLimitValue: string,
    wantedBudgetLimitSettings: IndeedCampaignBudgetType
): number | undefined => {
    if (formBudgetLimitSettings == wantedBudgetLimitSettings) {
        return Number(budgetLimitValue);
    }
    return undefined;
};
