import { Col, Row, Select, Space, Typography } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { spacingUnit } from "../../assets/styles/theme";
import { ClassificationType } from "../../models/vendor-management/ClassificationType";
import { EntityVendorResponse } from "../../models/vendor-management/EntityVendorResponse";
import vendorManagementService from "../../services/vendor-management/CampaignVendorService";
import { RootStoreContext } from "../../stores/RootStoreContext";
import Loader from "../general/loader/Loader";
import "./VendorSelectInput.scss";


const { Option } = Select;

interface Props {
    entityVendors: EntityVendorResponse[]
}


// ... (other imports)

const VendorSelectInput = (props: Props) => {
    const { entityVendors } = props;
    const [filterValue, setFilterValue] = useState<string>('');
    const { vendorManagementStore } = useContext(RootStoreContext);
    const [filteredOptions, setFilteredOptions] = useState<EntityVendorResponse[]>([]);
    const [selectedValues, setSelectedValues] = useState<string[]>();


    useEffect(() => {
        const defaultValue = vendorManagementStore.SearchedVendors.map((vendor) =>
            BuildOptionValue(vendor.vendorName, vendor.vendorId)
        );

        setSelectedValues(defaultValue);
        setFilteredOptions(filteredOptions.length ? filteredOptions : entityVendors);
        
    }, [vendorManagementStore.SearchedVendors]);

    const handleFilter = (inputValue: string) => {
        setFilterValue(inputValue);
        const filtered = entityVendors.filter(
            (vendor) =>
                vendor.vendorName.toLowerCase().includes(inputValue.toLowerCase()) ||
                vendor.vendorId.toString().includes(inputValue)
        );
        setFilteredOptions(filtered);
    };

    const handleBlur = () => {
        setFilteredOptions(entityVendors)
    };

    return (
        <section style={{ margin: `${spacingUnit * 2}px 0` }}>
            <Space wrap style={{ width: 1000 }} size="middle">
                <Typography.Title level={5}>Search Vendor(s)</Typography.Title>
                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                    <Select
                        mode="multiple"
                        allowClear
                        value={selectedValues}
                        style={{ width: '820px' }}
                        onSearch={handleFilter}
                        onBlur={handleBlur}
                        onChange={async (selectedOptionValues: string[]) => {
                            const vendorIds = selectedOptionValues.map((vendor) =>
                                ParseVendorId(vendor)
                            );
                            vendorManagementStore.SetSearchedVendors(vendorIds);
                        }}
                    >
                        {filteredOptions.map((vendor) => (
                            <Option
                                key={vendor.vendorId}
                                value={BuildOptionValue(vendor.vendorName, vendor.vendorId)}
                            >
                                {vendor.vendorName} - {vendor.vendorId}
                            </Option>
                        ))}
                    </Select>
                </div>
            </Space>
        </section>
    );
};


export default observer(VendorSelectInput);


const separator = "##";

const BuildOptionValue = (vendorName: string, vendorId: number) => {
    return `${vendorName}${separator}${vendorId}`;
};

const ParseVendorId = (vendor: string) => {
    return Number(vendor.split(separator)[1]);
};