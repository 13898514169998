
import { Campaign } from "../../../models/operation/Campaign";
import { Company } from "../../../models/operation/Company";
import { TableDto } from "../../general/supervision-table/Table.dto";

/**
 * Creates a company table dto that is displayed in the table
 */
export class CompanyTableDto extends TableDto {
    
    public companyId: number;
    public companyName: string;
    public affiliateId: number;

    constructor(company: Company) {
        super();
        this._id = company.companyId;
        this.companyId = company.companyId;
        this.companyName = company.companyName;
        this.affiliateId = company.affiliateId;
    }
}


export class CompanyTableDtoExpand extends TableDto {
    
    public companyId: number;
    public companyName: string;
    public affiliateId: number;

    constructor(company: Company) {
        super();
        this._id = company.affiliateId;
        this.companyId = company.companyId;
        this.companyName = company.companyName;
        this.affiliateId = company.affiliateId;
    }
}