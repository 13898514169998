import { RoutesPaths } from "../../../../constants/RoutePaths";
import { BreadCrumb } from "../../../../models/BreadCrumb";



export const CampaignSettingBreadCrumbs =
{

    CampaignSetting: (campaignId: number) => {
        return new BreadCrumb(
            "Campaign-Settings",
            RoutesPaths.Supervision.CampaignTrackSystemRoutes.BuildCampaignRoute(campaignId),
            null
        );
    },
    CampaignSettingAudit : (CampaignName: string) => {
        return new BreadCrumb(CampaignName, null, null);
    },
    AddCampaignSettingAudit : (campaignId: number) => {
        return new BreadCrumb(
            "Add Audit",
            RoutesPaths.Supervision.CampaignTrackSystemRoutes.BuildAddCampaignSettingAudit(campaignId),
            null
        );
    },
    EditCampaignSettingAudit: (campaignId: number, campaignSettingAuditId: string) => {
        return new BreadCrumb(
            "Edit",
            RoutesPaths.Supervision.CampaignTrackSystemRoutes.BuildEditCampaignSettingAudit(
                campaignId,
                campaignSettingAuditId
            ),
            null
        );
    }

}

export default CampaignSettingBreadCrumbs;