/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { EntityRecordDetailed } from "../../../models/EntityRecord";

interface Props {
    entityData: EntityRecordDetailed<void>[];
    HandleInputChange: (text: string[]) => void;
}

const EntityPageAutoComplete = (props: Props) => {
    return (
        <div>
            <Autocomplete
                freeSolo
                id="entity-page-auto-complete"
                multiple
                fullWidth
                clearOnEscape
                options={props.entityData.map((option) => option.entityName)}
                onChange={(_, entityNameList: string[]) => {
                    props.HandleInputChange(entityNameList);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search input"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: "search" }}
                    />
                )}
            />
        </div>
    );
};

export default EntityPageAutoComplete;
