import { CompanySettingStore } from "../../stores/CompanySettingStore";
import { ActionsBuilder } from "../general/supervision-table/types";
import { History } from "history";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { RoutesPaths } from "../../constants/RoutePaths";
import { CompanySettingsAuditDto } from "./CompanySettingsAudit.dto";
import { UIStore } from "../../stores/UIStore";
import companySettingAuditService from "../../services/company-settings/CompanySettingAuditService";
import { message, Typography } from "antd";
import { CompanySettingsStatus } from "../../models/company-track-system/CompanySettingsStatus";

const { Text } = Typography;

type CompanySettingActionsContext = {
  companySettingStore: CompanySettingStore;
  uiStore: UIStore;
  history: History<unknown>;
  companyId: number;
};

export const CompanySettingActionsBuilder: ActionsBuilder<
  CompanySettingsAuditDto,
  CompanySettingActionsContext
> = ({ history, companySettingStore, uiStore, companyId }) => [
  {
    text: "Edit Company Setting",
    action: (companySettingAudit) => {
      companySettingStore.SetSelectedCompanySettingAudit(
        companySettingStore.CompanySettingsAuditsResponse?.find(
          (x) => x.id == companySettingAudit.id
        )
      );
      history.push({
        pathname:
          RoutesPaths.Supervision.CompanyTrackSystemRoutes.BuildEditCompanySettingAudit(
            companyId,
            companySettingAudit.id
          ),
      });
    },
    Icon: FiEdit,
    isDisplayed: () => true,
  },

  {
    text: "Delete audit",
    action: (companySettingAudit) => {
      const popupBody = (
        <p>
          Sure you want to delete audit from company:{" "}
          <Text strong>{companySettingAudit.companyName}</Text> with audit ID:{" "}
          <Text strong>{companySettingAudit.id}</Text>
        </p>
      );

      uiStore.SetPopUp({
        body: popupBody,
        title: "Stem Control Request",
        okClickHandler: async () => {
          await companySettingAuditService.DeleteCompanySettingsAudit(
            companyId,
            companySettingAudit.id
          );
          let auditIndex =
            companySettingStore.CompanySettingsAuditsResponse.findIndex(
              (x) => x.id == companySettingAudit.id
            );
          companySettingStore.CompanySettingsAuditsResponse[auditIndex].status =
            CompanySettingsStatus.Removed;

          message.success(
            `Audit Company Setting ID:${companySettingAudit.id} deleted`
          );
        },
        okButtonText: "Accept",
      });
    },
    Icon: RiDeleteBin7Line,
    isDisplayed: () => true,
  },
];
