import { Job } from "../../models/operation/Job";
import { ISODateTime } from "../../types/types";
import { FormatDate } from "../../utils/DateHelpers";
import { TableDto } from "../general/supervision-table/Table.dto";

export class IndeedJobTableDto extends TableDto {
    constructor(
        public jobId: number, // alias="compPositionId"
        public jobTitle: string,
        public location: string,
        // public lastModified: ISODateTime,
        public postDate: ISODateTime,
    ) {
        super();
     }

    public static MapFromJob(job: Job) {
        return new IndeedJobTableDto(
            job.jobId,
            job.extJobTitleText,
            `${job.jobLocation.cityName}, ${job.jobLocation.stateShortName}`,
            FormatDate(job.posCreationDate)
        )
    }
}