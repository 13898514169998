import { CompanySettingTypeResponse } from "../../models/company-track-system/CompanySettingTypeResponse";
import { CompanySettingTypeValueOption } from "../../models/company-track-system/CompanySettingTypeValueOption";
import { BaseService } from "../BaseService";

export class CompanySettingTypeService extends BaseService {
  async GetCompanySettingTypes() {
    const companySettingTypeData = await this.Get<CompanySettingTypeResponse[]>(
      ""
    );
    return companySettingTypeData;
  }

  async GetSettingTypeValueOptionsPerType(companySettingTypeId: number) {
    const companySettingTypeData = await this.Get<
      CompanySettingTypeValueOption[]
    >(`/${companySettingTypeId}/valueOptions`);
    return companySettingTypeData;
  }
}

const companySettingTypeService = new CompanySettingTypeService(
  "CompanySettingTypes"
);
export default companySettingTypeService;
