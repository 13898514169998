import { Col, Row, Select, Space, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";

import { spacingUnit } from "../../../assets/styles/theme";
import useOnInitAsync from "../../../hooks/useOnInitAsync";
import { CampaignPartial } from "../../../models/operation/CampaignPartial";
import { Company } from "../../../models/operation/Company";
import campaignService from "../../../services/operation/CampaignService";
import companyService from "../../../services/operation/CompanyService";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import Loader from "../../general/loader/Loader";

const { Option } = Select;

interface Props {
    width?: string; // Define the width prop
    mode?: "multiple" | undefined;
    onChange?: (selectedOptionValues: string[]) => void;
    defaulatValueCompanies: Company[];
}

const CompanySelectInput = (props: Props) => {
    const [options, setOptions] = useState<Company[]>();
    const { width } = props;

    useOnInitAsync(async () => {
        const companies = await companyService.GetCompaniesWithActiveCampaigns();
        setOptions(companies);
    });

    if (!options || options.length == 0) {
        return <Loader position="normal" />;
    }

    const defaultValue =
        props.defaulatValueCompanies?.length > 0
            ? props.defaulatValueCompanies?.map((company) =>
                BuildOptionValue(company.companyName, company.companyId)
            )
            : [];

    return (
        <section style={{ margin: `${spacingUnit * 2}px 0`, display: 'flex', alignItems: 'center' }}>
            <div hidden={props.mode !== 'multiple'} style={{ marginRight: '16px' }}>
                <Typography.Title level={5}>Search Account(s)</Typography.Title>
            </div>
            <Select
                placeholder="Search Account"
                mode={props.mode}
                showSearch
                allowClear
                defaultValue={defaultValue}
                style={{ width: width }}
                onChange={props.onChange}
            >
                {options.map((company) => (
                    <Option
                        key={company.companyId}
                        value={BuildOptionValue(company.companyName, company.companyId)}
                    >
                        {company.companyName} - {company.companyId}
                    </Option>
                ))}
            </Select>
        </section>

    );
};

export default CompanySelectInput;

const separator = "##";

const BuildOptionValue = (companyName: string, companyId: number) => {
    return `${companyName}${separator}${companyId}`;
};
