import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import Loader from "../../../components/general/loader/Loader";
import GroupsEditForm from "../../../components/groups/GroupsEditForm";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import GroupsBreadCrumbs from "./helpers/GroupsBreadCrumbs";
import { GroupsContextInitializers } from "./helpers/GroupsContextInitializers ";

interface Props {}

const GroupsEditPage = (props: Props) => {
  const match = useParams<{ campaignId: string; groupId: string }>();
  const { groupStore } = useContext(RootStoreContext);
  const history = useHistory();

  usePageInitialization({
    onInitAsync: async () => {
      await GroupsContextInitializers.InitializeGroupData(
        Number(match.campaignId),
        groupStore,
        history
      );
      await GroupsContextInitializers.InitializeSelectedGroup(
        Number(match.groupId),
        groupStore,
        history
      );
      await GroupsContextInitializers.InitializeGroupBudgetBalance(
        Number(match.campaignId),
        groupStore,
        history,
        Number(match.groupId)
      );
      await GroupsContextInitializers.InitializeGroupCampaign(
        Number(match.campaignId),
        groupStore,
        history
      );
    },
    onCompanyContextCallBack: (company, uiStore) => {
      uiStore.SetPageTitle(
        `Edit Group: ${groupStore.SelectedGroup?.groupName}`
      );
      uiStore.AddBreadCrumbs(
        SupervisionBreadcrumbs.Companies(company.companyName),
        SupervisionBreadcrumbs.Campaigns(
          `${groupStore.SelectedCampaign?.campaignName}`
        ),
        GroupsBreadCrumbs.Groups(
          Number(groupStore.SelectedCampaign?.campaignID)
        ),
        GroupsBreadCrumbs.GroupName(groupStore.SelectedGroup?.groupName ?? ""),
        GroupsBreadCrumbs.EditGroup(
          Number(groupStore.SelectedGroup?.campaignId),
          Number(match.groupId)
        )
      );
    },
  });

  if (!groupStore.SelectedGroup) {
    return <Loader position="normal" />;
  }

  return (
    <div>
      <GroupsEditForm
        campaignId={Number(match.campaignId)}
        group={groupStore.SelectedGroup}
        groupBudgetBalance={groupStore.GroupBudgetBalance}
      />
    </div>
  );
};

export default observer(GroupsEditPage);
