
import { message } from "antd";

import { RoutesPaths } from "../../../../constants/RoutePaths";
import { PLIndeedData } from "../../../../models/indeed-manager/PLIndeedData";
import { Company } from "../../../../models/operation/Company";
import indeedService from "../../../../services/indeed/IndeedService";
import companyService from "../../../../services/operation/CompanyService";
import { IndeedStore } from "../../../../stores/IndeedStore";

export const IndeedContextInitializers = {
    InitializePLCampaignData: async (campaignId: number, indeedStore: IndeedStore, history: any) => {
        try {
            if (indeedStore.PLCampaignData && campaignId == indeedStore.PLCampaignData.pLCampaignId) {
                return;
            }

            indeedStore.SetIsDataLoading(true);

            const _indeedData =
                await indeedService.GetIndeedCampaignsByPLCampaignId(
                    campaignId
                );
            indeedStore.SetPLCampaignData(_indeedData);

            indeedStore.SetIsDataLoading(false);

            const company = await companyService.GetCompanyByCampaignId(campaignId);

            indeedStore.SetCompany(company);
        } catch (err) {
            history.push(RoutesPaths.Supervision.Campaigns);
            message.error(
                `Campaign with ID: ${campaignId} doesn't exist. Returned to home page`
            );
        }
    },

    InitializeSelectedIndeedCampaign: async (indeedCampaignId: string, plIndeedData: PLIndeedData, indeedStore: IndeedStore, history: any) => {
        //// BUG with caching code below:
        // if (indeedStore.SelectedIndeedCampaign && indeedCampaignId == indeedStore.SelectedIndeedCampaign.id) {
        //     return;
        // }

        const indeedCampaign = await indeedService.GetIndeedCampaignById(indeedCampaignId);
        if (!indeedCampaign) {
            history.push(RoutesPaths.Supervision.IndeedRoutes.BuildCampaignRoute(plIndeedData.pLCampaignId));
            message.error(
                `Indeed campaign with ID: ${indeedCampaignId} doesn't exist`
            );
        }

        indeedStore.SetSelectedIndeedCampaign(indeedCampaign);
    },


    InitializeIndeedSubAccount: async (indeedStore: IndeedStore, history: any, companyId?: number) => {

        try {
            const indeedSubAccount = await indeedService.GetIndeedSubAccountByCompanyId(companyId);
            if (!indeedSubAccount) {
              /*   message.info(
                    `Indeed sub account for ID: ${campaignId} doesn't exist`
                );*/
            }

            indeedStore.SetIndeedSubAccount(indeedSubAccount);
        }
        catch (err) {
           /* message.error(
                `Indeed sub account for ID: ${campaignId} doesn't exist.`
            ); */
        }


    }


}
