import { CgData } from "react-icons/cg";
import { FiSettings } from "react-icons/fi";
import { GiMoneyStack } from "react-icons/gi";
import { GoLinkExternal } from "react-icons/go";
import { IconType } from "react-icons/lib";
import {SiSmartthings} from "react-icons/si";


import {
    actionsRoutes,
    explainabilityRoute,
    logoutRoute,
    shortcutManualsRoute,
    supervisionAffiliatesRoute,
    supervisionCampaignsRoute,
    supervisionCompaniesRoute,
} from "../../../routes/routes";
import { useHistory } from "react-router-dom";
import { RoutesPaths } from "../../../constants/RoutePaths";
import { Company } from "../../../models/operation/Company";

import { IoMdCut } from "react-icons/io";
import { SiIndeed } from "react-icons/si";
import { MdGroupWork } from "react-icons/md";
import { FiLayers } from "react-icons/fi";
import { Affiliate } from "../../../models/operation/Affiliate";



export type sideMenuSectionOptionType = {
    optionTitle?: string;
    route: string;
    itemKey?: string;
    children?: sideMenuSectionOptionType[];
    sectionIcon?: IconType;
    action?: () => void;
}

export type sideMenuSectionType = {
    sectionTitle: string;
    sectionIcon: IconType;
    itemKey: string;
    sectionOptions: sideMenuSectionOptionType[];
}

const LinkRoute = (link: string) => {
    return `link:${link}`;
}


export const getSideMenuData = (company?: Company, affiliate?: Affiliate): sideMenuSectionType[] => {
    const sections: sideMenuSectionType[] = [
        {
            sectionTitle: "Supervision",
            sectionIcon: GiMoneyStack,
            itemKey: 'supervision',
            sectionOptions: [
                {
                    optionTitle: "Affiliates",
                    route: supervisionAffiliatesRoute.routePath,
                    itemKey: supervisionAffiliatesRoute.id
                },
                {
                    optionTitle: "Accounts",
                    route: supervisionCompaniesRoute.routePath,
                    itemKey: supervisionCompaniesRoute.id,
                    children: [],
                },
            ]
        },
        {
            sectionTitle: "Explainability",
            sectionIcon: SiSmartthings,
            itemKey: 'explainability',
            sectionOptions: [
                {
                    optionTitle: "Job Explainability View",
                    route: explainabilityRoute.routePath,
                    itemKey: explainabilityRoute.id,
                },
            ],
        },
        {
            sectionTitle: "User Actions",
            sectionIcon: CgData,
            itemKey: 'sub2',
            sectionOptions: [
                {
                    optionTitle: "Table View",
                    route: actionsRoutes.actionsTable.routePath,
                    itemKey: actionsRoutes.actionsTable.id,
                },
                // {
                //     optionTitle: "Calendar",
                //     route: "TODO",
                //     itemKey: "TODO",
                // },
            ],
        },
        {
            sectionTitle: "External Links",
            sectionIcon: GoLinkExternal,
            itemKey: 'external-links',
            sectionOptions: [
                {
                    optionTitle: "PandoIQ",
                    route: LinkRoute("https://pandoiq.com"),
                    itemKey: "pandoiq"
                },
                {
                    optionTitle: "Old Campaign Manager",
                    route: LinkRoute("http://campaignmanager.pandoiq.com/PandoIQmanager/Dashboard"),
                    itemKey: "old-campaign-manager"
                },
                {
                    optionTitle: "PPR",
                    route: LinkRoute("https://ppr.pandoiq.com"),
                    itemKey: "ppr"
                },
            ],
        },
        {
            sectionTitle: "Settings",
            sectionIcon: FiSettings,
            itemKey: 'sub3',
            sectionOptions: [
                {
                    optionTitle: "Shortcuts",
                    route: shortcutManualsRoute.routePath,
                    itemKey: shortcutManualsRoute.id,
                },
                {
                    optionTitle: "Log Out",
                    route: logoutRoute.routePath,
                    itemKey: logoutRoute.id,
                },
            ],
        },
    ];

    // Update "Affiliates" and "Accounts" sections based on company and affiliate selection
    if (company) {
        const accountsSection = sections.find(section => section.itemKey === 'supervision')
        ?.sectionOptions.find(option => option.itemKey === supervisionCompaniesRoute.id);
        if (accountsSection) {
            accountsSection.optionTitle = `Accounts (${company.companyName})`;
            accountsSection.children = [
                {
                    sectionIcon: CgData,
                    optionTitle: "Account Settings",
                    route: RoutesPaths.Supervision.CompanyTrackSystemRoutes.BuildCompanyRoute(company.companyId)
                },
                {
                    sectionIcon: IoMdCut,
                    optionTitle: "Vendor Management",
                    route: RoutesPaths.Supervision.VendorManagementRoutes.BuildCompanyVendorManagementRoute(company.companyId)
                },
                {
                    sectionIcon: FiLayers,
                    optionTitle: "Account Attributes",
                    route: RoutesPaths.Supervision.AttributesManagementRoutes.BuildCompanyAttributesRouteManagementRoute(company.companyId)
                },
                {
                    sectionIcon: SiIndeed,
                    optionTitle: "Indeed Management",
                    route: RoutesPaths.Supervision.IndeedRoutes.BuildCampaignRoute(company.campaign.campaignID)
                },
                {
                    sectionIcon: MdGroupWork,
                    optionTitle: "Groups",
                    route: RoutesPaths.Supervision.GroupsRoutes.BuildGroupRoute(company.campaign.campaignID)
                },
            ];
        }
    }
    if (affiliate) {
        const affiliatesSection = sections.find(section => section.itemKey === 'supervision')
        ?.sectionOptions.find(option => option.itemKey === supervisionAffiliatesRoute.id);
        if (affiliatesSection) {
            affiliatesSection.optionTitle = `Affiliates (${affiliate.affiliateName})`;
            affiliatesSection.children = [
                {
                    sectionIcon: IoMdCut,
                    optionTitle: "Vendor Management",
                    route: RoutesPaths.Supervision.VendorManagementRoutes.BuildAffiliateVendorManagementRoute(affiliate.affiliateId)
                },
            ];
        }
    }

    return sections;
};
