import "./IndeedJobsToCampaignTransfer.scss";

import { Transfer } from "antd";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";

import { Box } from "@material-ui/core";

import useOnInitAsync from "../../hooks/useOnInitAsync";
import { Job } from "../../models/operation/Job";
import indeedService from "../../services/indeed/IndeedService";
import jobService from "../../services/operation/JobService";
import { cachePaginatorService } from "../../services/pagination/CachePaginatorService";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { isNumber } from "../../utils/NumbersHelper";
import Loader from "../general/loader/Loader";

interface Props {
    plCampaignId: number;
    onChange: (jobIds: number[]) => void;
    indeedCampaignId?: string;
    onJobsLoad?: (jobIds: number[]) => void;
}

const IndeedJobsToCampaignTransfer = (props: Props) => {
    const { plCampaignId, onChange, indeedCampaignId, onJobsLoad } = props;
    const [hasOptionsLoaded, setHasOptionsLoaded] = useState<boolean>(false);
    const [jobOptions, setJobOptions] = useState<Job[]>([]);
    const [selectedJobIds, setSelectedJobIds] = useState<any>();

    const { indeedStore } = useContext(RootStoreContext);

    useOnInitAsync(async () => {
        //TODO: refactor to a Paginator service (this method exhausts all pages)
        const jobsCount = await jobService.GetCountAsync({
            campaignId: plCampaignId,
        });
        let tasks = [];
        let batchSize = 1000;
        for (let index = 0; index < jobsCount.jobsCount; index += batchSize) {
            tasks.push(
                jobService.GetJobsByCampaignIdPagination(plCampaignId, {
                    limit: batchSize,
                    offset: index,
                })
            );
        }
        const jobBatches = await Promise.all(tasks);
        const _jobs = jobBatches.reduce((prev, cur) => {
            return [...prev, ...cur];
        }, []);

        setJobOptions(_jobs);
        setHasOptionsLoaded(true);
    });

    useEffect(() => {
        (async () => {
            if (indeedCampaignId) {
                const indeedCampaign =
                    await indeedService.GetIndeedCampaignById(indeedCampaignId);
                if (!indeedCampaign.indeedJobs) return;

                // There is a use case that some indeed campaign jobs don't exist in the Pando campaign which causes a bug
                const jobsInIndeedCampaignAndPandoCampaign = jobOptions.filter(
                    (x) =>
                        indeedCampaign.indeedJobs?.some(
                            (indeedJob) => indeedJob.jobId == x.jobId
                        )
                );
                setSelectedJobIds(
                    jobsInIndeedCampaignAndPandoCampaign.map((job) =>
                        job.jobId.toString()
                    )
                );
                onJobsLoad &&
                    onJobsLoad(
                        jobsInIndeedCampaignAndPandoCampaign.map((job) =>
                            Number(job.jobId)
                        )
                    );
            }
        })();
    }, [indeedCampaignId, indeedStore.SelectedIndeedCampaign, jobOptions]);

    const filterOption = (inputValue: string, option: any) => {
        if (isNumber(inputValue)) {
            return searchByJobId(Number(inputValue));
        }

        if (isJobIdsInput(inputValue)) {
            const jobIds: number[] = inputValue
                .split(",")
                .map((jobId) => Number(jobId.trim()));
            return searchByJobIdsMultiple(jobIds);
        }

        if (inputValue.length >= 2) {
            return searchByJobTitleTextOrLocation();
        }
        return true;

        function searchByJobTitleTextOrLocation() {
            return (
                (getJobLocation(option).toLowerCase() ?? "").indexOf(
                    inputValue.toLowerCase()
                ) > -1 ||
                (option.extJobTitleText?.toLowerCase() ?? "").indexOf(
                    inputValue.toLowerCase()
                ) > -1
            );
        }

        function searchByJobId(inputValue: number) {
            return option.jobId == inputValue;
        }

        function isJobIdsInput(inputValue: string): boolean {
            return new RegExp(/^[\d, ]+$/gm).test(inputValue);
        }

        function searchByJobIdsMultiple(jobIds: number[]): boolean {
            return jobIds.some((x) => x == option.jobId);
        }
    };

    const handleChange = (jobIdsRightSide: string[]) => {
        setSelectedJobIds(jobIdsRightSide);
        onChange(jobIdsRightSide.map((jobId) => Number(jobId)));
    };

    const handleSearch = (
        direction: "left" | "right",
        searchValue: string
    ) => {};

    const getJobLocation = (job: Job) => {
        return `${job.jobLocation.cityName}, ${job.jobLocation.stateShortName}`;
    };

    return (
        <Box width={800}>
            <Box>
                {!hasOptionsLoaded ? (
                    <Loader position="normal" />
                ) : (
                    <Transfer
                        dataSource={jobOptions.map((job) => ({
                            key: job.jobId.toString(),
                            ...job,
                        }))}
                        showSearch
                        filterOption={filterOption}
                        targetKeys={selectedJobIds}
                        onChange={handleChange}
                        onSearch={handleSearch}
                        pagination
                        className="jobs-transfer"
                        render={(job) => (
                            <div>
                                <div
                                    title={`(${job.jobId}) ${
                                        job.extJobTitleText
                                    } | Location: ${getJobLocation(job)}`}>
                                    ({job.jobId}) {job.extJobTitleText} |
                                    Location: {getJobLocation(job)}
                                </div>
                            </div>
                        )}
                    />
                )}
            </Box>
        </Box>
    );
};

export default observer(IndeedJobsToCampaignTransfer);
