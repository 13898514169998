import React, { useEffect } from "react";

const useOnPoller = (
    pollIntervalInSeconds: number,
    pollFunction: () => void
) => {
    useEffect(() => {
        const interval = setInterval(
            pollFunction,
            pollIntervalInSeconds * 1000
        );

        return function cleanUp() {
            clearInterval(interval);
        };
    }, []);
};

export default useOnPoller;
