
import { action, makeObservable, observable, toJS } from "mobx";

import { Notification } from "../models/notifications/Notification";
import NotificationService from "../services/notifications/NotificationService";

export class NotificationStore {

    @observable
    public Notifications?: Notification[];

    constructor() {
        makeObservable(this);
    }


    @action
    public async SetNotificationStateForUser() {
        const notifications = await NotificationService.GetAll();
        this.Notifications = notifications;
    }
}