import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { StyledContainer, StyledInputLabel, StyledTextAreaWrapper, StyledTitle } from "./Styles";


const Comments = () => {

    const { attributesManagementStore } = useContext(RootStoreContext);
    const { CompanyPreference } = attributesManagementStore;

    useEffect(() => {
    }, [attributesManagementStore.CompanyPreference]);

    const setGenralText = (value: string) => {
        CompanyPreference.comment = value;
    };

    const setStrategyText = (value: string) => {
        CompanyPreference.strategy = value;
    };

    return (
        <StyledContainer>
            <StyledTitle><span>Comments</span></StyledTitle>
            <StyledInputLabel>Strategy</StyledInputLabel>
            <StyledTextAreaWrapper
                onChange={(e) => setStrategyText(e.target.value)}
                placeholder="Strategy"
                autoSize={{ minRows: 3, maxRows: 3 }}
                value={CompanyPreference.strategy}
            />
            <StyledInputLabel>General notes</StyledInputLabel>
            <StyledTextAreaWrapper
                onChange={(e) => setGenralText(e.target.value)}
                placeholder="General text"
                autoSize={{ minRows: 3, maxRows: 3 }}
                value={CompanyPreference.comment}
            />
        </StyledContainer>
    )

}

export default observer(Comments);