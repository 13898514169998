/* eslint-disable @typescript-eslint/no-useless-constructor */
import { ApiErrorSchema, IsApiErrorSchema } from "../models/ApiErrorSchema";

/**
 * Base exception for project. All exceptions should inherit from that exception
 */
export abstract class AlgoSupervisionException extends Error {
    constructor(message?: string | undefined) {
        super(message);
    }
}

export class PageNotFoundexception extends AlgoSupervisionException {
}

export class FetchError extends AlgoSupervisionException {
    apiError: ApiErrorSchema;

    constructor(message?: string | undefined | ApiErrorSchema) {
        
        if (IsApiErrorSchema(message)) {
            super(message.errorMessage);
            this.apiError = message;
            return;
        }

        super(message);
        this.apiError = {
            errorMessage: message ?? "Error occured",
            errorCode: 500,
            retryable: false
        }
    }
}

export class UnauthorizedError extends AlgoSupervisionException {

}