
import { Campaign } from "../../models/operation/Campaign";
import { CampaignPartial } from "../../models/operation/CampaignPartial";
import { BaseService } from "../BaseService";

export class CampaignService extends BaseService {

    async GetById(campaignId: number): Promise<Campaign> {
        const campaign = await this.Get<Campaign>(`/${campaignId}`);
        return campaign;
    }

    /**
     * Retrieves all campaigns with partial schema
     */
    async GetAllPartial(): Promise<CampaignPartial[]> {
        const campaignPartial = await this.Get<CampaignPartial[]>("/partial");
        return campaignPartial;
    }

    async GetManyByIdsAsync(
        GetManyByIdsRequest: { ids: number[] }
    ): Promise<Campaign[]> {
        const campaigns = await this.Post<Campaign[]>("/get-multiple-by-ids", GetManyByIdsRequest);
        return campaigns;
    }

    async GetByPagination(
        paginationOptions: {
            skip: number,
            take: number
        }
    ): Promise<Campaign[]> {
        throw new Error("Not Implemented");
    }

}


const campaignService = new CampaignService("Campaigns");
export default campaignService;