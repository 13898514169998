import { action, makeObservable, observable } from "mobx";
import { LocalStorageKeys } from "../constants/LocalStorageKeys";
import { ClassificationType } from "../models/vendor-management/ClassificationType";
import { EntityVendorRequest } from "../models/vendor-management/EntityVendorRequest";
import { EntityVendorResponse } from "../models/vendor-management/EntityVendorResponse";
import LocalStorageService from "../services/LocalStorageService";
import { EntityDistributionSettings } from "../models/vendor-management/settings/EntityDistributionSettings";
import { EntityDistributionSettingsRequest } from "../models/vendor-management/settings/EntityDistributionSettingsRequest";

export class VendorManagementStore {

    @observable
    public Vendors: EntityVendorResponse[] = [];

    @observable
    public SearchedVendors: EntityVendorResponse[] = [];

    @observable
    public rowsEffected: EntityVendorResponse[] = [];

    @observable
    public SelectedClassificationType: ClassificationType = ClassificationType.Excluded;

    @observable
    public EntitySettings! : EntityDistributionSettings;

    public SelectedVendorsToApply: EntityVendorRequest[] = [];

    /**
     * ctor
     */
    constructor() {
        makeObservable(this);
    }


    @action
    public async SetVendors(vendors: EntityVendorResponse[]) {
        this.Vendors = vendors;
    }

    @action
    public async SetEntityState(entitySettings: EntityDistributionSettings) {
        this.EntitySettings = entitySettings;
        this.SetClassificationType(this.EntitySettings.classificationType);
    }

    @action
    public async SetVendorsToApply(selectedVendorsToApply: any[]) {
        this.SelectedVendorsToApply = selectedVendorsToApply.map((v) => {

            return <EntityVendorRequest>{
                vendorId: v.vendorId,
                vendorName: v.vendorName,
                inclusionEnforcement: v.inclusionEnforcement,
                classificationType: v.classificationType,
                comment: v.comment
            }
        });
    }

    @action
    public async SetSearchedVendors(vendorsIds: number[]) {
        this.SearchedVendors = this.Vendors.filter(vendor => vendorsIds.includes(vendor.vendorId))
        // LocalStorageService.SetItemObject(LocalStorageKeys.SearchedVendors, this.SearchedVendors);
    }


    @action
    public async SetClassificationType(classificationType: ClassificationType) {
        this.SelectedClassificationType = classificationType;
        this.EntitySettings.classificationType = classificationType;
    }

    @action
    public async SetEntityStateIsInheritable(isInheritable: boolean) {
        this.EntitySettings.isInheritable = isInheritable;
    }


    public AddToEffectedRows(record: EntityVendorResponse) {
        try {
            const index = this.rowsEffected.findIndex(x => x.vendorId == record.vendorId);
            if (index > -1)
            {
                this.rowsEffected[index] = record;
            }
            else
            {
                this.rowsEffected.push(record);
            }
            
        } catch (error) {
            // console.log(error)
        }
   

    }

}