import { message } from "antd";
import { RoutesPaths } from "../../../../constants/RoutePaths";
import { PaginationParams } from "../../../../models/pagination/PaginationParams";
import companySettingAuditService from "../../../../services/company-settings/CompanySettingAuditService";
import companySettingTypeService from "../../../../services/company-settings/CompanySettingTypeService";
import { CompanySettingStore } from "../../../../stores/CompanySettingStore";

export const CompanySettingContextInitializers = {
  InitializeCompanyAuditData: async (
    companyId: number,
    companySettingStore: CompanySettingStore,
    history: any
  ) => {
    try {
      const _auditData =
        await companySettingAuditService.GetFilteredCompanySettingsAudits({
          companyId,
        });
      companySettingStore.SetCompanySettingsAudits(_auditData);
    } catch (err) {
      history.push(RoutesPaths.Supervision.Companies);
      message.error(
        `Company with ID: ${companyId} doesn't exist. Returned to home page`
      );
    }
  },

  InitializeCompanySettingTypes: async (
    companySettingStore: CompanySettingStore,
    history: any
  ) => {
    try {
      const _settingTypesData =
        await companySettingTypeService.GetCompanySettingTypes();

      await companySettingStore.SetCompanySettingTypes(_settingTypesData);
    } catch (err) {
      history.push(RoutesPaths.Supervision.Companies);
      message.error(
        `Failed to find Company setting types Returned to home page`
      );
    }
  },

  InitializeSelectedCompanySettingAudit: async (
    auditId: string,
    companySettingStore: CompanySettingStore,
    history: any
  ) => {
    try {
      companySettingStore.SetSelectedCompanySettingAudit(
        companySettingStore.CompanySettingsAuditsResponse?.find(
          (x) => x.id == auditId
        )
      );
    } catch (err) {
      history.push(RoutesPaths.Supervision.Companies);
      message.error(
        `Failed to find Company setting types Returned to home page`
      );
    }
  },
};
