import { RoutesPaths } from "../../../../constants/RoutePaths";
import { BreadCrumb } from "../../../../models/BreadCrumb";

export const IndeedBreadcrumbs = {
    Indeed: (campaignId: number) => {
        return new BreadCrumb(
            "Indeed",
            RoutesPaths.Supervision.IndeedRoutes.BuildCampaignRoute(campaignId),
            null
        );
    },
    IndeedCampaign: (indeedCampaignName: string) => {
        return new BreadCrumb(indeedCampaignName, null, null);
    },
    AddCampaign: (campaignId: number) => {
        return new BreadCrumb(
            "Add Campaign",
            RoutesPaths.Supervision.IndeedRoutes.BuildAddCampaign(campaignId),
            null
        );
    },
    CampaignJobs: (campaignId: number, indeedCampaignId: string) => {
        return new BreadCrumb(
            "Jobs",
            RoutesPaths.Supervision.IndeedRoutes.BuildCampaignJobsRoute(
                campaignId,
                indeedCampaignId
            ),
            null
        );
    },
    AttachJobsToIndeedCampaign: (
        campaignId: number,
        indeedCampaignId: string
    ) => {
        return new BreadCrumb(
            "Attach Jobs",
            RoutesPaths.Supervision.IndeedRoutes.BuildAttachJobsToIndeedCampaign(
                campaignId,
                indeedCampaignId
            ),
            null
        );
    },
    EditCampaign: (campaignId: number, indeedCampaignId: string) => {
        return new BreadCrumb(
            "Edit",
            RoutesPaths.Supervision.IndeedRoutes.BuildEditIndeedCampaign(
                campaignId,
                indeedCampaignId
            ),
            null
        );
    },
};
