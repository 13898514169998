import { CampaignSettingsAuditResponse } from "../../models/campaign-track-system/CampaignSettingsAuditResponse";
import { CampaignSettingsStatus } from "../../models/campaign-track-system/CampaignSettingsStatus";
import { ISODateTime } from "../../types/types";
import { FormatDate } from "../../utils/DateHelpers";
import { TableDto } from "../general/supervision-table/Table.dto";



export class CampaignSettingsAuditDto extends TableDto {
    constructor(
        public id: string,
        public campaignId: number,
        public companyId: number,
        public date : ISODateTime,
        public status: string,
        public campaignName: string,
        public type : string,
        public value: string,
        public caseNumber: string,
        public userName: string,
        public comment: string

    ) {
        super();
     }

    public static MapFromCampaignSettingsAudit(campaignSettingsAudit: CampaignSettingsAuditResponse) {
        return new CampaignSettingsAuditDto(
            campaignSettingsAudit.id,
            campaignSettingsAudit.campaignId,
            campaignSettingsAudit.companyId,
            FormatDate(campaignSettingsAudit.creationDate),
            campaignSettingsAudit.status ? CampaignSettingsStatus[campaignSettingsAudit.status] : "N.A",
            campaignSettingsAudit.campaignName,
            campaignSettingsAudit.type,
            campaignSettingsAudit.value,
            campaignSettingsAudit.caseNumber,
            campaignSettingsAudit.userName,
            campaignSettingsAudit.comment

        )
    }
}