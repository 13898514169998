import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite"
import ExplainabilityTable from "../../../components/explainability/ExplainabilityTable";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { Button, Col, Row, Select, Space, Typography } from "antd";
import { spacingUnit } from "../../../assets/styles/theme";
import CompanySelectInput from "../../../components/supervision/companies/CompanySelectInput";
import JobSelectInput from "../../../components/explainability/JobSelectInput";
import { useContext, useState } from "react";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import VendorSelect from "../../../components/explainability/VendorSelect";
import explainabilityService from "../../../services/explainability/ExplainabilityService";
import { JobSerachingType } from "../../../models/explainability/JobSerachingType";
import SearchJobState from "../../../components/explainability/SearchJobState";


const { Option } = Select;

const ExplainabilityMainPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const { explainabilityStore } = useContext(RootStoreContext);
    const { SelectedCompany } = explainabilityStore
    const companyId = SelectedCompany ? SelectedCompany.companyId : undefined;

    usePageInitialization({
        onInitAsync: async () => {
            explainabilityStore.AllVendors = await explainabilityService.getAllVendors();
        }
    })

    const onChange = (selectedCompany: any) => {
        if (selectedCompany != undefined) {
            let companyId = ParseCompanyId(selectedCompany)
            explainabilityStore.SetSearchedCompany(companyId);
        }
    }

    const ParseCompanyId = (company: string) => {
        const separator = "##";
        return Number(company.split(separator)[1]);
    };

    const handleSelectChange = (value: JobSerachingType) => {
        explainabilityStore.JobsState = []
        explainabilityStore.SelectedJobSerachingType = value
    };


    return (
        <div>
            <Box display="flex" alignItems="center">
                <CompanySelectInput defaulatValueCompanies={SelectedCompany ? [SelectedCompany] : []}
                    onChange={onChange} width="500px" mode={undefined} />
                <Select placeholder="Searching Type" style={{ width: 200 }} onChange={handleSelectChange}>
                    <Option value={JobSerachingType.JobState} >Job State</Option>
                    <Option value={JobSerachingType.JobVendorState}  >Job Vendor State</Option>
                </Select>
                <JobSelectInput companyId={companyId} />
                <VendorSelect
                    vendors={explainabilityStore.AllVendors}
                />
                <SearchJobState setLoding={setIsLoading} isLoading={isLoading}/>
            </Box>
            <ExplainabilityTable isLoading={isLoading}/>
        </div>
    );
}


export default observer(ExplainabilityMainPage)