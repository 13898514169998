// Theme
import "antd/dist/antd.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import { CssBaseline, ThemeProvider } from "@material-ui/core";

import App from "./App";
import ErrorHandlerComponent from "./components/general/ErrorHandlerComponent";
import { MuiLightTheme } from "./config/MuiTheme";
import reportWebVitals from "./reportWebVitals";
import { RootStoreProvider } from "./stores/RootStoreContext";

ReactDOM.render(
    <React.StrictMode>
        <RootStoreProvider>
            <ThemeProvider theme={MuiLightTheme}>
                <CssBaseline />
                <ErrorHandlerComponent>
                    <Router>
                        <App />
                    </Router>
                </ErrorHandlerComponent>
            </ThemeProvider>
        </RootStoreProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
