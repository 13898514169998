import React from "react";
import { CSSProperties } from "styled-components";

import miniLogo from "../../assets/images/pandologic-logo-mini.png";
import logoDefault from "../../assets/images/pandologic-logo.png";
import logoWhite from "../../assets/images/plogic_Logo_W.png";

type LogoType = "default" | "white" | "mini";

interface Props {
    logoType: LogoType;
    style?: CSSProperties;
}

const PandoLogicLogo = (props: Props) => {
    const { logoType, style } = props;
    return (
        <img
            style={style}
            className="pandologic-logo"
            // style={{ width: "100%" }}
            src={getLogoSrc(logoType)}
            alt="PandoLogic logo"
        />
    );
};

const getLogoSrc = (logoType: LogoType) => {
    switch (logoType) {
        case "default":
            return logoDefault;
        case "mini":
            return miniLogo;
        case "white":
            return logoWhite;
    }
};

export default PandoLogicLogo;
