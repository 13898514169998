import { CampaignSettingStore } from "../../stores/CampaignSettingStore";
import { ActionsBuilder } from "../general/supervision-table/types";
import { History } from "history";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { RoutesPaths } from "../../constants/RoutePaths";
import { CampaignSettingsAuditDto } from "./CampaignSettingsAudit.dto";
import { UIStore } from "../../stores/UIStore";
import campaignSettingAuditService from "../../services/campaign-setting/CampaignSettingAuditService";
import { message, Typography } from "antd";
import { CampaignSettingsStatus } from "../../models/campaign-track-system/CampaignSettingsStatus";

const { Text } = Typography;

type CampaignSettingActionsContext = {
    campaignSettingStore: CampaignSettingStore;
    uiStore: UIStore;
    history: History<unknown>;
    campaignId: number;
};

export const CampaignSettingActionsBuilder: ActionsBuilder<CampaignSettingsAuditDto, CampaignSettingActionsContext> = ({
    history, campaignSettingStore, uiStore, campaignId
}) => [

        {
            text: "Edit Campaign Setting",
            action: (campaignSettingAudit) => {
                campaignSettingStore.SetSelectedCampaignSettingAudit(
                    campaignSettingStore.CampaignSettingsAuditsResponse?.find(
                        (x) => x.id == campaignSettingAudit.id
                    )
                );
                history.push({
                    pathname:
                        RoutesPaths.Supervision.CampaignTrackSystemRoutes.BuildEditCampaignSettingAudit(campaignId, campaignSettingAudit.id)
                });
            },
            Icon: FiEdit,
            isDisplayed: () => true,

        },

        {
            text: "Delete audit",
            action: (campaignSettingAudit) => {

                const popupBody = (
                    <p>
                        Sure you want to delete audit from campaign: {" "}
                        <Text strong>{campaignSettingAudit.campaignName}</Text> with audit ID:{" "}
                        <Text strong>{campaignSettingAudit.id}</Text>
                    </p>
                );

                uiStore.SetPopUp(
                    {
                        body: popupBody,
                        title: "Stem Control Request",
                        okClickHandler: async () => {
                            await campaignSettingAuditService.DeleteCampaignSettingsAudit(campaignId ,campaignSettingAudit.id);
                            let auditIndex = campaignSettingStore
                                    .CampaignSettingsAuditsResponse
                                    .findIndex(x => x.id == campaignSettingAudit.id);
                                    campaignSettingStore.CampaignSettingsAuditsResponse[auditIndex].status = CampaignSettingsStatus.Removed

                            message.success(
                                `Audit Campaign Setting ID:${campaignSettingAudit.id} deleted`
                            );
                        },
                        okButtonText: "Accept",
                    }
                );
            },
            Icon: RiDeleteBin7Line,
            isDisplayed: () => true,

        }

    ]

