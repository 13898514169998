// styles.js
import styled from 'styled-components';
import { Checkbox, Input, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

export const StyledInputNumberWrapper = styled(InputNumber)`
    background: white;
    border-radius: 4px;
    border: 1px #809BAF solid;
    justify-content: flex-start;
    align-items: center;
    display: flex;
`;

export const StyledTextAreaWrapper = styled(Input.TextArea)`
    border-radius: 4px;
    border: 1px #809BAF solid;
`;

export const StyledTitle = styled.div`
  align-self: stretch;
  height: 19px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #225A78;
  font-size: 16px;
  font-family: 'Open Sans';
  font-weight: 700;
  line-height: 19.20px;
  word-wrap: break-word;
`;

export const StyledHeader = styled.div`
 color: '#225A78';
 font-size: 18px;
 font-family: 'Open Sans';
 font-weight: '400';
 word-wrap: 'break-word' ;
`;

export const StyledInputGroupWrapper = styled.div`
  flex-direction: row; 
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  display: flex;
`;

export const StyledColumnGroupWrapper = styled.div`
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 12px;
display: flex;
`;

export const StyledColumnGroupToTopWrapper = styled.div`
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 12px;
display: flex;
`;

export const StyledInputLabel = styled.div`
  color: #485465;
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 600;
  line-height: 18.20px;
  word-wrap: break-word;
  
`;

export const StyledToggleWrapper = styled.div`
    width: 198px;
    background: white;
    border-radius: 4px;
    border: 1px #809BAF solid;
    justify-content: flex-start;
    align-items: center;
    display: flex;
`;

export const StyledContainer = styled.div`
width: 100%;
height: 100%;
padding: 26px 24px 30px 24px;
background: white;
box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.10);
border-radius: 4px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 24px;
`;

export const StyledCheckbox = styled(Checkbox)`
  color: #225A78;
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 500;
  line-height: 14.40px;
  word-wrap: break-word;
`;

export const StyledSubSection = styled.div`
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    display: flex;
`;

export const StyledGroupWrapper = styled.div`
  flex-direction: row; 
  justify-content: center;
  gap: 24px;
  display: flex;
`;

export const StyledModeWrapper = styled.div`
   justify-content: flex-start;
   align-items: center;
   gap: 8px;
   display: inline-flex;
`;




