import { CompanySettingsAuditResponse } from "../../models/company-track-system/CompanySettingsAuditResponse";
import { CompanySettingsStatus } from "../../models/company-track-system/CompanySettingsStatus";
import { ISODateTime } from "../../types/types";
import { FormatDate } from "../../utils/DateHelpers";
import { TableDto } from "../general/supervision-table/Table.dto";



export class CompanySettingsAuditDto extends TableDto {
    constructor(
        public id: string,
        public companyId: number,
        public date : ISODateTime,
        public status: string,
        public companyName: string,
        public type : string,
        public value: string,
        public caseNumber: string,
        public userName: string,
        public comment: string

    ) {
        super();
     }

    public static MapFromCompanySettingsAudit(companySettingsAudit: CompanySettingsAuditResponse) {
        return new CompanySettingsAuditDto(
            companySettingsAudit.id,
            companySettingsAudit.companyId,
            FormatDate(companySettingsAudit.creationDate),
            companySettingsAudit.status ? CompanySettingsStatus[companySettingsAudit.status] : "N.A",
            companySettingsAudit.companyName,
            companySettingsAudit.type,
            companySettingsAudit.value,
            companySettingsAudit.caseNumber,
            companySettingsAudit.userName,
            companySettingsAudit.comment

        )
    }
}