import { action, makeObservable, observable, toJS } from "mobx";

import {
  vendorMockType,
  vendorsData
} from "../components/publisher-selection/mock-data/vendors-list";
import { EntityRecordDetailed } from "../models/EntityRecord";
import { PublisherSelectionDialog } from "../models/publisher-selection/PublisherSelectionDialog";
import {
  EntityTypeOption,
  PublisherSelectionActionType
} from "../types/publisher-selection/PublisherSelectionTypes";
import { Nullable } from "../types/types";

export class PublisherSelectionStore {

    /**
     * Fields
     */


    /**
     * Ctor
     */
    constructor() {
        makeObservable(this);
    }

    /**
     * Props
     */
    @observable
    public MockActiveEntityType: EntityTypeOption = "campaigns";

    @observable
    public MockEntityDrillDownList: string[] = []; //TODO: Refactor to class type instead of string

    @observable
    public DialogBox: PublisherSelectionDialog = new PublisherSelectionDialog();

    @observable
    public VendorsForEntity: Nullable<vendorMockType[]>;


    // Refactor the below 3 properties to a class
    @observable
    public SelectedPublisherActionType: Nullable<PublisherSelectionActionType>;

    @observable
    public SelectedEntity: Nullable<EntityRecordDetailed>;

    @observable
    public SelectedVendor: Nullable<vendorMockType>;



    /**
     * Methods
     */
    @action
    public SetMockActiveEntityType(entityType: EntityTypeOption): void {
        this.MockActiveEntityType = entityType;
    }

    @action
    public AddEntityToDrillDownList(entity: string): void {
        this.MockEntityDrillDownList.push(entity);
    }

    @action
    public SetVendorsForEntity(): vendorMockType[] {
        if (!this.VendorsForEntity) {
            this.VendorsForEntity = vendorsData.vendors;
        }

        return this.VendorsForEntity;
    }

    @action
    public HandleVendorAction(actionType: PublisherSelectionActionType, vendor: vendorMockType): void {
        if (!this.VendorsForEntity) throw new Error("VendorsForEntity is empty")

        const vendorIndex = this.VendorsForEntity.findIndex(x => x.vendorId == vendor.vendorId);
        if (actionType == "Exclude") {
            this.VendorsForEntity[vendorIndex].isCutOff = true;
            this.VendorsForEntity[vendorIndex].operationTypeId =1;
        }
        if (actionType == "Include") {
            this.VendorsForEntity[vendorIndex].isCutOff = false;
            this.VendorsForEntity[vendorIndex].operationTypeId = 2;
        }

        if (actionType == "Exclusive") {
            this.VendorsForEntity = this.VendorsForEntity.map((vendor, index) => {
                if (index == vendorIndex || vendor.operationTypeId == 3) {
                    return {
                        ...vendor,
                        isCutOff: false,
                        operationTypeId: 3
                    }
                }

                return {
                    ...vendor,
                    isCutOff: true,
                    operationTypeId: undefined
                }
            })
        }
    }


    // Refactor the below to a class
    @action
    public SetSelectedPublisherActionType(actionType: PublisherSelectionActionType): void {
        this.SelectedPublisherActionType = actionType;
    }

    @action
    public SetSelectedVendor(vendor: vendorMockType): void {
        this.SelectedVendor = vendor;
    }

    @action
    public SetSelectedEntity(entity: EntityRecordDetailed): void {
        this.SelectedEntity = entity;
    }

    @action
    public RemoveSelectedEntity(): void {
        this.SelectedEntity = undefined;
    }


}