import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import Loader from "../../../components/general/loader/Loader";
import GroupsJobsTable from "../../../components/groups/GroupsJobsTable";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import GroupsBreadCrumbs from "./helpers/GroupsBreadCrumbs";
import { GroupsContextInitializers } from "./helpers/GroupsContextInitializers ";

interface Props { }

const IndeedJobsViewContainer = () => {
    const match = useParams<{ campaignId: string; groupId: string }>();
    const history = useHistory();
    const { groupStore } = useContext(RootStoreContext);

    usePageInitialization({
        onInitAsync: async () => {
            await GroupsContextInitializers.InitializeGroupJobsData(
                Number(match.groupId),
                groupStore,
                history,
            );
            await GroupsContextInitializers.InitializeSelectedGroup(
                Number(match.groupId),
                groupStore,
                history
            );
            await GroupsContextInitializers.InitializeGroupCampaign(
                Number(match.campaignId),
                groupStore,
                history
            );
        },
        onCompanyContextCallBack: (company, uiStore) => {
            uiStore.SetPageTitle(
              `Jobs for Group: ${groupStore.SelectedGroup?.groupName}`
            );
            uiStore.AddBreadCrumbs(
              SupervisionBreadcrumbs.Companies(company.companyName),
              SupervisionBreadcrumbs.Campaigns(
                `${groupStore.SelectedCampaign?.campaignName}`
              ),
              GroupsBreadCrumbs.Groups(
                Number(groupStore.SelectedCampaign?.campaignID)
              ),
              GroupsBreadCrumbs.GroupName(
                groupStore.SelectedGroup?.groupName?? ""
            ),
              GroupsBreadCrumbs.GroupJobs(
                Number(groupStore.SelectedGroup?.campaignId),
                Number(match.groupId)
              )
            );
          },
    });

    if (!groupStore.GroupJobsResponse) {
        return <Loader position="normal" />;
    }

    return (
        <div>
            <GroupsJobsTable
                groupId={Number(match.groupId)}
                groupName={""}
                groupJobs={groupStore.GroupJobsResponse}
            />
        </div>
    );
};

export default observer(IndeedJobsViewContainer);