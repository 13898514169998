import { Empty} from "antd";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { CampaignSettingsAuditResponse } from "../../models/campaign-track-system/CampaignSettingsAuditResponse";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { CampaignSettingActionsBuilder } from "./CampaignSettingAuditTable.actions";
import { CampaignSettingsAuditDto } from "./CampaignSettingsAudit.dto";

import { observer } from "mobx-react-lite";
import SupervisionTable from "../general/supervision-table/SupervisionTable";

interface Props {
    campaignId: number;
    campaignAudits: CampaignSettingsAuditResponse[];
}



const CampaignSettingAuditTable = (props: Props) => {

    const {campaignAudits , campaignId } = props;
    const { campaignSettingStore, uiStore } = useContext(RootStoreContext);
    const history = useHistory();
 

    if (campaignAudits.length == 0) {
        return (
            <Empty
                style={{ marginTop: "40px" }}
                description={`No Campaign Setting Audits`}
            />
        );
    }

    return (
        <div>


            <SupervisionTable
                dataSource={campaignAudits.map(CampaignSettingsAuditDto
                    .MapFromCampaignSettingsAudit)}
                rowActionSettings={{
                    actions: CampaignSettingActionsBuilder({
                        history: history,
                        campaignSettingStore: campaignSettingStore,
                        campaignId: campaignId,
                        uiStore: uiStore,
                    }),
                    showActionsOnLastColumn: true,
                    showActionsOnRightClick: true,
                }}
            />
        </div>

    )
}



export default observer(CampaignSettingAuditTable);