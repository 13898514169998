import { RoutesPaths } from "../../../../constants/RoutePaths";
import { BreadCrumb } from "../../../../models/BreadCrumb";

export const SupervisionBreadcrumbs = {

    Affiliates: (affiliateName: string) => {
        return new BreadCrumb(affiliateName, null, null);
    },
    Campaigns: (campaignName: string) => {
      return new BreadCrumb(campaignName, null, null);
    },
    Companies: (companyName: string) => {
      return new BreadCrumb(companyName, null, null);
    },
};
