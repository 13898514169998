import settings from "../../config/AppSettings/SettingsFactory";
import { JobVendorState } from "../../models/explainability/JobVendorState";
import { BaseService } from "../BaseService";

export class JobVendorStateService extends BaseService {


  async getJobVendorStateByCompanyId(account_id: number): Promise<JobVendorState[]> {
    const jobsStateVendorByAccountId = await this.Get<JobVendorState[]>(`/JobVendorStateByAccount/${account_id}`);
    return jobsStateVendorByAccountId;
  }

  async getJobVendorStateByJobsIds(account_id: number, jobs_lst: number[], vendors_id_lst: number[]): Promise<JobVendorState[]> {
    const data = {
      jobs_lst: jobs_lst,
      vendors_id_lst: vendors_id_lst,
    };
    const jobsStateVendorByJobIds = await this.Post<JobVendorState[]>(`/JobVendorStateByJobIds/${account_id}`, data);
    return jobsStateVendorByJobIds;
  }


}

const jobVendorStateService = new JobVendorStateService("JobVendor", settings.explainabilityServiceUrl);
export default jobVendorStateService;