import moment from "moment";

import { EnumDefinition } from "../../../types/types";
import { dateFormat, dateTimeFormat } from "../../../utils/DateHelpers";
import BuildActionsColumn from "./ActionsColumn";
import { SearchColumn } from "./SearchColumn";
import {
  CategoricalColumns,
  RowActionSettings,
  TableColumn,
  TableColumnDataType
} from "./types";

export const BuildColumns = <T extends object>(
    dataSource: T[],
    rowActionSettings?: RowActionSettings<T>,
    categoricalColumns?: CategoricalColumns<T>[]
) => {
    let tableColumns = BuildColumnsFromSchema(
        dataSource[0],
        categoricalColumns
    );

    // Remove _id column
    tableColumns = tableColumns.filter((column) => column.title != "_id");

    if (rowActionSettings && rowActionSettings.showActionsOnLastColumn) {
        tableColumns = [
            ...tableColumns,
            BuildActionsColumn({ rowActionSettings }),
        ];
    }

    return tableColumns;
};

const BuildColumnsFromSchema = <T extends Object>(
    record: T,
    categoricalColumns?: CategoricalColumns<T>[]
): TableColumn[] => {
    return Reflect.ownKeys(record).map((key) => {
        let column: TableColumn = {
            dataType: ParseDataType(record, key, categoricalColumns),
            dataIndex: key.toString(),
            title: [...key.toString()].reduce(
                (prev: string, cur: string, index: number) => {
                    if (index == 0) {
                        return key.toString()[0].toUpperCase();
                    } else if (cur.match(/[A-Z]/)) {
                        return (prev += ` ${cur}`);
                    }
                    return prev + cur;
                },
                ""
            ),
        };

        return column;
    });
};

function ParseDataType<T>(
    record: T,
    key: string | symbol,
    categoricalColumns?: CategoricalColumns<T>[]
): TableColumnDataType {
    if (categoricalColumns && categoricalColumns.some((c) => c.column == key)) {
        return "categorical";
    }

    const value = (record as any)[key as any];
    if (typeof value == "number" || IsANumberWithPrefixOrPostfix(value)) {
        return "number";
    }

    if (IsADateTime(value)) {
        return "datetime";
    }
    return "string";
}

function IsANumberWithPrefixOrPostfix(value: string): boolean {
    if (!value) return false;
    const match = (value.match(/.\d+./gi) ?? [""])[0];
    return value.length == match.length;
}

function IsADateTime(value: string): boolean {
    return (
        moment(value, dateFormat).isValid() ||
        moment(value, dateTimeFormat).isValid()
    );
}

//https://ant.design/components/table/#Column
type AntDesignColumnType = any;

export const PopulateColumnActions = <T extends object>(
    column: TableColumn,
    categoricalColumns?: CategoricalColumns<T>[]
): AntDesignColumnType => {
    if (column.title == "Actions") {
        return column;
    }

    const searchColumn = new SearchColumn(column.dataIndex);

    // only numbers are sortable or strings that are numbers with prefix or postfix
    if (column.dataType == "number") {
        const extractNumber = (value: string | number): number =>
            Number((value.toString()?.match(/\d+/gi) ?? [0])[0]);

        // we need to add "sorter" property to add sorting to our table
        return {
            ...column,
            ...searchColumn.CreateColumn(),
            sorter: (a: any, b: any) =>
                extractNumber(a[column.dataIndex]) -
                extractNumber(b[column.dataIndex]),
        };
    }

    if (column.dataType == "categorical") {
        // we need to add "filters" and "onFilter" property to add categorical filtering
        const categoricalColumnOptions = categoricalColumns?.find(
            (x) => x.column == column.dataIndex
        );

        if (!categoricalColumnOptions) {
            return column;
        }

        let filters: string[] = [];
        if (categoricalColumnOptions.possibleValues.hasOwnProperty("length")) {
            // then possibleValue = string list
            filters = categoricalColumnOptions.possibleValues as string[];
        } else {
            for (let key in categoricalColumnOptions.possibleValues as EnumDefinition) {
                if (!key.match(/^\d+$/)) {
                    filters.push(key);
                }
            }
        }

        return {
            ...column,
            filters: filters.map((value) => ({
                text: value,
                value: value,
            })),
            onFilter: (filteredValue: any, record: T) => {
                const recordPropertyValue = (record as any)[column.dataIndex];
                return filteredValue == recordPropertyValue;
            },
            defaultFilteredValue: categoricalColumnOptions.defaultFilteredValue,
        };
    }

    // sort date timer
    if (column.dataType == "datetime") {
        const extractDateValueSinceUnixEpoch = (value: string): number =>
            moment(value, dateTimeFormat).unix();

        // we need to add "sorter" property to add sorting to our table
        return {
            ...column,
            ...searchColumn.CreateColumn(),
            sorter: (a: any, b: any) =>
                extractDateValueSinceUnixEpoch(a[column.dataIndex]) -
                extractDateValueSinceUnixEpoch(b[column.dataIndex]),
        };
    }

    return {
        ...column,
        ...searchColumn.CreateColumn(),
    };
};
