import axios from "axios";
import settings from "../../config/AppSettings/SettingsFactory";
import { JobState } from "../../models/explainability/JobState";
import { BaseService } from "../BaseService";
import { JobVendorState } from "../../models/explainability/JobVendorState";
import { ActiveJob } from "../../models/explainability/ActiveJob";




export class JobsStateService extends BaseService {

  async getJobStateByCompanyId(account_id: number): Promise<JobState[]> {
    const jobsStateByAccountId = await this.Get<JobState[]>(`/JobStatebyAccount/${account_id}`);
    return jobsStateByAccountId;
  }

  async getJobStateByJobsIds(jobs_lst: number[]): Promise<JobState[]> {
    const jobsStateByJobIds = await this.Post<JobState[]>(`/JobStatebyJob`, jobs_lst);
    return jobsStateByJobIds;
  }

  async getAciveJobByAccount(account_id: number): Promise<ActiveJob[]> {
    const activeJobs = await this.Get<ActiveJob[]>(`/AccountJobs/${account_id}`);
    return activeJobs;
  }
  
}


const jobsStateService = new JobsStateService("Job", settings.explainabilityServiceUrl);
export default jobsStateService;
