import { observer } from 'mobx-react-lite';
import { StyledColumnGroupWrapper, StyledContainer, StyledHeader } from './Styles';
import Comments from './Comments';
import { useContext } from 'react';
import { RootStoreContext } from '../../stores/RootStoreContext';
import AttributeLimit from './AttributeLimit';
import AttributePriorityLimit from './AttributePriorityLimit';


const CampaignRules = () => {
    const { attributesManagementStore } = useContext(RootStoreContext);
    const { CompanyAttributesAggregation, CustomerCampaignAttributesAggregation } = attributesManagementStore;
    const { applicantLimitPerJob, applicantLimitPerPriorityJob,
        budgetLimitPerJob, budgetLimitPerPriorityJob, } = CompanyAttributesAggregation;

    return (
        <StyledColumnGroupWrapper>
            <StyledHeader>Campaign rules</StyledHeader>
            <StyledContainer>
                {/*<ApplicantLimitPerJob /> */}
                <AttributeLimit limiAttrubute={applicantLimitPerJob} header="Applicant limit per job"
                    customerRequestLimit={CustomerCampaignAttributesAggregation.applicantLimitPerJob}
                    customerRequestPriorityLimit={CustomerCampaignAttributesAggregation.applicantLimitPerPriorityJob}
                    defaultValueForExpressWay={20}
                    limitType="Applicants"
                />
                <AttributePriorityLimit priorityLimitAttrubute={applicantLimitPerPriorityJob} label="Is Applicant limit Priority"
                    defaultValueForExpressWay={50}
                    limitType="Applicants" />
                <AttributeLimit limiAttrubute={budgetLimitPerJob} header="Spend limit per job (Client)"
                    customerRequestLimit={CustomerCampaignAttributesAggregation.budgetLimitPerJob}
                    customerRequestPriorityLimit={CustomerCampaignAttributesAggregation.budgetLimitPerPriorityJob}
                    defaultValueForExpressWay={200}
                    limitType="Spend limit" />
                <AttributePriorityLimit priorityLimitAttrubute={budgetLimitPerPriorityJob} label="Is Spend Limit Priority"
                    defaultValueForExpressWay={500}
                    limitType="Spend limit" />
                {/* <BudgetLimitPerJob /> */}
            </StyledContainer>
            <Comments />
        </StyledColumnGroupWrapper>
    );
};

export default observer(CampaignRules);
