import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import Loader from "../../../components/general/loader/Loader";
import IndeedJobsTable from "../../../components/indeed-manager/IndeedJobsTable";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import { IndeedContextInitializers } from "./helpers/IndeedContextInitializers";
import { IndeedBreadcrumbs } from "./helpers/IndeedManager.breadcrumbs";

interface Props {}

const IndeedJobsViewContainer = () => {
    const match = useParams<{ campaignId: string; indeedCampaignId: string }>();
    const history = useHistory();
    const { indeedStore } = useContext(RootStoreContext);

    usePageInitialization({
        onInitAsync: async () => {
            await IndeedContextInitializers.InitializePLCampaignData(
                Number(match.campaignId),
                indeedStore,
                history
            );
            await IndeedContextInitializers.InitializeSelectedIndeedCampaign(
                match.indeedCampaignId,
                indeedStore.PLCampaignData!,
                indeedStore,
                history
            );
        },
        onCompanyContextCallBack: (company, uiStore) => {
            uiStore.SetPageTitle(
                `Indeed Jobs for Indeed Campaign: ${indeedStore.SelectedIndeedCampaign?.name}`
            );
            uiStore.AddBreadCrumbs(
                SupervisionBreadcrumbs.Companies(company.companyName),
                SupervisionBreadcrumbs.Campaigns(`${indeedStore.PLCampaignData?.indeedCampaignSourceInfo.campaignName}`),
                IndeedBreadcrumbs.Indeed(Number(indeedStore.PLCampaignData?.indeedCampaignSourceInfo.campaignID)),
                IndeedBreadcrumbs.IndeedCampaign(
                    indeedStore.SelectedIndeedCampaign?.name ?? ""
                ),
                IndeedBreadcrumbs.CampaignJobs(
                    Number(indeedStore.PLCampaignData?.indeedCampaignSourceInfo.campaignID),
                    match.indeedCampaignId
                )
            );
        },
    });

    if (
        !indeedStore.SelectedIndeedCampaign ||
        !indeedStore.SelectedIndeedCampaign.indeedJobs
    ) {
        return <Loader position="normal" />;
    }

    return (
        <div>
            {/* <IndeedSearchSection /> */}
            <IndeedJobsTable
                indeedCampaignName={indeedStore.SelectedIndeedCampaign.name}
                jobs={indeedStore.SelectedIndeedCampaign.indeedJobs.map(
                    (x) => x.jobId
                )}
            />
        </div>
    );
};

export default observer(IndeedJobsViewContainer);
