import "./App.scss";

import { Layout } from "antd";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { Box } from "@material-ui/core";

import Footer from "./components/general/footer/Footer";
import PageHeader from "./components/general/page-header/PageHeader";
import SupervisionPopUp from "./components/general/popup/SupervisionPopUp";
import SideMenu from "./components/general/side-menu/SideMenu";
import { RoutesPaths } from "./constants/RoutePaths";
import useKeyboardListener from "./hooks/useKeyboardListener";
import RouterComponent from "./routes";
import LoginPage from "./routes/pages/Login/LoginPage";
import { RouteManager } from "./services/RouteManager";
import { RootStoreContext } from "./stores/RootStoreContext";
import { Guid } from "./utils/Guid";
import SeedUserData from "./utils/SeedUserData";


const { Content } = Layout;

interface Props {}

const App = (props: Props) => {
    const history = useHistory();
    const { userStore } = useContext(RootStoreContext);

    const routeService = new RouteManager();
    const location = useLocation();
    const _ = routeService.GetCurrentRoute(location.pathname); // Will throw if doesn't exist - for detection purposes

    SeedUserData(userStore);

    useKeyboardListener(Guid.NewGuid(), ["Alt", "q"], () => {
        history.push(RoutesPaths.Settings.ShortCutsManual);
    });

    return (
        <>
            <Switch>
                <Route exact path={RoutesPaths.Root.Login}>
                    <LoginPage />
                </Route>
                <Route>
                <Layout style={{ fontFamily: "'Open Sans', sans-serif", fontSize:'18px', fontWeight: '600', minHeight: "100vh" }}>
                        <SideMenu />
                        <Layout className="site-layout">
                            <Content>
                                <PageHeader />
                                <Box mx={6} my={6}>
                                    <RouterComponent />
                                </Box>
                                <SupervisionPopUp />
                            </Content>
                            <Footer />
                        </Layout>
                    </Layout>
                </Route>
            </Switch>
        </>
    );
};

export default observer(App);
