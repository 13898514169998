
import { Action } from "../../models/users/Action";
import { ActionResponse } from "../../models/users/ActionResponse";
import { FormatDate, FormatDateTime } from "../../utils/DateHelpers";
import { ELevelTypes } from "./ELevelTypes";

export class UserActionsTableDto {
    public _id: number;
    public user: string;
    public entityId: number;
    public entityLevel: string;
    public userActionType: string;
    public description: string;
    public timeStamp: string;

   
    constructor(action: ActionResponse) {
        this._id = action.id;
        this.user = action.userName;
        this.entityId = action.entityId;
        this.entityLevel = ELevelTypes[action.entityLevel];
        this.userActionType = action.actionType.name;
        this.description = action.description;
        this.timeStamp = FormatDateTime(action.creationDate);
    }
}