import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  TimePicker,
  Typography,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { CompanySettingTypeResponse } from "../../models/company-track-system/CompanySettingTypeResponse";
import { RootStoreContext } from "../../stores/RootStoreContext";
import companySettingTypeService from "../../services/company-settings/CompanySettingTypeService";
import { CompanySettingTypeValueOption } from "../../models/company-track-system/CompanySettingTypeValueOption";
import { observer } from "mobx-react-lite";
import { CompanySettingsAuditCreateModel } from "../../models/company-track-system/CompanySettingsAuditCreateModel";
import { CompanySettingsStatus } from "../../models/company-track-system/CompanySettingsStatus";
import CompanySettingAuditService from "../../services/company-settings/CompanySettingAuditService";
import moment from "moment";
import { Box } from "@material-ui/core";
import { FetchError } from "../../exceptions/AlgoSupervisionExceptions";
import { RoutesPaths } from "../../constants/RoutePaths";
import { useHistory } from "react-router-dom";

interface Props {
  companyAuditId: string;
  companyId: number;
}

const layout = {
  labelCol: { span: 4 },
};

const layoutType = {
  labelCol: { span: 8 },
};

const layoutValueOptionlabel = {
  labelCol: { span: 6 },
};

const AuditCompanyForm = (props: Props) => {
  const { companySettingStore, userStore } = useContext(RootStoreContext);

  const { companyId, companyAuditId } = props;

  const [selectedType, setSelectedType] =
    useState<CompanySettingTypeResponse>();
  const [valueOptionTypes, setValueOptionTypes] =
    useState<CompanySettingTypeValueOption[]>();
  const [selectedValueOption, setSelectedValueOption] = useState<
    string | undefined
  >();
  const [caseNumber, setCaseNumber] = useState<string | undefined>();
  const [comment, setComment] = useState<string | undefined>();
  const [companySettingTypeTextValue, setCompanySettingTypeTextValue] =
    useState<string | undefined>("");

  const [date, setDate] = useState<any>(moment(Date.now()));

  const history = useHistory();

  const [typeOptions, setTypeOptions] = useState<any[]>();

  useEffect(() => {
    setTypeOptions(
      companySettingStore.CompanySettingTypes?.filter((x) =>
        x.settingType.toLowerCase()
      ).map((x) => ({ value: x.settingType, id: x.id, dataType: x.dataType }))
    );
  }, [companySettingStore.CompanySettingTypes]);

  useEffect(() => {
    if (companySettingStore.updateOperationState) {
      setUpdateState();
      return;
    }
    cleanUpState();
  }, [companySettingStore.updateOperationState]);

  const setUpdateState = () => {
    const selectedAudit = companySettingStore.SelectedCompanySettingAudit;

    const typeRes = companySettingStore.CompanySettingTypes?.find(
      (x) => x.settingType == selectedAudit?.type
    );
    setSelectedType(typeRes);
    setCompanySettingTypeTextValue(typeRes?.settingType);
    setSelectedValueOption(selectedAudit?.value);
    setCaseNumber(selectedAudit?.caseNumber);
    setComment(selectedAudit?.comment);
    setDate(moment(selectedAudit?.creationDate));
  };

  const setSelectedTypeOnSelection = async (
    data: string,
    selectedType: any
  ) => {
    setCompanySettingTypeTextValue(data);

    setSelectedType(selectedType);

    const valueOptions =
      await companySettingTypeService.GetSettingTypeValueOptionsPerType(
        selectedType?.id
      );
    setValueOptionTypes(valueOptions);
  };

  const onSubmit = async (e: React.MouseEvent) => {
    let auditCreateModel: CompanySettingsAuditCreateModel = {
      companyId: companyId,
      creationDate: date,
      companySettingTypeId: selectedType?.id,
      caseNumber: caseNumber || "",
      status: CompanySettingsStatus.Active,
      userId: userStore.User!.id,
      value: selectedValueOption,
      comment: comment,
    };

    try {
      if (companySettingStore.updateOperationState) {
        const updatedAudit =
          await CompanySettingAuditService.UpdateCompanySettingsAudit(
            companyAuditId,
            auditCreateModel
          );
        message.success("Company setting audit updated successfully");
      } else {
        const createdAudit =
          await CompanySettingAuditService.CreateCompanySettingsAudit(
            auditCreateModel
          );
        message.success("Company setting audit created successfully");
      }

      history.push(
        RoutesPaths.Supervision.CompanyTrackSystemRoutes.BuildCompanyRoute(
          companyId
        )
      );
    } catch (error) {
      if (error instanceof FetchError) {
        message.error("Company setting audit is not created");
      }
    }

    cleanUpState();
  };

  const cleanUpState = () => {
    setCaseNumber("");
    setComment("");
    setSelectedValueOption("");
    setDate(moment(Date.now()));
    setSelectedType(undefined);
    setCompanySettingTypeTextValue("");
  };

  return (
    <Form {...layout}>
      <Input.Group>
        <Row style={{ marginTop: "5px" }}>
          <Col span={12}>
            <Typography.Title
              level={4}
              style={{ marginBottom: 35, marginLeft: 20 }}
            >
              {companySettingStore.updateOperationState ? (
                <span>Edit Audit</span>
              ) : (
                <span>Create Audit</span>
              )}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              {...layoutType}
              label="Type"
              rules={[
                {
                  required: true,
                  message: "Please choose type",
                },
              ]}
            >
              {
                <AutoComplete
                  options={typeOptions}
                  allowClear={true}
                  onSelect={setSelectedTypeOnSelection}
                  onSearch={setCompanySettingTypeTextValue}
                  value={companySettingTypeTextValue}
                  filterOption={(inputValue, option) =>
                    option!.value
                      ?.toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  placeholder="Choose audit criterion"
                />
              }
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              {...layoutValueOptionlabel}
              label={
                selectedType?.dataType
                  ? `Value (${selectedType?.dataType})`
                  : "Value"
              }
              rules={[
                {
                  required: true,
                  message: "Please choose value",
                },
              ]}
            >
              <AutoComplete
                options={valueOptionTypes?.map((x) => ({
                  value: x.valueOption,
                }))}
                onSelect={(data: string) => {
                  setSelectedValueOption(data);
                }}
                onSearch={(data: string) => setSelectedValueOption(data)}
                filterOption={(inputValue, option) =>
                  option!.value
                    ?.toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                allowClear={true}
                placeholder="Choose value"
                value={selectedValueOption}
              />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Date Time"
            rules={[
              {
                required: true,
                message: "Please choose the dateTime",
              },
            ]}
          >
            <DatePicker
              style={{ width: "50%" }}
              value={date}
              onChange={(dateInput, dateString) => {
                setDate(dateInput);
              }}
            />
            <TimePicker
              style={{ width: "50%" }}
              value={date}
              onChange={(time, timeString) => {
                setDate(time);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Case Number">
            <Input
              onChange={(e) => setCaseNumber(e.target.value)}
              value={caseNumber}
              placeholder="123456"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Comment">
            <Input.TextArea
              rows={4}
              placeholder="Leave a comment to help you remember the reason for taking this decision."
              onChange={(e) => setComment(e.target.value)}
              value={comment}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item wrapperCol={{ offset: 2, span: 12 }} style={{ marginTop: 50 }}>
        <Button onClick={onSubmit} type="primary">
          {companySettingStore.updateOperationState ? (
            <span>Edit Audit</span>
          ) : (
            <span>Create Audit</span>
          )}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default observer(AuditCompanyForm);
