import settings from "../../config/AppSettings/SettingsFactory";
import { JobState } from "../../models/explainability/JobState";
import { Vendor } from "../../models/explainability/Vendor";
import { BaseService } from "../BaseService";



export class ExplainabilityService extends BaseService {


  async getJobStateByIds(GetManyByIdsRequest: { ids: number[] }) {
    const jobStateData = await this.Post<JobState[]>("/get-multiple-by-ids", GetManyByIdsRequest);
    return jobStateData;
  }

  async getAllVendors()
  {
    const vendors = await this.Get<Vendor[]>(`/AllVendors`);
    return vendors;
  }

}


const explainabilityService = new ExplainabilityService("Vendor", settings.explainabilityServiceUrl);
export default explainabilityService;