import { Box } from "@material-ui/core";
import { Button, message } from 'antd';
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import VendorManagement from "../../../components/vendor-management/VendorManagement";
import VendorSelectInput from "../../../components/vendor-management/VendorSelectInput";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { VendorManagmentContextInitializers } from "./helpers/VendorManagmentContextInitializers";
import { ClassificationTypeValidation } from "./helpers/ClassificationTypeValidation";
import VendorManagmentMode from "../../../components/vendor-management/VendorManagmentMode";
import { ClassificationType } from "../../../models/vendor-management/ClassificationType";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import VendorManagmentBreadCrumbs from "./helpers/VendorManagmentBreadCrumbs";
import { RoutesPaths } from "../../../constants/RoutePaths";
import affiliateVendorService from "../../../services/vendor-management/AffiliateVendorService";
import usePageInitializationAffiliate from "../../../hooks/usePageInitializationAffiliate";
import { VendorManagmentStateContextInitializers } from "./helpers/VendorManagmentStateContextInitializers";
import { ReportPage } from "./helpers/ReportPage";
import { EntityDistributionSettings } from "../../../models/vendor-management/settings/EntityDistributionSettings";
import { ValidAction } from "../../../models/ValidAction";



interface Props { }

const AffiliateVendorPage = (props: Props) => {

    const match = useParams<{ affiliateId: string }>();
    const { vendorManagementStore, userStore, uiStore } = useContext(RootStoreContext);

    const history = useHistory();

    usePageInitializationAffiliate({
        onInitAsync: async () => {
            await vendorManagementStore.SetSearchedVendors([]) // reset
            await VendorManagmentStateContextInitializers.InitializeAffiliateSettingsData(
                Number(match.affiliateId),
                vendorManagementStore,
                history
            )
            await VendorManagmentContextInitializers.InitializeAffiliateVendorsData(
                Number(match.affiliateId),
                vendorManagementStore,
                history
            )
            vendorManagementStore.SearchedVendors = vendorManagementStore.Vendors?.filter(vendor =>
                vendor.classificationType != ClassificationType.None || vendor.inclusionEnforcement !== false
            );

        },
        onAffiliateContextCallBack: (affiliate, uiStore) => {
            uiStore.SetPageTitle(
                `Vendor Managment for: ${affiliate.affiliateName}`
            );
            uiStore.AddBreadCrumbs(
                SupervisionBreadcrumbs.Affiliates(affiliate.affiliateName),
                VendorManagmentBreadCrumbs.AffiliatVendor(affiliate.affiliateId),
            );
        },
    })

    const onApply = async () => {
        try {
            vendorManagementStore.SetVendorsToApply(vendorManagementStore.rowsEffected);
            vendorManagementStore.SelectedVendorsToApply.forEach(vendor => {
                vendor.userId = userStore.User!.id;
            });

            //settings changes
            let entityDistributionSettingsResponse = await affiliateVendorService.GetEffectedEntitesChangeModeAsync(
                vendorManagementStore.EntitySettings,
                Number(match.affiliateId)
            );

            const reportResult = await ReportPage.BuildReport(uiStore,
                vendorManagementStore.rowsEffected, entityDistributionSettingsResponse.resetEntitiesCollections);

            if (reportResult.action) {
                await affiliateVendorService.SetAffiliateDistributionSettings(
                    vendorManagementStore.EntitySettings,
                    Number(match.affiliateId)
                )

                await affiliateVendorService.SetAffiliatesVendors(
                    vendorManagementStore.SelectedVendorsToApply, Number(match.affiliateId), reportResult.sourceRequest);
                vendorManagementStore.rowsEffected = [];
            }
            else {
                return;
            }
            //

            message.success("Affiliate vendors updated successfully");
            history.push(RoutesPaths.Supervision.Affiliates);
        } catch (error) {
            message.error("Affiliate vendors updated failed");

        }

    }

    return (
        <div>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <VendorSelectInput
                    entityVendors={vendorManagementStore.Vendors}
                />
                <VendorManagmentMode onValid = {async (newSettings: EntityDistributionSettings) => {return {isValid: true, errorMessage: ''}}}/>
            </Box>
            <VendorManagement />
            <div style={{ width: 270 }}>
                <Button
                    onClick={onApply}
                    type="primary"
                    size="large"
                    style={{ display: "flex", alignItems: "center", borderRadius: '4px' }}>
                    <span>Apply Changes</span>
                </Button>

            </div>
        </div>
    )
}

export default observer(AffiliateVendorPage);