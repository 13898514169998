import { RoutesPaths } from "../../../../constants/RoutePaths";
import { BreadCrumb } from "../../../../models/BreadCrumb";

export const CompanySettingBreadCrumbs = {
  CompanySetting: (companyId: number) => {
    return new BreadCrumb(
      "Company-Settings",
      RoutesPaths.Supervision.CompanyTrackSystemRoutes.BuildCompanyRoute(
        companyId
      ),
      null
    );
  },
  CompanySettingAudit: (CompanyName: string) => {
    return new BreadCrumb(CompanyName, null, null);
  },
  AddCompanySettingAudit: (companyId: number) => {
    return new BreadCrumb(
      "Add Audit",
      RoutesPaths.Supervision.CompanyTrackSystemRoutes.BuildAddCompanySettingAudit(
        companyId
      ),
      null
    );
  },
  EditCompanySettingAudit: (
    companyId: number,
    companySettingAuditId: string
  ) => {
    return new BreadCrumb(
      "Edit",
      RoutesPaths.Supervision.CompanyTrackSystemRoutes.BuildEditCompanySettingAudit(
        companyId,
        companySettingAuditId
      ),
      null
    );
  },
};

export default CompanySettingBreadCrumbs;
