import { ISODateTime } from "../../types/types";
import { IndeedBudgetFirstMonthBehavior } from "./IndeedBudgetFirstMonthBehavior";
import { IndeedCampaignObjective } from "./IndeedCampaignObjective";
import { IndeedCampaignStatus } from "./IndeedCampaignStatus";

export class IndeedCreateCampaignEventPayload {
    constructor(
        public name: string,
        public userId: number,
        public status: IndeedCampaignStatus,
        // public trackingToken: string,
        public jobsList: number[],
        public startDate: ISODateTime,
        public pandoCampaignId: number,
        public pandoCompanyId: number,
        public objective: IndeedCampaignObjective,
        public jobsSourceName?: string,
        public budgetFirstMonthBehavior?: IndeedBudgetFirstMonthBehavior,
        public fixedEndDate?: ISODateTime,
        public targetEndDate?: ISODateTime,
        public budgetOnetimeLimit?: number,
        public budgetMonthlyLimit?: number,
    ) {
    }
}
