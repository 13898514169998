import { PageNotFoundexception } from "../exceptions/AlgoSupervisionExceptions";
import { RouteModel } from "../models/RouteModel";
import { routes } from "../routes/routes";

export class RouteManager {
    /**
     * ctor
     */
    constructor() { }

    public GetCurrentRoute(pathName: string): RouteModel {
        const routePath: RouteModel | undefined = routes.find(x => {
            return this.ConvertNumbersOrGuidInRouteToColons(pathName) == this.ConvertPathParametersToColons(x.routePath);
        });
        if (!routePath) {
            throw new PageNotFoundexception(`${pathName} route is not configured in routes file`);
        }
        return routePath;
    }

    private ConvertNumbersOrGuidInRouteToColons(pathName: string): string {
        // A path can also be a GUID (contain partially numbers) like: da4dc430dcf1627f

        // return pathName.replace(/\d+/g, ":");
        return pathName.replace(/(\w*\d+\w*)/g, ":");
    }

    private ConvertPathParametersToColons(routePath: string): string {
        return routePath.replace(/(:\w*)/g, ":");
    }
}