import { Spin } from "antd";
import React, { CSSProperties } from "react";

interface Props {
    position: "center" | "pad-top" | "normal";
}

const Loader = (props: Props) => {

    const createStyles = (): CSSProperties => {
        switch (props.position) {
            case "center":
                return {
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                };
            case "pad-top":
                return {
                    marginTop: "2rem",
                };
            case "normal":
                return {};
        }
    };

    return (
        <div style={createStyles()}>
            <Spin tip="Loading..."></Spin>
        </div>
    );
};

export default Loader;
