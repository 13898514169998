/**
 * Configuration for all routes in the application
 */

import { Redirect } from "react-router-dom";

import { RoutesPaths } from "../constants/RoutePaths";
import { BreadCrumb } from "../models/BreadCrumb";
import { RouteModel } from "../models/RouteModel";
import AccessDeniedPage from "./pages/AccessDeniedPage";
import CampaignTrackAuditPage from "./pages/campaign-track-system/CampaignTrackAuditPage";
import CampaignTrackEditAuditPage from "./pages/campaign-track-system/CampaignTrackEditAuditPage";
import IFramePage from "./pages/IFramePage";
import UserActionsTablePage from "./pages/actions/UserActionsTablePage";
import IndeedAddCampaignContainer from "./pages/indeed-manager/IndeedAddCampaignPage";
import IndeedAttachJobsToCampaignPage from "./pages/indeed-manager/IndeedAttachJobsToCampaignPage";
import IndeedJobsViewContainer from "./pages/indeed-manager/IndeedCampaignJobsPage";
import IndeedCampaignPage from "./pages/indeed-manager/IndeedCampaignPage";
import IndeedEditCampaignPage from "./pages/indeed-manager/IndeedEditCampaignPage";
import LoginPage from "./pages/Login/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import NotFoundPage from "./pages/NotFoundPage";
import ChooseCutOffContainer from "./pages/publisher-selection/ChooseCutOffPage";
import ShortCutsManualContainer from "./pages/ShortCutsManualPage";
import CampaignsContainer from "./pages/supervision/CampaignsPage";
import CampaignTrackAddAuditPage from "./pages/campaign-track-system/CampaignTrackAddAuditPage";
import GroupsPage from "./pages/groups/GroupsPage";
import GroupsAddPage from "./pages/groups/GroupsAddPage";
import GroupJobsPage from "./pages/groups/GroupJobsPage";
import GroupsEditPage from "./pages/groups/GroupsEditPage";
import GroupsAttachJobsPage from "./pages/groups/GroupsAttachJobsPage";
import VendorManagement from "../components/vendor-management/VendorManagement";
import CampaignVendorPage from "./pages/vendor-management/CampaignVendorPage";
import GroupVendorPage from "./pages/vendor-management/GroupVendorPage";
import CompaniesContainer from "./pages/supervision/CompaniesPage";
import CompanyVendorPage from "./pages/vendor-management/CompanyVendorPage";
import CompanyTrackAuditPage from "./pages/company-track-system/CompanyTrackAuditPage";
import AffiliateVendorPage from "./pages/vendor-management/AffiliateVendorPage";
import CompanyTrackEditAuditPage from "./pages/company-track-system/CompanyTrackEditAuditPage";
import CompanyTrackAddAuditPage from "./pages/company-track-system/CompanyTrackAddAuditPage";
import AffiliatesContainer from "./pages/supervision/AffiliatesPage";
import ExplainabilityMainPage from "./pages/explainability/ExplainabilityMainPage";
import CompanyAttributesPage from "./pages/attributes-management/CompanyAttributesPage";

const emptyHandlerToShowCursorArrow = () => {};

/**
 * Publisher Selection
 */
export const rootRoute = new RouteModel(
  RoutesPaths.Root.Index,
  <Redirect to={RoutesPaths.PublisherSelection.ChooseCutOff} />,
  "Algo Supervision",
  [new BreadCrumb("Algo Supervision", null, emptyHandlerToShowCursorArrow)]
);

export const loginRoute = new RouteModel(
  RoutesPaths.Root.Login,
  <LoginPage />,
  "",
  []
);

export const logoutRoute = new RouteModel(
  RoutesPaths.Root.Logout,
  <LogoutPage />,
  "",
  []
);

export const notFoundRoute = new RouteModel(
  RoutesPaths.Root.NotFound,
  <NotFoundPage />,
  "",
  []
);

export const accessDeniedRoute = new RouteModel(
  RoutesPaths.Root.AccessDenied,
  <AccessDeniedPage />,
  "",
  []
);


export const publisherSelectionPageRoute = new RouteModel(
  RoutesPaths.PublisherSelection.ChooseCutOff,
  <ChooseCutOffContainer />,
  "Publisher Selection Page",
  [new BreadCrumb("Publisher Selection", null, emptyHandlerToShowCursorArrow)]
);

/**
 * Settings
 */
export const shortcutManualsRoute = new RouteModel(
  RoutesPaths.Settings.ShortCutsManual,
  <ShortCutsManualContainer />,
  "Settings",
  [
    new BreadCrumb("Settings", null, null),
    new BreadCrumb(
      "Short Cuts Manual",
      RoutesPaths.Settings.ShortCutsManual,
      emptyHandlerToShowCursorArrow
    ),
  ]
);

/* 
============================ NEW
Supervision
*/
const supervisionBaseBreadCrumb = new BreadCrumb(
  "Algo Supervision",
  RoutesPaths.Supervision.Companies,
  emptyHandlerToShowCursorArrow
);

const campaignsBreadCrumb = new BreadCrumb(
  "Campaigns",
  RoutesPaths.Supervision.Campaigns,
  emptyHandlerToShowCursorArrow
);

const companiesBreadCrumb = new BreadCrumb(
  "Accounts",
  RoutesPaths.Supervision.Companies,
  emptyHandlerToShowCursorArrow
);


const affiliatesBreadCrumb = new BreadCrumb(
    "Affiliates",
    RoutesPaths.Supervision.Affiliates,
    emptyHandlerToShowCursorArrow
);

export const supervisionCampaignsRoute = new RouteModel(
    RoutesPaths.Supervision.Campaigns,
    <CampaignsContainer />,
    "Campaigns Supervision",
    [supervisionBaseBreadCrumb, campaignsBreadCrumb]
);

export const supervisionCompaniesRoute = new RouteModel(
  RoutesPaths.Supervision.Companies,
  <CompaniesContainer />,
  "Accounts Supervision",
  [supervisionBaseBreadCrumb, companiesBreadCrumb]
);

export const supervisionAffiliatesRoute = new RouteModel(
  RoutesPaths.Supervision.Affiliates,
  <AffiliatesContainer />,
  "Affiliates Supervision",
  [supervisionBaseBreadCrumb, affiliatesBreadCrumb]
);

/**
 * Indeed Management
 */
export const indeedRoutes = {
  campaignRoute: new RouteModel(
    RoutesPaths.Supervision.IndeedRoutes.CampaignRoute,
    <IndeedCampaignPage />,
    "Indeed Campaigns",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),
  addCampaignRoute: new RouteModel(
    RoutesPaths.Supervision.IndeedRoutes.AddCampaign,
    <IndeedAddCampaignContainer />,
    "Add Indeed Campaign",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),
  campaignJobsRoute: new RouteModel(
    RoutesPaths.Supervision.IndeedRoutes.CampaignJobsRoute,
    <IndeedJobsViewContainer />,
    "Indeed Jobs",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),
  attachJobsToIndeedCampaign: new RouteModel(
    RoutesPaths.Supervision.IndeedRoutes.AttachJobsToIndeedCampaign,
    <IndeedAttachJobsToCampaignPage />,
    "Attach Jobs To Indeed Campaign",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),
  editCampaign: new RouteModel(
    RoutesPaths.Supervision.IndeedRoutes.EditIndeedCampaign,
    <IndeedEditCampaignPage />,
    "Edit Indeed Campaign",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),
};

/**
 * Company Setting
 */
export const CompanyTrackSystemRoutes = {
  audit: new RouteModel(
    RoutesPaths.Supervision.CompanyTrackSystemRoutes.CompanySettingsRoute,
    <CompanyTrackAuditPage />,
    "Company Track System",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),
  edit: new RouteModel(
    RoutesPaths.Supervision.CompanyTrackSystemRoutes.EditCompanySettingAudit,
    <CompanyTrackEditAuditPage />,
    "Company Track System",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),
  add: new RouteModel(
    RoutesPaths.Supervision.CompanyTrackSystemRoutes.AddCompanySettingAudit,
    <CompanyTrackAddAuditPage />,
    "Company Track System",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),
};

/**
 * Campaign Setting
 */
export const CampaignTrackSystemRoutes = {
  audit: new RouteModel(
    RoutesPaths.Supervision.CampaignTrackSystemRoutes.CampaignSettingsRoute,
    <CampaignTrackAuditPage />,
    "Campaign Track System",
    [supervisionBaseBreadCrumb, campaignsBreadCrumb]
  ),
  edit: new RouteModel(
    RoutesPaths.Supervision.CampaignTrackSystemRoutes.EditCampaignSettingAudit,
    <CampaignTrackEditAuditPage />,
    "Campaign Track System",
    [supervisionBaseBreadCrumb, campaignsBreadCrumb]
  ),

  add: new RouteModel(
    RoutesPaths.Supervision.CampaignTrackSystemRoutes.AddCampaignSettingAudit,
    <CampaignTrackAddAuditPage />,
    "Campaign Track System",
    [supervisionBaseBreadCrumb, campaignsBreadCrumb]
  ),
};

// Actions
const actionsBreadCrumb = new BreadCrumb("Actions", null, null);

export const actionsRoutes = {
  actionsTable: new RouteModel(
    RoutesPaths.Actions.Table,
    <UserActionsTablePage />,
    "User Actions - Table View",
    [
      supervisionBaseBreadCrumb,
      actionsBreadCrumb,
      new BreadCrumb(
        "Table",
        RoutesPaths.Actions.Table,
        emptyHandlerToShowCursorArrow
      ),
    ]
  ),
};

/**
 * Groups
 */
export const groupsRoutes = {
  group: new RouteModel(
    RoutesPaths.Supervision.GroupsRoutes.GroupRoute,
    <GroupsPage />,
    "Groups",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),

  add: new RouteModel(
    RoutesPaths.Supervision.GroupsRoutes.AddGroup,
    <GroupsAddPage />,
    "Groups",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),

  jobs: new RouteModel(
    RoutesPaths.Supervision.GroupsRoutes.GroupJobsRoute,
    <GroupJobsPage />,
    "Group Jobs",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),

  editGroup: new RouteModel(
    RoutesPaths.Supervision.GroupsRoutes.EditGroup,
    <GroupsEditPage />,
    "Edit Group",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),

  attachJobs: new RouteModel(
    RoutesPaths.Supervision.GroupsRoutes.AttachJobsToGroup,
    <GroupsAttachJobsPage />,
    "Attach Jobs",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),
};

/**
 * VendorManagment
 */
export const vendorManagementRoutes = {
  companyVendorManagement: new RouteModel(
    RoutesPaths.Supervision.VendorManagementRoutes.CompanyVendorRoute,
    <CompanyVendorPage />,
    "Account Vendor Management",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),

  affiliateVendorManagement: new RouteModel(
      RoutesPaths.Supervision.VendorManagementRoutes.AffiliateVendorRoute,
      <AffiliateVendorPage />,
      "Affiliate Vendor Management",
      [supervisionBaseBreadCrumb, affiliatesBreadCrumb]  
  ),

  campaignVendorManagement: new RouteModel(
    RoutesPaths.Supervision.VendorManagementRoutes.CampaignVendorRoute,
    <CampaignVendorPage />,
    "Campaign Vendor",
    [supervisionBaseBreadCrumb, campaignsBreadCrumb]
  ),

  groupVendorManagement: new RouteModel(
    RoutesPaths.Supervision.VendorManagementRoutes.GroupVendorRoute,
    <GroupVendorPage />,
    "Group Vendor",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),
};

/**
 * Explainability
 */
export const explainabilityRoute = new RouteModel(
  RoutesPaths.Explainability.JobExplainabilityRoute,
  <ExplainabilityMainPage />,
  "Explainability Page",
  []
);

/**
 * AttributesManagement
 */
export const AttributesManagementRoutes = {
  companyAttributesManagement : new RouteModel(
    RoutesPaths.Supervision.AttributesManagementRoutes.CompanyAttributesRoute,
    <CompanyAttributesPage />,
    "Company Attributes Page",
    [supervisionBaseBreadCrumb, companiesBreadCrumb]
  ),
}




/**
 * Routes
 */
export const routes: RouteModel[] = [
  // Post refactor
  supervisionCampaignsRoute,
  supervisionCompaniesRoute,
  supervisionAffiliatesRoute,
  loginRoute,
  logoutRoute,
  accessDeniedRoute,
  ...Object.values(indeedRoutes),
  ...Object.values(CompanyTrackSystemRoutes),
  ...Object.values(CampaignTrackSystemRoutes),
  ...Object.values(actionsRoutes),
  ...Object.values(groupsRoutes),
  ...Object.values(vendorManagementRoutes),
  ...Object.values(AttributesManagementRoutes),
  //
  rootRoute,
  publisherSelectionPageRoute,
  shortcutManualsRoute,
  explainabilityRoute,
];
