import React, { useEffect } from "react";

const useOnInit = (callback: () => void) => {
    useEffect(() => {
        callback();
    }, [])
}


export default useOnInit;
