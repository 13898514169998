import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { RootStoreContext } from "../stores/RootStoreContext";

interface Props {}

const useSetCurrentRoute = () => {
    const currentLocation = useLocation();
    const { uiStore } = useContext(RootStoreContext);

    useEffect(() => {
        uiStore.SetCurrentRoute(currentLocation.pathname);
    }, []);
};

export default useSetCurrentRoute;
