import settings from "../../config/AppSettings/SettingsFactory";
import { CompanyProfile } from "../../models/company-attributes/CompanyProfile";
import { CompanyPreferenceDto } from "../../models/company-attributes/CompanyPreferenceDto";
import { BaseService } from "../BaseService";



export class CompanyPreferenceService extends BaseService
{
    async getCompanyPreference(companyId: number) {
        const companyPreferenceData = await this.Get<CompanyPreferenceDto>(`/${companyId}`);
        return companyPreferenceData;
    }

    async setCompanyPreference(companyPreferenceDto: CompanyPreferenceDto) {
        const companyAttributesdata = await this.Post(``, companyPreferenceDto);
        return companyAttributesdata;
    }

    async getCompanyProfile(companyId: number) : Promise<CompanyProfile> {
        const CompanyProfile = await this.Get<CompanyProfile>(`/profile/${companyId}`);
        return CompanyProfile;
    }

}


const companyPreferenceService = new CompanyPreferenceService("company-preference", settings.attributesManagementServiceUrl);
export default companyPreferenceService;

