import { JobState } from "../../models/explainability/JobState";
import { ISODateTime } from "../../types/types";
import { TableDto } from "../general/supervision-table/Table.dto";



export class JobStateDto extends TableDto 
{
    public job_id?: number;
    public is_distributed?: string;
    public reason_name?: string;
    public user_name?: string;
    public last_modified?: ISODateTime


    
    constructor(jobState?: JobState)
    {
        super();
        this.job_id = jobState?.job_id;
        this.user_name = jobState?.user_name;
        this.last_modified = jobState?.last_modified;
        this.reason_name = jobState?.reason_name;
        this.is_distributed = jobState?.is_distributed;

    }
    



}