import Sider from "antd/lib/layout/Sider";
import styled from "styled-components";

import { makeStyles } from "@material-ui/core";

export const SiderStyled = styled(Sider)`
  
`;


// Material UI

export const useStyles = makeStyles((theme) => ({
    logo: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto'
    }
}))
