import { observer } from "mobx-react-lite"
import SupervisionTable from "../general/supervision-table/SupervisionTable"
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { JobState } from "../../models/explainability/JobState";
import { JobStateDto } from "./JobState.dto";
import Loader from "../general/loader/Loader";
import useOnInitAsync from "../../hooks/useOnInitAsync";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { JobVendorState } from "../../models/explainability/JobVendorState";
import { JobBase } from "../../models/explainability/JobBase";
import { JobVendorStateDto } from "./JobVendorState.dto";
import { JobSerachingType } from "../../models/explainability/JobSerachingType";
import { Empty, Spin } from "antd";

interface Props {
    isLoading : boolean;
 }

const ExplainabilityTable = (props: Props) => {

    const { explainabilityStore } = useContext(RootStoreContext);
    const [jobs, setJobs] = useState<JobBase[]>([]);

    const jobDtos = jobs.map(job => {
        if (explainabilityStore.SelectedJobSerachingType == JobSerachingType.JobVendorState) {
            return new JobVendorStateDto(job as JobVendorState);
        }
        return new JobStateDto(job as JobState);
    });

    useEffect(() => {
        setJobs(explainabilityStore.JobsState);
    }, [explainabilityStore.JobsState])

    if (props.isLoading) {
        return <Loader position="normal" />;
    }
    
    if (!jobDtos || jobDtos.length == 0) {
        return <Empty style={{ marginTop: "40px" }} description={`No Data Found`} />
    }



    return (
        <SupervisionTable
            dataSource={jobDtos}
        />
    )
}


export default observer(ExplainabilityTable)