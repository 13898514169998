import React, { useEffect } from "react";

const useOnInitAsync = (callback: () => Promise<void>, dependencyArray: any[] = []) => {
    useEffect(() => {
        (async () => {
            await callback();
        })();
    }, dependencyArray);
};

export default useOnInitAsync;
