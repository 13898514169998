import { message } from "antd";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { CookieConstants } from "../../constants/CookieConstants";
import { RoutesPaths } from "../../constants/RoutePaths";

type Props = {};

const LogoutPage = (props: Props) => {
    useEffect(() => {
        message.success("Successfully logged out");
    }, []);

    Cookies.remove(CookieConstants.UserIdentityCookie);
    return <Redirect to={RoutesPaths.Root.Login} />;
};

export default LogoutPage;
