export type shortcutType = {
    Keys: string,
    Action: string
}

export const userShortcuts: shortcutType[] = [
    {
        Keys: "Alt + b",
        Action: "Toggles sidebar",
    },
    {
        Keys: "Alt + h",
        Action: "Toggles header menu",
    },
    {
        Keys: "Alt + q",
        Action: "Open this page (shortcut page)",
    },
];