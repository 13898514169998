import React, { useContext, useEffect } from 'react';
import {
  StyledCheckbox, StyledContainer, StyledGroupWrapper,
  StyledColumnGroupWrapper, StyledInputLabel,
  StyledInputNumberWrapper, StyledTitle, StyledColumnGroupToTopWrapper
} from './Styles';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/RootStoreContext';



const Caps = () => {

  const { attributesManagementStore } = useContext(RootStoreContext);
  const { CompanyAttributesAggregation } = attributesManagementStore;
  const { allVendorsCap, indeedCap, craiglistBudget } = CompanyAttributesAggregation;


  useEffect(() => {

  }, [CompanyAttributesAggregation.allVendorsCap,
  CompanyAttributesAggregation.indeedCap,
  CompanyAttributesAggregation.craiglistBudget,]);


  const onChangeAllVendorsCap = (value: any) => {
    if (allVendorsCap) {
      allVendorsCap.cap = value;
      attributesManagementStore.onChangeAttribute(allVendorsCap);
    }
  }

  const onIsOriginalsOnlyAllVendors = () => {
    if (allVendorsCap) {
      allVendorsCap.isOriginalsOnly = !allVendorsCap.isOriginalsOnly;
      attributesManagementStore.onChangeAttribute(allVendorsCap);
    }
  }

  const onChangeIndeedCap = (value: any) => {
    if (indeedCap) {
      indeedCap.cap = value;
      attributesManagementStore.onChangeAttribute(indeedCap);
    }
  }

  const onIsOriginalsOnly = () => {
    if (indeedCap) {
      indeedCap.isOriginalsOnly = !indeedCap.isOriginalsOnly;
      attributesManagementStore.onChangeAttribute(indeedCap);
    }
  }
  ///

  const onChangeCraiglistCap = (value: any) => {
    if (craiglistBudget) {
      craiglistBudget.budget = value;
      attributesManagementStore.onChangeAttribute(craiglistBudget);
    }
  }


  return (

    <StyledContainer>
      <StyledTitle><span>Carousel Cap</span></StyledTitle>
      <StyledGroupWrapper>
        <StyledColumnGroupWrapper>
          <StyledInputLabel>All Vendors</StyledInputLabel>
          <StyledInputNumberWrapper min={0}
            onChange={onChangeAllVendorsCap}
            value={allVendorsCap?.cap} />
          <StyledCheckbox
            onClick={onIsOriginalsOnlyAllVendors}
            checked={allVendorsCap?.isOriginalsOnly} >Original Only</StyledCheckbox>
        </StyledColumnGroupWrapper>
        <StyledColumnGroupWrapper>
          <StyledInputLabel>Indeed</StyledInputLabel>
          <StyledInputNumberWrapper min={0}
            onChange={onChangeIndeedCap}
            value={indeedCap?.cap} />
          <StyledCheckbox
            onClick={onIsOriginalsOnly}
            checked={indeedCap?.isOriginalsOnly} >Original Only</StyledCheckbox>
        </StyledColumnGroupWrapper>
        <StyledColumnGroupToTopWrapper>
          <StyledInputLabel>CraigsList budget</StyledInputLabel>
          <StyledInputNumberWrapper min={0}
            onChange={onChangeCraiglistCap}
            value={craiglistBudget?.budget} />
        </StyledColumnGroupToTopWrapper>
      </StyledGroupWrapper>

    </StyledContainer>

  );
};

export default observer(Caps);
