import "./SideMenu.scss";

import { Badge, Layout, Menu, Popover, Tooltip } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { UserOutlined } from "@ant-design/icons";
import { Box } from "@material-ui/core";

import useKeyboardListener from "../../../hooks/useKeyboardListener";
import { RouteManager } from "../../../services/RouteManager";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { Guid } from "../../../utils/Guid";
import PandoLogicLogo from "../../theme/PandoLogicLogo";
import NotificationsList from "./components/NotificationsList/NotificationsList";
import UserInformation from "./components/UserInformation/UserInformation";
import { useStyles } from "./SideMenu.styles";
import { getSideMenuData, sideMenuSectionOptionType, sideMenuSectionType } from "./SideMenuData";
import { supervisionCompaniesRoute } from "../../../routes/routes";

const { Sider } = Layout;
const { SubMenu } = Menu;



const isRouteALink = (route: string) => {
    return route.includes("link:");
};

const GetLinkFromRoute = (route: string) => {
    return route.substring("link:".length);
};

interface Props { }
const SideMenu = (props: Props) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const { userStore, companyStore,affiliateStore } = useContext(RootStoreContext);
    const { SelectedCompany } = companyStore;
    const { SelectedAffiliate} = affiliateStore;
    const [sideNavigationData, setSideNavigationData] = useState<sideMenuSectionType[]>(getSideMenuData());


    const location = useLocation();
    const classes = useStyles();

    const routeService = new RouteManager();
    const currentRoute = routeService.GetCurrentRoute(location.pathname);

    useEffect(() => {
        if (SelectedCompany && SelectedCompany.campaign) {
            const sideMenuDataUpdated = getSideMenuData(SelectedCompany, SelectedAffiliate);
            setSideNavigationData(sideMenuDataUpdated);
        }
    }, [SelectedCompany]);

    useEffect(() => {
    if (SelectedAffiliate) {
            const sideMenuDataUpdated = getSideMenuData(SelectedCompany, SelectedAffiliate);
            setSideNavigationData(sideMenuDataUpdated);
        }
    }, [SelectedAffiliate]);
    
    /**
     * Short cuts
     */
    useKeyboardListener(Guid.NewGuid(), ["Control", "b"], () => {
        setCollapsed(!collapsed);
    });

    useKeyboardListener(Guid.NewGuid(), ["Alt", "b"], () => {
        setCollapsed(!collapsed);
    });

    const handleMenuItemClick = () => {
        console.log("handleMenuItemClick")

    };

    const renderMenuItem = (menuItem: sideMenuSectionOptionType) => {
        if (menuItem.children && menuItem.children.length > 0 && SelectedCompany != null) {
            // If the menu item has children, render a SubMenu
            return (
                <SubMenu key={menuItem.itemKey} title={(
                    <Tooltip title={menuItem.optionTitle}>
                        <Link to={menuItem.route}>{menuItem.optionTitle}</Link>
                    </Tooltip>
                )}>
                    {menuItem.children.map((child) => renderMenuItem(child))}
                </SubMenu>
            );
        } else {
            // If the menu item has no children, render a regular Menu.Item
            return (
                <Menu.Item
                    key={menuItem.itemKey}
                    icon={menuItem.sectionIcon ? <menuItem.sectionIcon /> : null}
                    style={{ padding: '0 0.8rem' }}
                >
                    {isRouteALink(menuItem.route) ? (
                        <a rel="noreferrer" target="_blank" href={GetLinkFromRoute(menuItem.route)}>
                            {menuItem.optionTitle}
                        </a>
                    ) : (
                        <Link to={menuItem.route}>{menuItem.optionTitle}</Link>
                    )}
                </Menu.Item>
            );
        }
    };



    return (
        <Sider
            className="app-sider"
            collapsible
            collapsed={collapsed}
            width = {240} 
            onCollapse={() => setCollapsed(!collapsed)}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%">
                <Box>
                    <Box className={classes.logo} width={collapsed ? 30 : 240}>
                        <PandoLogicLogo
                            style={{ transform: "scale(0.75)" }}
                            logoType={collapsed ? "mini" : "default"}
                        />
                    </Box>
                    <Menu theme="dark" selectedKeys={[currentRoute.id]} mode="inline" defaultOpenKeys={["supervision"]}>
                        {sideNavigationData.map((sideMenuSection) => (
                            <SubMenu
                                key={sideMenuSection.itemKey}
                                icon={
                                    <sideMenuSection.sectionIcon
                                        style={{ width: collapsed ? '100%' : 'auto' }}
                                    />
                                }
                                title={sideMenuSection.sectionTitle}
                            >
                                {sideMenuSection.sectionOptions.map((sideMenuSectionOption) =>
                                    renderMenuItem(sideMenuSectionOption)
                                )}
                            </SubMenu>
                        ))}
                    </Menu>
                </Box>

                <Box>
                    <NotificationsList collapsed={collapsed} />
                    <UserInformation collapsed={collapsed} />
                </Box>
            </Box>
        </Sider>
    );
};

export default observer(SideMenu);
