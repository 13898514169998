import { Typography } from "@material-ui/core";
import React from "react";
import { Box } from "@material-ui/core";
import { userShortcuts } from "../../components/general/shortcuts/ShortcutsData";
import ShortCutItem from "../../components/general/shortcuts/ShortCutItem";

interface Props {}

const ShortCutsManualContainer = (props: Props) => {
    return (
        <Box width="750px">
            <Box mb={6}>
                <Typography variant="h6" component="h3">
                    Shortcuts Guide
                </Typography>
            </Box>

            <Box
                display="flex"
                flexDirection="column" /* style={{borderBottom: '1px solid gray'}} */
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ borderBottom: "1px solid #001529" }}>
                    <Box fontWeight="600" fontSize="16px">
                        Shortcut
                    </Box>
                    <Box fontWeight="600" fontSize="16px">
                        Action
                    </Box>
                </Box>

                {userShortcuts.map((shortcut) => (
                    <ShortCutItem key={shortcut.Action} shortcut={shortcut} />
                ))}
            </Box>
        </Box>
    );
};

export default ShortCutsManualContainer;
