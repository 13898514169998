import React, { useContext, useEffect, useState } from "react";

import { Box } from "@material-ui/core";

import usePageInitialization from "../../../hooks/usePageInitialization";
import CompanySelectInput from "../../../components/supervision/companies/CompanySelectInput";
import CompaniesTableIconActions from "../../../components/supervision/companies/CompaniesTableIconActions";
import CompaniesTable from "../../../components/supervision/companies/CompaniesTable";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import companyService from "../../../services/operation/CompanyService";
import { observer } from "mobx-react-lite";

interface Props { }

const CompaniesContainer = (props: Props) => {

    const { companyStore, uiStore } = useContext(RootStoreContext);
    const [title, setTitle] = useState<string>(companyStore.SelectedCompany?.companyName ?? "");

   useEffect(() => {
        if (companyStore.SelectedCompany != undefined) {
            setTitle(companyStore.SelectedCompany.companyName)
            uiStore.SetPageTitle(`Algo Supervision - Accounts (${companyStore.SelectedCompany.companyName})`)
          
        }
    }, [companyStore.SelectedCompany]);

    
    usePageInitialization({
        onInitAsync: async () => {
            let companies = await companyService.GetCompaniesWithActiveCampaigns();
            companyStore.SetCompanies(companies)

        },
        pageTitle: `Algo Supervision - Accounts (${title})`
    })



    return (
        <div>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center">
            </Box>
            <CompaniesTable />
        </div>
    );
};

export default observer(CompaniesContainer);
