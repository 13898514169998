import { Empty } from "antd";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { RootStoreContext } from "../../stores/RootStoreContext";

import { observer } from "mobx-react-lite";
import SupervisionTable from "../general/supervision-table/SupervisionTable";
import { GroupResponse } from "../../models/groups/GroupResponse";
import { GroupStore } from "../../stores/GroupStore";
import { GroupTableDto } from "./GroupTable.dto";
import { GroupsActionBuilder } from "./GroupTable.actions"

interface Props {
    campaignId: number;
    groups: GroupResponse[];
}



const GroupsTable = (props: Props) => {

    const { groups, campaignId } = props;
    const { groupStore, uiStore, userStore } = useContext(RootStoreContext);
    const history = useHistory();


    if (groups.length == 0) {
        return (
            <Empty
                style={{ marginTop: "40px" }}
                description={`No Groups Records`}
            />
        );
    }

    return (
        <div>
            <SupervisionTable
                dataSource={groups.map(GroupTableDto
                    .MapFromGroup)}
                rowActionSettings={{
                    actions: GroupsActionBuilder({
                        history: history,
                        groupStore: groupStore,
                        campaignId: campaignId,
                        uiStore: uiStore,
                        userStore: userStore
                    }),
                    showActionsOnLastColumn: true,
                    showActionsOnRightClick: true,
                }}
            />
        </div>
    )


}



export default observer(GroupsTable);