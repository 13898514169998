import "./SupervisionPopUp.scss";

import { Button, Form, FormInstance, Modal, Select } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";


import { RootStoreContext } from "../../../stores/RootStoreContext";
import { ManualActionRequestSource } from "../../../models/manual-actions/ManualActionRequestSource";

type Props = {};
const { Option } = Select;

const SupervisionPopUp = (props: Props) => {
    const { uiStore } = useContext(RootStoreContext);

    const formRef = React.createRef<FormInstance>();

    const okClickHandler = () => {
        if (uiStore.PopUp != undefined && uiStore.PopUp.validationHandler) {
            uiStore.PopUp.validationHandler()
                .then((isValid) => {
                    console.log("Validation result: " + isValid);
                    if (isValid) {
                        popUpOkClickHandler();
                    }
                })
        }
        else {
            console.log("Without validation");
            popUpOkClickHandler();
        }
    };

    const popUpOkClickHandler = ()=>{
        if (uiStore.PopUp != undefined && uiStore.PopUp.okClickHandler) {
            uiStore.PopUp?.okClickHandler();
        }
        formRef.current?.resetFields();
        uiStore.ClearPopUp();
    }

    const cancelClickHandler = () => {
        if (
            uiStore.PopUp != undefined &&
            uiStore.PopUp?.cancelClickHandler != undefined
        ) {
            uiStore.PopUp.cancelClickHandler();
        }

        formRef.current?.resetFields();
        uiStore.ClearPopUp();
    };

    const handleSelectChange = (value: ManualActionRequestSource) => {
        if (uiStore.PopUp != undefined) {
            uiStore.PopUp.selectedValue = value
        }

    };

    const onRequired = () => {
        if (uiStore.PopUp?.hideSelectButton != undefined)
            return !uiStore.PopUp?.hideSelectButton;
        return false;
    }

    return (
        <div>
            <Modal
                visible={uiStore.PopUp != undefined}
                title={uiStore.PopUp?.title}
                onOk={okClickHandler}
                onCancel={cancelClickHandler}
                okText={uiStore.PopUp?.okButtonText}
                cancelText={uiStore.PopUp?.cancelButtonText}
                closable={uiStore.PopUp?.closable}
                footer={[
                    <Form ref={formRef}
                        key="form" onFinish={okClickHandler} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Form.Item
                            initialValue={ManualActionRequestSource.CampaignTeam}
                            name={"mySelector"}
                            hidden={uiStore.PopUp?.hideSelectButton ?? true}
                            rules={[{ required: onRequired(), message: 'Please select an option' }]}
                            style={{ marginRight: '8px', flex: 0.5 }}
                        >
                            <Select style={{ width: '100%' }} onChange={handleSelectChange}>
                                <Option value={ManualActionRequestSource.CampaignTeam}>
                                    Campaign Team
                                </Option>
                                <Option value={ManualActionRequestSource.ClientRequest}>
                                    Client Request
                                </Option>
                            </Select>
                        </Form.Item>
                        <div>
                            {!uiStore.PopUp?.hideCancelButton && (
                                <Button onClick={cancelClickHandler} key="cancel" style={{ marginRight: '8px' }}>
                                    {uiStore.PopUp?.cancelButtonText ?? 'Cancel'}
                                </Button>
                            )}
                            <Button type="primary" htmlType="submit" key="submit">
                                {uiStore.PopUp?.okButtonText ?? 'Ok'}
                            </Button>
                        </div>
                    </Form>

                ]}
            >
                {uiStore.PopUp?.body}
            </Modal>
        </div>
    );
};

export default observer(SupervisionPopUp);
