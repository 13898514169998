import "./UserInformation.scss";

import Avatar from "antd/lib/avatar/avatar";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import { UserOutlined } from "@ant-design/icons";
import { Box } from "@material-ui/core";

import { RootStoreContext } from "../../../../../stores/RootStoreContext";

type Props = {
    collapsed: boolean;
};

const UserInformation = (props: Props) => {
    const { collapsed } = props;
    const { userStore } = useContext(RootStoreContext);

    return (
        <Box
            className="avatar"
            display="flex"
            alignItems="center"
            mb={3}>
            <Avatar icon={<UserOutlined />} />

            <Box className="avatar-text" display={collapsed ? "none" : "block"}>
                <p>{userStore.User?.name}</p>
                <p
                    style={{
                        color: "#999",
                        fontSize: 10,
                    }}>
                    {userStore.User?.email}
                </p>
            </Box>
        </Box>
    );
};

export default observer(UserInformation);
