import { ISODateTime } from "../../types/types";
import { IndeedBudgetFirstMonthBehavior } from "./IndeedBudgetFirstMonthBehavior";

export class IndeedUpdateCampaignBudgetEventPayload {
    constructor(
        public indeedCampaignId: string,
        public userId: number,
        public startDate?: ISODateTime,
        public fixedEndDate?: ISODateTime,
        public targetEndDate?: ISODateTime,
        public budgetFirstMonthBehavior?: IndeedBudgetFirstMonthBehavior,
        public budgetOnetimeLimit?: number,
        public budgetMonthlyLimit?: number
    ) {}
}
