import { useContext, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import useOnInitAsync from "../../../hooks/useOnInitAsync";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { CampaignSettingsAuditResponse } from "../../../models/campaign-track-system/CampaignSettingsAuditResponse";
import { observer } from "mobx-react-lite";
import AuditTable from "../../../components/campaign-track-system/CampaignSettingAuditTable";
import { CampaignSettingContextInitializers } from "./helpers/CampaignSettingContextInitializers";
import { AutoComplete, Button, DatePicker, Empty, message, Space } from "antd";
import { BiPlusCircle } from "react-icons/bi";
import { RoutesPaths } from "../../../constants/RoutePaths";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import CampaignSettingBreadCrumbs from "./helpers/CampaignSetting.breadcrumbs";
import { ISODateTime } from "../../../types/types";
import { UserResponse } from "../../../models/users/UserResponse";
import { Moment } from "moment";
import SelectUserInput from "../../../components/general/inputs/SelectUserInput";
import Loader from "../../../components/general/loader/Loader";
import moment from "moment";
import campaignSettingAuditService from "../../../services/campaign-setting/CampaignSettingAuditService";
import UploadCsvFile from "../../../components/campaign-track-system/UploadCsvFile";


interface Props { }

type FilterSettings = {
    userId?: number;
    from?: ISODateTime;
    to?: ISODateTime;
    optionTypeId?: string;
    campaignId?: number;
};

const CampaignTrackAuditPage = (props: Props) => {
    const match = useParams<{ campaignId: string }>();
    const { campaignSettingStore } = useContext(RootStoreContext);
    const history = useHistory();

    const [filterSettings, setFilterSettings] = useState<FilterSettings>({ campaignId: Number(match.campaignId) });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [userInputValue, setUserInputValue] = useState<UserResponse | undefined>();

    const [typeValue, setTypeValue] = useState<string | undefined>();
    const campaignSettingTypes = campaignSettingStore.CampaignSettingTypes;

    usePageInitialization({

        onInitAsync: async () => {
            await CampaignSettingContextInitializers.InitializeCampaignAuditData(
                Number(match.campaignId),
                campaignSettingStore,
                history
            )

            await CampaignSettingContextInitializers.InitializeCampaignSettingTypes(
                campaignSettingStore,
                history
            )

            setIsLoading(false);
        },

        onCampaignContextCallBack: (campaign, uiStore) => {
            uiStore.SetPageTitle(
                `Campaign Audits for: ${campaign.campaignName}`
            );
            uiStore.AddBreadCrumbs(
                SupervisionBreadcrumbs.Campaigns(campaign.campaignName),
                CampaignSettingBreadCrumbs.CampaignSetting(campaign.campaignID),
            );
        },
    })


    if (!campaignSettingStore.CampaignSettingsAuditsResponse) {
        return <Loader position="normal" />;
    }


    const filterHandler = async () => {
        setIsLoading(true);
        await campaignSettingAuditService.GetFilteredCampaignSettingsAudits(filterSettings)
            .then(
                (audits) => {

                    campaignSettingStore.SetCampaignSettingsAudits(audits);
                }
            )

        setIsLoading(false);
        message.info("Filtered actions");
    };

    const initialDateValue = () => {
        let date: [Moment?, Moment?] = [undefined, undefined];

        if (filterSettings.from) {
            date[0] = moment(filterSettings.from);
        }
        if (filterSettings.to) {
            date[1] = moment(filterSettings.to);
        }
        return date;
    };

    if (isLoading) {
        return <Loader position="normal" />;
    }

    const typeOptions = campaignSettingTypes
        ?.filter((x) =>
            x.settingType
                .toLowerCase()

        )
        .map((x) => ({ value: x.settingType, id: x.id }));


    return (

        <div>
            <Space style={{ marginBottom: 10 }}>
                <SelectUserInput
                    defaultValue={userInputValue}
                    onChange={(user) => {
                        setUserInputValue(user);
                        setFilterSettings({
                            ...filterSettings,
                            userId: user?.id,
                        });
                    }}
                    onClear={() => {
                        setUserInputValue(undefined);
                        setFilterSettings({
                            ...filterSettings,
                            userId: undefined,
                        });
                    }}
                />
                <AutoComplete
                    style={{ width: 300 }}
                    options={typeOptions}
                    onSelect={(typeValue: string, selectedType: any) => {
                        setFilterSettings({
                            ...filterSettings,
                            optionTypeId: selectedType?.id,
                        });
                        setTypeValue(typeValue);
                    }}
                    allowClear
                    onClear={() => {
                        setFilterSettings({
                            ...filterSettings,
                            optionTypeId: undefined,
                        });
                        setTypeValue("");
                    }}
                    value={typeValue}
                    placeholder="Setting Type"
                />

                <DatePicker.RangePicker
                    onChange={(date) => {
                        const from = date?.[0];
                        const to = date?.[1];

                        setFilterSettings({
                            ...filterSettings,
                            from: from?.toISOString() || undefined,
                            to: to?.toISOString() || undefined,
                        });
                    }}
                    defaultValue={initialDateValue() as any}
                />
                <Button type="primary" onClick={filterHandler}>
                    Filter
                </Button>
            </Space>

            <AuditTable
                campaignId={Number(match.campaignId)}
                campaignAudits={campaignSettingStore.CampaignSettingsAuditsResponse}
            />

            <div style={{ width: 270 }}>
                <Link
                    to={RoutesPaths.Supervision.CampaignTrackSystemRoutes.BuildAddCampaignSettingAudit(Number(match.campaignId))
                    }>
                    <Button
                        type="primary"
                        shape="round"
                        size="large"
                        style={{ display: "flex", alignItems: "center" }}>
                        <BiPlusCircle
                            size={20}
                            style={{ marginRight: "10px" }}
                        />
                        <span> Add New Audit</span>
                    </Button>
                </Link>
            </div>
            <div style={{ margin: "15px 0" }}>
                <UploadCsvFile />
            </div>

        </div>
    )

};


export default observer(CampaignTrackAuditPage);