import "./SupervisionTable.scss";

import { Button, Input, InputRef, Space } from "antd";
import {
    FilterConfirmProps,
    FilterDropdownProps,
    Key
} from "antd/lib/table/interface";
import { makeObservable, observable } from "mobx";
import Highlighter from "react-highlight-words";

import { SearchOutlined } from "@ant-design/icons";

export class SearchColumn<T> {
    private _dataIndex: string;
    private _searchInput: InputRef | null = null;

    constructor(dataIndex: string) {
        makeObservable(this);
        this._dataIndex = dataIndex;
    }

    @observable
    public searchText: string = "";

    @observable
    public searchColumn: string = "";

    public handleSearch(
        selectedKeys: Key[],
        confirm: (param?: FilterConfirmProps | undefined) => void,
        dataIndex: string
    ) {
        confirm(); //triggers OnFilter() function in CreateColumn()
        this.searchText = selectedKeys[0] as string;
        this.searchColumn = dataIndex;
    }

    public handleReset(
        clearFilters: (() => void) | undefined,
        confirm: (param?: FilterConfirmProps | undefined) => void
    ) {
        clearFilters!();
        this.searchText = "";
        confirm();
    }

    public CreateColumn() {
        return {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }: FilterDropdownProps) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={(node) => {
                            this._searchInput = node;
                        }}
                        placeholder={`Search ${this._dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            )
                        }
                        onPressEnter={() =>
                            this.handleSearch(
                                selectedKeys,
                                confirm,
                                this._dataIndex
                            )
                        }
                        style={{ marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                this.handleSearch(
                                    selectedKeys,
                                    confirm,
                                    this._dataIndex
                                )
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}>
                            Search
                        </Button>
                        <Button
                            onClick={() =>
                                this.handleReset(clearFilters, confirm)
                            }
                            size="small"
                            style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined
                    style={{ color: filtered ? "#1890ff" : undefined }}
                />
            ),
            onFilter: (value: string, record: T) => {
                let recordKeyValue = (record as any)[this._dataIndex];
                return recordKeyValue
                    ? recordKeyValue
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    : false;
            },
            onFilterDropdownVisibleChange: (visible: boolean) => {
                if (visible && this._searchInput != null) {
                    setTimeout(() => this._searchInput!.select(), 100);
                }
            },
            render: (text: any, record: T) => {
                if (this.searchColumn == this._dataIndex) {
                    return (
                        <Highlighter
                            highlightStyle={{
                                backgroundColor: "#ffc069",
                                padding: 0,
                            }}
                            searchWords={[this.searchText]}
                            autoEscape
                            textToHighlight={text ? text.toString() : ""}
                        />
                    );
                }
                return text;
            },
        };
    }
}
