import { action, makeObservable, observable } from "mobx";
import { JobState } from "../models/explainability/JobState";
import explainabilityService from "../services/explainability/ExplainabilityService";
import { Company } from "../models/operation/Company";
import { Vendor } from "../models/explainability/Vendor";
import companyService from "../services/operation/CompanyService";
import LocalStorageService from "../services/LocalStorageService";
import { LocalStorageKeys } from "../constants/LocalStorageKeys";
import { Job } from "../models/operation/Job";
import { JobSerachingType } from "../models/explainability/JobSerachingType";
import { JobBase } from "../models/explainability/JobBase";



export class ExplainabilityStore {

    /**
    * Props
    */
     @observable
     public SearchedJobs: number[] = [];

     @observable
     public AllJobs: Job[] = [];

     @observable
     public JobsState: JobBase[] = [];
  
     @observable
     public SelectedCompany?: Company;

     @observable
     public SearchedVendors: Vendor[] = [];

     @observable
     public AllVendors: Vendor[] = [];

     @observable
     public SelectedJobSerachingType: JobSerachingType = JobSerachingType.JobState;
   


    constructor()
    {
        const company = LocalStorageService.GetItemObject<Company>(LocalStorageKeys.SearchedCompanyExplainability)
        if (company != undefined) {
            this.SelectedCompany = company;
        }
        makeObservable(this);
    }

    @action
    public async SetSearchedJobsIds(ids: number[]) {
       // const jobs = await explainabilityService.getJobStateByIds({ ids });
        this.SearchedJobs = ids;
    }

    @action
    public async SetSearchedVendors(vendorsIds: number[]) {
        this.SearchedVendors = this.AllVendors.filter(vendor => vendorsIds.includes(vendor.vendor_id))
        // LocalStorageService.SetItemObject(LocalStorageKeys.SearchedVendors, this.SearchedVendors);
    }

    @action
    public async SetSearchedCompany(id: number) {
        const company = await companyService.GetCompanyById(id);
        this.SelectedCompany = company;
        LocalStorageService.SetItemObject(LocalStorageKeys.SearchedCompanyExplainability, company);
    }


}