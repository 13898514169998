import { Action } from "../../models/users/Action";
import { ActionResponse } from "../../models/users/ActionResponse";
import { ActionType } from "../../models/users/ActionType";
import { ISODateTime } from "../../types/types";
import { BaseService } from "../BaseService";

class ActionTypeService extends BaseService {
    public async GetAll(): Promise<ActionType[]> {
       
        return await this.Get<ActionType[]>("/");
    }
}


export const actionTypeService = new ActionTypeService("userActionTypes");