import { makeStyles } from "@material-ui/core";
// Material UI

export const useStyles = makeStyles((theme) => ({
    activeVendor: {
        backgroundColor: "rgba(67, 160, 71, 0.2)",
        borderBottom: '1px solid #f0f0f0',
    },
    inActiveVendor: {
        backgroundColor: "rgba(229, 57, 53, 0.2)",
        borderBottom: '1px solid #f0f0f0',
    },
    avatarBackground: {
        backgroundColor: "#fff",
    },
    vendorImg: {
        objectFit: "contain",
        width: "100%",
        height: "100%",
    },
}));
