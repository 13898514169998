import settings from "../../config/AppSettings/SettingsFactory";
import { Notification } from "../../models/notifications/Notification";
import { BaseService } from "../BaseService";

class NotificationService extends BaseService {

    /**
     * Gets all notifications for the user. Uses the JWT to determine the User ID
     */
    public async GetAll(): Promise<Notification[]> {
        const notifications = await this.Get<Notification[]>(`/`);
        return notifications;
    }

    
    public async Update(notificationId: string, updateNotificationRequest: Omit<Notification, "createdAt" | "id">) {
        updateNotificationRequest.seenAt = new Date().toISOString();
        const updatedNotification = await this.Put(`/${notificationId}`, updateNotificationRequest);
        return updatedNotification;
    }
}


export default new NotificationService("notifications", settings.notificationServerUrl);