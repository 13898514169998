import { Empty } from "antd";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Affiliate } from "../../../models/operation/Affiliate";
import { Company } from "../../../models/operation/Company";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { DOMCoordinates } from "../../../types/types";
import Loader from "../../general/loader/Loader";
import SupervisionTable from "../../general/supervision-table/SupervisionTable";
import { CompaniesTableActionsBuilder } from "../companies/CompaniesTable.actions";
import { CompanyTableDto, CompanyTableDtoExpand } from "../companies/CompanyTable.dto";
import { AffiliatesTableActionsBuilder } from "./AffiliatesTable.action";
import { AffiliateTableDto } from "./AffiliateTable.dto";

export type RightClickData = {
    clickCoordinates: DOMCoordinates;
    clickedCompany: AffiliateTableDto;
    rowIndex: number;
};

interface Props { }

const AffiliatesTable = (props: Props) => {

    const history = useHistory();

    const { affiliateStore } = useContext(RootStoreContext);

    const [affiliates, setAffiliates] = useState<Affiliate[] | undefined>(undefined);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([affiliateStore.SelectedAffiliate?.affiliateId]);
    const [companies, setCompanies] = useState<Company[] | undefined>(
        undefined
    );

    useEffect(() => {
        SortAffiliates();
        setAffiliates(affiliateStore.allAffiliates);
        var companiesFlat = affiliateStore.allAffiliates?.flatMap(affiliates => affiliates.companies);
        setCompanies(companiesFlat);

    }, [affiliateStore.allAffiliates])

    const SortAffiliates = () => {
        affiliateStore.allAffiliates = affiliateStore.allAffiliates.sort((a, b) => {
            if (a.affiliateId == affiliateStore.SelectedAffiliate?.affiliateId) {
                return -1;
            } else if (b.affiliateId == affiliateStore.SelectedAffiliate?.affiliateId) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    if (affiliates == undefined) {
        return <Loader position="pad-top" />;
    }

    if (affiliates.length == 0) {
        return (
            <Empty
                style={{ marginTop: "40px" }}
                description="Please select campaigns to show"
            />
        );
    }

    const rowSelection = {
        selectedRowKeys,
        hideSelectAll: true,
        renderCell: (originNode: any, record: any) => {
            return <a onClick={() => rowSelection.onSelect(record)}>Select</a>;
        },
        onSelect: (record: any) => {
            const affiliate = affiliates.find(c => c.affiliateId == record.affiliateId);

            if (affiliate != undefined) {
                affiliateStore.SetSelectedAffiliate(affiliate);
            }
            setSelectedRowKeys([record.key]);
        }
    };

    return (
        <SupervisionTable
            dataSource={affiliates.map((aff) => new AffiliateTableDto(aff))}
            rowActionSettings={{
                actions: AffiliatesTableActionsBuilder({
                    history: history
                }),
                showActionsOnRightClick: true,
                showActionsOnLastColumn: true,
            }}
            expandableDataSource={companies?.map((c) => new CompanyTableDtoExpand(c))}
            tableRowSelection={rowSelection}
            isExpendable={true}
            expandableActionSettings={{
                actions: CompaniesTableActionsBuilder({ history: history }),
                showActionsOnRightClick: true,
                showActionsOnLastColumn: true,
            }} />
    );

}

export default observer(AffiliatesTable);