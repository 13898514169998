import { AutoComplete } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import useOnInitAsync from "../../../hooks/useOnInitAsync";
import { UserResponse } from "../../../models/users/UserResponse";
import { userService } from "../../../services/users/UserService";
import Loader from "../loader/Loader";

type Props = {
    defaultValue?: UserResponse;
    onChange?: (user: UserResponse | undefined) => void;
    onClear?: () => void;
};

const SelectUserInput = (props: Props) => {
    const { defaultValue, onChange, onClear } = props;

    const [users, setUsers] = useState<UserResponse[]>([]);
    const [usersFiltered, setUsersFiltered] = useState<UserResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useOnInitAsync(async () => {
        const _users = await userService.GetAll();
        setUsers(_users);
        setUsersFiltered(_users);

        setIsLoading(false);
    });

    if (isLoading) {
        return <Loader position="normal" />;
    }

    const initialValue = () => {
        if (!defaultValue) return "";

        return displayValue(defaultValue);
    };

    const displayValue = (user: UserResponse) => `${user.name} (${user.id})`;

    return (
        <div>
            <AutoComplete
                options={usersFiltered.map((user) => ({
                    value: displayValue(user),
                }))}
                style={{ width: 300 }}
                defaultValue={initialValue()}
                onSelect={(value: string) => {
                    const match = value.match(/\d+/gi);
                    if (match == null) return;

                    const userId = Number(match[0]);
                    const selectedUser = users.find((x) => x.id == userId);

                    onChange && onChange(selectedUser);
                }}
                onSearch={(value) => {
                    if (value == undefined || value == "") {
                        setUsersFiltered(users);
                    }

                    setUsersFiltered(
                        users.filter((x) =>
                            x.name.toLowerCase().includes(value.toLowerCase())
                        )
                    );
                }}
                allowClear
                onClear={() => {
                    onClear && onClear();
                }}
                placeholder="User Name"
            />
        </div>
    );
};

export default observer(SelectUserInput);
