import { createTheme } from '@material-ui/core/styles'

export const MuiLightTheme = createTheme({
    // palette: {
    //     background: {
    //         default: "#F8F8F8"
    //     },
    //     primary: {
    //         main: "#225a78"
    //     },
    //     secondary: {
    //         main: "#77992e"
    //     }
    // },
    // typography: {
    //     fontFamily: "Open Sans"
    // },
    spacing: 5
});