import { Empty } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import useOnInitAsync from "../../hooks/useOnInitAsync";
import { GetJobsByIdApiDto } from "../../models/operation/GetJobsByIdApiDto";
import { Job } from "../../models/operation/Job";
import jobService from "../../services/operation/JobService";
import Loader from "../general/loader/Loader";
import SupervisionTable from "../general/supervision-table/SupervisionTable";
import { IndeedJobTableDto } from "./IndeedJobsTable.dto";

interface Props {
    indeedCampaignName: string;
    jobs: number[];
}

const IndeedJobsTable = (props: Props) => {
    const { indeedCampaignName, jobs } = props;

    const [jobsWithPLDataEnriched, setJobsWithPLDataEnriched] = useState<Job[]>(
        []
    );

    useOnInitAsync(async () => {
        if (jobs) {
            const getJobsByIdRequest = new GetJobsByIdApiDto(jobs);
            const plJobs = await jobService.GetJobsByIds(getJobsByIdRequest);
            setJobsWithPLDataEnriched(plJobs);
        }
    });

    if (jobs.length == 0) {
        return (
            <Empty
                style={{ marginTop: "40px" }}
                description={`No jobs attached to campaign: ${indeedCampaignName}`}
            />
        );
    }

    if (jobsWithPLDataEnriched.length == 0) {
        return <Loader position="normal" />;
    }

    return (
        <SupervisionTable
            dataSource={jobsWithPLDataEnriched.map(
                IndeedJobTableDto.MapFromJob
            )}
        />
    );
};

export default observer(IndeedJobsTable);
