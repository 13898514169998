import "./SupervisionTable.scss";

import { Divider, Radio, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { BuildColumns, PopulateColumnActions } from "./ColumnsBuilder";
import RightClickDropdown from "./RightClickDropdown";
import TableFooter from "./TableFooter";
import { CategoricalColumns, RightClickData, RowActionSettings } from "./types";
import { TableRowSelection } from "antd/lib/table/interface";
import { TableDto } from "./Table.dto";

interface Props<T, Y> {
    dataSource: T[];
    rowActionSettings?: RowActionSettings<T>;
    categoricalColumns?: CategoricalColumns<T>[];
    tableRowSelection?: TableRowSelection<T>;
    expandableDataSource?: Y[];
    isExpendable?: boolean;
    isShowSizeChanger?: boolean;
    expandableActionSettings?: RowActionSettings<Y>;
    isChild?: boolean;
}

const SupervisionTable = <T extends TableDto, Y extends TableDto>(props: Props<T, Y>) => {
    const { dataSource, rowActionSettings, categoricalColumns,
        tableRowSelection, expandableDataSource, expandableActionSettings,
         isExpendable, isChild ,isShowSizeChanger} = props;
    
    var expandableDataSources = new Array<Y>();
    if(expandableDataSource != undefined)
    {
        expandableDataSources = expandableDataSource;
    }

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const [rightClickData, setRightClickData] = useState<
        RightClickData<T> | undefined
    >();
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    
    let tableColumns = BuildColumns(
        dataSource,
        rowActionSettings,
        categoricalColumns
    );

    return (
        <>
            {rowActionSettings && rowActionSettings.showActionsOnRightClick && (
                <RightClickDropdown
                    dropDownMenu={rowActionSettings.actions}
                    rightClickData={rightClickData}
                />
            )}
            <Table
                rowSelection={tableRowSelection}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => {
                            setRightClickData(undefined);
                        },
                        onContextMenu: (event) => {
                            event.preventDefault();
                            setRightClickData({
                                clickCoordinates: {
                                    xPosition: event.clientX,
                                    yPosition: event.clientY,
                                },
                                rowIndex: rowIndex ?? -1, // Should never get to -1
                                clickedRowData: record,
                            });
                        },
                    };
                }}
                rowClassName={(record, index) => {
                    if (index == rightClickData?.rowIndex) {
                        return "table-row-right-clicked";
                    }
                    return "";
                }}
                size= {isChild ? "small" : "middle"}
                columns={tableColumns.map((tableColumn) =>
                    PopulateColumnActions(tableColumn, categoricalColumns)
                )}
                dataSource={dataSource.map((data, i) => ({ ...data, key: data._id != undefined ? data._id : i }))}
                expandable={{
                    expandedRowRender: (record) =>  <SupervisionTable
                                                        dataSource={expandableDataSources.filter((x) => x._id == record.key)}
                                                        rowActionSettings={expandableActionSettings}
                                                        isChild={true}
                                                    />,
                    rowExpandable: (record) => isExpendable ?? false,
                }}
                pagination={ isChild ? false : {
                   
                    showSizeChanger: isShowSizeChanger,
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    // console.log("params", pagination, filters, sorter, extra);
                }}
                summary={(pageData) => (
                    <TableFooter
                        pageData={pageData}
                        dataSource={dataSource}
                        tableColumns={tableColumns}
                    />
                )}
            />
        </>
    );
};

export default observer(SupervisionTable);
