import { Button, Col, Form, Row, Typography, message } from "antd";
import AlgoModes from "../../../components/attributes-management/AlgoModes";
import usePageInitialization from "../../../hooks/usePageInitialization";
import "./CompanyAttributesPage.scss";
import { useHistory, useParams } from "react-router-dom";
import { AttributesManagmentContextInitializers } from "./heplers/AttributesManagmentContextInitializers";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { useContext, useEffect } from "react";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import AttributesManagmentBreadCrumbs from "./heplers/AttributesManagmentBreadCrumbs";
import { observer } from "mobx-react-lite";
import companyAttributesService from "../../../services/attributes-management/CompanyAttributesService";
import CampaignRules from "../../../components/attributes-management/CampaignRules";
import Comments from "../../../components/attributes-management/Comments";
import companyPreferenceService from "../../../services/attributes-management/CompanyPreference";
import { FetchError } from "../../../exceptions/AlgoSupervisionExceptions";
import styled from "styled-components";
import companyService from "../../../services/operation/CompanyService";
import { UpdateReasonPage } from "./heplers/UpdateReasonPage";


const StyledContainer = styled.div`
width: 80%;
height: 100%;
align-items: flex-start;
gap: 24px;
display: flex; 
`;

const StyledComponent = styled.div`
  flex: 1; // This will make both components share the available space equally
`;


const CompanyAttributesPage = () => {

  const match = useParams<{ companyId: string }>();
  const { attributesManagementStore, userStore, uiStore } = useContext(RootStoreContext);
  const { CompanyProfile } = attributesManagementStore;
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    if(CompanyProfile != undefined)
    {
      const fetchData = async () => {
        const company = await companyService.GetCompanyById(
          Number(match.companyId)
        );
        uiStore.SetPageTitle(
          `Company Attributes for: ${company.companyName} - ${CompanyProfile.displayLabel}`
        );
      }
      fetchData();
    }
}, [CompanyProfile]);


  usePageInitialization({
    onInitAsync: async () => {
      await AttributesManagmentContextInitializers.InitializeAttributesManagementData(
        Number(match.companyId),
        attributesManagementStore,
        history
      )
      await AttributesManagmentContextInitializers.InitializeCompanyPreferenceData(
        Number(match.companyId),
        attributesManagementStore,
        history
      )
      await AttributesManagmentContextInitializers.InitializeCompanyProfileData(
        Number(match.companyId),
        attributesManagementStore,
        history
      )
      await AttributesManagmentContextInitializers.InitializeCustomerRequestData(
        Number(match.companyId),
        attributesManagementStore,
        history
      )
    },

    onCompanyContextCallBack: (company, uiStore) => {

      uiStore.AddBreadCrumbs(
        SupervisionBreadcrumbs.Companies(company.companyName),
        AttributesManagmentBreadCrumbs.CompanyAttributes(company.companyId)
      );
    },
  });

  
  const onApply = async () => {
    try {
      let companysAttributesToUpdate = attributesManagementStore.getEditedAttributes(userStore.User?.id);
      await companyAttributesService.setCompanysAttributes(companysAttributesToUpdate);

      let companyPreference = attributesManagementStore.getCompanyPreference();
      companyPreference.userId = userStore.User?.id;
      await companyPreferenceService.setCompanyPreference(companyPreference);

      attributesManagementStore.resetIsEdited();

      message.success("Company Attributes updated successfully");
    }
    catch (error) {
      message.error("Company Attributes updated failed");
      console.log(error);
    }

  }

  return (
    <>
      <StyledContainer>
        <StyledComponent>
          <CampaignRules />
        </StyledComponent>
        <StyledComponent>
          <AlgoModes />
        </StyledComponent>
      </StyledContainer>
      <Button
        onClick={onApply}
        type="primary"
        size="large"
        style={{marginTop : 10}}>
        <span>Apply Changes</span>
      </Button>
    </>

  )
}


export default observer(CompanyAttributesPage)