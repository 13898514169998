
import { RoutesPaths } from "../../../../constants/RoutePaths";
import { BreadCrumb } from "../../../../models/BreadCrumb";

export const GroupsBreadCrumbs = {

    Groups: (campaignId: number) => {
        return new BreadCrumb(
            "Groups",
            RoutesPaths.Supervision.GroupsRoutes.BuildGroupRoute(campaignId),
            null
        );
    },

    GroupName: (groupName: string) => {
        return new BreadCrumb(groupName, null, null);
    },

    GroupJobs: (campaignId: number, groupId: number) => {
        return new BreadCrumb(
            "Jobs",
            RoutesPaths.Supervision.GroupsRoutes.BuildGroupJobsRoute(
                campaignId,
                groupId
            ),
            null
        );
    },

    AddGroup: (campaignId: number) => {
        return new BreadCrumb(
            "Add Group",
            RoutesPaths.Supervision.GroupsRoutes.BuildAddGroup(campaignId),
            null
        );
    },

    EditGroup: (campaignId: number, groupId: number) => {
        return new BreadCrumb(
            "Edit Group",
            RoutesPaths.Supervision.GroupsRoutes.BuildEditGroup(campaignId, groupId),
            null
        );
    },

    AttachJobs: (campaignId: number, groupId: number) => {
        return new BreadCrumb(
            "Attach Jobs",
            RoutesPaths.Supervision.GroupsRoutes.BuildAttachJobsToGroup(campaignId, groupId),
            null
        );
    },
}


export default GroupsBreadCrumbs;