import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import AuditCampaignForm from "../../../components/campaign-track-system/AuditCampaignForm";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import CampaignSettingBreadCrumbs from "./helpers/CampaignSetting.breadcrumbs";
import { CampaignSettingContextInitializers } from "./helpers/CampaignSettingContextInitializers";


const CampaignTrackEditAuditPage = () => {

    const { campaignSettingStore } = useContext(RootStoreContext);
    const match = useParams<{ campaignId: string; auditId: string }>();
    const history = useHistory();

    usePageInitialization({

        onInitAsync: async () => {
            await CampaignSettingContextInitializers.InitializeCampaignAuditData(
                Number(match.campaignId),
                campaignSettingStore,
                history
            );

            await CampaignSettingContextInitializers.InitializeCampaignSettingTypes(
                campaignSettingStore,
                history
            )

            await CampaignSettingContextInitializers.InitializeSelectedCampaignSettingAudit(
                match.auditId,
                campaignSettingStore,
                history).then(async () =>
                    await campaignSettingStore.SetupdateOperationState(true)
                );
        },

        onCampaignContextCallBack: (campaign, uiStore) => {
            uiStore.SetPageTitle(
                `Campaign Audits for: ${campaign.campaignName}`
            );
            uiStore.AddBreadCrumbs(
                SupervisionBreadcrumbs.Campaigns(campaign.campaignName),
                CampaignSettingBreadCrumbs.CampaignSetting(campaign.campaignID),
                CampaignSettingBreadCrumbs.EditCampaignSettingAudit(
                    campaign.campaignID,
                    match.auditId
                )
            );
        },
    })

    return (
        <div>
            <AuditCampaignForm
                campaignAuditId={match.auditId}
                campaignId={Number(match.campaignId)}
            />
        </div>)

}

export default observer(CampaignTrackEditAuditPage);