import React from "react";
import { Route, Switch } from "react-router-dom";

import PrivateRoute from "../hoc/PrivateRoute";
import useSetCurrentRoute from "../hooks/useSetCurrentRoute";
import { routes } from "./routes";

interface RouteWrapperProps {
    children: React.ReactNode;
}
const RouteWrapper = (props: RouteWrapperProps) => {
    useSetCurrentRoute();

    return <>{props.children}</>;
};


interface RouterComponentProps {}
const RouterComponent = (props: RouterComponentProps) => {
    return (
        <Switch>
            {routes
                .sort((a, b) => b.routePath.length - a.routePath.length) // otherwise route params will match concrete routes
                .map((route) => (
                    <PrivateRoute exact key={route.id} path={route.routePath}>
                        {route.isAutoRouteSetup ? (
                            <RouteWrapper>{route.renderComponent}</RouteWrapper>
                        ) : (
                            route.renderComponent
                        )}
                    </PrivateRoute>
                ))}
        </Switch>
    );
};

export default RouterComponent;
