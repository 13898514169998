import { Button, Form, message, Select } from "antd";
import moment, { Moment } from "moment";
import { useContext } from "react";
import { useHistory } from "react-router";

import { Box } from "@material-ui/core";

import { RoutesPaths } from "../../constants/RoutePaths";
import { FetchError } from "../../exceptions/AlgoSupervisionExceptions";
import { IndeedBudgetFirstMonthBehavior } from "../../models/indeed-manager/IndeedBudgetFirstMonthBehavior";
import { IndeedCampaign } from "../../models/indeed-manager/IndeedCampaign";
import { IndeedCampaignBudgetType } from "../../models/indeed-manager/IndeedCampaignBudgetType";
import { IndeedCampaignObjective } from "../../models/indeed-manager/IndeedCampaignObjective";
import {
  INDEED_CAMPAIGN_OBJECTIVE_DEFAULT_VALUE,
  IndeedCampaignObjectiveType
} from "../../models/indeed-manager/IndeedCampaignObjectiveType";
import { IndeedCampaignStatus } from "../../models/indeed-manager/IndeedCampaignStatus";
import { IndeedEndDateType } from "../../models/indeed-manager/IndeedEndDateType";
import { IndeedUpdateCampaignBudgetEventPayload } from "../../models/indeed-manager/IndeedUpdateCampaignBudgetEventPayload";
import { IndeedUpdateCampaignEventPayload } from "../../models/indeed-manager/IndeedUpdateCampaignEventPayload";
import indeedService from "../../services/indeed/IndeedService";
import { RootStoreContext } from "../../stores/RootStoreContext";
import CampaignBudgetInputs from "./CampaignBudgetInputs";
import CampaignDetailsInputs from "./CampaignDetailsInputs";

const { Option } = Select;

interface Props {
    plCampaignId: number;
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

type FormState = {
    campaignName: string;
    campaignStatus: IndeedCampaignStatus;
    budgetType: IndeedEndDateType;
    startDate: Moment;
    endDate: Moment;
    budgetLimitSettings: IndeedCampaignBudgetType;
    budgetLimitValue: string;
    budgetFirstMonthBehavior: IndeedBudgetFirstMonthBehavior | undefined;
    campaignObjective: IndeedCampaignObjective;
};

interface Props {
    plCampaignId: number;
    indeedCampaign: IndeedCampaign;
}

const IndeedEditCampaignForm = (props: Props) => {
    const { plCampaignId, indeedCampaign } = props;
    const { userStore } = useContext(RootStoreContext);
    const history = useHistory();

    const onFinish = async (formState: FormState) => {
        const changedBudgetSettings = GetChangedBudgetSettings(
            formState,
            initialValues
        );

        const changedCampaignSettings = GetChangedCampaignSettings(
            formState,
            initialValues
        );

        if (
            IsEmptyObject(changedCampaignSettings) &&
            IsEmptyObject(changedBudgetSettings)
        ) {
            message.info("Nothing changed in campaign settings.");
            return;
        }

        let hasErrorOccured = false;

        if (!IsEmptyObject(changedBudgetSettings)) {
            const newBudgetLimits = GetNewBudgetLimits(
                changedBudgetSettings,
                initialValues
            );

            const endDates = GetEndDates(changedBudgetSettings, initialValues);

            const updateBudgetRequest =
                new IndeedUpdateCampaignBudgetEventPayload(
                    indeedCampaign.id,
                    userStore.User!.id,
                    changedBudgetSettings.startDate?.toISOString(),
                    endDates.fixedEndDate,
                    endDates.targetEndDate,
                    changedBudgetSettings.budgetFirstMonthBehavior,
                    newBudgetLimits.budgetOneTimeLimit,
                    newBudgetLimits.budgetMonthlyLimit
                );

            try {
                var response = await indeedService.UpdateCampaignBudget(updateBudgetRequest);
                message.success(response.body);
            } catch (error) {
                if (error instanceof FetchError) {
                    message.error(error.apiError.errorMessage);
                }
                hasErrorOccured = true;
            }
        }

        if (!IsEmptyObject(changedCampaignSettings)) {
            const updateCampaignRequest = new IndeedUpdateCampaignEventPayload(
                indeedCampaign.id,
                userStore.User!.id,
                changedCampaignSettings.name,
                changedCampaignSettings.status,
                changedCampaignSettings.campaignObjective
            );
            try {
                var response = await indeedService.UpdateCampaign(updateCampaignRequest);
                message.success(response.body);
            } catch (error) {
                if (error instanceof FetchError) {
                    message.error(error.apiError.errorMessage);
                }
                hasErrorOccured = true;
            }
        }

        if (!hasErrorOccured) {
            history.push(
                RoutesPaths.Supervision.IndeedRoutes.BuildCampaignRoute(
                    plCampaignId
                )
            );
        }
    };

    const initialValues: FormState = {
        campaignName: indeedCampaign.name,
        campaignStatus: indeedCampaign.status,
        budgetType: indeedCampaign.endDateType,
        startDate: moment(indeedCampaign.startDate),
        endDate: moment(indeedCampaign.endDate),
        budgetLimitSettings: indeedCampaign.campaignBudgetType,
        budgetLimitValue: indeedCampaign.campaignBudget?.toString() ?? "0",
        budgetFirstMonthBehavior: indeedCampaign.budgetFirstMonthBehavior,
        campaignObjective: {
            type:
                indeedCampaign.campaignObjectiveType ||
                INDEED_CAMPAIGN_OBJECTIVE_DEFAULT_VALUE,
            target: indeedCampaign.campaignObjectiveTarget,
        },
    };

    return (
        <Box width={650}>
            <Form
                initialValues={initialValues}
                {...layout}
                name="Edit Campaign"
                onFinish={onFinish}
                validateMessages={validateMessages}>
                <CampaignDetailsInputs
                    isStatusEditable={true}
                    isCampaignObjectiveTypeEditable={false}
                    campaignObjectiveTypeDefaultValue={
                        indeedCampaign.campaignObjectiveType ||
                        INDEED_CAMPAIGN_OBJECTIVE_DEFAULT_VALUE
                    }
                />

                <CampaignBudgetInputs
                    budgetLimitTypeInitialValue={
                        indeedCampaign.campaignBudgetType
                    }
                    isStartDateEditable={false}
                />

                <Form.Item style={{ marginTop: 50 }}>
                    <Button type="primary" htmlType="submit">
                        Edit Campaign
                    </Button>
                </Form.Item>
            </Form>
        </Box>
    );
};

export default IndeedEditCampaignForm;

type ChangedBudgetSettings = {
    budgetType?: IndeedEndDateType;
    startDate?: Moment;
    endDate?: Moment;
    budgetLimitSettings?: IndeedCampaignBudgetType;
    budgetLimitValue?: string;
    budgetFirstMonthBehavior?: IndeedBudgetFirstMonthBehavior;
};

function GetChangedBudgetSettings(
    newFormState: FormState,
    oldFormState: FormState
): ChangedBudgetSettings {
    const changedBudgetSettings: ChangedBudgetSettings = {};

    if (oldFormState.budgetType != newFormState.budgetType) {
        changedBudgetSettings["budgetType"] = newFormState.budgetType;
    }

    if (!oldFormState.startDate.isSame(newFormState.startDate)) {
        changedBudgetSettings["startDate"] = newFormState.startDate;
    }

    if (!oldFormState.endDate.isSame(newFormState.endDate)) {
        changedBudgetSettings["endDate"] = newFormState.endDate;
    }

    if (oldFormState.budgetLimitSettings != newFormState.budgetLimitSettings) {
        changedBudgetSettings["budgetLimitSettings"] =
            newFormState.budgetLimitSettings;
    }

    if (oldFormState.budgetLimitValue != newFormState.budgetLimitValue) {
        changedBudgetSettings["budgetLimitValue"] =
            newFormState.budgetLimitValue;
    }

    if (
        oldFormState.budgetFirstMonthBehavior !=
        newFormState.budgetFirstMonthBehavior
    ) {
        changedBudgetSettings["budgetFirstMonthBehavior"] =
            newFormState.budgetFirstMonthBehavior;
    }

    return changedBudgetSettings;
}

type CampaignChangedSettings = {
    name?: string;
    status?: IndeedCampaignStatus;
    campaignObjective?: IndeedCampaignObjective;
};

function GetChangedCampaignSettings(
    newFormState: FormState,
    oldFormState: FormState
): CampaignChangedSettings {
    const changedCampaignAttributes: CampaignChangedSettings = {};

    if (oldFormState.campaignName != newFormState.campaignName) {
        changedCampaignAttributes["name"] = newFormState.campaignName;
    }

    if (oldFormState.campaignStatus != newFormState.campaignStatus) {
        changedCampaignAttributes["status"] = newFormState.campaignStatus;
    }

    const changedCampaignObjective = GetChangedCampaignObjective(
        newFormState,
        oldFormState
    );
    if (changedCampaignObjective) {
        changedCampaignAttributes.campaignObjective = changedCampaignObjective;
    }

    return changedCampaignAttributes;
}

function GetNewBudgetLimits(
    changedBudgetSettings: ChangedBudgetSettings,
    initialFormState: FormState
): {
    budgetOneTimeLimit?: number;
    budgetMonthlyLimit?: number;
} {
    // if both settings and value changed
    if (
        changedBudgetSettings.budgetLimitSettings &&
        changedBudgetSettings.budgetLimitValue
    ) {
        if (
            changedBudgetSettings.budgetLimitSettings ==
            IndeedCampaignBudgetType.Monthly
        ) {
            return {
                budgetMonthlyLimit: Number(
                    changedBudgetSettings.budgetLimitValue
                ),
            };
        } else if (
            changedBudgetSettings.budgetLimitSettings ==
            IndeedCampaignBudgetType.OneTime
        ) {
            return {
                budgetOneTimeLimit: Number(
                    changedBudgetSettings.budgetLimitValue
                ),
            };
        }
    }

    // if only settings changed
    else if (
        changedBudgetSettings.budgetLimitSettings &&
        changedBudgetSettings.budgetLimitValue == undefined
    ) {
        if (
            changedBudgetSettings.budgetLimitSettings ==
            IndeedCampaignBudgetType.Monthly
        ) {
            return {
                budgetMonthlyLimit: Number(initialFormState.budgetLimitValue),
            };
        } else if (
            changedBudgetSettings.budgetLimitSettings ==
            IndeedCampaignBudgetType.OneTime
        ) {
            return {
                budgetOneTimeLimit: Number(initialFormState.budgetLimitValue),
            };
        }
    }

    // if only value changed
    else if (
        changedBudgetSettings.budgetLimitSettings == undefined &&
        changedBudgetSettings.budgetLimitValue
    ) {
        if (
            initialFormState.budgetLimitSettings ==
            IndeedCampaignBudgetType.Monthly
        ) {
            return {
                budgetMonthlyLimit: Number(
                    changedBudgetSettings.budgetLimitValue
                ),
            };
        } else if (
            initialFormState.budgetLimitSettings ==
            IndeedCampaignBudgetType.OneTime
        ) {
            return {
                budgetOneTimeLimit: Number(
                    changedBudgetSettings.budgetLimitValue
                ),
            };
        }
    }

    return {};
}

function GetEndDates(
    changedBudgetSettings: ChangedBudgetSettings,
    initialFormState: FormState
): {
    fixedEndDate?: string;
    targetEndDate?: string;
} {
    // if both budget type and end date changed
    if (changedBudgetSettings.budgetType && changedBudgetSettings.endDate) {
        if (changedBudgetSettings.budgetType == IndeedEndDateType.Fixed) {
            return {
                fixedEndDate: changedBudgetSettings.endDate.toISOString(),
            };
        } else if (
            changedBudgetSettings.budgetType == IndeedEndDateType.Target
        ) {
            return {
                targetEndDate: changedBudgetSettings.endDate.toISOString(),
            };
        }
    }

    // if only budgetType changed
    else if (
        changedBudgetSettings.budgetType &&
        changedBudgetSettings.endDate == undefined
    ) {
        if (changedBudgetSettings.budgetType == IndeedEndDateType.Fixed) {
            return {
                fixedEndDate: initialFormState.endDate.toISOString(),
            };
        } else if (
            changedBudgetSettings.budgetType == IndeedEndDateType.Target
        ) {
            return {
                targetEndDate: initialFormState.endDate.toISOString(),
            };
        }
    }

    // if only end date changed
    else if (
        changedBudgetSettings.budgetType == undefined &&
        changedBudgetSettings.endDate
    ) {
        if (initialFormState.budgetType == IndeedEndDateType.Fixed) {
            return {
                fixedEndDate: changedBudgetSettings.endDate.toISOString(),
            };
        } else if (initialFormState.budgetType == IndeedEndDateType.Target) {
            return {
                targetEndDate: changedBudgetSettings.endDate.toISOString(),
            };
        }
    }

    return {};
}

function GetChangedCampaignObjective(
    newFormState: FormState,
    oldFormState: FormState
): IndeedCampaignObjective | undefined {
    if (
        oldFormState.campaignObjective.type ==
            IndeedCampaignObjectiveType.BALANCE ||
        oldFormState.campaignObjective.type ==
            IndeedCampaignObjectiveType.MAXIMUM
    ) {
        return undefined;
    }

    // If target changed
    if (
        newFormState.campaignObjective.target !=
        oldFormState.campaignObjective.target
    ) {
        return {
            type: oldFormState.campaignObjective.type,
            target: newFormState.campaignObjective.target,
        };
    }

    // If target didn't change
    return undefined;
}

function IsEmptyObject(object: object): boolean {
    return Object.keys(object).length == 0;
}
