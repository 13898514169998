import { action, makeObservable, observable } from "mobx";
import { LocalStorageKeys } from "../constants/LocalStorageKeys";
import { Affiliate } from "../models/operation/Affiliate";
import LocalStorageService from "../services/LocalStorageService";


export class AffiliateStore {

    /**
       * Props
       */


    @observable
    public SearchedAffiliates: Affiliate[] = [];

    @observable
    public allAffiliates: Affiliate[] = [];



    /**
     * The Affiliate that is selected in the supervision campaigns page to further do operations on
     */
    @observable
    public SelectedAffiliate: Affiliate | undefined;


    /**
     * Ctor
     */
    constructor() {
        makeObservable(this);

        const selectedAffiliate = LocalStorageService.GetItemObject<Affiliate>(LocalStorageKeys.SearchedAffiliates)
        if (selectedAffiliate != undefined) {
            this.SelectedAffiliate = selectedAffiliate;
        }
    }

    @action
    public async SetSearchedAffiliates(affiliatesIds: number[]) {
        const affiliates = this.allAffiliates?.filter(affiliate => affiliatesIds.includes(affiliate.affiliateId))
        this.SearchedAffiliates = affiliates;
        //LocalStorageService.SetItemObject(LocalStorageKeys.SearchedAffiliates, affiliates);
    }

    @action
    public async SetAllAffiliates(affiliates: Affiliate[]) {
        this.allAffiliates = affiliates;/*.sort((a, b) =>
        (a.affiliateId === this.SelectedAffiliate?.affiliateId ? -1 :
             b.affiliateId == this.SelectedAffiliate?.affiliateId ? 1 : 0) || a.affiliateId - b.affiliateId
    );*/
    }

    @action
    public async SetSelectedAffiliate(affiliate: Affiliate) {
        this.SelectedAffiliate = affiliate;
        LocalStorageService.SetItemObject(LocalStorageKeys.SearchedAffiliates, affiliate);
    }

}