import { Tooltip } from "antd";
import React from "react";
import { SiAdobeacrobatreader, SiMicrosoftexcel } from "react-icons/si";

import { Box } from "@material-ui/core";

interface Props {}

const CampaignsTableIconActions = (props: Props) => {
    return (
        <Box width="80px" display="flex" justifyContent="space-between">
            <Tooltip placement="top" title={"Download Excel"}>
                <SiMicrosoftexcel
                    color="#185C37"
                    style={{ cursor: "pointer" }}
                    size={30}
                    onClick={() => {
                        window.alert("To be done");
                    }}
                />
            </Tooltip>

            <Tooltip placement="topLeft" title={"Download Adobe Data"}>
                <SiAdobeacrobatreader
                    color="#F20F00"
                    style={{ cursor: "pointer" }}
                    size={30}
                    onClick={() => {
                        window.alert("To be done");
                    }}
                />
            </Tooltip>
        </Box>
    );
};

export default CampaignsTableIconActions;
