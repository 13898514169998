import { Button, message } from "antd";
import { useContext } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import "./CampaignVendorPage.scss";

interface Props {
    onApply: () => void;
    onReset: () => void;
}

const Commands = (props: Props) => {

    const { uiStore } = useContext(RootStoreContext);
    const { onApply, onReset } = props;

    const handleApplyClick = () => {
        onApply();

    }

    const handleResetClick = () => {
        const popupBody = (
            <p>
                Are you sure you want to reset? All changes will be lost:
            </p>
        );

        uiStore.SetPopUp(
            {
                body: popupBody,
                title: "Reset",
                okClickHandler: async () => {
                    await onReset();
                },
                cancelClickHandler: () => { },
                okButtonText: "Approve",
                cancelButtonText: "Abort",
            });

    }

    return (
        <div>
            <Button
                type="primary"
                size="large"
                onClick={handleApplyClick}>
                Apply Changes
            </Button>
            <Button
                className="reset-button"
                type='default'
                size="large"
                onClick={handleResetClick}>
                Reset
            </Button>
        </div>
    );

}

export default Commands;
