import React from "react";

import { Box } from "@material-ui/core";

import { shortcutType } from "./ShortcutsData";

interface Props {
    shortcut: shortcutType
}

const ShortCutItem = (props: Props) => {
    return (
        <Box
            mb={3}
            py={4}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            style={{ borderBottom: "1px solid #001529" }}>
            <Box
                p="3px"
                style={{
                    border: "1px solid rgba(1, 20, 39, 0.4)",
                }}>
                {props.shortcut.Keys}
            </Box>
            <Box>{props.shortcut.Action}</Box>
        </Box>
    );
};

export default ShortCutItem;
