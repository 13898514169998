import { RoutesPaths } from "../../constants/RoutePaths";
import { UIStore } from "../../stores/UIStore";
import { UserStore } from "../../stores/UserStore";
import { GroupTableDto } from "./GroupTable.dto";
import { message, Typography } from "antd";
import { ActionsBuilder } from "../general/supervision-table/types";
import { GroupStore } from "../../stores/GroupStore";
import { History } from "history";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin7Line, RiSuitcaseLine } from "react-icons/ri";
import { GroupStatus } from "../../models/groups/GroupStatus";
import groupService from "../../services/groups/GroupsService";
import { IoMdCut } from "react-icons/io";
import { BiTransfer } from "react-icons/bi";

const { Text } = Typography;

type GroupActionsContext = {
    groupStore: GroupStore;
    history: History<unknown>;
    campaignId: number;
    uiStore: UIStore;
    userStore: UserStore;
};

export const GroupsActionBuilder: ActionsBuilder<GroupTableDto, GroupActionsContext> =
    ({ history, groupStore, campaignId, uiStore, userStore }) => [
        {
            text: "View Jobs",
            action: (groupTableDto) => {
                groupStore.SetSelectedGroup(
                    groupStore.GroupsResponse?.find(
                        (x) => x.groupId == groupTableDto.groupId
                    )
                );
                history.push({
                    pathname:
                        RoutesPaths.Supervision.GroupsRoutes.BuildGroupJobsRoute(campaignId, groupTableDto.groupId)
                });
            }
            ,
            Icon: RiSuitcaseLine,
            isDisplayed: () => true,
        },
        {
            text: "Delete Group",
            action: (groupTableDto) => {

                const popupBody = (
                    <p>
                        Sure you want to delete audit from campaign: {" "}
                        <Text strong>{groupTableDto.groupName}</Text> with group ID:{" "}
                        <Text strong>{groupTableDto.groupId}</Text>
                    </p>
                );

                uiStore.SetPopUp(
                    {
                        body: popupBody,
                        title: "Stem Control Request",
                        okClickHandler: async () => {
                            await groupService.DeleteAsync(campaignId, groupTableDto.groupId);
                            let auditIndex = groupStore
                                .GroupsResponse
                                .findIndex(x => x.groupId == groupTableDto.groupId);
                            groupStore.GroupsResponse[auditIndex].isActive = GroupStatus.Removed

                            message.success(
                                `Group ID:${groupTableDto.groupId} deleted`
                            );
                        },
                        okButtonText: "Accept",
                    }
                );
            },
            Icon: RiDeleteBin7Line,
            isDisplayed: () => true,

        },
        {
            text: "Edit Group",
            action: (groupTableDto) => {
                groupStore.SetSelectedGroup(
                    groupStore.GroupsResponse?.find(
                        (x) => x.groupId == groupTableDto.groupId
                    )
                );
                history.push({
                    pathname: RoutesPaths.Supervision.GroupsRoutes.BuildEditGroup(
                        campaignId,
                        groupTableDto.groupId
                    ),
                });
            },
            Icon: FiEdit,
            isDisplayed: () => true,
        },
        {
            text: "Attach Jobs",
            action: (groupTableDto) => {
                groupStore.SetSelectedGroup(
                    groupStore.GroupsResponse?.find(
                        (x) => x.groupId == groupTableDto.groupId
                    )
                );
                history.push({
                    pathname: RoutesPaths.Supervision.GroupsRoutes.BuildAttachJobsToGroup(
                        campaignId,
                        groupTableDto.groupId
                    ),
                });
            },
            Icon: BiTransfer,
            isDisplayed: () => true,
        },
        {
            text: "Vendor Management",
            action: (groupTableDto) => {
                if (!history) {
                    throw new Error("history doesn't exist in context... Can't perform redirect");
                }
                history.push(
                    RoutesPaths.Supervision.VendorManagementRoutes
                    .BuildGroupVendorManagementRoute(campaignId, groupTableDto.groupId)
                );
            },
            Icon: IoMdCut,
            isDisplayed: () => true
        }
    ]