import { Empty } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import { ActionResponse } from "../../models/users/ActionResponse";
import { EActionTypes } from "../../models/users/EActionTypes";
import { RootStore } from "../../stores/RootStore";
import { RootStoreContext } from "../../stores/RootStoreContext";
import SupervisionTable from "../general/supervision-table/SupervisionTable";
import { UserActionsTableBuilder } from "./UserActionsTable.actions";
import { UserActionsTableDto } from "./UserActionsTable.dto";

type Props = {
    userActions: ActionResponse[];
};

const UserActionsTable = (props: Props) => {
    const { userActions } = props;

    const { uiStore } = useContext(RootStoreContext);

    if (userActions.length == 0) {
        return <Empty style={{ marginTop: 50 }} />;
    }

    return (
        <SupervisionTable
            dataSource={userActions.map(
                (userAction) => new UserActionsTableDto(userAction)
            )}
            rowActionSettings={{
                actions: UserActionsTableBuilder({
                    uiStore,
                }),
                showActionsOnLastColumn: true,
                showActionsOnRightClick: true,
            }}
            categoricalColumns={[
                {
                    column: "userActionType",
                    possibleValues: EActionTypes
                }
            ]}
        />
    );
};

export default observer(UserActionsTable);
