import "./Footer.scss";

import { Box } from "@material-ui/core";

import VerticalDivider from "../../theme/vertical-divider/VerticalDivider";

interface Props {}

const Footer = (props: Props) => {
    return (
        <footer className="text text-gray">
            <Box
                py={3}
                px={4}
                data-about="wrapper"
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Box component="p">
                    © {new Date().getFullYear()} PandoLogic Inc. All rights
                    reserved
                </Box>

                <Box display="flex" className="terms-and-conditions">
                    <a
                        href="https://www.pandologic.com/privacy-policy/"
                        target="_blank">
                        Privacy Policy
                    </a>
                    <Box mx={1}>
                        <VerticalDivider height="20px" />
                    </Box>
                    <a
                        href="https://www.pandologic.com/cookie-info/"
                        target="_blank">
                        Cookie Policy
                    </a>
                    <Box mx={1}>
                        <VerticalDivider height="20px" />
                    </Box>
                    <a
                        href="https://www.pandologic.com/term-of-use/"
                        target="_blank">
                        Terms of Use
                    </a>
                </Box>
            </Box>
        </footer>
    );
};

export default Footer;
