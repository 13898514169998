import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import EntityPage from "../../../components/publisher-selection/entity-page/EntityPage";
import EntityPageAutoComplete from "../../../components/publisher-selection/entity-page-autocomplete/EntityPageAutoComplete";
import { EntityRecordDetailed } from "../../../models/EntityRecord";
import useSetCurrentRoute from "../../../hooks/useSetCurrentRoute";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { BreadCrumb } from "../../../models/BreadCrumb";
import VendorDrawer from "../../../components/publisher-selection/vendor-drawer/VendorDrawer";

/**
 * Mock Data
 */
import { groupsMock } from "../../../components/publisher-selection/mock-data/groups-list";
import {
    campaignsMock,
    EntityRecordHierarchyType,
} from "../../../components/publisher-selection/mock-data/campaigns-list";
import { jobsMock } from "../../../components/publisher-selection/mock-data/jobs-list";
import PublisherSelectionDialog from "../../../components/publisher-selection/publisher-selection-dialog/PublisherSelectionDialog";

interface Props {}

const ChooseCutOffContainer = (props: Props) => {
    const { uiStore, publisherSelectionStore } = useContext(RootStoreContext);

    const GetEntities = (): [
        EntityRecordHierarchyType<any>,
        { dataIndex: string | string[]; title: string }[]
    ] => {
        switch (publisherSelectionStore.MockActiveEntityType) {
            case "campaigns":
                return [
                    campaignsMock,
                    [
                        {
                            dataIndex: ["entityData", "campaignName"],
                            title: "Campaign Name",
                        },
                        {
                            dataIndex: ["entityData", "startDate"],
                            title: "Start Date",
                        },
                        {
                            dataIndex: ["entityData", "endDate"],
                            title: "End Date",
                        },
                    ],
                ];

            // case "groups":
            //     return [groupsMock, []];

            case "jobs":
                return [
                    jobsMock,
                    [
                        {
                            dataIndex: ["entityData", "jobTitleText"],
                            title: "Job Title",
                        },
                        {
                            dataIndex: ["entityData", "location"],
                            title: "Location",
                        },
                    ],
                ];

            default:
                throw new Error("Invalid MockActiveEntityType type");
        }
    };

    /**
     * Local State
     */

    const [entityRecordHierarchy, setEntityRecordHierarchy] = useState<
        EntityRecordHierarchyType<any>
    >({} as EntityRecordHierarchyType<any>);

    const [entityDataFiltered, setEntityDataFiltered] = useState<
        EntityRecordDetailed<any>[]
    >([]);

    const [isVendorDrawerOpen, setIsVendorDrawerOpen] =
        useState<boolean>(false);

    /**
     * Event Handlers
     */

    const HandleInputSearch = (entityNameList: string[]) => {
        if (entityNameList.length == 0) {
            setEntityDataFiltered(entityRecordHierarchy.entities);
            return;
        }

        setEntityDataFiltered(
            entityRecordHierarchy.entities.filter((entityRecord) =>
                entityNameList.some((selectValue) =>
                    selectValue
                        .toLowerCase()
                        .includes(entityRecord.entityName.toLowerCase())
                )
            )
        );
    };

    const HandleChildEntityButtonClick = (entityName: string) => {
        if (entityRecordHierarchy.entityLevelName == "Campaigns") {
            const breadCrumb = new BreadCrumb(entityName, null, () => {
                publisherSelectionStore.SetMockActiveEntityType("jobs");
                uiStore.RemoveBreadCrumbsUntil(entityName);
            });
            uiStore.AddBreadCrumbs(breadCrumb);
            publisherSelectionStore.SetMockActiveEntityType("jobs");
        }

        // if (entityRecordHierarchy.entityLevelName == "Groups") {
        //     const breadCrumb = new BreadCrumb(entityName, null, () => {
        //         publisherSelectionStore.SetMockActiveEntityType("jobs");
        //         uiStore.RemoveBreadCrumbsUntil(entityName);
        //     });
        //     uiStore.AddBreadCrumbPath(breadCrumb);
        //     publisherSelectionStore.SetMockActiveEntityType("jobs");
        // }
    };

    const HandleVendorButtonClick = (record: EntityRecordDetailed) => {
        publisherSelectionStore.SetSelectedEntity(record);
        setIsVendorDrawerOpen(true);
    };

    const HandleDrawerClose = () => {
        publisherSelectionStore.RemoveSelectedEntity();
        setIsVendorDrawerOpen(false);
    };

    /**
     * Hooks
     */

    useEffect(() => {
        const [_entityRecordHierarchy] = GetEntities();
        setEntityRecordHierarchy(_entityRecordHierarchy);
        setEntityDataFiltered(_entityRecordHierarchy.entities);
    }, [publisherSelectionStore.MockActiveEntityType]);

    useSetCurrentRoute();

    useEffect(() => {
        const rootContainerBreadCrumb = new BreadCrumb(
            "Select Cut Offs",
            null,
            () => {
                publisherSelectionStore.SetMockActiveEntityType("campaigns");
                uiStore.RemoveBreadCrumbsUntil(rootContainerBreadCrumb);
            }
        );

        uiStore.AddBreadCrumbs(rootContainerBreadCrumb);
    }, []);

    /**
     * Render
     */

    if (Object.keys(entityRecordHierarchy).length == 0) {
        return <div>loading...</div>;
    }

    return (
        <Box>
            <Box mb={2}>
                <Typography variant="h6" component="h2">
                    {entityRecordHierarchy.entityLevelName}
                </Typography>
            </Box>

            <Box mb={2}>
                <EntityPageAutoComplete
                    HandleInputChange={HandleInputSearch}
                    entityData={entityRecordHierarchy.entities}
                />
            </Box>

            <EntityPage
                columns={GetEntities()[1]}
                entityData={entityDataFiltered}
                entityName={entityRecordHierarchy.entityLevelName}
                HandleChildEntityButtonClick={HandleChildEntityButtonClick}
                HandleVendorButtonClick={HandleVendorButtonClick}
            />

            <VendorDrawer
                isOpen={isVendorDrawerOpen}
                onClose={HandleDrawerClose}
            />

            <PublisherSelectionDialog />
        </Box>
    );
};

export default observer(ChooseCutOffContainer);
