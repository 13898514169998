import { Button, Col, message, Row, Typography } from "antd";
import { useContext, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useHistory } from "react-router-dom";
import { RoutesPaths } from "../../constants/RoutePaths";
import { FetchError } from "../../exceptions/AlgoSupervisionExceptions";
import { GroupJobResponse } from "../../models/groups/GroupJobResponse";
import { GroupJobsRequest } from "../../models/groups/GroupJobsRequest";
import groupJobsService from "../../services/groups/GroupJobsService";
import { RootStoreContext } from "../../stores/RootStoreContext";
import JobTransfer from "../jobs/JobTransfer";



interface Props {
    campaignId: number;
    groupId: number;
}



const GroupsAttachJobsForm = (props: Props) => {

    const { campaignId, groupId } = props;
    const [campaignJobsIds, setCampaignJobsIds] = useState<number[]>([]);
    const { userStore, groupStore } = useContext(RootStoreContext);
    const history = useHistory();
    const handleAttachJobsClick = async () => {
        let groupCreateRequest: GroupJobsRequest = {
            campaignId: campaignId,
            isActive: true,
            userId: userStore.User?.id,
            groupId: groupId,
            groupName: groupStore.SelectedGroup?.groupName || "",
            jobsList: campaignJobsIds,
        };
        try {
            await groupJobsService.AttachedDetachedAsync(groupCreateRequest);
            message.success("Successfully attach jobed to campaign");
            history.push(
                RoutesPaths.Supervision.GroupsRoutes.BuildGroupRoute(
                    campaignId
                )
            );
        } catch (error) {
            if (error instanceof FetchError) {
                message.error(error.apiError.errorMessage);
            }
        }
    };

    return (
        <div>
            <Typography.Title level={4} style={{ marginBottom: 15 }}>
                Attach Jobs for Group
            </Typography.Title>
            <JobTransfer
                onChange={(jobIds) => {
                    setCampaignJobsIds(jobIds);
                }}
                plCampaignId={campaignId}
                groupId={groupId}
            />
            <div>
                <Button
                    type="primary"
                    onClick={handleAttachJobsClick}
                    style={{ marginTop: 50, marginRight: 15 }}>
                    Attach Jobs
                </Button>
                <Button type="default" style={{ marginTop: 50 }}>
                    <CopyToClipboard
                        text={campaignJobsIds.join(",")}
                        onCopy={() => {
                            message.info("Copied to clipboard");
                        }}>
                        <span>Copy Active Job Ids</span>
                    </CopyToClipboard>
                </Button>
            </div>
        </div>

    )

}

export default GroupsAttachJobsForm