import React, { useContext, useEffect } from 'react';
import { Typography, Switch, Select, Radio, Tooltip } from 'antd';
import { EAppGoalMode } from '../../models/company-attributes/attributes-types/EAppGoalMode';
import { EPacingMode } from '../../models/company-attributes/attributes-types/EPacingMode';
import {
    StyledCheckbox,
    StyledColumnGroupWrapper, StyledContainer, StyledHeader, StyledInputGroupWrapper, StyledInputLabel, StyledInputNumberWrapper,
    StyledModeWrapper, StyledSubSection, StyledTitle,
    StyledToggleWrapper
} from './Styles';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/RootStoreContext';
import Caps from './Caps';

const { Option } = Select;



const AlgoModes = () => {

    const { attributesManagementStore } = useContext(RootStoreContext);
    const { CompanyAttributesAggregation, CompanyProfile } = attributesManagementStore;
    const { pacingMode, reachAppGoalMode,
        manualDistribution, agencyMode,
        holdback, expressWay, autoPilot,avoidSoftLaunchJobsCarouselCAP } = CompanyAttributesAggregation;



    useEffect(() => {

    }, [CompanyAttributesAggregation,
        CompanyAttributesAggregation.pacingMode,
        CompanyAttributesAggregation.reachAppGoalMode,
        CompanyAttributesAggregation.manualDistribution,
        CompanyAttributesAggregation.agencyMode,
        CompanyAttributesAggregation.expressWay,
        CompanyAttributesAggregation.autoPilot,
        CompanyAttributesAggregation.avoidSoftLaunchJobsCarouselCAP,
        CompanyProfile]);

    const onChangePacingMode = (e: any) => {

        if (e.target.value == EPacingMode.Accelerated && holdback?.isHoldback) {
            return;
        }

        if (pacingMode) {
            pacingMode.pacingModeType = e.target.value;
            attributesManagementStore.onChangeAttribute(pacingMode);
        }
    };

    const onChangeReachAppGoalMode = (value: EAppGoalMode) => {
        if (reachAppGoalMode) {
            reachAppGoalMode.appGoalMode = value;
            attributesManagementStore.onChangeAttribute(reachAppGoalMode);
        }
    };

    const onChangeManualDistribution = () => {
        if (manualDistribution) {
            let isStopDistribution = manualDistribution.stopDistribution;
            manualDistribution.stopDistribution = !isStopDistribution;
            attributesManagementStore.onChangeAttribute(manualDistribution);
        }
    };

    const onChangeAgencyModeNumberOfDays = (value: any) => {
        if (agencyMode) {
            agencyMode.cap = value;
            attributesManagementStore.onChangeAttribute(agencyMode);
        }
    };

    const onIsHoldback = () => {
        if (holdback && pacingMode?.pacingModeType != EPacingMode.Accelerated) {
            holdback.isHoldback = !holdback.isHoldback;
            attributesManagementStore.onChangeAttribute(holdback);
        }
    }

    const onIsExpressWay = () => {
        if (expressWay) {
            expressWay.isExpressWay = !expressWay.isExpressWay;
            attributesManagementStore.onChangeAttribute(expressWay);
        }
    }

    const onIsAutoPilot = () => {
        if (autoPilot) {
            autoPilot.isAutoPilot = !autoPilot.isAutoPilot;
            attributesManagementStore.onChangeAttribute(autoPilot);
        }
    }

    const onIsAvoidSoft = () => {
        if (avoidSoftLaunchJobsCarouselCAP) {
            avoidSoftLaunchJobsCarouselCAP.isAvoidSoftLaunchJobsCarouselCAP = !avoidSoftLaunchJobsCarouselCAP.isAvoidSoftLaunchJobsCarouselCAP;
            attributesManagementStore.onChangeAttribute(avoidSoftLaunchJobsCarouselCAP);
        }
    }

    return (
        <StyledColumnGroupWrapper>
            <StyledHeader>Algo strategy</StyledHeader>
            <StyledContainer>
                <StyledTitle>Modes</StyledTitle>
                <StyledSubSection>
                    <StyledInputLabel>Pacing</StyledInputLabel>
                    <Radio.Group onChange={onChangePacingMode} value={pacingMode?.pacingModeType}>
                        <Tooltip
                            title={
                                pacingMode?.pacingModeType == EPacingMode.Paced && holdback?.isHoldback
                                    ? 'Acceleration is not available for Holdback accounts'
                                    : undefined
                            }
                        >
                            <Radio value={EPacingMode.Accelerated}>Accelerated</Radio>
                        </Tooltip>
                        <Radio value={EPacingMode.Paced}>Paced</Radio>
                    </Radio.Group>
                </StyledSubSection>
                <StyledSubSection>
                    <StyledInputLabel>Bidding</StyledInputLabel>
                    <Tooltip
                        title={
                            pacingMode?.pacingModeType == EPacingMode.Accelerated
                                ? 'Holdback is not available for accelerated accounts'
                                : undefined
                        }
                    >
                        <StyledCheckbox
                            onClick={onIsHoldback}
                            checked={holdback?.isHoldback} >HoldBack
                        </StyledCheckbox>
                    </Tooltip>
                </StyledSubSection>
                <StyledSubSection>
                    <StyledInputLabel>Agency</StyledInputLabel>
                    <StyledModeWrapper>
                        <StyledInputNumberWrapper
                            min={0}
                            onChange={onChangeAgencyModeNumberOfDays}
                            value={agencyMode?.cap} />
                        <StyledInputLabel>Days</StyledInputLabel>
                    </StyledModeWrapper>
                </StyledSubSection>
                <StyledSubSection>
                    <StyledInputLabel>Reach app goal mode</StyledInputLabel>
                    <StyledToggleWrapper>
                        <Select style={{ width: '100%' }}
                            onChange={onChangeReachAppGoalMode} value={reachAppGoalMode?.appGoalMode}>
                            <Option value={EAppGoalMode.Stop}>
                                Stop
                            </Option>
                            <Option value={EAppGoalMode.NoStop}>
                                No Stop
                            </Option>
                            <Option value={EAppGoalMode.Minimal}>
                                Minimal
                            </Option>
                        </Select>
                    </StyledToggleWrapper>
                </StyledSubSection>
                <StyledInputLabel>Stop distribution</StyledInputLabel>
                <StyledModeWrapper>
                    <StyledInputLabel>Off</StyledInputLabel>
                    <Switch checked={manualDistribution?.stopDistribution}
                        onClick={onChangeManualDistribution} />
                    <StyledInputLabel>On</StyledInputLabel>
                </StyledModeWrapper>
                <StyledInputLabel>Additional functionality</StyledInputLabel>
                <StyledInputGroupWrapper>
                    <StyledSubSection>
                        <StyledCheckbox
                            onClick={onIsAutoPilot}
                            checked={autoPilot?.isAutoPilot} >Is AutoPilot
                        </StyledCheckbox>
                    </StyledSubSection>
                    <StyledSubSection hidden={CompanyProfile?.businessUnit == "Amazon DSP"}>
                        <StyledCheckbox
                            onClick={onIsAvoidSoft}
                            checked={avoidSoftLaunchJobsCarouselCAP?.isAvoidSoftLaunchJobsCarouselCAP} >Avoid Soft Launch
                        </StyledCheckbox>
                    </StyledSubSection>
                    <StyledSubSection hidden={CompanyProfile?.businessUnit != "Express Pros"}>
                        <StyledCheckbox
                            onClick={onIsExpressWay}
                            checked={expressWay?.isExpressWay} >Is Express Way
                        </StyledCheckbox>
                    </StyledSubSection>
                </StyledInputGroupWrapper>
            </StyledContainer>
            <Caps />
        </StyledColumnGroupWrapper>

    );
};

export default observer(AlgoModes);
