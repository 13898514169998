import { Dropdown, Menu } from "antd";
import { observer } from "mobx-react-lite";

import { DOMCoordinates } from "../../../types/types";
import { RightClickData, RowAction } from "./types";

const defaultDropdownCoordinates: DOMCoordinates = {
    xPosition: 230,
    yPosition: 240,
};

interface Props<T> {
    rightClickData: RightClickData<T> | undefined;
    dropDownMenu: RowAction<T>[];
}

const RightClickDropdown = <T extends object>(props: Props<T>) => {
    const { rightClickData,dropDownMenu } = props;

    if (!rightClickData) {
        return <></>;
    }

    const dropdownMenu = (
        <Menu
            style={{
                top:
                    rightClickData.clickCoordinates.yPosition -
                    defaultDropdownCoordinates.yPosition,
                left:
                    rightClickData.clickCoordinates.xPosition -
                    defaultDropdownCoordinates.xPosition,
            }}>
            {dropDownMenu.map((data, index) => (
                data.isDisplayed(rightClickData.clickedRowData)
                &&
                <Menu.Item
                    key={index}
                    icon={<data.Icon />}
                    onClick={() => {
                        data.action(rightClickData.clickedRowData)
                    }}>
                    {data.text}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <div style={{ width: 200 }}>
            <Dropdown arrow visible={true} overlay={dropdownMenu}>
                <div></div>
            </Dropdown>
        </div>
    );
};

export default observer(RightClickDropdown);
