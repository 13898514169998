import { Link, useHistory, useParams } from "react-router-dom";
import { ISODateTime } from "../../../types/types";
import { observer } from "mobx-react-lite";
import usePageInitialization from "../../../hooks/usePageInitialization";
import GroupsTable from "../../../components/groups/GroupsTable";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import GroupsBreadCrumbs from "./helpers/GroupsBreadCrumbs";
import { GroupsContextInitializers } from "./helpers/GroupsContextInitializers ";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { useContext } from "react";
import { Button } from "antd";
import { BiPlusCircle } from "react-icons/bi";
import { RoutesPaths } from "../../../constants/RoutePaths";

interface Props {}

const GroupPage = (props: Props) => {
  const match = useParams<{ campaignId: string }>();
  const { groupStore } = useContext(RootStoreContext);
  const history = useHistory();

  usePageInitialization({
    onInitAsync: async () => {
      await GroupsContextInitializers.InitializeGroupData(
        Number(match.campaignId),
        groupStore,
        history
      );
      await GroupsContextInitializers.InitializeGroupCampaign(
        Number(match.campaignId),
        groupStore,
        history
      );
    },

    onCompanyContextCallBack: (company, uiStore) => {
      uiStore.SetPageTitle(
        `Groups for: ${groupStore.SelectedCampaign?.campaignName}`
      );
      uiStore.AddBreadCrumbs(
        SupervisionBreadcrumbs.Companies(company.companyName),
        SupervisionBreadcrumbs.Campaigns(
          `${groupStore.SelectedCampaign?.campaignName}`
        ),
        GroupsBreadCrumbs.Groups(
          Number(groupStore.SelectedCampaign?.campaignID)
        )
      );
    },
  });

  return (
    <div>
      <GroupsTable
        groups={groupStore.GroupsResponse}
        campaignId={Number(match.campaignId)}
      />

      <div style={{ width: 270 }}>
        <Link
          to={RoutesPaths.Supervision.GroupsRoutes.BuildAddGroup(
            Number(match.campaignId)
          )}
        >
          <Button
            type="primary"
            shape="round"
            size="large"
            style={{ display: "flex", alignItems: "center" }}
          >
            <BiPlusCircle size={20} style={{ marginRight: "10px" }} />
            <span> Add New Group</span>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default observer(GroupPage);
