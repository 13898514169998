import { History } from "history";
import { IoMdCut } from "react-icons/io";
import { SiIndeed } from "react-icons/si";
import { CgData } from "react-icons/cg";
import { MdGroupWork } from "react-icons/md";

import { RoutesPaths } from "../../../constants/RoutePaths";
import { ActionsBuilder } from "../../general/supervision-table/types";
import { CampaignTableDto } from "./CampaignTable.dto";

type CampaignsTableActionsContext = {
    history: History<unknown>;
}

export const CampaignsTableActionsBuilder: ActionsBuilder<CampaignTableDto, CampaignsTableActionsContext> =
    ({ history }) => [
        {
            text: "Indeed Management",
            action: (campaign) => {
                if (!history) {
                    throw new Error("history doesn't exist in context... Can't perform redirect");
                }

                history.push(
                    RoutesPaths.Supervision.IndeedRoutes.BuildCampaignRoute(campaign.CampaignId)
                );
            },
            Icon: SiIndeed,
            isDisplayed: () => true
        },
        /* NOT ACTIVE
        {
            text: "Campaign Settings",
            action: (campaign) => {
                if (!history) {
                    throw new Error("history doesn't exist in context... Can't perform redirect");
                }

                history.push(
                    RoutesPaths.Supervision.CampaignTrackSystemRoutes.BuildCampaignRoute(campaign.CampaignId)
                );
            },
            Icon: CgData,
            isDisplayed: () => true
        },
        */
        {
            text: "Groups",
            action: (campaign) => {
                if (!history) {
                    throw new Error("history doesn't exist in context... Can't perform redirect");
                }

                history.push(
                    RoutesPaths.Supervision.GroupsRoutes.BuildGroupRoute(campaign.CampaignId)
                );
            },
            
            Icon: MdGroupWork,
            isDisplayed: () => true
        },
        /*
        {
            text: "Vendor Management",
            action: (campaign) => {
                if (!history) {
                    throw new Error("history doesn't exist in context... Can't perform redirect");
                }

                history.push(
                   RoutesPaths.Supervision.VendorManagementRoutes.BuildCampaignVendorManagementRoute(campaign.CampaignId)
                  // RoutesPaths.PublisherSelection.ChooseCutOff
                );
            },
            Icon: IoMdCut,
            isDisplayed: () => true
        }
        */
    ]