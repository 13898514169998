import { message, Typography } from "antd";
import { History } from "history";
import { AiOutlineRobot } from "react-icons/ai";
import { BiTransfer } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { RiSuitcaseLine } from "react-icons/ri";

import { UserIds } from "../../constants/IAM";
import { RoutesPaths } from "../../constants/RoutePaths";
import { IndeedUpdateCampaignBudgetEventPayload } from "../../models/indeed-manager/IndeedUpdateCampaignBudgetEventPayload";
import { EActionTypes } from "../../models/users/EActionTypes";
import { userActionService } from "../../services/actions/UserActionService";
import indeedService from "../../services/indeed/IndeedService";
import { IndeedStore } from "../../stores/IndeedStore";
import { UIStore } from "../../stores/UIStore";
import { UserStore } from "../../stores/UserStore";
import { ActionsBuilder } from "../general/supervision-table/types";
import { IndeedCampaignTableDto } from "./IndeedCampaignsTable.dto";

const { Text } = Typography;

type IndeedCampaignActionsContext = {
    indeedStore: IndeedStore;
    history: History<unknown>;
    plCampaignId: number;
    uiStore: UIStore;
    userStore: UserStore;
};

export const IndeedCampaignActionsBuilder: ActionsBuilder<
    IndeedCampaignTableDto,
    IndeedCampaignActionsContext
> = ({ history, indeedStore, plCampaignId, uiStore, userStore }) => [
    {
        text: "View Jobs",
        action: (indeedCampaign) => {
            indeedStore.SetSelectedIndeedCampaign(
                indeedStore.PLCampaignData?.indeedCampaigns.find(
                    (x) => x.id == indeedCampaign.id
                )
            );
            history.push({
                pathname:
                    RoutesPaths.Supervision.IndeedRoutes.BuildCampaignJobsRoute(
                        plCampaignId,
                        indeedCampaign.id
                    ),
            });
        },
        Icon: RiSuitcaseLine,
        isDisplayed: () => true,
    },
    {
        text: "Attach Jobs",
        action: (indeedCampaign) => {
            indeedStore.SetSelectedIndeedCampaign(
                indeedStore.PLCampaignData?.indeedCampaigns.find(
                    (x) => x.id == indeedCampaign.id
                )
            );
            history.push({
                pathname:
                    RoutesPaths.Supervision.IndeedRoutes.BuildAttachJobsToIndeedCampaign(
                        plCampaignId,
                        indeedCampaign.id
                    ),
            });
        },
        Icon: BiTransfer,
        isDisplayed: () => true,
    },
    {
        text: "Edit Campaign",
        action: (indeedCampaign) => {
            indeedStore.SetSelectedIndeedCampaign(
                indeedStore.PLCampaignData?.indeedCampaigns.find(
                    (x) => x.id == indeedCampaign.id
                )
            );
            history.push({
                pathname:
                    RoutesPaths.Supervision.IndeedRoutes.BuildEditIndeedCampaign(
                        plCampaignId,
                        indeedCampaign.id
                    ),
            });
        },
        Icon: FiEdit,
        isDisplayed: () => true,
    },
    {
        text: "Assign to Algo (Stem It)",
        action: async (indeedCampaign) => {
            const popupBody = (
                <p>
                    Stem is about to take control over campaign:{" "}
                    <Text strong>{indeedCampaign.name}</Text> with ID:{" "}
                    <Text strong>{indeedCampaign.id}</Text>
                    <br />
                    <Text>
                        Which is currently managed by:{" "}
                        <Text strong> {indeedCampaign.user}</Text>
                    </Text>
                </p>
            );

            const updateCampaignBudgetPayload: IndeedUpdateCampaignBudgetEventPayload =
                {
                    indeedCampaignId: indeedCampaign.id,
                    userId: UserIds.Algo,
                    budgetOnetimeLimit: Number(
                        indeedCampaign.budget.replace("$", "")
                    ),
                };

            uiStore.SetPopUp({
                body: popupBody,
                title: "Stem Control Request",
                okClickHandler: async () => {
                    await indeedService.UpdateCampaignBudget(
                        updateCampaignBudgetPayload
                    );

                    await userActionService.Create({
                        actionTypeId: EActionTypes.IndeedAlgoControlRequest,
                        data: JSON.stringify({
                            campaignId: plCampaignId,
                            indeedCampaignId: indeedCampaign.id,
                        }),
                        userId: userStore.User!.id,
                    });

                    message.success(
                        `Indeed Campaign ID:${indeedCampaign.id} returned control to Algo`
                    );
                },
                okButtonText: "Accept",
            });
        },
        Icon: AiOutlineRobot,
        isDisplayed: (indeedCampaign) => indeedCampaign.user != "Algo",
    },
];
