import { Tooltip } from "antd";
import React from "react";

import { Box } from "@material-ui/core";

import { RowActionSettings } from "./types";

type Props<T> = {
    rowActionSettings?: RowActionSettings<T>;
};

const BuildActionsColumn = <T extends object>(columnOptions: Props<T>) => {
    const { rowActionSettings } = columnOptions;

    const actionsColumn: any = {
        title: "Actions",
        render: (text: any, record: T) => (
            <Box display="flex">
                {rowActionSettings &&
                    rowActionSettings.actions.map(
                        (action) =>
                            action.isDisplayed(record) && (
                                <Box
                                    key={action.text}
                                    ml={2}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => action.action(record)}>
                                    <Tooltip
                                        placement="top"
                                        title={action.text}>
                                        <action.Icon />
                                    </Tooltip>
                                </Box>
                            )
                    )}
            </Box>
        ),
    };

    return actionsColumn;
};

export default BuildActionsColumn;
