
import { action, makeObservable, observable } from "mobx";

import { UnauthorizedError } from "../exceptions/AlgoSupervisionExceptions";
import { User } from "../models/users/User";
import { userService } from "../services/users/UserService";

export class UserStore {

    @observable
    public User?: User;

    constructor() {
        makeObservable(this);
    }


    @action
    public async SetUser(user: User) {
        this.User = user;
    }
}