import { Box } from "@material-ui/core";
import { Button, Col, Form, Input, message, Row, Select, Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { useContext, useState } from "react";
import { FiInfo } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { RoutesPaths } from "../../constants/RoutePaths";
import { FetchError } from "../../exceptions/AlgoSupervisionExceptions";
import { BudgetType } from "../../models/groups/BudgetType";
import { GroupBudgetBalance } from "../../models/groups/GroupBudgetBalance";
import { GroupCreateRequest } from "../../models/groups/GroupCreateRequest";
import { GroupStatus } from "../../models/groups/GroupStatus";
import GroupsService from "../../services/groups/GroupsService";
import { RootStoreContext } from "../../stores/RootStoreContext";
import JobTransfer from "../jobs/JobTransfer";

const { Option } = Select;

type FormState = {
    groupName: string;
    isActive: GroupStatus;
    budget: number;
    budgetType: BudgetType;
};

interface Props {
    campaignId: number;
    isStatusEditable: boolean;
    groupBudgetBalance: GroupBudgetBalance;
}


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};



const GroupAddForm = (props: Props) => {

    const {
        campaignId,
        isStatusEditable,
        groupBudgetBalance
    } = props;

    const { userStore, groupStore } = useContext(RootStoreContext);
    const [campaignJobsIds, setCampaignJobsIds] = useState<number[]>([]);
    const history = useHistory();
    const onFinish = async (formState: FormState) => {
        try {

            let groupCreateRequest: GroupCreateRequest = {

                campaignId: campaignId,
                groupName: formState.groupName,
                isActive: true,
                userId: userStore.User?.id,
                jobsList: campaignJobsIds,
                budgetType: formState.budgetType,
                amount: formState.budget
            };

            await GroupsService.CreateAsync(groupCreateRequest);
            history.push(
                RoutesPaths.Supervision.GroupsRoutes.BuildGroupRoute(
                    campaignId
                )
            );
            message.success("Group creation requested successfully");

        } catch (error) {
            if (error instanceof FetchError) {
                message.error(error.apiError.errorMessage);
            }
        }
    };


    return (
        <Box width={650}>
            <Form
                {...layout}
                name="Add Group"
                onFinish={onFinish}>
                <Row style={{ marginBottom: "5px" }}>
                    <Col offset={2}>
                        <Typography.Title level={4}>
                            General Details
                        </Typography.Title>
                    </Col>
                </Row>
                <Form.Item
                    name={"groupName"}
                    label="Group Name"
                    rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name={"groupStatus"}
                    label="Status"
                    initialValue={GroupStatus.Active}
                    rules={[{ required: true }]}>
                    <Select style={{ width: "100%" }} disabled={!isStatusEditable}>
                        <Option value={GroupStatus.Active}>Active</Option>
                        <Option value={GroupStatus.Removed}>Removed</Option>
                    </Select>
                </Form.Item>

                <Row style={{ marginTop: "35px", marginBottom: "5px" }}>
                    <Col offset={2}>
                        <Typography.Title level={4}>
                            Budget Settings (Client) 
                        </Typography.Title>
                    </Col>
                </Row>
                <Form.Item
                    name="budgetType"
                    label="Budget Type"
                    rules={[{ required: false }]}>
                    <Select
                        allowClear>
                        <Option title="that the group can reach the budget amount, but it is not mandatory"
                            value={BudgetType.Capped}>
                            <FiInfo style={{ marginRight: '5' }} />
                            Capped
                        </Option>
                        <Option title="The budget amount is intended only for the current group"
                            value={BudgetType.Fixed}>
                            <FiInfo style={{ marginRight: '5' }} />
                            Fixed
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="budget"
                    label="Budget"
                    dependencies={['budgetType']}
                    rules={[
                        {
                            required: false,

                        },
                        ({ getFieldValue }) => ({
                            validator(ruleObject, value, err) {
                                if (value < 0) {
                                    return Promise.reject(
                                        "Value must be greater than 0"
                                    );
                                }
                                if (value > groupBudgetBalance.campaignBalance && getFieldValue("budgetType") == BudgetType.Fixed) {
                                    return Promise.reject(
                                        `Value must be equal or less than ${groupBudgetBalance.campaignBalance}`
                                    );
                                }
                                if (value > groupBudgetBalance.campaignBudget && getFieldValue("budgetType") == BudgetType.Capped) {
                                    return Promise.reject(
                                        `Value must be equal or less than ${groupBudgetBalance.campaignBudget}`
                                    );
                                }
                                return Promise.resolve();
                            }
                        })
                    ]}>
                    <Input prefix={"$"} type="number" />
                </Form.Item>

                <Row style={{ marginTop: "50px" }}>

                    <Typography.Title
                        level={4}
                        style={{ marginBottom: 15 }}>
                        Choose Jobs for Group
                    </Typography.Title>

                    <JobTransfer
                        onChange={(jobIds) => {
                            setCampaignJobsIds(jobIds);
                        }}
                        plCampaignId={campaignId}
                    />
                    <Form.Item
                        wrapperCol={{ offset: 0, span: 16 }}
                        style={{ marginTop: 50 }}>
                        <Button type="primary" htmlType="submit">
                            Create Group
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Box >
    );
}


export default GroupAddForm;