import settings from "../../config/AppSettings/SettingsFactory";
import { ManualActionRequestSource } from "../../models/manual-actions/ManualActionRequestSource";
import { Affiliate } from "../../models/operation/Affiliate";
import { EntityVendorRequest } from "../../models/vendor-management/EntityVendorRequest";
import { EntityVendorResponse } from "../../models/vendor-management/EntityVendorResponse";
import { EntityDistributionSettings } from "../../models/vendor-management/settings/EntityDistributionSettings";
import { EntityDistributionSettingsResponse } from "../../models/vendor-management/settings/EntityDistributionSettingsResponse";
import { BaseService } from "../BaseService";



export class AffiliateVendorService extends BaseService {

  async GetAllAsync(affiliateId: number): Promise<EntityVendorResponse[]> {
    const vendorManagementdata = await this.Get<EntityVendorResponse[]>(`/all/${affiliateId}`);
    return vendorManagementdata;
  }

  async SetAffiliatesVendors(vendorsWithDecision: EntityVendorRequest[], affiliateId: number, 
    manualActionRequestSource: ManualActionRequestSource) {

    const vendorManagementdata = await this.Post<EntityVendorRequest[]>(`/${affiliateId}?manualActionRequestSource=${manualActionRequestSource}`, vendorsWithDecision);
    return vendorManagementdata;
  }

  async GetAffiliateSettingsAsync(affiliateId: number) {
    const vendorManagementdata = await this.Get<EntityDistributionSettings>(`/settings/${affiliateId}`);
    return vendorManagementdata;
  }

  async SetAffiliateDistributionSettings(entityStateAttributeRequest: EntityDistributionSettings, affiliateId: number) {
    const dataState = await this
      .Post<EntityDistributionSettings>(`/settings/${affiliateId}`, entityStateAttributeRequest);
    return dataState;
  }

  async GetEffectedEntitesChangeModeAsync(entityDistributionSettings: EntityDistributionSettings, affiliateId: number) {
    const entitySettingsResponse = await this.Post<EntityDistributionSettingsResponse>(
      `/settings-validation/${affiliateId}`,
      entityDistributionSettings
    );
    return entitySettingsResponse;
  }


}

const affiliateVendorService = new AffiliateVendorService("AffiliateVendors", settings.vendorManagementUrl);
export default affiliateVendorService;