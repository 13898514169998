import { makeObservable, observable } from 'mobx';
import { BreadCrumb } from './BreadCrumb';
import React, { ReactNode } from 'react';
import { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

export class RouteModel {
    public id: string;

    // Make properties observable
    public routePath: string;
    public renderComponent: React.ReactElement;
    public mainTitle: string;
    public breadCrumbsPrefix: BreadCrumb[];
    public isAutoRouteSetup: boolean;

    constructor(
        routePath: string,
        renderComponent: React.ReactElement,
        mainTitle: string,
        breadCrumbsPrefix: BreadCrumb[],
        isAutoRouteSetup: boolean = true
    ) {
        this.id = uuidv4();
        this.routePath = routePath;
        this.renderComponent = renderComponent;
        this.mainTitle = mainTitle;
        this.breadCrumbsPrefix = breadCrumbsPrefix;
        this.isAutoRouteSetup = isAutoRouteSetup;

        // Make the class observable
        makeObservable(this, {
            routePath: observable,
            renderComponent: observable,
            mainTitle: observable,
            breadCrumbsPrefix: observable,
            isAutoRouteSetup: observable,
        });
    }
}