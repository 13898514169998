import { Table } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";

import { TableColumn } from "./types";

type Props<T> = {
    dataSource: T[];
    tableColumns: TableColumn[];
    pageData: readonly (T & {
        key: number;
    })[];
};

const FillRestOfCellsEmpty = (tableColumns: TableColumn[]) => {
    return (
        <>
            {/* We need to skip first to indexes because they are taken for the summary text */}
            {tableColumns.map((_, i) => {
                if (i < 2) {
                    return;
                }
                return (
                    <Table.Summary.Cell key={i} index={i}></Table.Summary.Cell>
                );
            })}
            <Table.Summary.Cell key={tableColumns.length} index={tableColumns.length}></Table.Summary.Cell>
        </>
    );
};

const TableFooter = <T extends object>(props: Props<T>) => {
    const { dataSource, tableColumns, pageData } = props;
    return (
        <Table.Summary fixed>
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                    Contains: {dataSource.length} rows
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}/>
                {FillRestOfCellsEmpty(tableColumns)}
            </Table.Summary.Row>
        </Table.Summary>
    );
};

export default observer(TableFooter);
