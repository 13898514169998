import React, { useContext, useEffect, useState } from 'react';
import { Button, Segmented, Switch, message } from 'antd';
import VendorManagmentMode from './VendorManagmentMode';
import { RootStoreContext } from '../../stores/RootStoreContext';
import { observer } from 'mobx-react-lite';
import { Box } from "@material-ui/core";
import { ValidAction } from '../../models/ValidAction';
import { EntityDistributionSettings } from '../../models/vendor-management/settings/EntityDistributionSettings';
import companyVendorsService from "../../services/vendor-management/CompanyVendorService";


interface Props {
    onValid: (newSettings: EntityDistributionSettings) => Promise<ValidAction>;
  }
  

const PanelControl = (props: Props) => {
    const { vendorManagementStore, uiStore } = useContext(RootStoreContext);
    const [isInheritable, setIsInheritable] = useState<string>();
    const { onValid } = props;


    useEffect(() => {
  
        if (vendorManagementStore.EntitySettings) {
            setIsInheritable(BoolToMode(vendorManagementStore.EntitySettings.isInheritable));
        }
    }, [vendorManagementStore.EntitySettings]);

    const handleIsInheritChange = async (value: any) => {
        let isInheritableBoolValue = ModeToBool(value);
        let validAction = await actionIsValid(isInheritableBoolValue);

        if (validAction.isValid) {
            setIsInheritable(value);
            vendorManagementStore.SetEntityStateIsInheritable(isInheritableBoolValue);
        }
        else {
            const popupBody = (
                <p>
                    {validAction.errorMessage.toString()}
                </p>
            );
            uiStore.SetPopUp(
                {
                    body: popupBody,
                    title: "Change Work Mode",
                    okClickHandler: async () => {
                        message.error(
                            `Can't change mode`
                        );
                    },
                    okButtonText: "Ok",
                })
        }
    }

    const actionIsValid = async (value: boolean) => {

        let newSettings = {...vendorManagementStore.EntitySettings};
        newSettings.classificationType = vendorManagementStore.EntitySettings.classificationType
        newSettings.isInheritable = value;

        let validActionReponse = await onValid(newSettings);

        return validActionReponse;
    }

    const BoolToMode = (isInheritable: boolean) => isInheritable ? 'Inherit' : 'Not Inherit';
    const ModeToBool = (isInheritable: string) => isInheritable == 'Inherit' ? true : false;

    return (
        <Box display="flex">
            <Segmented
                value={isInheritable}
                onChange={handleIsInheritChange}
                style={{ fontWeight: 'bold' }}
                options={['Inherit', 'Not Inherit']}
            />
            <Box marginLeft="16px">
                <VendorManagmentMode onValid={onValid} />
            </Box>
        </Box>
    );
}

export default observer(PanelControl);
