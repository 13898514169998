import { CampaignSettingsAuditDto } from "../../components/campaign-track-system/CampaignSettingsAudit.dto";
import { IndeedCampaignObjective } from "./IndeedCampaignObjective";
import { IndeedCampaignStatus } from "./IndeedCampaignStatus";

export class IndeedUpdateCampaignEventPayload {
    constructor(
        public indeedCampaignId: string,
        public userId: number,
        public name?: string,
        public status?: IndeedCampaignStatus,
        public objective?: IndeedCampaignObjective
    ) { }
}
